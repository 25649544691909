import { useEffect } from "react";
import Nav from "../components/Nav";
import grow from "../assets/landshop_grow.jpg";
import growLogo from "../assets/landshop_grow_logo.png";
import PageHero from "../common/components/PageHero";
import { GrowBeta, GrowMorgage } from "../components/Grow";
import { FooterBlack } from "../components/FooterBlack";

export const Grow = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="h-auto">
      <Nav />
      <PageHero
        bannerBg={grow}
        pageLogo={growLogo}
        title="The 2-in-1 Mortgage!"
        description="Own a Home Now or Rent-To-Own"
      />
      <div className=" h-auto px-4 flex flex-col gap-y-8">
        <div className="md:gap-y-3 flex flex-col items-center justify-center gap-y-5 py-7 ">
          <div className="">
            <h3 className="font-bold text-black font-urbanist text-xl">
              <span className="sm:text-3xl text-primaryColor1 text-xl text-urbanist font-bold">
                Grow Property
              </span>{" "}
              Equals Easy Mortgage
            </h3>
          </div>
          <div className="">
            <p className="text-md font-urbanist text-center max-w-2xl mx-auto">
              Grow Property is our flagship mortgage product that offers
              Nigerians access to fast and affordable mortgages for quick home
              ownership at super low interest rates and extended repayment
              periods.
            </p>
          </div>
        </div>
        <GrowMorgage />
        <GrowBeta />
      </div>
      <div className="pt-8">
        <FooterBlack />
      </div>
    </div>
  );
};
