import React from "react";
import { useUserInvestsQuery } from "../store/api";
import DashboardItem from "../components/DashboardItem";
import { PropagateLoader } from "react-spinners";
import { useAppSelector } from "../store";

function UserInvest() {
  // @ts-ignore
  const id = useAppSelector((state) => state.auth?.user?._id);
  const { data, isLoading } = useUserInvestsQuery(id);

  return (
    <>
      {isLoading ? (
        <div className="h-full col-span-full flex items-center justify-center">
          <PropagateLoader />
        </div>
      ) : data.userInvests.data.length > 0 ? (
        data.userInvests.data.map((itm: any) => (
          <DashboardItem
            key={itm._id}
            description={itm.description}
            duration_month="12"
            image={itm.images}
            location={itm.state}
            name={itm.title}
            product={itm.format}
            roi={itm.return_of_investment}
            status={itm.paid ? 100 : 0}
            type={itm.property_type}
          />
        ))
      ) : (
        <p className="h-full col-span-full flex items-center justify-center">
          No Data Found
        </p>
      )}
    </>
  );
}

export default UserInvest;
