import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";
import routes from "../../common/routes";
import { tail } from "../../common/utils/helper";
import logo from "../../assets/loginImage.png";

function AuthLayout() {
  const { pathname } = useLocation();
  return (
    <div className="relative xl:max-w-screen-xl lg:h-screen lg:overflow-y-scroll lg:flex lg:items-center lg:justify-start 2xl:justify-center xl:justify-center xl:mx-auto h-screen">
      <div
        className={tail(
          "lg:h-full lg:bg-cover lg:bg-no-repeat lg:flex lg:flex-col lg:items-start lg:justify-start lg:flex-1 p-3 lg:p-11",
          pathname === routes.nonprotected.login
            ? "lg:bg-bgsignin lg:bg-auto lg:bg-top"
            : "lg:bg-bgregister relative after:content-authEl2 after:absolute  before:content-authEl1 before:absolute before:bottom-0 before:left-0 after:top-1/4 after:left-1/2 after:-translate-y-1/4 after:translate-x-1/2 before:opacity-40 after:opacity-40",
          pathname === routes.nonprotected.forgot_password ? "lg:bg-center" : " "
        )}
      >
        <Link
          to={
            pathname !== routes.nonprotected.login
              ? routes.nonprotected.login
              : routes.public.home
          }
        >
          <img src={logo} alt="" className="lg:w-32 lg:h-16 object-contain h-4 w-auto" />
        </Link>

        {pathname !== routes.nonprotected.forgot_password ? (
          <>
            {pathname === routes.nonprotected.login ? (
              <div className="hidden lg:flex flex-col items-start justify-center gap-y-3 max-w-sm flex-1 font-poppins">
                <h2 className="xl:text-[3.5rem] xl:leading-[4rem] lg:text-2xl  lg:text-white lg:font-semibold">
                  Welcome to Landshop
                </h2>
                <h6 className="xl:text-lg lg:text-xs lg:text-white lg:font-normal">
                  Please enter your login details to proceed
                </h6>
              </div>
            ) : (
              <div className="hidden lg:flex items-center justify-center self-center flex-1">
                <blockquote className="text-white max-w-[473px]">
                  <p className="after:content-quoteClose relative after:absolute after:-bottom-20 before:content-quoteOpen before:absolute before:-top-10 before:w-7 leading-9 text-xl mx-auto">
                    The passage experienced a surge in popularity during the
                    1960s when Letraset used it on their dry-transfer sheets,
                    and again during the 90s as desktop publishers bundled the
                    text with their software.
                  </p>
                  <footer className="flex items-center gap-x-2.5 mt-5">
                    <span>Vincent Obi</span>
                    <span className="bg-white rounded-full">
                      <BsCheckCircleFill className="fill-[#08AD36]" />
                    </span>
                  </footer>
                </blockquote>
              </div>
            )}
          </>
        ) : null}
      </div>
      <div
        className={tail(
          pathname !== routes.nonprotected.forgot_password
            ? "flex-1"
            : "lg:absolute lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2  flex items-center justify-center h-full"
        )}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
