import React from "react";
import TandC3 from "../components/Grow/TandC3";

const TermsandCondition3 = () => {
  return (
    <div>
      <TandC3 />
    </div>
  );
};

export default TermsandCondition3;
