
import {RecentPost} from '../data'
import RecentEach from './RecentEach'

interface Post {
  title: { rendered: string }
  content: { rendered: string }
  rttpg_author: { display_name: string }
  excerpt: { rendered: string }
  date: string,
  link: string,
	rttpg_featured_image_url: string

}

const RecentCard = (props: any) => {
  return (
	<div className='xl:grid xl:grid-cols-2 xl:place-content-center xl:gap-x-2 xl:gap-y-9 xl:w-[744px] xl:mt-[2rem]
  lg:grid lg:grid-cols-2 lg:place-content-center lg:gap-x-2 lg:gap-y-9 lg:w-[744px] lg:mt-[2rem] lg:overflow-x-hidden lg:pb-8 lg:bg-transparent
  xs:flex xs:w-full xs:oveflow-x-scroll xs:flex-row  xs:align-center xs:gap-x-9
  
  '>
       {props.posts && props.posts.slice(0, 4).map((data: Post) => (
          <RecentEach new = {data}/>

	   ))}

	</div>
  )
}

export default RecentCard