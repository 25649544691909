import React from "react";
import { Carousel } from "react-responsive-carousel";
import PropertyCard from "../../common/components/PropertyCard";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import useSliderCards from "../../common/hooks/useSliderCards";
import { tail } from "../../common/utils/helper";
import { GrowGenType } from "../../typings";

type Ibtn = {
  click: VoidFunction;
  has: boolean;
  type: string;
};

const CarouselBtn = ({ click, has, type }: Ibtn) => {
  return (
    <>
      {has && (
        <button
          className={tail(
            "lg:w-14 lg:h-14 h-8 w-8 rounded-full bg-[#E9EFF2] flex items-center justify-center text-3xl text-[#666666CC] absolute  top-1/2 z-[2]",
            type === "next" ? "-right-2 lg:-right-14" : "-left-2 lg:-left-14"
          )}
          onClick={click}
        >
          {type === "next" ? (
            <BiChevronRight className="cursor-pointer" />
          ) : (
            <BiChevronLeft className="cursor-pointer" />
          )}
        </button>
      )}
    </>
  );
};

type Props = {
  title: string;
  img?: string;
  subtitle: string;
  moreinfo: string[];
  data: GrowGenType[];
  cardBtnClick: (itm: GrowGenType) => void;
};

function GrowCard({
  img,
  moreinfo,
  subtitle,
  title,
  data,
  cardBtnClick,
}: Props) {
  const group = new Array(6).fill(".");
  const centerPecentage = useSliderCards({ items: data });
  return (
    <section className="flex flex-col gap-y-12 items-center">
      <header className="flex flex-col items-center">
        <h4 title={title}>
          <img src={img} alt={title} />
        </h4>
        <p>({subtitle})</p>
      </header>
      <div className="lg:w-[990px] md:w-[690px] flex flex-col gap-y-4">
        {moreinfo.map((text, idx) => (
          <p className="text-sm font-poppins " key={idx}>
            {text}
          </p>
        ))}
      </div>
      <section className="w-full lg:max-w-4xl xl:max-w-6xl lg:mx-auto md:py-9 md:px-6 lg:border border-[#D9D9D9] lg:rounded-3xl ">
        {data.length === 0 ? (
          <p className="text-center" >*No Property Available</p>
        ) : (
          <Carousel
            dynamicHeight={false}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            centerMode
            centerSlidePercentage={centerPecentage}
            renderArrowNext={(click, hasNext) => (
              <CarouselBtn click={click} has={hasNext} type="next" />
            )}
            renderArrowPrev={(click, hasPrev) => (
              <CarouselBtn click={click} has={hasPrev} type="prev" />
            )}
          >
            {data.map((info) => (
              <PropertyCard
                key={info._id}
                features={info.features}
                format={info.format}
                images={info.images}
                location={info.city}
                name={info.property_name}
                size={info.size}
                cardBtnClick={() => cardBtnClick(info)}
              />
            ))}
          </Carousel>
        )}
      </section>
    </section>
  );
}

export default GrowCard;
