import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import BannerOne from "../assets/landshop_grow.jpg";
import BannerTwo from "../assets/landshop_invest.png";
import BannerThree from "../assets/landshop_develop.png";

// import "./styles.css";

// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";

const BannerSlide = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[EffectFade, Autoplay, Navigation, Pagination]}
        className="mySwiper"
      >
        <div className="w-[100%]">
          <SwiperSlide>
            {/* <img src="https://swiperjs.com/demos/images/nature-1.jpg" /> */}
            <Link to="/grow">
              <img className="lg:hidden md:block sm:h-[500px] xs: h-[300px] object-fill object-right" src={BannerOne} alt="" />

              <div className="cursor-pointer bg-bgHomePage w-full h-[570px] bg-no-repeat bg-cover lg:block xs:hidden sm:hidden md:hidden"></div>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            {/* <img src="https://swiperjs.com/demos/images/nature-2.jpg" /> */}
            <Link to="/invest">
              <img className="lg:hidden md:block sm:h-[500px] xs: h-[300px] object-fill object-right" src={BannerTwo} alt="" />
              <div className="cursor-pointer bg-bgHomeGif w-[100%] h-[570px] bg-no-repeat bg-cover lg:block xs:hidden sm:hidden md:hidden"></div>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            {/* <img src="https://swiperjs.com/demos/images/nature-3.jpg" /> */}
            <Link to="/develop">
              <img className="lg:hidden md:block sm:h-[500px] xs: h-[300px] object-fill object-right" src={BannerThree} alt="" />
              <div className="cursor-pointer bg-bgHomeDevelop w-full h-[570px] bg-no-repeat bg-cover lg:block xs:hidden sm:hidden md:hidden"></div>
            </Link>
          </SwiperSlide>
        </div>
      </Swiper>
    </>
  );
};

export default BannerSlide;
