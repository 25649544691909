import axios from "axios";
import React, { useEffect, useState } from "react";
import logo from "../../assets/mortgage_logo.png";
import { GrowGenType } from "../../typings";
import { API } from "../../utils/backend";
import GrowCard from "./GrowCard";
import GrowForm from "./GrowForm";

export const GrowMorgage = () => {
  const [grow, setGrow] = useState<GrowGenType[]>([]);
  const [moreInfo, setMoreInfo] = useState<GrowGenType | null>(null);

  useEffect(() => {
    const getGrows = async () => {
      try {
        const growResponse = await axios.get(`${API}grows?mortgage_type=extra`);
        setGrow(growResponse?.data?.data?.grows?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getGrows();
  }, []);

  const handleCloseModal = () => {
    setMoreInfo(null);
  };
  const handleOpenModal = (item: GrowGenType) => {
    setMoreInfo(item);
  };

  return (
    <>
      <GrowCard
        img={logo}
        moreinfo={[
          "So why continue the tenant lifestyle in your current abode when you can switch to a comfier life right now. At no taxing cost",
        ]}
        subtitle="Pay rent to own a House"
        title="morgage"
        data={grow}
        cardBtnClick={handleOpenModal}
      />
      <GrowForm data={moreInfo} handleCloseModal={handleCloseModal} />
    </>
  );
};
