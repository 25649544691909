import React from "react";
import { Carousel } from "react-responsive-carousel";
import { tail } from "../utils/helper";
import Amenities from "./Amenities";

type Props = {
  name: string;
  location: string;
  features: string[];
  images: string[];
  size: string;
  format: string;
  cardBtnClick?: VoidFunction;
  type?: "develop";
};

function PropertyCard({
  name,
  location,
  features,
  format,
  images,
  size,
  cardBtnClick,
  type,
}: Props) {
  return (
    <div className="pt-2 px-2.5 pb-5 bg-white border border-[#D9D9D9] rounded-2xl lg:mx-2.5 max-w-sm h-full">
      <img src={images?.[0]} className="w-full h-64 rounded-lg object-cover" />
      <div className="pb-8 pt-4 px-3 flex flex-col gap-y-1.5 items-start">
        <h5 className="font-bold text-base ">{name}</h5>
        <p className="text-xs px-2 py-1 bg-[#17B28D] text-white rounded-sm ">
          {location}
        </p>
        <p className="text-[10px] py-1 px-2 bg-black text-white rounded-sm ">
          {`${size?.trim()} | ${format?.toUpperCase()}`}
        </p>
      </div>
      <div className="grid grid-cols-4 md:px-6 px-3 ">
        {features?.slice(0, 4)?.map((feature, id) => (
          <div key={id} className="flex flex-col items-center ">
            <Amenities type={feature} />
            <p className="text-[10px] text-center capitalize whitespace-pre-wrap">
              {feature}
            </p>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-y-12 pt-6">
        <div className="">
          <Carousel
            dynamicHeight={false}
            showThumbs={false}
            showStatus={false}
            centerMode
            centerSlidePercentage={33.3333}
            autoPlay
            infiniteLoop
            showArrows={false}
            renderIndicator={(click, isSelected) => (
              <div
                onClick={click}
                className={tail(
                  "w-1.5 h-1.5 rounded-full bg-[#D9D9D9]",
                  isSelected && "bg-[#F48120]"
                )}
              />
            )}
          >
            {images?.map((img, id) => (
              <img
                key={id}
                src={img}
                alt="more of property"
                className="h-12 object-contain rounded-md mx-1"
              />
            ))}
          </Carousel>
        </div>

        <button
          onClick={cardBtnClick}
          className=" self-center px-2 h-7 text-center bg-[#F48120] text-white text-xs font-medium rounded"
          type="button"
        >
          {type === "develop" ? "Buy Now" : "See more"}
        </button>
      </div>
    </div>
  );
}

export default PropertyCard;
