import React from "react";
import { useAppSelector } from "../store";
import { FaUserCircle } from "react-icons/fa";
import { TbLoaderQuarter } from "react-icons/tb";
import { tail } from "../common/utils/helper";

type Props = {
  className?: string;
  hassBorder?: boolean;
  isLoading?: boolean;
  sourceImage?: string;
};

function Avatar({ className, hassBorder, isLoading, sourceImage }: Props) {
  const profile_picture = useAppSelector(
    (state) => state.auth?.user?.profile_picture
  );
  return (
    <>
      {(profile_picture || sourceImage) && !isLoading ? (
        <img
          src={sourceImage || profile_picture}
          alt="profile"
          className={tail(
            "w-40 h-40 rounded-full bg-gray-10 object-cover",
            hassBorder ? "border-[6px] border-orange-10" : null,
            className
          )}
        />
      ) : null}
      {!(profile_picture || sourceImage) && !isLoading ? (
        <FaUserCircle className={tail("w-40 h-40 rounded-full", className)} />
      ) : null}
      {isLoading ? (
        <div
          style={{ backgroundImage: `url(${profile_picture})` }}
          className={tail(
            "w-40 h-40 rounded-full bg-gray-10 flex items-center justify-center bg-cover bg-center",
            profile_picture && hassBorder
              ? "border-[6px] border-orange-10"
              : "",
            className
          )}
        >
          <TbLoaderQuarter size={40} className="animate-spin" />
        </div>
      ) : null}
    </>
  );
}

export default Avatar;
