import React, { useState } from "react";
import image2 from "../../assets/miniTandC.png";

import growImg from "../../assets/growShow.png";
import { ModalGrow } from "./ModalGrow";
import Button from "../Button";

const TandC3 = () => {
  const [modal, setModal] = useState<boolean>(false);

  const handleModal = () => setModal(true);
  const handleCloseModal = () => setModal(false);

  return (
    <div className=" flex items-center justify-center bg-bgTerms w-full h-[100vh]">
      {/* <img src={image} alt="" /> */}

      <div className="flex items-center justify-center bg-white shadow-2xl rounded-md w-[1056px] h-[785px]">
        <div className="flex flex-col items-center justify-center gap-y-9">
          <div>
            <img src={image2} alt="" />
          </div>
          <div className="flex items-center justify-center">
            <div>
              <img src={growImg} alt="" />
            </div>
            <div className="flex flex-col items-center gap-y-3">
              <div className="w-[505px] h-[53px]">
                <h6 className="text-center font-bold text-primaryColor1">
                  Green Island Merit Mews Excision (Via Lagos Government
                  Gazette)
                </h6>
              </div>
              <div className="flex items-center justify-center gap-x-9">
                <div className="flex flex-col items-center justify-center gap-y-9 w-[209px] h-[286px]">
                  <div className="flex items-center justify-center w-[600px]">
                    <div>
                      <h6 className="text-black text-xs font-poppins">Price</h6>
                    </div>
                  </div>
                  <div className="flex items-center justify-center ">
                    <div>
                      <h6 className="text-black text-xs font-poppins">
                        Repayment Amount
                      </h6>
                    </div>
                  </div>
                  <div className="flex items-center justify-center ">
                    <div>
                      <h6 className="text-black text-xs font-poppins">
                        Repayment Duration
                      </h6>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <div>
                      <h6 className="text-black text-xs font-poppins">
                        Interest rate
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center gap-y-9 w-[184px] h-[286px]">
                  <div className="flex items-center justify-center ">
                    <div>
                      <h6 className="text-black text-xs font-poppins">
                        NGN 17,000,000.00
                      </h6>
                    </div>
                  </div>
                  <div className="flex items-center justify-center ">
                    <div>
                      <h6 className="text-black text-xs font-poppins">
                        NGN 1,360,000.00
                      </h6>
                    </div>
                  </div>
                  <div className="flex items-center justify-center ">
                    <div>
                      <h6 className="text-black text-xs font-poppins">
                        12.5 Months
                      </h6>
                    </div>
                  </div>
                  <div className="flex items-center justify-center ">
                    <div>
                      <h6 className="text-black text-xs font-poppins">8%</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center gap-x-9 mt-5">
                <Button myStyle="decline" onClick={handleModal}>
                  Decline{" "}
                </Button>
                <Button myStyle="growButton2">Accept</Button>
              </div>
            </div>
          </div>

          {/* 					
					<div className='flex items-center justify-center gap-x-9'>
                       <Button  myStyle="decline" >Decline </Button>
					   <Button  myStyle="growButton2" >Accept</Button>
					</div> */}
        </div>
      </div>
      {modal ? (
        <ModalGrow close={handleCloseModal}>
          <div className="bg-white shadow-sm w-[657px] h-[501px] rounded-sm flex flex-col items-center justify-center gap-y-2">
            <div>
              <img src={image2} alt="" />
            </div>
            <div>
              <h6 className="text-sm">Wait for real?</h6>
            </div>
            <div>
              <h6>Are you sure you want to decline this sweet package </h6>
            </div>
            <div>
              <button className="w-[240px] h-[60px] bg-primaryColor1 rounded-lg text-white">
                Yes
              </button>
            </div>
            <div>
              <button className="w-[240px] h-[60px] bg-[#C4C4C4] rounded-lg text-white">
                Cancel
              </button>
            </div>
          </div>
        </ModalGrow>
      ) : (
        ""
      )}
    </div>
  );
};

export default TandC3;
