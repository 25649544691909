import React from "react";

type Props = {
  image: string;
  name: string;
  roi: string;
  duration_month: string;
  location: string;
  type: string;
  product: string;
  description: string;
  status: number;
};

function DashboardItem(props: Props) {
  return (
    <div className="text-[0.625rem] py-2 rounded-2.5 shadow-propertyCard bg-white border">
      <div className="px-1">
        <div className="relative before:absolute before:bottom-0 before:right-0 before:left-0 before:h-12 before:bg-black/70 before:z-[1] before:blur ">
          <img
            src={props.image}
            alt={`property ${props.name}`}
            className="h-48 w-full border border-orange-10 rounded object-cover "
          />
          <h5 className="absolute bottom-1 left-5 text-white text-xs max-w-[50%] z-[2]">
            {props.name}
          </h5>
        </div>
      </div>
      <div className="px-2">
        <div className="py-2.5">
          <p className="">{props.status}%</p>
          <div className="h-1 w-full mt-1 bg-primaryColor1" />
        </div>
        <div className="p-1.5 border-b border-gray-40 flex justify-between">
          <div className="flex flex-col gapy-1.5 items-center">
            <p className="text-gray-20  ">R.O.I</p>
            <p className="text-xs font-semibold text-black ">{props.roi}% pa</p>
          </div>
          <div className="flex flex-col gapy-1.5 items-center">
            <p className="text-gray-20  ">Duration</p>
            <p className="text-xs font-semibold text-black ">
              {props.duration_month} Months
            </p>
          </div>
        </div>
        <div className="pt-2 pb-3.5 flex flex-col gap-y-3 border-b border-gray-40">
          <div className="flex items-center justify-between">
            <p className="text-primaryColor1">Location</p>
            <p className="text-gray-70">{props.location}</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-primaryColor1">Type</p>
            <p className="text-gray-70">{props.type}</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-primaryColor1">Product</p>
            <p className="text-gray-70">{props.product}</p>
          </div>
        </div>
        <p className="pt-2 text-[#8692A6]">{props.description}</p>
      </div>
    </div>
  );
}

export default DashboardItem;
