import React, { useState } from "react";
import Nav from "../components/Nav";
import GreenComma from "../assets/DevPro/green_comma.png";
import EstatePro from "../assets/DevPro/estate_logo.png";
import bgEstate from "../assets/DevPro/estatepro.png";
import BannerPro from "../assets/DevPro/devbanner.png";
import devPro from "../assets/DevPro/devpro.png";
import bgDev from "../assets/DevPro/prodev.png";
import OrangeComma from "../assets/DevPro/orange_comma.png";
import devBanner from "../assets/DevPro/devbanner2.png";
import WorkOne from "../assets/DevPro/work1.png";
import WorkTwo from "../assets/DevPro/work2.png";
import WorkThree from "../assets/DevPro/work3.png";
import WorkFour from "../assets/DevPro/work4.png";
import ProgressOne from "../assets/DevPro/devwork1.jpg";
import ProgressTwo from "../assets/DevPro/devwork2.jpg";
import ProgressThree from "../assets/DevPro/devwork3.jpg";
import ProgressFour from "../assets/DevPro/devwork4.jpg";
import ProgressFive from "../assets/DevPro/devwork5.jpg";
import EstateProOne from "../assets/DevPro/estate_progress1.jpg";
import EstateProTwo from "../assets/DevPro/estate_progress2.jpg";
import EstateProThree from "../assets/DevPro/estate_progress3.jpg";
import EstateProFour from "../assets/DevPro/estate_progress4.jpg";
import EstateProFive from "../assets/DevPro/estate_progress5.jpg";
import EstateBanner from "../assets/DevPro/estate_banner.png";
import EstateOne from "../assets/DevPro/estate_work1.png";
import EstateTwo from "../assets/DevPro/estate_work2.png";
import EstateThree from "../assets/DevPro/estate_work3.png";
import EstateFour from "../assets/DevPro/estate_work4.png";
import EstateFive from "../assets/DevPro/estate_work5.png";
import FAQ from "../assets/DevPro/FAQ.png";
import plus from "../assets/plus.png";
import minus from "../assets/minus.png";
import { FooterBlack } from "../components/FooterBlack";
import { ModalDevPro } from "../components/ModalDevPro";
import Swal from "sweetalert2";
import { API } from "../utils/backend";
import axios from "axios";
import { PropagateLoader } from "react-spinners";
import ModernCalendar from "../components/ModernCalendar";
import Upload from "../assets/DevPro/upload.png";
import { ModalEstatePro } from "../components/ModalEstatePro";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useForm, SubmitHandler } from "react-hook-form";

const DevPro = () => {
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);
  const [expanded6, setExpanded6] = useState(false);
  const [expanded7, setExpanded7] = useState(false);
  const [expanded8, setExpanded8] = useState(false);
  const [devModal, setDevModal] = useState<boolean>(false);
  const [estateModal, setEstateModal] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>("");
  const [midddleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [address, setAddress] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [occupation, setOccupation] = useState<string>("");
  const [designation, setDesignation] = useState<string>("");
  const [income, setIncome] = useState<number>(1);
  const [estateName, setEstateName] = useState<string>("");
  const [developmentLevy, setDevelopmentLevy] = useState<number>(1);
  const [levyRequest, setLevyRequest] = useState<string>("");
  const [duration, setDuration] = useState<number>(1);
  const [format, setFormat] = useState<string>("");
  const [constructionAnount, setConstructionAmount] = useState<number>(1);
  const [repayAmount, setRepayAmount] = useState<number>(1);
  const [plots, setPlots] = useState<number>(1);
  const [estateN, setEstateN] = useState<string>("");

  const [dateValue, dateChange] = useState<any>(new Date());

  const handleSelectEstate = (e: any) => {
    setEstateName(e.target.value);
    // console.log(estateName);
  };

  const handleSelectDuration = (e: any) => {
    setDuration(parseInt(e.target.value));
    console.log(duration);
  };

  const handleSelectFormat = (e: any) => {
    setFormat(e.target.value);
    // console.log(format);
  };

  const navigate = useNavigate();

  const handleDevModal = () => setDevModal(true);
  const handleDevCloseModal = () => setDevModal(false);

  const handleEstateModal = () => setEstateModal(true);
  const handleEstateCloseModal = () => setEstateModal(false);

  interface IFormInputs {
    firstName: string;
    lastName: string;
  }

  const onSubmitDev: SubmitHandler<IFormInputs> = async () => {
    setLoading(true);
    try {
      const body = {
        id_url:
          "https://landshopappbucket.s3.amazonaws.com/aboutBanner1.png-1674143370114.png",
        first_name: firstName,
        last_name: lastName,
        middle_name: midddleName,
        email: email,
        phone: phone,
        date_of_birth: dateValue,
        residential_address: address,
        marital_status: status,
        occupation: occupation,
        designation: designation,
        annual_income: income,
        estate_name: estateName,
        next_of_kin_name: "next first",
        next_of_kin_phone: "next phone",
        next_of_kin_email: "nextmail@mail.com",
        next_of_kin_address: "34, Odogboolu, Jakande Estate",
        next_of_kin_means_of_identification: "NIN",
        next_of_kin_id_url:
          "https://landshopappbucket.s3.amazonaws.com/aboutBanner1.png",
        interest_rate: 30,
      };

      // console.log(body);
      const response = await axios.post(`${API}pro/submit/dev`, body);
      // console.log(response);
      if (response.status === 200) {
        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Successful",
        });

        setFirstName("");
        setMiddleName("");
        setLastName("");
        setEmail("");
        dateChange("");
        setPhone("");
        setAddress("");
        setStatus("");
        setOccupation("");
        setDesignation("");
        setIncome(1);
        setEstateName("");
        setDuration(1);
        setFormat("");
        setDevelopmentLevy(1);
        setLevyRequest("");

        navigate("/devtermsconditions");
      }
    } catch (error) {
      setLoading(false);
    }

    // console.log(data);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>();

  const onSubmitEstate: SubmitHandler<IFormInputs> = async () => {
    setLoading(true);
    try {
      const body = {
        id_url:
          "https://landshopappbucket.s3.amazonaws.com/aboutBanner1.png-1674143370114.png",
        first_name: firstName,
        last_name: lastName,
        middle_name: midddleName,
        email: email,
        phone: phone,
        date_of_birth: dateValue,
        residential_address: address,
        marital_status: status,
        occupation: occupation,
        designation: designation,
        annual_income: income,
        estate_name: estateN,
        repayment_duration: duration,
        repayment_format: format,
        next_of_kin_name: "next first",
        next_of_kin_phone: "next phone",
        next_of_kin_email: "nextmail@mail.com",
        next_of_kin_address: "34, Odogboolu, Jakande Estate",
        next_of_kin_means_of_identification: "NIN",
        next_of_kin_id_url:
          "https://landshopappbucket.s3.amazonaws.com/aboutBanner1.png",
        number_of_plots: plots,
        construction_amount: constructionAnount,
        repayment_amount: 30,
      };

      // console.log(body);
      const response = await axios.post(`${API}pro/submit/estate`, body);
      // console.log(response);
      if (response.status === 200) {
        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Successful",
        });

        setFirstName("");
        setMiddleName("");
        setLastName("");
        setEmail("");
        dateChange("");
        setPhone("");
        setAddress("");
        setStatus("");
        setOccupation("");
        setDesignation("");
        setIncome(1);
        setEstateN("");
        setDuration(1);
        setFormat("");
        setConstructionAmount(1);
        setRepayAmount(1);
        setPlots(1);

        navigate("/estatetermsconditions");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="overflow-x-hidden">
      <Nav />

      {/* <div className="flex pro-container pt-[5rem] px-[2rem] overflow-x-hidden justify-evenly">
        <div className="">
          <div className=" relative pl-[4rem]">
            <img className="w-[300px]" src={EstatePro} alt="" />
          </div>
          <div className="relative top-[-30px]">
            <img className="float-left  comma" src={GreenComma} alt="" />
            <p className="w-[250px] ml-[2rem] mt-[2rem] real-text float-left font-poppins opacity-50">
              Real wealth is tangible things produced with tangible effort.
              Loans made out of thin-air 'money' require no effort and are
              entirely ephemeral
            </p>
          </div>
          <div className="relative estate-re left-[72px]">
            <p className="float-left w-[100%] mt-[2rem] text-estate pb-[1rem] text-primaryColor2 font-black leading-relaxed  text-[35px] ">
              EstatePro
            </p>
          </div>
          <div>
            <img
              className="absolute top-[570px] background-img2 left-[180px] w-[700px]"
              src={bgEstate}
              alt=""
            />
          </div>
        </div>
        <div className="pl-[2rem]">
          <img className="w-[380px]" src={BannerPro} alt="" />
        </div>
        <div className="">
          <img className="w-[300px] mt-[-30px]" src={devPro} alt="" />
          <p className="w-[280px] mt-[-20px] bad-debt ml-[2rem] float-left font-poppins opacity-50">
            Bad debt is debt that makes you poorer
          </p>
          <img
            className="mt-[-35px]  comma comma-orange ml-[10px] float-right"
            src={OrangeComma}
            alt=""
          />
          <div className="relative">
            <img
              className="absolute top-[80px] background-img right-[100px] w-[500px]"
              src={bgDev}
              alt=""
            />
            <p className="float-left text-dev w-[100%] mt-[9rem] pb-[1rem] text-primaryColor1 font-black leading-relaxed  text-[35px] ">
              DevPro
            </p>
          </div>
        </div>
      </div> */}

      <div className="image-container flex pb-[2rem] mt-[0rem] px-[2rem] bg-[#f6f6f6] justify-between">
        <div className="flex justify-center items-center">
          <img className="image-2" src={devBanner} alt="" />
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="relative">
            <img className="w-[500px]" src={bgDev} alt="" />
            <img
              className="absolute top-[-10px] right-[-40px]"
              src={devPro}
              alt=""
            />
          </div>
          <div className="w-[700px] banner-2 mt-[3rem] text-right font-poppins">
            <p className="opacity-50">
              As part of any proper estate development, infrastructural
              constructions of drainage system, security fencing, road network,
              recreational and nature facilities etc are not only essential
              requirements, they attract considerable investments by the
              developer, funded via development fees. Mostly pooled from the
              estate subscribers.
            </p>
            <br />
            <p className="opacity-50">
              And because it can be daunting for the client to pay the
              development fee, DevPro is Landshop’s credit solution to help
              clients pay their development levy. With a 3-year repayment
              duration.
            </p>
            <br />
            <p className="opacity-50">
              Developed in partnership with highly credible financial
              institutions, this is available to all existing clients.
            </p>
            <br />
            <button
              onClick={handleDevModal}
              className=" outline-0 bg-primaryColor1 opacity-100 px-[18px] py-[12px] text-white border rounded-lg font-bold font-poppins"
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>

      <div className="">
        <div className="bg-bgDevWork bg-no-repeat bg-cover w-[full] h-[350px]">
          <div className="text-center text-white pt-[5rem] ">
            <p className="text-4xl font-bold">How it Works</p>
            <p className="text-lg mt-[1rem]">
              Devpro works in the best way possible that you can imagine{" "}
            </p>
          </div>
        </div>
        <div className="flex card-1 mx-auto w-[1200px] px-[2rem] relative top-[-100px] justify-between">
          <div className=" relative w-[262px] h-[380px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor1 border-2 rounded-full text-primaryColor1">
                1
              </span>
            </div>
            <img className="img-1" src={WorkOne} alt="" />
            <p className="my-[1rem] font-medium">Application</p>
            <p className="text-center opacity-50 text-sm">Apply for DevPro</p>
          </div>
          <div className=" relative w-[262px] h-[380px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor1 border-2 rounded-full text-primaryColor1">
                2
              </span>
            </div>
            <img className="img-1" src={WorkTwo} alt="" />
            <p className="my-[1rem] font-medium">Profiling</p>
            <p className="text-center opacity-50 text-sm">
              Get profiled by Landshop/financial institution
            </p>
          </div>
          <div className=" relative w-[262px] h-[380px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor1 border-2 rounded-full text-primaryColor1">
                3
              </span>
            </div>
            <img className="img-1" src={WorkThree} alt="" />
            <p className="my-[1rem] font-medium">Credit</p>
            <p className="text-center opacity-50 text-sm">
              Get up to credit to finance your estate development levy
            </p>
          </div>
          <div className=" relative w-[262px] h-[380px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor1 border-2 rounded-full text-primaryColor1">
                4
              </span>
            </div>
            <img className="img-1" src={WorkFour} alt="" />
            <p className="my-[1rem] font-medium">Repayment</p>
            <p className="text-center opacity-50 text-sm">
              Repay loan over 3 years on a monthly, quarterly, annual basis
            </p>
          </div>
        </div>
      </div>

      <div className="infrastructure mt-[4rem]">
        <p className="font-poppins font-bold text-2xl text-center mb-[3rem]">
          Infrastructural Constructions{" "}
          <span className="text-primaryColor2">Progress Status</span>
        </p>
        <div className=" card-2 flex justify-between w-[1100px] mx-auto">
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor2"
            src={ProgressOne}
            alt=""
          />
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor2"
            src={ProgressTwo}
            alt=""
          />
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor2"
            src={ProgressThree}
            alt=""
          />
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor2"
            src={ProgressFour}
            alt=""
          />
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor2"
            src={ProgressFive}
            alt=""
          />
        </div>
      </div>

      <div className="image-container2 mt-[8rem] flex bg-[#f6f6f6] px-[2rem] py-[2rem] justify-between">
        <div className="flex flex-col justify-center items-center">
          <div className="relative">
            <img className=" w-[500px]" src={bgEstate} alt="" />
            <img
              className="imag-2 absolute top-[-20px] left-[-140px]"
              src={EstatePro}
              alt=""
            />
          </div>
          <div className="w-[700px] banner-2 mt-[3rem] font-poppins">
            <p className="opacity-50">
              You have acquired the plot of land, but you are stalling on
              constructing because funding is a constraint. EstatePro powers the
              property construction for estate subscribers nationwide.
            </p>
            <br />
            <p className="opacity-50">
              This simply provides you with the credit facility to build your
              dream home.
            </p>
            <br />
            <p className="opacity-50">
              That’s because we understand the cost burden of building a home
              from scratch, the inflationary traits of materials that accompany
              the extended period of construction – it’s why we partnered with
              financial heavyweights + PAC Trustee, to ease the load and help
              you start building in earnest.
            </p>
            <br />
            <p className="opacity-50">
              Request an EstatePro loan to fund the construction of your next
              project in any of Landshop’s estate. With a convenient repayment
              period of 15 years at an interest rate of 6%.
            </p>
            <br />
            <button
              onClick={handleEstateModal}
              className=" outline-0 bg-primaryColor2 opacity-100 px-[18px] py-[12px] text-white border rounded-lg font-bold font-poppins"
            >
              Apply Now
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <img className="image-2" src={EstateBanner} alt="" />
        </div>
      </div>

      <div className="">
        <div className="bg-bgEstateWork bg-no-repeat bg-cover w-[full] h-[350px]">
          <div className="text-center text-white pt-[5rem] ">
            <p className="text-4xl font-bold">How it Works</p>
            <p className="text-lg mt-[1rem]">
              EstatePro works in the best way possible that you can imagine{" "}
            </p>
          </div>
        </div>
        <div className="card-3 flex mx-auto w-[1200px] px-[2rem] relative top-[-100px] justify-between">
          <div className="relative w-[200px] h-[340px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor2 border-2 rounded-full text-primaryColor2">
                1
              </span>
            </div>
            <img className="img-3" src={EstateOne} alt="" />
            <p className="my-[1rem] font-medium">Application</p>
            <p className="text-center opacity-50 text-sm">
              Apply for Estate Pro
            </p>
          </div>
          <div className="relative w-[200px] h-[340px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor2 border-2 rounded-full text-primaryColor2">
                2
              </span>
            </div>
            <img className="img-3" src={EstateTwo} alt="" />
            <p className="my-[1rem] font-medium">Profiling</p>
            <p className="text-center opacity-50 text-sm">
              Get profiled by Landshop/financial institution
            </p>
          </div>
          <div className="relative w-[200px] h-[340px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor2 border-2 rounded-full text-primaryColor2">
                3
              </span>
            </div>
            <img className="img-3" src={EstateThree} alt="" />
            <p className="my-[1rem] font-medium">Prepayment</p>
            <p className="text-center opacity-50 text-sm">
              Pay 30% equity of the value of your proposed property (within
              first 2 months of applying)
            </p>
          </div>
          <div className="relative w-[200px] h-[340px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor2 border-2 rounded-full text-primaryColor2">
                4
              </span>
            </div>
            <img className="img-3" src={EstateFour} alt="" />
            <p className="my-[1rem] font-medium">Credit</p>
            <p className="text-center opacity-50 text-sm">
              Get 70% credit to finance construction of your home
            </p>
          </div>
          <div className="relative w-[200px] h-[340px] bg-white rounded-xl p-3 shadow-2xl flex flex-col justify-center items-center ">
            <div className="absolute top-[-28px] shadow-3xl border-t-sm pt-6 px-[7px] rounded-full bg-white">
              <span className=" relative top-[-10px] font-bold px-3 py-2 mb-3 bg-white border-primaryColor2 border-2 rounded-full text-primaryColor2">
                5
              </span>
            </div>
            <img className="img-3" src={EstateFive} alt="" />
            <p className="my-[1rem] font-medium">Re-payment</p>
            <p className="text-center opacity-50 text-sm">
              Repay the 70% borrowed over 15 years on a monthly, quarterly,
              annual basis
            </p>
          </div>
        </div>
      </div>

      <div className="infrastructure mt-[4rem]">
        <p className="font-poppins font-bold text-2xl text-center mb-[3rem]">
          Ongoing Estate Constructions{" "}
          <span className="text-primaryColor2">Progress Status</span>
        </p>
        <div className="card-2 flex justify-between w-[1100px] mx-auto">
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor1"
            src={EstateProOne}
            alt=""
          />
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor1"
            src={EstateProTwo}
            alt=""
          />
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor1"
            src={EstateProThree}
            alt=""
          />
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor1"
            src={EstateProFour}
            alt=""
          />
          <img
            className="w-[200px] h-[200px] border rounded-lg border-2 border-primaryColor1"
            src={EstateProFive}
            alt=""
          />
        </div>
      </div>

      <div className="mt-[8rem]  flex flex-col justify-center items-center">
        <div className="">
          <img className="" src={FAQ} alt="" />
          <div className="relative top-[-100px] font-poppins font-bold text-2xl text-center mb-[3rem]">
            Frequently Asked Questions
            <div className="absolute left-[170px] w-[50px] h-[3px] bg-primaryColor1"></div>
          </div>
        </div>

        <div>
          <div className="flex flex-col items-center justify-center gap-y-7">
            <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
              <div onClick={() => setExpanded(!expanded)} className="">
                {!expanded ? (
                  <span className="font-black font-manrope text-base text-black float-left">
                    Are you offering the development levy credit at the initial
                    rate that I got the property?
                  </span>
                ) : (
                  <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                    Are you offering the development levy credit at the initial
                    rate that I got the property?
                  </span>
                )}
              </div>
              <button
                className="float-right"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                    />
                  </div>
                )}
              </button>
              {expanded && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <div className="text-sm font-medium text-black text-justify mb-5">
                      No. Due to time and inflation, the development levy has
                      increased. And we will be offering the credit at the
                      prevailing cost.
                    </div>
                  </span>
                </div>
              )}
              {!expanded ? (
                <div
                  className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
                ></div>
              ) : (
                <div
                  className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
                ></div>
              )}
            </div>
            <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
              <div onClick={() => setExpanded2(!expanded2)} className="">
                {!expanded2 ? (
                  <span className="font-black font-manrope text-base text-black float-left">
                    Is DevPro available across all your properties nationwide?
                  </span>
                ) : (
                  <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                    Is DevPro available across all your properties nationwide?
                  </span>
                )}
              </div>
              <button
                className="float-right"
                onClick={() => setExpanded2(!expanded2)}
              >
                {expanded2 ? (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                    />
                  </div>
                )}
              </button>
              {expanded2 && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <div className="text-sm font-medium text-black text-justify mb-5">
                      Yes. However, speak to your sales rep or one of our client
                      service support staff for confirmation on the specific
                      property.
                    </div>
                  </span>
                </div>
              )}
              {!expanded2 ? (
                <div
                  className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
                ></div>
              ) : (
                <div
                  className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
                ></div>
              )}
            </div>
            <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
              <div onClick={() => setExpanded3(!expanded3)} className="">
                {!expanded3 ? (
                  <span className="font-black font-manrope text-base text-black float-left">
                    How long does it take to get profiled and get approved?
                  </span>
                ) : (
                  <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                    How long does it take to get profiled and get approved?
                  </span>
                )}
              </div>
              <button
                className="float-right"
                onClick={() => setExpanded3(!expanded3)}
              >
                {expanded3 ? (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                    />
                  </div>
                )}
              </button>
              {expanded3 && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <div className="text-sm font-medium text-black text-justify mb-5">
                      Assessment takes five working days to profile and confirm
                      your eligibility.
                    </div>
                  </span>
                </div>
              )}
              {!expanded3 ? (
                <div
                  className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
                ></div>
              ) : (
                <div
                  className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
                ></div>
              )}
            </div>
            <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
              <div onClick={() => setExpanded4(!expanded4)} className="">
                {!expanded4 ? (
                  <span className="font-black font-manrope text-base text-black float-left">
                    Can I repay in full before the 3 years repayment period
                    elapses?
                  </span>
                ) : (
                  <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                    Can I repay in full before the 3 years repayment period
                    elapses?
                  </span>
                )}
              </div>
              <button
                className="float-right"
                onClick={() => setExpanded4(!expanded4)}
              >
                {expanded4 ? (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                    />
                  </div>
                )}
              </button>
              {expanded4 && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <div className="text-sm font-medium text-black text-justify mb-5">
                      Yes. Even better. This will save you more cost.
                    </div>
                  </span>
                </div>
              )}
              {!expanded4 ? (
                <div
                  className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
                ></div>
              ) : (
                <div
                  className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
                ></div>
              )}
            </div>
            <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
              <div onClick={() => setExpanded5(!expanded5)} className="">
                {!expanded5 ? (
                  <span className="font-black font-manrope text-base text-black float-left">
                    Can I get DevPro credit for more than one property?
                  </span>
                ) : (
                  <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                    Can I get DevPro credit for more than one property?
                  </span>
                )}
              </div>
              <button
                className="float-right"
                onClick={() => setExpanded5(!expanded5)}
              >
                {expanded5 ? (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                    />
                  </div>
                )}
              </button>
              {expanded5 && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <div className="text-sm font-medium text-black text-justify mb-5">
                      Yes. However, it’s on a first come, first serve basis. And
                      we will also prioritise based on demand and availability
                      to all applicants.
                    </div>
                  </span>
                </div>
              )}
              {!expanded5 ? (
                <div
                  className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
                ></div>
              ) : (
                <div
                  className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
                ></div>
              )}
            </div>
            <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
              <div onClick={() => setExpanded6(!expanded6)} className="">
                {!expanded6 ? (
                  <span className="font-black font-manrope text-base text-black float-left">
                    Where and how do I apply?
                  </span>
                ) : (
                  <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                    Where and how do I apply?
                  </span>
                )}
              </div>
              <button
                className="float-right"
                onClick={() => setExpanded6(!expanded6)}
              >
                {expanded6 ? (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                    />
                  </div>
                )}
              </button>
              {expanded6 && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <div className="text-sm font-medium text-black text-justify mb-5">
                      You can request for an application form from a sales rep,
                      download or apply on the Landshop website, or contact us.
                    </div>
                  </span>
                </div>
              )}
              {!expanded6 ? (
                <div
                  className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
                ></div>
              ) : (
                <div
                  className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
                ></div>
              )}
            </div>
            <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
              <div onClick={() => setExpanded7(!expanded7)} className="">
                {!expanded7 ? (
                  <span className="font-black font-manrope text-base text-black float-left">
                    Can I apply for part funding of the Development levy?
                  </span>
                ) : (
                  <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                    Can I apply for part funding of the Development levy?
                  </span>
                )}
              </div>
              <button
                className="float-right"
                onClick={() => setExpanded7(!expanded7)}
              >
                {expanded7 ? (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                    />
                  </div>
                )}
              </button>
              {expanded7 && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <div className="text-sm font-medium text-black text-justify mb-5">
                      Yes. As long as you are profiled and eligible.
                    </div>
                  </span>
                </div>
              )}
              {!expanded7 ? (
                <div
                  className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
                ></div>
              ) : (
                <div
                  className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
                ></div>
              )}
            </div>
            <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
              <div onClick={() => setExpanded8(!expanded8)} className="">
                {!expanded8 ? (
                  <span className="font-black font-manrope text-base text-black float-left">
                    If I sell my property, will you refund me the Development
                    levy ?
                  </span>
                ) : (
                  <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                    If I sell my property, will you refund me the Development
                    levy ?
                  </span>
                )}
              </div>
              <button
                className="float-right"
                onClick={() => setExpanded8(!expanded8)}
              >
                {expanded8 ? (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="text-primaryColor1 float-right mb-6">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                    />
                  </div>
                )}
              </button>
              {expanded8 && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <div className="text-sm font-medium text-black text-justify mb-5">
                      No. The development levy is non-refundable. As the name
                      implies, it is used to provide infrastructure and
                      amenities for the estate.
                    </div>
                  </span>
                </div>
              )}
              {!expanded8 ? (
                <div
                  className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
                ></div>
              ) : (
                <div
                  className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
                ></div>
              )}
            </div>
            {/* {expanded && <p>{answer}</p>} */}
          </div>
        </div>
      </div>

      <div className="mt-[12rem]">
        <FooterBlack />
      </div>

      {devModal ? (
        <ModalDevPro close={handleDevCloseModal}>
          <div className="flex items-center justify-center flex-col">
            <form onSubmit={handleSubmit(onSubmitDev)}>
              <div className="flex items-center justify-center flex-col">
                <div className="flex items-center justify-center pt-5 pb-5">
                  <h3 className="text-xl font-poppins text-[#494E50] font-medium opacity-50 px-[1rem] py-[2rem]">
                    Please Complete your profile to proceed
                  </h3>
                </div>

                <div className="flex items-center justify-center flex-col">
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-3
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      First Name
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-3 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50">
                      Middle Name
                    </label>
                    <input
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={midddleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-3 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Last Name
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Phone
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Date of Birth
                    </label>
                    {/* <input
                  type="text"
                  className="placeholder-opacity-50 border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                  placeholder="DD/MM/YY"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                /> */}
                    <div>
                      <DatePicker onChange={dateChange} value={dateValue} />
                    </div>
                  </div>
                  
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Residential Address
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>

                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Marital Status
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      onChange={(e) => setStatus(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Occupation
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      onChange={(e) => setOccupation(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Designation
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      placeholder="Position at work"
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Annual Income
                    </label>
                    <input
                      required
                      type="number"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      placeholder="Total amount in Naira"
                      onChange={(e) => setIncome(parseInt(e.target.value))}
                    />
                  </div>
                  {/* <div
                className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
              >
                {" "}
                <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                  Estate Name (Acquired)
                </label>
                <input
                  type="text"
                  className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                />
              </div> */}
                  
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Development Levy Down Payment
                    </label>
                    <input
                      required
                      type="number"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      placeholder="Amount you can personally pay"
                      onChange={(e) =>
                        setDevelopmentLevy(parseInt(e.target.value))
                      }
                    />
                  </div>
                  
                 
                  

                  <div
                    className="  xl:flex xl:items-center xl:justify-center xl:gap-x-3 xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:gap-x-3 lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:gap-x-3 sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:gap-x-3 md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:gap-x-3 xs:w-[247.41px] xs:h-[60.33px] xs:mt-[2rem]"
                  >
                    <input
                      required
                      type="checkbox"
                      className="border-primaryColor1 rounded-sm"
                    />
                    <div>
                      By creating an account, you agree to the{" "}
                      <span className="text-primaryColor1 underline">
                        Terms of use
                      </span>{" "}
                      and{" "}
                      <span className="text-primaryColor2 underline">
                        Privacy Policy
                      </span>
                    </div>
                  </div>
                  <div
                    className="
				  xl:flex xl:flex-row xl:items-center xl:justify-around xl:ml-[-4rem]
				  lg:flex lg:flex-row lg:items-center lg:justify-around lg:ml-[-4rem]
				  md:flex md:flex-row md:items-center md:justify-around md:ml-[-2rem]
				  sm:flex sm:flex-row sm:items-center sm:justify-around sm:ml-[-2rem]
				  xs:flex xs:flex-col xs:items-start xs:justify-start xs:gap-y-[2rem]
				  
				  "
                  >
                    <div className="flex items-center justify-center mt-[2rem] pb-5">
                      {loading ? (
                        <PropagateLoader color="#17B28D" size={30} />
                      ) : (
                        <input
                          type="submit"
                          value="Create Now"
                          className="w-[161px] h-[50px] bg-primaryColor1 rounded-lg text-white cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalDevPro>
      ) : (
        ""
      )}
      {estateModal ? (
        <ModalEstatePro close={handleEstateCloseModal}>
          <div className="flex items-center justify-center flex-col">
            <form onSubmit={handleSubmit(onSubmitEstate)}>
              <div className="flex items-center justify-center flex-col">
                <div className="flex items-center justify-center pt-5 pb-5">
                  <h3 className="text-xl font-poppins text-[#494E50] font-medium opacity-50 px-[1rem] py-[2rem]">
                    Please Complete your profile to proceed
                  </h3>
                </div>

                <div className="flex items-center justify-center flex-col">
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-3
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      First Name
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-3 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 ">
                      Middle Name
                    </label>
                    <input
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={midddleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-3 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Last Name
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Phone
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Date of Birth
                    </label>
                    {/* <input
                  type="text"
                  className="placeholder-opacity-50 border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                  placeholder="DD/MM/YY"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                /> */}
                    <div>
                      <DatePicker onChange={dateChange} value={dateValue} />
                    </div>
                  </div>
                 
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Residential Address
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>

                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Marital Status
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      onChange={(e) => setStatus(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Occupation
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      onChange={(e) => setOccupation(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Designation
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      placeholder="Position at work"
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Annual Income
                    </label>
                    <input
                      required
                      type="number"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      placeholder="Total amount in Naira"
                      onChange={(e) => setIncome(parseInt(e.target.value))}
                    />
                  </div>
                  {/* <div
                className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
              >
                {" "}
                <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                  Estate Name (Acquired)
                </label>
                <input
                  type="text"
                  className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                />
              </div> */}
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Estate Name(Acquired)
                    </label>
                    <input
                      required
                      type="text"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      placeholder=""
                      onChange={(e) => setEstateN(e.target.value)}
                    />
                  </div>
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Number of Plots (Acquired)
                    </label>
                    <input
                      required
                      type="number"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      placeholder=""
                      onChange={(e) => setPlots(parseInt(e.target.value))}
                    />
                  </div>

                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="mb-2 text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                      Construction Down Payment
                    </label>
                    <input
                      required
                      type="number"
                      className=" border-[#D9D9D9] border focus:ring-0 w-[100%] px-[12px] py-[15px] rounded-md"
                      placeholder="Amount you can personally pay"
                      onChange={(e) =>
                        setConstructionAmount(parseInt(e.target.value))
                      }
                    />
                  </div>
                 
                  
                  <div
                    className="
				  xl:flex   xl:w-[100%] xl:h-[60.33px]
				  lg:fle  lg:w-[100%] lg:h-[60.33px]
				  sm:flex   sm:w-[100%] sm:h-[60.33px]
				  md:flex  md:w-[100%] md:h-[60.33px]
				  xs:flex  xs:w-[100%] xs:h-[60.33px] xs:flex-col xs:px-4 xs:mb-4 mt-[2rem]
				  "
                  >
                    {" "}
                    <label className="block mb-2 opacity-50 text-[#494E50] font-poppins text-gray-900 dark:text-white after:content-['_*'] after:text-red-600">
                      Have You Paid Your Development Levy?
                    </label>
                    <select
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-[12px] py-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Choose an option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <div
                    className="  xl:flex xl:items-center xl:justify-center xl:gap-x-3 xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:gap-x-3 lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:gap-x-3 sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:gap-x-3 md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:gap-x-3 xs:w-[247.41px] xs:h-[60.33px] xs:mt-[2rem]"
                  >
                    <input
                      required
                      type="checkbox"
                      className="border-primaryColor1 rounded-sm"
                    />
                    <div>
                      By creating an account, you agree to the{" "}
                      <span className="text-primaryColor1 underline">
                        Terms of use
                      </span>{" "}
                      and{" "}
                      <span className="text-primaryColor2 underline">
                        Privacy Policy
                      </span>
                    </div>
                  </div>
                  <div
                    className="
				  xl:flex xl:flex-row xl:items-center xl:justify-around xl:ml-[-4rem]
				  lg:flex lg:flex-row lg:items-center lg:justify-around lg:ml-[-4rem]
				  md:flex md:flex-row md:items-center md:justify-around md:ml-[-2rem]
				  sm:flex sm:flex-row sm:items-center sm:justify-around sm:ml-[-2rem]
				  xs:flex xs:flex-col xs:items-start xs:justify-start xs:gap-y-[2rem]
				  
				  "
                  >
                    <div className="flex items-center justify-center mt-[2rem] pb-5">
                      {loading ? (
                        <PropagateLoader color="#17B28D" size={30} />
                      ) : (
                        <input
                          type="submit"
                          value="Create Now"
                          className="w-[161px] h-[50px] bg-primaryColor1 rounded-lg text-white cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalEstatePro>
      ) : (
        ""
      )}
    </div>
  );
};

export default DevPro;
