import Nav from "../components/Nav";
import tribe from "../assets/joinOurTribe.jpg";
import successAlert from "../assets/successAlert.gif";
import { useEffect, useState } from "react";
import { ModalTribeForm } from "../components/ModalTribeForm";
import { ModalTribeFormAlert } from "../components/ModalTribeFormAlert";
import CustomButton from "../components/CustomButton";
import axios from "axios";
import { API } from "../utils/backend";
import { validationRules } from "../common/utils/helper";

const initialdata = {
  first_name: "",
  last_name: "",
  email: "",
  whatsapp_no: "",
};

export default function Tribe() {
  const [openModalAlert, setOpenModalAlert] = useState<Boolean>(false);
  const [openModal, setOpenModal] = useState<Boolean>(false);
  const [inData, setInData] = useState(initialdata);
  const [loading, setLoading] = useState({ initial: false, sub: false });
  const [coralVideo, setCoralVideo] = useState<Record<string, string>>({});

  useEffect(() => {
    const getVideoLink = async () => {
      setLoading((prev) => ({ ...prev, initial: true }));
      try {
        const response = await axios.get(`${API}settings/`);

        if (response) {
          setCoralVideo(response.data.data.settings);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => ({ ...prev, initial: false }));
      }
    };
    getVideoLink();
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setInData({ ...initialdata });
  };

  const handleOpenModalAlert = () => {
    handleCloseModal();
    setOpenModalAlert(true);
  };
  const handleCloseModalAlert = () => {
    setOpenModalAlert(false);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "whatsapp_no":
        if (value.length > 0 && validationRules.number.test(value)) {
          setInData((prev) => ({ ...prev, [name]: value }));
        }
        if (value.length === 0) {
          setInData((prev) => ({ ...prev, [name]: value }));
        }
        break;

      default:
        setInData((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading((prev) => ({ ...prev, sub: true }));
    try {
      const response = await axios.post(`${API}join-our-tribe/`, inData);

      if (response.status === 201) {
        console.log(response.data, "response");
        handleOpenModalAlert();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, sub: false }));
    }
  };

  return (
    <div>
      <Nav />
      <div
        className="sm:flex sm:flex-col sm:mt-[2rem] sm:items-center sm:justify-center sm:gap-y-5 lg:bg-white lg:mx-auto lg:w-screen lg:h-[30rem]
		 lg:flex lg:flex-row lg:items-center lg:justify-evenly lg:gap-y-5 xs:flex xs:flex-col xs:mt-[2rem] xs:items-center xs:justify-center xs:gap-y-5 "
      >
        <div>
          {coralVideo.video_link ? (
            <iframe
              className="xs:w-[328px] xs:h-[184px] xl:w-[695px] xl:h-[390px] lg:w-[495px] lg:h-[290px]"
              src={coralVideo.video_link}
              title="Why Chelsea Can&#39;t Unlock Teams (But Man City Can)"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          ) : (
            <img
              src={tribe}
              alt=""
              className="xs:w-[328px] xs:h-[184px] xl:w-[695px] xl:h-[390px] lg:w-[495px] lg:h-[290px] "
            />
          )}
        </div>
        <div className=" flex flex-col items-center justify-center gap-y-5">
          <div className="xs:self-center lg:self-start xs:text-center lg:text-justify">
            <span className="text-3xl font-bold font-urbanist">
              Earn up to{" "}
              <span className="font-urbanist text-3xl text-primaryColor2 font-bold">
                N3 million monthly{" "}
              </span>
              <br /> selling properties from <br /> anywhere
            </span>
          </div>
          <div className="xs:self-center lg:self-start">
            <span className="text-sm font-bold font-urbanist">
              Join our tribe to get started
            </span>
          </div>
          <div className="xs:self-center lg:self-start">
            <button
              onClick={handleOpenModal}
              className="bg-primaryColor1  transition-all duration-300 ease-in-out text-white px-6 py-2 rounded-lg hover:bg-transparent hover:border hover:border-primaryColor1  hover:text-primaryColor1"
            >
              Join Now
            </button>
          </div>
        </div>
      </div>
      {openModal ? (
        <ModalTribeForm close={handleCloseModal}>
          <form
            onSubmit={submitHandler}
            className="flex flex-col items-center justify-center gap-y-[2rem]"
          >
            <div className="flex items-center justify-center ">
              <h6 className="text-gray-300 font-urbanist font-bold text-2xl">
                Fill the form
              </h6>
            </div>
            <div className="w-[259px] h-[53.52px] flex flex-col items-center justify-center gap-y-4">
              <div className="self-start">
                <label className=" after:content-['_*'] after:text-red-600">
                  First Name
                </label>
              </div>
              <div>
                <input
                  type="text"
                  className="w-[259px] h-[1.01px] border-l-0 border-t-0 border-r-0 border-gray-300 focus:ring-0"
                  name="first_name"
                  value={inData.first_name}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="w-[259px] h-[53.52px] flex flex-col items-center justify-center gap-y-4">
              <div className="self-start">
                <label className=" after:content-['_*'] after:text-red-600">
                  Last Name
                </label>
              </div>
              <div>
                <input
                  type="text"
                  className="w-[259px] h-[1.01px] border-l-0 border-t-0 border-r-0 border-gray-300 focus:ring-0"
                  name="last_name"
                  value={inData.last_name}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="w-[259px] h-[53.52px] flex flex-col items-center justify-center gap-y-4">
              <div className="self-start">
                <label className=" after:content-['_*'] after:text-red-600">
                  Email{" "}
                </label>
              </div>
              <div>
                <input
                  type="email"
                  className="w-[259px] h-[1.01px] border-l-0 border-t-0 border-r-0 border-gray-300 focus:ring-0"
                  name="email"
                  value={inData.email}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="w-[259px] h-[53.52px] flex flex-col items-center justify-center gap-y-4">
              <div className="self-start">
                <label className=" after:content-['_*'] after:text-red-600">
                  Whatsapp
                </label>
              </div>
              <div>
                <input
                  type="text"
                  className="w-[259px] h-[1.01px] border-l-0 border-t-0 border-r-0 border-gray-300 focus:ring-0"
                  name="whatsapp_no"
                  value={inData.whatsapp_no}
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div className="flex items-center justify-center">
              <CustomButton
                // className="w-[161px] h-[50px] mb-4 bg-primaryColor1 rounded-md text-white font-poppins"
                className="rounded-md"
                isloading={loading.sub}
                // onClick={handleOpenModalAlert}
                disabled={Object.values(inData).includes("")}
              >
                Submit
              </CustomButton>
            </div>
          </form>
        </ModalTribeForm>
      ) : (
        ""
      )}

      {openModalAlert ? (
        <ModalTribeFormAlert close={handleCloseModalAlert}>
          <div className="flex flex-col items-center justify-center gap-y-6">
            <div className="w-[231px] h-[231px] flex items-center justify-center">
              <img src={successAlert} alt="" />
            </div>

            <div className="w-[273px] h-[95px]">
              <h6 className="font-poppins text-sm text-thin">
                Thank you for your interest in Joining our real estate tribe of
                maestros
                <br />
                <br />A Community manager will be in touch soon to get you start
              </h6>
            </div>
            <div>
              <button className="w-[129px] h-[35px] bg-primaryColor2 text-white font-bold rounded-md mb-5 font-poppins">
                Ok
              </button>
            </div>
          </div>
        </ModalTribeFormAlert>
      ) : (
        ""
      )}
    </div>
  );
}
