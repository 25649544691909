import React from "react";
import Nav from "../components/Nav";
import { useState } from "react";
import axios from "axios";
import { API } from "../utils/backend";
import { PropagateLoader } from "react-spinners";
import Swal from "sweetalert2";

export const BuyGreenProduct = () => {
  const [value, setValue] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [inspection, setInspection] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");

  const devId = localStorage.getItem("developId");

  // const handleInspection = (e : React.FormEvent<HTMLInputElement>) => {
  // 	console.log(e.target)
  // 	setInspection(e.currentTarget.value)
  // }

  const funcIncrease = () => {
    if (value > 0 || value === 0) {
      setValue(value + 1);
    }
  };

  const funcDecrease = () => {
    if (value > 0 || value < 0) {
      setValue(value - 1);
    }
  };

  const submitDevelopForm = async () => {
    setLoading(true);
    try {
      const body = {
        develop_id: devId,
        email: email,
        name: name,
        phone_number: phone,
        inspection: inspection,
        quantity: value,
        date: date,
        time: time,
      };

      console.log(body);
      const response = await axios.post(`${API}submit/develop/form/`, body);
      console.log(response);
      if (response.status === 200) {
        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Successful",
        });

        setName("");
        setEmail("");
        setDate("");
        setTime("");
        setInspection("");
        setPhone("");
        setValue(0);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div>
      <Nav />
      <div
        className="
	lg:bg-white lg:w-[744px]  lg:shadow-2xl lg:rounded-md lg:mx-auto lg:h-[45rem] lg:my-auto lg:mt-9
	md:bg-white md:w-[744px]  md:shadow-2xl md:rounded-md md:mx-auto md:h-[52rem] md:my-auto md:mt-9
	sm:bg-white sm:w-[744px]  sm:shadow-2xl sm:rounded-md sm:mx-auto sm:h-[58rem] sm:my-auto sm:mt-9
	"
      >
        <div className="flex items-center justify-center">
          <span
            className="
		 lg:text-4xl lg:text-gray-300 lg:text-center lg:font-bold
		 sm:text-xl sm:text-gray-300 sm:text-center sm:font-bold sm:ml-[-24rem]
		 md:text-2xl md:text-gray-300 md:text-center md:font-bold md:ml-[3rem]
		 
		 
		 "
          >
            House Development Form
          </span>
        </div>

        <div className="flex flex-col items-center justify-center gap-y-8">
          <div className="mt-[5rem] after:content-['_*'] after:text-red-600 after:self-start">
            <input
              type="text"
              placeholder="Name"
              className="
		 lg:border-gray-500 lg:border-b-2 lg:w-[647.41px]  lg:outline-none lg:bg-transparent lg:ml-[1rem]
		 sm:border-gray-500 sm:border-b-2 sm:w-[287.41px]  sm:outline-none sm:bg-transparent ml-[-21rem]
		 md:border-gray-500 md:border-b-2 md:w-[499.41px]  md:outline-none md:bg-transparent md:ml-[4rem]
		 
		 "
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="after:content-['_*'] after:text-red-600 after:self-start">
            <input
              type="email"
              placeholder="Email"
              className="
		 lg:border-gray-500 lg:border-b-2 lg:w-[647.41px]  lg:outline-none lg:bg-transparent lg:ml-[1rem]
		 sm:border-gray-500 sm:border-b-2 sm:w-[287.41px]  sm:outline-none sm:bg-transparent ml-[-21rem]
		 md:border-gray-500 md:border-b-2 md:w-[499.41px]  md:outline-none md:bg-transparent md:ml-[4rem]
		 "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="after:content-['_*'] after:text-red-600 after:self-start">
            <input
              type="text"
              placeholder="Phone Number"
              className="
		  lg:border-gray-500 lg:border-b-2 lg:w-[647.41px]  lg:outline-none lg:bg-transparent lg:ml-[1rem]
		  sm:border-gray-500 sm:border-b-2 sm:w-[287.41px]  sm:outline-none sm:bg-transparent ml-[-21rem]
		  md:border-gray-500 md:border-b-2 md:w-[499.41px]  md:outline-none md:bg-transparent md:ml-[4rem]
		 
		  "
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div
            className="
		lg:flex-row lg:items-center lg:justify-center lg:gap-x-[15rem]
		sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-[2rem] sm:ml-[-26rem]
		md:flex md:flex-col md:items-center md::justify-center md::gap-y-[5rem] md:ml-[4rem]
	
	"
          >
            <div
              className="
		flex flex-col items-center justify-between gap-y-5
	
		
		"
            >
              <label className="ml-[-2rem] after:content-['_*'] after:text-red-600">
                Inspection
              </label>
              <select
                className="bg-transparent px-5 py-3 w-[190px] 
			 border border-primaryColor2 outline-none"
                onChange={(e) => setInspection(e.target.value)}
                value={inspection}
              >
                <option value="Select an Inspection">
                  Select an Inspection
                </option>
                <option value="Book Inspection">Book Inspection</option>
                <option value="Virtual Inspection">Virtual Inspection</option>
              </select>
            </div>
            <div className="flex flex-col items-center justify-center gap-y-5">
              <label className="after:content-['_*'] after:text-red-600">
                Quantity
              </label>
              <div className="bg-primaryColor2 w-[190px] h-[60px] rounded-full flex items-center justify-center gap-x-8">
                <div>
                  <button
                    className="bg-white  p-3 text-lg"
                    onClick={funcDecrease}
                  >
                    -
                  </button>
                </div>
                <div>
                  <span className="text-white">{value}</span>
                </div>

                <div>
                  <button
                    className=" bg-white  p-3 text-lg"
                    onClick={funcIncrease}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className="
				lg:flex-row lg:items-center lg:justify-center lg:gap-x-[17.5rem]
				sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-[5rem] sm:ml-[-25rem]
				md:flex md:flex-col md:items-center md::justify-center md::gap-y-[5rem] md:ml-[6rem]
				
				"
            >
              <div className="after:content-['_*'] after:text-red-600 after:self-start">
                <input
                  type="date"
                  placeholder="Date"
                  className="px-4 py-2 bg-transparent border border-gray-500 w-[170.41px] ml-[-1.2rem]  outline-none"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="after:content-['_*'] after:text-red-600 after:self-start">
                <input
                  type="time"
                  placeholder="Time"
                  className="px-4 py-2 bg-transparent border border-gray-500 w-[170.41px] ml-[-1.5rem]  outline-none"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className='
		  sm:flex sm:items-center sm:justify-center sm:mt-[2rem] sm:ml-[-27rem]
		  md:flex md:items-center md:justify-center md:mt-[2rem] md:ml-[4rem]
		  lg:flex lg:items-center justify-center lg:mt-[6rem] lg:ml-[0rem] type="button" data-modal-toggle="defaultModal
		  
		  '
        >
          {loading ? (
            <PropagateLoader color="#17B28D" size={30} />
          ) : (
            <button
              className="bg-primaryColor1 text-white py-2 px-8 rounded-md cursor-pointer"
              onClick={submitDevelopForm}
            >
              Submit
            </button>
          )}
        </div>
      </div>

      {/* modal */}
    </div>
  );
};
