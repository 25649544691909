import React, { useState } from "react";
import { BiFileBlank } from "react-icons/bi";
import { tail } from "../common/utils/helper";

const inboxItem = {
  message_title: "Your Product is growing.",
  category: "Xtra Mortgage",
  product_group: "Mortgage Product owners",
  timestamp: {
    full: "2023, Jan 23, 10: 30 pm",
    short: "Jan 23",
  },
  content: {
    main: "Lorem ipsum dolor sit amet consectetur. Et cursus neque vestibulum volutpat. Aliquam pulvinar malesuada ac consectetur aliquam arcu nisi. Sollicitudin orci morbi tempus gravida egestas feugiat justo dictum. Sollicitudin risus risus justo non vitae. Laoreet viverra scelerisque in sed. Sed volutpat mattis arcu eu massa praesent convallis. Vulputate aliquam et est rhoncus neque libero aliquam integer. Sapien non euismod at ac. Ultrices condimentum faucibus vel et velit malesuada scelerisque maecenas. Id tempus quis bibendum nascetur orci amet adipiscing non in. Nisl feugiat sagittis tellus adipiscing amet viverra nisi morbi. Amet venenatis amet mauris vitae nibh viverra. Proin enim habitasse massa in diam mollis egestas ipsum. Pellentesque eget ornare fermentum ut sit lobortis donec orci rutrum. Tempus ligula mauris a venenatis pharetra vestibulum lacus neque ac. Fringilla nulla blandit condimentum in donec. Nisi turpis nibh habitant commodo eget ut sit enim.",
  },

  attachment: {
    name: "Statistic.pdf",
    type: "PDF",
    size: "3.7MB ",
  },
};

const inboxItems = new Array(4).fill("0").map((itm) => inboxItem);

function Inbox() {
  const [selectedMessage, setSelectedMessage] = useState<null | number>(null);
  const selectMessageHandler = (id: number) => {
    setSelectedMessage(id);
  };
  const message = inboxItems.find((_, idx) => idx === selectedMessage);
  return (
    <div className="grid grid-cols-9 ">
      {/* catelogue */}
      <div className="col-span-full pt-20 border-r border-gray-40">
        {inboxItems.map((itm, index) => (
          <div
            className={tail(
              "p-5 font-medium leading-6 cursor-pointer",
              selectedMessage === index ? "bg-gray-50" : ""
            )}
            onClick={() => selectMessageHandler(index)}
          >
            <header className="flex items-center justify-between">
              <h3 className="text-black text-lg">{itm.message_title}</h3>
              <p className="text-gray-30/80">{itm.timestamp.short}</p>
            </header>
            <div>
              <p className="text-primaryColor1">{itm.category}</p>
              <p className="text-gray-60 text-xs">{itm.product_group}</p>
              <p className="truncate max-w-[17rem] text-gray-30/80 text-sm">
                {itm.content.main}
              </p>
            </div>
            <footer className="flex items-center justify-start mt-1">
              <div className="p-1.5 flex items-center gap-x-1 border border-primaryColor1 text-gray-30/80 text-xs">
                <BiFileBlank />
                {itm.attachment.name}
              </div>
            </footer>
          </div>
        ))}
      </div>
      {/* selected message
      {typeof selectedMessage === "number" ? (
        <div>
          <header>
            <h2>{message?.message_title}</h2>
          </header>
        </div>
      ) : null} */}
    </div>
  );
}

export default Inbox;
