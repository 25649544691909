import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import image2 from "../../assets/miniTandC.png";
import Button from "../Button";
import { ModalGrow } from "./ModalGrow";

const TandC2 = () => {
	const navigate = useNavigate();
	const [modal, setModal] = useState<boolean>(false);

	const handleModal = () => setModal(true);
	const handleCloseModal = () => setModal(false);

	const decline = () => {
		setModal(false);
	};

	const homePage = () => {
		navigate("/grow");
	};

	const goToLogin = () => {
		navigate("/grow");
	};

	return (
		<div className=" flex items-center justify-center bg-bgTerms w-full h-[100vh]">
			{/* <img src={image} alt="" /> */}

			<div
				className="
		 xl:flex xl:items-center xl:justify-center xl:bg-white xl:shadow-2xl xl:rounded-md xl:w-[900px] xl:h-[585px]
		 lg:flex lg:items-center lg:justify-center lg:bg-white lg:shadow-2xl lg:rounded-md lg:w-[900px] lg:h-[585px]
		 md:flex md:items-center md:justify-center md:bg-white md:shadow-2xl md:rounded-md md:w-[900px] md:h-[585px]
		 sm:flex sm:items-center sm:justify-center sm:bg-white sm:shadow-2xl sm:rounded-md sm:w-[485px] sm:h-[585px]
		 xs:flex xs:items-center xs:justify-center xs:bg-white xs:shadow-2xl xs:rounded-md xs:w-[320px] xs:h-[585px]
		 
		 "
			>
				<div className="flex flex-col items-center justify-center gap-y-9">
					<div>
						<img src={image2} alt="" />
					</div>
					<div>
						<h5 className="text-md font-poppins text-[#808191]">
							Privacy Policy
						</h5>
					</div>

					<div
						className="
					xl:border xl:border-[#808191] xl:rounded-md  xl:w-[691px] xl:h-[279px] xl:flex xl:flex-col xl:justify-start xl:gap-y-5 xl:p-2 xl:overflow-y-auto
					lg:border lg:border-[#808191] lg:rounded-md  lg:w-[691px] lg:h-[279px] lg:flex lg:flex-col lg:justify-start lg:gap-y-5 lg:p-2 lg:overflow-y-auto
					md:border md:border-[#808191] md:rounded-md  md:w-[691px] md:h-[279px] md:flex md:flex-col md:justify-start md:gap-y-5 md:p-2 md:overflow-y-auto
					sm:border sm:border-[#808191] sm:rounded-md  sm:w-[470px] sm:h-[279px] sm:flex sm:flex-col sm:justify-start sm:gap-y-5 sm:p-2 sm:overflow-y-auto
					xs:border xs:border-[#808191] xs:rounded-md  xs:w-[300px] xs:h-[279px] xs:flex xs:flex-col xs:justify-start xs:gap-y-5 xs:p-2 xs:overflow-y-auto
					
					"
					>
						<div className="w-[197px] h-[20px]">
							<h5 className="text-black text-xs font-bold font-poppins">
								Last Updated: Jan 24, 2023
							</h5>
						</div>

						<div className="h-[380px]">
							<h6 className="font-poppins text-[#808191] text-sm">
								Landshop Limited is committed to protecting the privacy and
								security of your personal information. This Privacy Policy
								describes how we collect, use, and disclose personal information
								when you visit our website [landshop.ng] (www.landshop.ng) and
								interact with us. By using our Site, you agree to the terms of
								this Privacy Policy.
								<ol className="p-2 list-decimal">
									<li>
										Information We Collect
										<ul className="p-2 list-disc">
											<li>
												Personal Information: When you visit our Site, we may
												collect personal information such as your name, email
												address, phone number, and any other information you
												provide to us voluntarily.
											</li>
											<li>
												Usage Information: We may also collect non-personal
												information about your interaction with our Site, such
												as your IP address, browser type, operating system, and
												the pages you visit.
											</li>
										</ul>
									</li>
									<li>
										How We Use Your Information.
										<p>
											We may use the information we collect for the following
											purposes:
										</p>
										<ul className="p-2 list-disc">
											<li>To provide you with the services you request.</li>
											<li>
												To communicate with you about our services, promotions,
												and events.
											</li>
											<li>To improve our Site and services.</li>
											<li>To analyze trends and statistics.</li>
											<li>To comply with legal obligations.</li>
										</ul>
									</li>
                  <li>
                  Information Sharing
                  <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described below:</p>
                  <ul className="p-2 list-disc">
                    <li>Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you.</li>
                    <li>Legal Requirements: We may disclose your information if required to do so by law or in response to a subpoena or court order.</li>
                  </ul>
                  </li>
                  <li>
                    Data Security
                    <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.</p>
                  </li>
                  <li>
                  Your Choices
                  <p>You may choose to restrict the collection or use of your personal information by contacting us directly. You also have the right to access, update, or delete your personal information by contacting us.</p>
                  </li>
                  <li>
                  Changes to this Privacy Policy
                  <p>We reserve the right to modify this Privacy Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website.</p>
                  </li>
                  <li>Contact Us
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at care@landshop.ng.</p>
                  </li>
                  <p>By using our Site, you consent to the terms of this Privacy Policy.</p>
								</ol>
							</h6>
						</div>
					</div>
					<div className="flex items-center justify-center gap-x-9">
						<Button myStyle="decline" onClick={handleModal}>
							Decline
						</Button>{" "}
						<Button myStyle="growButton2" onClick={goToLogin}>
							Accept
						</Button>
					</div>
				</div>
			</div>

			{modal ? (
				<ModalGrow close={handleCloseModal}>
					<div
						className="
		   bg-white shadow-sm w-full h-[501px] rounded-sm flex flex-col items-center justify-center gap-y-9
		   
		   "
					>
						<div>
							<img src={image2} alt="" />
						</div>
						<div>
							<h6 className="text-md font-poppins text-[#C4C4C4]">
								Wait for real?
							</h6>
						</div>
						<div>
							<h6>Are you sure you want to decline this sweet package </h6>
						</div>
						<div>
							<button
								className="w-[240px] h-[60px] bg-primaryColor1 rounded-lg text-white"
								onClick={homePage}
							>
								Yes
							</button>
						</div>
						<div>
							<button
								className="w-[240px] h-[60px] bg-[#C4C4C4] rounded-lg text-white"
								onClick={decline}
							>
								Cancel
							</button>
						</div>
					</div>
				</ModalGrow>
			) : (
				""
			)}
		</div>
	);
};

export default TandC2;
