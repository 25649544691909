import telephone from "../assets/telephone.png";
import message from "../assets/message.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, } from "@fortawesome/free-brands-svg-icons";

type Props = {
  close: () => void;
  brd: {
    id: number;
    image: string;
    name: string;
    position: string;
    context: string;
    link?: string;
  };
  index: number;
};

export const ModalBoard = ({ close, brd, index }: Props) => {
  console.log(brd);
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      key={index}
    >
      <div
        className="xl:w-[840px] xl:h-[990px] xl:flex xl:flex-col
    lg:w-[840px] lg:h-[700px] lg:flex lg:flex-col
	sm:w-[600px] sm:h-[500px] sm:flex sm:flex-col sm:items-center sm:justify-center
	xs:w-[600px] xs:h-[600px] xs:flex xs:flex-col xs:items-center xs:justify-center

    
    "
      >
        <button
          className="xs:text-black xs:text-xl md:place-self-end xs:text-2xl xs:relative md:top-[40px] md:right-[40px] xs:top-[30px] lg:right-[140px]"
          onClick={close}
        >
          X
        </button>
        <div className="bg-white p-2 rounded">
          <div
            className="
					xl:flex xl:flex-col xl:items-center xl:justify-center xl:gap-y-6 xl:w-[600px] xl:h-[500px]
					lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-6 lg:w-[600px] lg:h-[500px]
					sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-6 sm:w-[600px] sm:h-[500px]
					xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-6 xs:w-[600px] xs:h-[500px]
				"
          >
            <div className="xl:self-center">
              <img src={brd.image} alt="" className="xl:rounded-[100%]" />
            </div>
            <div className="xl:self-center">
              <span className="text-sm text-primaryColor1 font-bold font-poppins">
                {brd.name}
              </span>
            </div>
            <div className="xl:self-center">
              <span className="text-sm text-primaryColor1 font-bold font-poppins">
                {brd.position}
              </span>
            </div>
            <div
              className="xl:self-center xl:w-[550px] xl:h-[95px] xl:font-poppins
						lg:self-center lg:w-[550px] lg:h-[95px] lg:font-poppins
						sm:self-center sm:w-[350px] sm:h-[95px] sm:font-poppins
						xs:self-center xs:w-[250px] xs:h-[95px] xs:font-poppins
						"
            >
              <p className="text-xs text-center">{brd.context}</p>
            </div>
            <div className="flex items-center justify-center gap-x-2 mt-[2rem]">
              
              {brd.link && (<div className="flex items-center justify-center gap-x-2 ">
                <div className="border border-primaryColor1 rounded-[100%] p-2">
                <a href={brd.link} target="_blank">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="text-primaryColor2 hover:text-primaryColor2 w-[24px] h-[24px]"
                  ></FontAwesomeIcon>
                </a>
                </div>
              </div>)}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
