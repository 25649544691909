import { createContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import About from "../pages/About";
import Blog from "../pages/Blog";
import { BuyGreenProduct } from "../pages/BuyGreenProduct";
import Contact from "../pages/Contact";
import Develop from "../pages/Develop";
import Faq from "../pages/Faq";
import GreenExtra from "../pages/greenExtra";
import { GreenExtraProduct } from "../pages/GreenExtraProduct";
import { Grow } from "../pages/Grow";
import Home from "../pages/Home";
import Invest from "../pages/Invest";
import Projects from "../pages/Projects";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Thift from "../pages/Thift";
import Tribe from "../pages/Tribe";
import SlideShow from "../pages/SlideShow";
import BannerSlide from "../pages/BannerSlide";
import Main from "../pages/FAQ/Main";
// import { Main } from '../pages/FAQ/Main';
import Search from "../pages/Search";
import FAQClone from "../pages/FAQClone";
// import TermsAndCondition from "../pages/TermsandCondition1";
// import TermsandCondirtion2 from "../pages/TermsandCondition2";
import TermsandCondition2 from "../pages/TermsandCondition2";
import TermsandCondition3 from "../pages/TermsandCondition3";
import DevPro from "../pages/DevPro";
import DevTermsConditions from "./DevTermsConditions";
import DevTermsConditions2 from "./DevTermsConditions2";
import EstateTermsConditions from "./EstateTermsConditions";
import EstateTermsConditions2 from "./EstateTermsConditions2";
import Layout from "./Layout";
import Dashboard from "../pages/Dashboard";
import Inbox from "../pages/Inbox";
import Terms from "../pages/Terms";
import Notification from "../pages/Notification";
import Profile from "../pages/Profile";
import routes from "../common/routes";
import EditProfile from "../pages/EditProfile";
import AuthLayout from "./AuthLayout";
import ForgotPassword from "../pages/ForgotPassword";
import UserGrow from "../pages/UserGrow";
import UserInvest from "../pages/UserInvest";
import UserDevelop from "../pages/UserDevelop";

export const AppContext = createContext<any>({});

function Routing() {
  const [cartItems, setCartItems] = useState<any>([]);

  const AppContextProvider = {
    cartItems,
    setCartItems,
  };

  return (
    <>
      <AppContext.Provider value={AppContextProvider}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/tribe" element={<Tribe />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/thift" element={<Thift />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/develop" element={<Develop />} />
          <Route path="/invest" element={<Invest />} />
          <Route path="/grow" element={<Grow />} />
          <Route path="/20daysofchristmas" element={<GreenExtra />} />

          <Route path="/privacy" element={<TermsandCondition2 />} />
          <Route path="/termsandcondition2" element={<TermsandCondition2 />} />
          <Route path="/termsandcondition3" element={<TermsandCondition3 />} />

          <Route
            path="/20daysofchristmas/:id"
            element={<GreenExtraProduct />}
          />
          <Route path="/buy20daysofchristmas" element={<BuyGreenProduct />} />
          <Route path="/slideshow" element={<SlideShow />} />
          <Route path="/bannerslide" element={<BannerSlide />} />
          <Route path="/main" element={<Main />} />
          <Route path="/search" element={<Search />} />
          <Route path="/faqclone" element={<FAQClone />} />
          <Route path="devpro" element={<DevPro />} />
          <Route path="/devtermsconditions" element={<DevTermsConditions />} />
          <Route
            path="/devtermsconditions2"
            element={<DevTermsConditions2 />}
          />
          <Route
            path="/estatetermsconditions"
            element={<EstateTermsConditions />}
          />
          <Route
            path="/estatetermsconditions2"
            element={<EstateTermsConditions2 />}
          />

          <Route element={<AuthLayout />}>
            <Route
              path={routes.nonprotected.forgot_password}
              element={<ForgotPassword />}
            />
            <Route path={routes.nonprotected.login} element={<SignIn />} />
            <Route path={routes.nonprotected.register} element={<SignUp />} />
          </Route>

          <Route element={<Layout />}>
            <Route path={routes.dashboard.index} element={<Dashboard />}>
              <Route path={routes.dashboard.grow} element={<UserGrow />} />
              <Route path={routes.dashboard.invest} element={<UserInvest />} />
              <Route path={routes.dashboard.develop} element={<UserDevelop />} />
            </Route>
            <Route path={routes.inbox} element={<Inbox />} />
            <Route path={routes.terms} element={<Terms />} />
            <Route path={routes.notification} element={<Notification />} />
            <Route path={routes.profile} element={<Profile />} />
            <Route path={routes.profile_edit} element={<EditProfile />} />
          </Route>
        </Routes>
      </AppContext.Provider>
    </>
  );
}

export default Routing;
