import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../Header";
import SideNav from "../SideNav";
import { fetchLocalData } from "../../common/service/storage";
import routes from "../../common/routes";
import Swal from "sweetalert2";

import { useAppDispatch, useAppSelector } from "../../store";
import { fetchUserProfile } from "../../store/auth/thunk";

function Layout() {
  const { pathname } = useLocation();
  const { access_token } = fetchLocalData();
  const disapatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    document.body.className = "no-scrollbar";
    if (user._id) return;
    disapatch(fetchUserProfile());
  }, []); //eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!access_token) {
    Swal.fire({
      icon: "error",
      title: "UnAuthorized",
    });
    return <Navigate replace to={routes.nonprotected.login} />;
  }

  return (
    <div className="max-w-screen-xl mx-auto">
      <Header />
      <div className="grid grid-cols-12 lg:pl-10">
        <div className="col-span-3 justify-self-start self-start lg:block hidden">
          <SideNav />
        </div>
        <div className="col-span-full lg:col-span-9 max-h-[calc(100vh_-_93px)] overflow-y-scroll relative  pb-12 lg:pr-10 px-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
