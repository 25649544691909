import { useState } from "react";
import thriftBanner from "../assets/thrift_banner.jpg";
import Nav from "../components/Nav";
import {
  faFacebookF,
  faPinterest,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import generalshopAboutLogo from "../assets/landshopAboutLogo.png";
import { Link } from "react-router-dom";
import ThriftLogo from "../assets/thrift_logo.png";
import { validationRules } from "../common/utils/helper";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../utils/backend";
import CustomButton from "../components/CustomButton";
import { FooterBlack } from "../components/FooterBlack";

const initialdata = {
  name: "",
  email: "",
  contact_phone: "",
  group_name: "",
  message: "",
  address: "",
};

export default function Thift() {
  const [inData, setInData] = useState(initialdata);
  const [loading, setLoading] = useState(false);
  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "contact_phone":
        if (value.length > 0 && validationRules.number.test(value)) {
          setInData((prev) => ({ ...prev, [name]: value }));
        }
        if (value.length === 0) {
          setInData((prev) => ({ ...prev, [name]: value }));
        }
        break;

      default:
        setInData((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${API}thrift`, inData);

      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Successful",
        });
        setInData({ ...initialdata });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error encountered",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-auto">
      <Nav />

      <div className="flex sm:w-full md:items-center justify-center relative xs:h-[400px] sm:h-[450px] md:h-[450px]">
        <img className="w-full" src={thriftBanner} alt="" />
        <div className="absolute xs:right-[0] sm:right-[2rem] sm:bottom-[5rem] md:bottom-[-3rem] lg:bottom-[1rem] xl:bottom-[-3rem] xs:bottom-[4rem]">
          <img
            className=" xs:w-[170px] sm: w-[200px] md:w-[239px]"
            src={ThriftLogo}
            alt=""
          />
        </div>
      </div>

      <div className="flex items-center lg:mt-[16rem] md:mt-[12rem] sm:mt-[3rem] xs:mt-[1rem] xl:mt-[20rem] justify-center md:flex-row xs:flex-col md:gap-x-[3rem] lg:gap-x-[2rem] mb-20 lg:px-[8rem] md:px-[5rem] xs:px-[5rem] xs:pt-10">
        <form className="flex flex-col " onSubmit={submitHandler}>
          <div className="w-[auto] h-[auto]">
            <h2 className="text-primaryColor2 text-5xl font-bold font-urbanist">
              Thrift
            </h2>
          </div>
          <div className="lg:w-[500px] h-[auto] py-5 md:w-[400px] xs:w-[auto]">
            <h5 className="text-gray-700 font-thin font-poppins text-xs">
              <p>
                Are you a member of a religious group, co-operative , social
                club , association, august meeting or any specialised tean with
                a unified purpose? <br />
                <br />
                Have you got a real estate investment idea or project you would
                like Landshop to offer you a deal or discount on? Reach out to
                us for a personalised quote.
              </p>
            </h5>
          </div>

          <div className=" after:content-['_*'] after:text-red-600 after:self-start">
            <input
              type="text"
              className="border-r-0 border-l-0 border-t-0 border border-gray-300 font-poppins focus:ring-0 lg:w-[548px]  xs:w-[100%] md:w-[400px] h-[90px]"
              placeholder="Name"
              name="name"
              value={inData.name}
              onChange={changeHandler}
              alt=""
            />
          </div>
          <div className="lg:w-[548px] h-[90px] md:w-[400px] xs:w-[100%] after:content-['_*'] after:text-red-600 after:self-start">
            <input
              type="email"
              className="border-r-0 border-l-0 border-t-0 border border-gray-300 font-poppins focus:ring-0 lg:w-[548px] md:w-[400px]  xs:w-[100%] h-[90px]"
              placeholder="Email"
              name="email"
              value={inData.email}
              onChange={changeHandler}
              alt=""
            />
          </div>
          <div className="lg:w-[548px] h-[90px] xs:w-[100%] md:w-[400px] after:content-['_*'] after:text-red-600 after:self-start">
            <input
              type="text"
              className="border-r-0 border-l-0 border-t-0 border border-gray-300 font-poppins focus:ring-0 lg:w-[548px] xs:w-[100%] md:w-[400px] h-[90px]"
              placeholder="Group's Name"
              name="group_name"
              value={inData.group_name}
              onChange={changeHandler}
              alt=""
            />
          </div>
          <div className="lg:w-[548px] h-[90px] md:w-[400px] xs:w-[100%] after:content-['_*'] after:text-red-600 after:self-start">
            <input
              type="text"
              className="border-r-0 border-l-0 border-t-0 border border-gray-300 font-poppins focus:ring-0 lg:w-[548px] xs:w-[100%] md:w-[400px] h-[90px]"
              placeholder="Contact Phone"
              name="contact_phone"
              value={inData.contact_phone}
              onChange={changeHandler}
              alt=""
            />
          </div>
          <div className="lg:w-[548px] h-[90px] md:w-[400px] xs:w-[100%] after:content-['_*'] after:text-red-600 after:self-start">
            <input
              type="text"
              className="border-r-0 border-l-0 border-t-0 border border-gray-300 font-poppins focus:ring-0 lg:w-[548px] xs:w-[100%] md:w-[400px] h-[90px]"
              placeholder="Address"
              name="address"
              value={inData.address}
              onChange={changeHandler}
              alt=""
            />
          </div>
          <div className="lg:w-[548px] h-[90px] md:w-[400px] after:content-['_*'] after:text-red-600 after:self-start">
            <textarea
              className="border-r-0 border-l-0 border-t-0 border border-gray-300 font-poppins focus:ring-0 md:w-[548px] h-[90px] xs:w-[300px] rounded-lg pt-5"
              placeholder="Any Additional Information"
              name="message"
              value={inData.message}
              onChange={changeHandler}
            />
          </div>

          <div className="lg:w-[548px] xs:w-[300px] md:w-[400px] mt-4">
            <CustomButton
              isloading={loading}
              disabled={Object.values(inData).includes("")}
              className="bg-primaryColor1 rounded-lg text-white font-poppins xs:w-[300px] md:w-[548px] h-[60px] text-center justify-center"
            >
              SUBMIT
            </CustomButton>
          </div>
        </form>
        <div className="self-start mt-[3.5rem]">
          <div className="flex flex-col gap-y-1">
            <h6 className="text-sm font-poppins font-bold">
              For Quick Inquiries
            </h6>
            <h6 className="text-sm font-poppins font-bold">
              Email:{" "}
              <span className="text-xs font-thin underline font-poppins">
                care@landshop.ng
              </span>
            </h6>
            <h6 className="text-sm font-poppins font-bold">
              Phone:{" "}
              <span className="text-xs font-thin  font-poppins">
                +234 1 888 {""} 0063
              </span>
            </h6>
            <h6 className="text-sm font-poppins font-bold">
              WhatsApp Only :{" "}
              <span className="text-xs font-thin  font-poppins">
                +234 818 886 6772
              </span>
            </h6>
          </div>
        </div>
      </div>

      <FooterBlack/>

      {/* <div
        className="
		lg:flex lg:justify-between lg:align-center lg:mt-[5rem]  lg:w-full lg:h-[15rem] lg:px-20  
		md:grid md:grid-cols-2 md:place-content-around md:gap-x-1 md:mt-[10rem]  md:w-screen md:h-[15rem] md:gap-y-[4rem]
		sm:grid sm:grid-cols-1 sm:place-content-around sm:gap-x-1 sm:mt-[22rem]  sm:w-screen sm:h-[15rem] sm:gap-y-[4rem]
		xs:grid xs:grid-cols-1 xs:place-content-around xs:gap-x-1 xs:mt-[20rem]  xs:w-screen xs:h-[15rem] xs:gap-y-[4rem]
		
		
		"
      >
        <div className="flex flex-col gap-y-4  items-center justify-center lg:w-[20%]">
          <div>
            <span className="text-gray-400 font-bold font-urbanist text-xl ml-4">
              ABOUT US
            </span>
          </div>
          <div className="flex flex-col gap-y-2">
            <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
              Trust By
            </span>
            <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
              Our Team
            </span>
            <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
              Privacy Policy
            </span>
          </div>
        </div>
        <div
          className="
            flex flex-col gap-y-4  items-center justify-center lg:w-[20%]"
        >
          <div>
            <span className="text-gray-400 font-bold font-urbanist text-xl ml-12">
              Our Projects
            </span>
          </div>
          <div className="flex flex-col gap-y-2">
            <Link to="/grow">
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Grow
              </span>
            </Link>
            <Link to="/invest">
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Invest
              </span>
            </Link>
            <Link to="/develop">
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Develop
              </span>
            </Link>
          </div>
        </div>
        <div
          className="
			lg:flex lg:flex-col lg:gap-y-4  lg:items-center lg:justify-center lg:w-[40%] lg:ml-0
			md:flex md:flex-col md:gap-y-4  md:items-center md:justify-center md:ml-14
			sm:flex sm:flex-col sm:gap-y-4  sm:items-center sm:justify-center sm:px-10
			xs:flex xs:flex-col xs:gap-y-4  xs:items-center xs:justify-center xs:px-10
			
			
			"
        >
          <div>
            <span
              className="
					
					lg:text-gray-400 lg:font-bold lg:font-urbanist lg:text-xl lg:text-left
					sm:text-gray-400 sm:font-bold sm:font-urbanist sm:text-xl 
					md:text-gray-400 md:font-bold md:font-urbanist md:text-xl 
					xs:text-gray-400 xs:font-bold xs:font-urbanist xs:text-xl 
					
					"
            >
              Contact Us
            </span>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="flex gap-x-3">
              <span className="text-gray-400 font-bold font-urbanist text-sm">
                Address:
              </span>
              <span className="text-gray-400 font-bold font-urbanist text-sm">
                Suite 1. 3rd Floor, Kings Deck, Alternative Route, Off Chevron
                Drive Lekki-Epe Expressway Lagos
              </span>
            </div>
            <div className="flex gap-x-3">
              <span className="text-gray-400 font-bold font-urbanist text-sm">
                Contact Info:
              </span>
              <span className="text-gray-400 font-bold font-urbanist text-sm">
                +234 1 888 0063
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4  items-center justify-center lg:w-[20%]">
          <div>
            <img
              src={generalshopAboutLogo}
              className="w-[126px] h-[70px]"
              alt=""
            />
          </div>
          <div className=" relative top-[-20px] flex flex-col gap-y-4  items-center justify-center">
            <span className="text-gray-400 font-bold font-urbanist text-sm">
              Call us now at:
            </span>
            <span className="text-gray-400 font-bold font-urbanist text-sm">
              +234 1 888 0063
            </span>
            <div className="flex gap-x-4">
              <FontAwesomeIcon
                icon={faFacebookF}
                className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
              ></FontAwesomeIcon>
              <FontAwesomeIcon
                icon={faPinterest}
                className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
              ></FontAwesomeIcon>
              <FontAwesomeIcon
                icon={faTwitter}
                className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
              ></FontAwesomeIcon>
              <FontAwesomeIcon
                icon={faYoutube}
                className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
