import React , {useState , useEffect , useRef} from 'react'
// import { GreenExtraLandGallery } from '../data'
import { GreenLandCard } from './GreenLandCard'
import axios from 'axios'
import { API } from '../utils/backend'
import { developGenType } from '../typings'
import {PropagateLoader} from 'react-spinners'
import 'flowbite';
import { config } from 'process'


export const GreenLandGallery = () => {
	const [develop , setDevelop] = useState<developGenType>()
	const [loading , setLoading] = useState<Boolean>(false)

	const dataFetchedRef = useRef<boolean>(false);
    


	const getDevelop = async() => {

		setLoading(true)
		try {
		 
		 const response : developGenType = await axios.get(`${API}develops/`,
		{
			headers: {
			'Access-Control-Allow-Origin' : '*',
			}
		 }
		)

		 console.log('res: ', response)
		 setLoading(false)
		 setDevelop(response)
		 
		}
		catch(error) {
		  console.log(error)
		  setLoading(false)

		}
	}
	
     useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
       getDevelop()
	 }, [])




  return (
   <div className='lg:flex lg:items-center lg:justify-center lg:px-5'>
		<div
		className='lg:grid lg:grid-cols-3 lg:gap-x-5 lg:gap-y-4
		md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-4
		sm:grid sm:grid-cols-1 sm:gap-y-4 sm:mb-4
		promo_banner
		'
		

		>




				 { loading === true ?  
				 <div className='col-span-full' >
                     <PropagateLoader color="#17B28D" size={30}/><br/>
					 <span className='lg:text-primaryColor1 lg:text-xl lg:font-manrope lg:font-bold'>Fetching ....</span>

				 </div>
				 
				 
				 : develop && develop?.data?.data?.develops?.data?.filter(x => x.type === 'land').map(ghouse => (
				<GreenLandCard  data={ghouse} />
		      	))}

					

		</div>

		{/* Pagination */}

		{/* <div className='lg:flex lg:items-center lg:justify-center  lg:w-screen lg:h-[3rem]'>
						<nav aria-label="Page navigation example">
							<ul className="inline-flex -space-x-px">
								<li>
								<a href="/#" className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
								</li>
								<li>
								<a href="/#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
								</li>
								<li>
								<a href="/#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">2</a>
								</li>
								<li>
								<a href="/#" aria-current="page" className="px-3 py-2 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</a>
								</li>
								<li>
								<a href="/#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">4</a>
								</li>
								<li>
								<a href="/#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">5</a>
								</li>
								<li>
								<a href="/#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
								</li>
							</ul>
						</nav>

				</div> */}
 </div>
  )
}
