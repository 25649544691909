
import { ButtonPropsType} from '../typings'



export default function Button({children,myStyle ,onClick}: ButtonPropsType) {
  return (
	 <button className={`${myStyle}`} onClick = {onClick}>
		{children}
	 </button>
  )
}