import { GrowGenType } from "../../typings";
import { useEffect, useState } from "react";
// import { useParams , useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API, PAYMENT_PUBLIC } from "../../utils/backend";
// import { PropagateLoader } from "react-spinners";
import axios from "axios";
import { ModalGrow } from "./ModalGrow";
import { Link, useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
// import TandC from "./TandC";
import { growCountryData } from "../../data";
import {
  truncatedText,
  validationRules,
} from "../../common/utils/helper";
import { useAppSelector } from "../../store";

const initialdata = {
  email: "",
  first_name: "",
  last_name: "",
  middle_name: "",
  phone_number: "",
  state_of_residence: "",
  local_government: "",
  next_of_kin: "",
  next_of_kin_phone_number: "",
  relationship: "",
  occupation: "",
};

// const initialConfig = {
//   public_key: PAYMENT_PUBLIC,
//   tx_ref: "",
//   amount: 0,
//   currency: "NGN",
//   payment_options: "card,mobilemoney,ussd",
//   customer: {
//     email: "",
//     phone_number: "",
//     name: "",
//   },
//   customizations: {
//     title: "LandShop",
//     description: "",
//     logo: "https://www.landshop.ng/img/landshoplogo.png",
//   },
// };

type Props = {
  data: GrowGenType | null;
  handleCloseModal: VoidFunction;
};

const GrowForm = ({ data, handleCloseModal }: Props) => {
  // const [value, setValue] = useState<number>(0);
  const [indata, setIndata] = useState(initialdata);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [secondary, setSecondary] = useState<string>("");
  const [propertyType, setPropertyType] = useState<string>("");
  const [check, setCheck] = useState<boolean>(false);
  // const [generateRef, { isLoading }] = useRefGrowProperiesMutation();
  const user = useAppSelector((state) => state.auth?.user);
  // const [config, setConfig] = useState(initialConfig);
  // const handleFlutterPayment = useFlutterwave(config);

  const feature = data
    ? truncatedText(data.features[0].split("•        ").join(""), 50)
    : "";

  useEffect(() => {
    if (!user?._id) return;
    (Object.keys(indata) as Array<keyof typeof indata>).forEach((key) => {
      setIndata((prev) => ({
        ...prev,
        [key]: user[key] ? user[key] : "",
      }));
    });
  }, [user?._id]);

  // useEffect(() => {
  //   const customer = config.customer;
  //   (Object.keys(customer) as Array<keyof typeof customer>).forEach((key) => {
  //     switch (key) {
  //       case "name":
  //         setConfig((prev) => ({
  //           ...prev,
  //           customer: {
  //             ...prev.customer,
  //             [key]: `${indata.first_name}${` ${indata.middle_name}`} ${
  //               indata.last_name
  //             }`,
  //           },
  //         }));

  //         break;

  //       default:
  //         setConfig((prev) => ({
  //           ...prev,
  //           customer: { ...prev.customer, [key]: indata[key] },
  //         }));
  //         break;
  //     }
  //   });
  // }, [JSON.stringify(indata)]);

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case "phone_number":
      case "next_of_kin_phone_number":
      case "mortgage_bank_account_number":
        if (value.length > 0 && validationRules.number.test(value)) {
          setIndata((prev) => ({ ...prev, [name]: value }));
        }
        if (value.length === 0) {
          setIndata((prev) => ({ ...prev, [name]: value }));
        }
        break;

      default:
        setIndata((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const checkForm = () => {
    let ans = false;
    let key: keyof typeof indata;
    for (key in indata) {
      if (key === "middle_name") {
        continue;
      }
      if (indata[key] === "") {
        ans = true
      }
    }
    return ans || !check
  }

  // const handleModal = () => setModal(true);
  // const handleCloseModal = () => setModal(false);

  const handleGenerateRef = () => {
    // if (!data._id) return;
    // setLoading(true);
    // generateRef({ id: data._id })
    //   .unwrap()
    //   .then((refRes) => {
    //     setConfig((prev) => ({
    //       ...prev,
    //       tx_ref: refRes.reference,
    //       customizations: {
    //         ...prev.customizations,
    //         description: `Payment for LandShop ${data.property_name} @ ${data.address}`,
    //       },
    //       amount: data.current_price,
    //     }));
    //     handleFlutterPayment({
    //       callback: (response) => {
    //         console.log(response);
    //         setModal(false);
    //         setIndata({ ...initialdata });
    //       },
    //       onClose: () => {
    //         setModal(false);
    //         setIndata({ ...initialdata });
    //       },
    //     });
    //   })
    //   .catch((err) => console.error(err))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  // const navigate = useNavigate();

  // console.log(growCountryData)

  const submitGrowForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      (Object.keys(indata) as Array<keyof typeof indata>).forEach((key) => {

        if (indata[key] === "" && key !== "middle_name") {
          Swal.fire({
            icon: "error",
            title: `${key.split("_").join(" ")} cannot be empty!`,
          });
          return;
        }
      });

      const req = {
        ...indata,
        grow_id: data?._id || "",
      };

      const response = await axios.post(`${API}submit/grow/form/`, req);
      console.log(response);

      if (response.status === 200) {
        setCheck(false);
        handleCloseModal();
        setIndata({ ...initialdata });
        setSecondary("");
        setPropertyType("");
        setDateOfBirth("");
        Swal.fire({
          icon: "success",
          title: response.data.status,
        });
      }
      // }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error encountered",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {data?._id ? (
        <ModalGrow close={handleCloseModal}>
          <form
            className="flex items-center justify-center flex-col"
            onSubmit={submitGrowForm}
          >
            <div className="flex items-center justify-center pt-5 pb-5">
              <h3 className="text-2xl font-urbanist text-[#494E50] font-bold">
                Please Complete your profile to proceed
              </h3>
            </div>

            <div
              className="
				  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px]
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px]
				  after:content-['_*'] after:text-red-600 after:self-start
				  
				  
				  "
            >
              <input
                type="text"
                className=" border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.first_name}
                name="first_name"
                onChange={changeHandler}
                placeholder="First Name"
              />
            </div>

            <div
              className="
				  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px]
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px]
				  after:content-['_*'] after:text-red-600 after:self-start
				  
				  
				  "
            >
              <input
                type="text"
                className=" border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.last_name}
                name="last_name"
                onChange={changeHandler}
                placeholder="Last Name"
              />
            </div>
            <div
              className="
				  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px]
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px]
				  
				  
				  "
            >
              <input
                type="text"
                className=" border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.middle_name}
                name="middle_name"
                onChange={changeHandler}
                placeholder=" Middle Name"
              />
            </div>

            <div
              className="
				  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px]
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px]
				  after:content-['_*'] after:text-red-600 after:self-start
				  
				  
				  "
            >
              <input
                type="tel"
                className=" border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.phone_number}
                name="phone_number"
                onChange={changeHandler}
                placeholder=" Phone"
              />
            </div>

            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px]
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
              <input
                type="text"
                className="border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.next_of_kin}
                name="next_of_kin"
                onChange={changeHandler}
                placeholder="Next of Kin"
              />
            </div>
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px]
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
              <input
                type="tel"
                className="border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.next_of_kin_phone_number}
                name="next_of_kin_phone_number"
                onChange={changeHandler}
                placeholder="Next of Kin Phone Number"
              />
            </div>
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
              <input
                type="text"
                className="border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.relationship}
                name="relationship"
                onChange={changeHandler}
                placeholder="Relationship"
              />
            </div>
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
              <input
                type="email"
                className="border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.email}
                name="email"
                onChange={changeHandler}
                placeholder="Email"
              />
            </div>
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
              <input
                type="text"
                className="border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                placeholder="Date of Birth"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
              />
            </div>
           
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
              <select
                className="xl:flex xl:items-center xl:justify-center xl:w-[547.41px] xl:h-[50.33px] xl:rounded-md
                   lg:flex lg:items-center lg:justify-center lg:w-[547.41px] lg:h-[50.33px] lg:rounded-md
                   sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[50.33px] sm:rounded-md
                   md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[50.33px] md:rounded-md
                   xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[50.33px] xs:rounded-md
                   "
                value={indata.state_of_residence}
                name="state_of_residence"
                onChange={changeHandler}
              >
                <option value="" disabled>
                  Select State of Residence
                </option>
                {growCountryData?.map((x, i) => (
                  <option
                    value={x.state.name}
                    defaultValue="Select your State"
                    key={i}
                  >
                    {x.state.name}
                  </option>
                ))}
              </select>
            </div>
            
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
              <select
                className="xl:flex xl:items-center xl:justify-center xl:w-[547.41px] xl:h-[50.33px] xl:rounded-md
                   lg:flex lg:items-center lg:justify-center lg:w-[547.41px] lg:h-[50.33px] lg:rounded-md
                   sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[50.33px] sm:rounded-md
                   md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[50.33px] md:rounded-md
                   xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[50.33px] xs:rounded-md
                   "
                value={indata.local_government}
                name="local_government"
                onChange={changeHandler}
                disabled={indata.state_of_residence === ""}
              >
                <option value="" disabled>
                  Select Local Government
                </option>
                {growCountryData
                  .filter((x) => x.state.name === indata.state_of_residence)
                  .map((x) => x.state.locals)
                  .map((x) =>
                    x.map((x, i) => (
                      <option
                        value={x.name}
                        key={i}
                        defaultValue="Select your Local government"
                      >
                        {x.name}
                      </option>
                    ))
                  )}
              </select>
            </div>
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
              <input
                type="text"
                className="border-[#494E50] border focus:ring-0 w-[548px] h-[44px] rounded-md"
                value={indata.occupation}
                name="occupation"
                onChange={changeHandler}
                placeholder="Occupation"
              />
            </div>
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[60.33px] after:content-['_*'] after:text-red-600 after:self-start"
            >
               <select
                className="xl:flex xl:items-center xl:justify-center xl:w-[547.41px] xl:h-[50.33px] xl:rounded-md
                   lg:flex lg:items-center lg:justify-center lg:w-[547.41px] lg:h-[50.33px] lg:rounded-md
                   sm:flex sm:items-center sm:justify-center sm:w-[400.41px] sm:h-[50.33px] sm:rounded-md
                   md:flex md:items-center md:justify-center md:w-[400.41px] md:h-[50.33px] md:rounded-md
                   xs:flex xs:items-center xs:justify-center xs:w-[247.41px] xs:h-[50.33px] xs:rounded-md
                   "
                value={propertyType}
                name="property_type"
                onChange={e => {setPropertyType(e.target.value)}}
              >
                <option value="" disabled>
                  Select Property Type
                </option>
                <option value="land">
                  Land
                </option>
                <option value="house">
                  House
                </option>
                
              </select>
            </div>
            
           
            <div
              className="  xl:flex xl:items-center xl:justify-center xl:gap-x-3 xl:w-[647.41px] xl:h-[60.33px] 
				  lg:flex lg:items-center lg:justify-center lg:gap-x-3 lg:w-[647.41px] lg:h-[60.33px]
				  sm:flex sm:items-center sm:justify-center sm:gap-x-3 sm:w-[400.41px] sm:h-[60.33px]
				  md:flex md:items-center md:justify-center md:gap-x-3 md:w-[400.41px] md:h-[60.33px]
				  xs:flex xs:items-center xs:justify-center xs:gap-x-3 xs:w-[247.41px] xs:h-[60.33px]"
            >
              <input
                type="checkbox"
                className="border-primaryColor1 rounded-sm"
                onChange={(e) => setCheck(e.target.checked)}
              />
              <div>
                By creating an account, you agree to the{" "}
                <Link
                  to="/termsandcondition"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="text-primaryColor1 underline">
                    Terms of use
                  </span>
                </Link>{" "}
                and{" "}
                <Link
                  to="/termsandcondition"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="text-primaryColor2 underline">
                    Privacy Policy
                  </span>
                </Link>
              </div>
            </div>
            <div
              className="
				  xl:flex xl:flex-row xl:items-center xl:justify-around xl:ml-[-4rem]
				  lg:flex lg:flex-row lg:items-center lg:justify-around lg:ml-[-4rem]
				  md:flex md:flex-row md:items-center md:justify-around md:ml-[-2rem]
				  sm:flex sm:flex-row sm:items-center sm:justify-around sm:ml-[-2rem]
				  xs:flex xs:flex-col xs:items-start xs:justify-start xs:gap-y-[2rem]
				  
				  "
            >
              <div className="flex items-center justify-center mt-[4rem] pb-5">
                {loading ? (
                  <PropagateLoader color="#17B28D" size={30} />
                ) : (
                  <button
                    disabled={checkForm()}
                    className="bg-primaryColor1 text-white py-2 px-8 rounded-md cursor-pointer disabled:bg-gray-10 disabled:text-gray-30"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </ModalGrow>
      ) : null}

      {/* <TandC
      //  name = {name}
      //  kin = {kin}
      //  nhf = {nhf}
      //  primary = {primary}
      //  secondary = {secondary}
      //  account = {account}
      //  propertyType = {propertyType}
      //  govt = {govt}
      //  residenceState = {residenceState}
      //  occupation = {occupation}
      //  email = {email}
    

      /> */}
    </>
  );
};

export default GrowForm;
