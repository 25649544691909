import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { registerUser } from "../store/auth/thunk";
import { useAppDispatch } from "../store";
import routes from "../common/routes";

const initialstate = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  password: "",
  loading: false,
};

const SignUp = () => {
  const navigate = useNavigate();
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.data) return;

    (Object.keys(inData) as Array<keyof typeof inData>).forEach((key) => {
      setInData((prev) => ({
        ...prev,
        [key]: state?.data[key] ? state?.data[key] : initialstate[key],
      }));
    });
  }, []); //eslint-disable-line

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));
  };

  const submitDevelopForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setInData((prev) => ({ ...prev, loading: true }));
    const body: any = { ...inData };
    delete body.loading;
    dispatch(registerUser(body))
      .unwrap()
      .then(() => {
        if (state?.prevPathname) {
          navigate(state?.prevPathname, { replace: true });
          state?.action();
        } else {
          navigate(routes.profile);
        }

        setInData({ ...initialstate });
      })
      .catch((err) => console.error(err))
      .finally(() => setInData((prev) => ({ ...prev, loading: false })));
  };
  return (
    <form
      className="flex flex-col justify-center gap-y-4.5 lg:py-8 py-12 h-screen overflow-y-scroll px-8 lg:pr-20"
      onSubmit={submitDevelopForm}
    >
      <h4 className="text-3xl font-bold self-start pb-4.5">
        Create An Account
      </h4>

      <div className="flex flex-col gap-y-2">
        <div className="">
          <label className="font-poppins text-xs font-normal after:content-['_*'] after:text-red-600">
            First Name
          </label>
        </div>
        <div>
          <input
            type="text"
            className="w-full px-2 rounded-lg border border-[#482121] h-14"
            name="first_name"
            value={inData.first_name}
            onChange={changeHandler}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="">
          <label className="font-poppins text-xs font-normal after:content-['_*'] after:text-red-600">
            Last Name
          </label>
        </div>
        <div>
          <input
            type="text"
            className="w-full px-2 rounded-lg border border-[#482121] h-14"
            name="last_name"
            value={inData.last_name}
            onChange={changeHandler}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="">
          <label className="font-poppins text-xs font-normal after:content-['_*'] after:text-red-600">
            Email
          </label>
        </div>
        <div>
          <input
            type="email"
            className="w-full px-2 rounded-lg border border-[#482121] h-14"
            name="email"
            value={inData.email}
            onChange={changeHandler}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="">
          <label className="font-poppins text-xs font-normal after:content-['_*'] after:text-red-600">
            Password
          </label>
        </div>
        <div>
          <input
            type="password"
            className="w-full px-2 rounded-lg border border-[#482121] h-14"
            name="password"
            value={inData.password}
            onChange={changeHandler}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="">
          <label className="font-poppins text-xs font-normal after:content-['_*'] after:text-red-600">
            Phone Number
          </label>
        </div>
        <div>
          <input
            type=""
            className="w-full px-2 rounded-lg border border-[#482121] h-14"
            name="phone_number"
            value={inData.phone_number}
            onChange={changeHandler}
          />
        </div>
      </div>
      <div className="flex flex-col justify-start gap-y-2">
        {inData.loading ? (
          <PropagateLoader color="#17B28D" size={30} />
        ) : (
          <button className="text-white bg-primaryColor1 lg:self-start  py-4 px-28 rounded-2xl font-poppins font-bold">
            Sign up
          </button>
        )}
        <p className="font-poppins text-xs">
          You have an account?{" "}
          <Link
            className="text-primaryColor2 cursor-pointer"
            to={routes.nonprotected.login}
          >
            {" "}
            Sign In
          </Link>
        </p>
      </div>
    </form>
  );
};
export default SignUp;
