import React from 'react'
import { developHouseCard} from '../typings'
import  greenBarge from '../assets/discountBarge.png'
import { Link } from 'react-router-dom'



export const GreenHouseCard = (props :  developHouseCard) => {
	
  return (
	<div className='
	xl:w-[661px] xl:h-[673px] xl:bg-white xl:shadow-2xl xl:rounded-2xl xl:mt-4 xl:flex xl:flex-row xl:items-center xl:justify-center xl:relative
	lg:w-[388px] lg:h-[394.84px] lg:bg-white lg:shadow-2xl lg:rounded-2xl lg:mt-4 lg:flex  lg:items-center lg:justify-center lg:relative
	sm:w-[388px] sm:h-[394.84px] sm:bg-white sm:shadow-2xl sm:rounded-2xl sm:mt-4 sm:flex  sm:items-center sm:justify-center sm:relative
	md:w-[388px] md:h-[394.84px] md:bg-white md:shadow-2xl md:rounded-2xl md:mt-4 md:flex  md:items-center md:justify-center md:relative
	xs:w-[328px] xs:h-[381.44px] xs:bg-white xs:shadow-2xl xs:rounded-2xl xs:mt-4 xs:flex  xs:items-center xs:justify-center xs:relative
	'>
		   <Link to = {`/20daysofchristmas/${props.data._id}`}>
			<div className=''>
				<img src={props.data.images[0]} alt="" className=' object-contain
                xl:w-[636px] xl:h-[646px] xl:shadow-2xl xl:rounded-2xl
				lg:w-[374.32px] lg:h-[380.18px] lg:shadow-2xl lg:rounded-2xl
				sm:w-[374.32px] sm:h-[380.18px] sm:shadow-2xl sm:rounded-2xl
				md:w-[374.32px] md:h-[380.18px] md:shadow-2xl md:rounded-2xl
				xs:w-[328.32px] xs:h-[380.18px] xs:shadow-2xl xs:rounded-2xl
				' />
			</div>
			<div className ="xl:w-[453px] xl:h-[112px] xl:rounded-2xl xl:shadow-2xl xl:absolute xl:bottom-0 
			xl:left-0 xl:bg-white xl:flex xl:flex-col xl:items-center xl:justify-center
			lg:w-[388px] lg:h-[99px] lg:rounded-2xl lg:shadow-2xl lg:absolute lg:bottom-0 
			lg:left-0 lg:bg-white lg:flex lg:flex-col lg:items-center lg:justify-centlg
			sm:w-[388px] sm:h-[99px] sm:rounded-2xl sm:shadow-2xl sm:absolute sm:bottom-0 
			sm:left-0 sm:bg-white sm:flex sm:flex-col sm:items-center sm:justify-center
			xs:w-[328px] xs:h-[99px] xs:rounded-2xl xs:shadow-2xl xs:absolute xs:bottom-0 
			xs:left-0 xs:bg-white xs:flex xs:flex-col xs:items-center xs:justify-center

			
			">
                      <div className='xl:flex xl:items-center xl:justify-center
					  lg:flex lg:items-center lg:justify-center
					  sm:flex sm:items-center sm:justify-center
					  xs:flex xs:items-center xs:justify-center
					  '>
						    <div className='xl:w-[183px] xl:h-[53px] xl:flex xl:items-center xl:justify-center
							 lg:w-[134px] lg:h-[53px] lg:flex lg:items-center lg:justify-center
							 sm:w-[134px] sm:h-[53px] sm:flex sm:items-center sm:justify-center
							 xs:w-[134px] xs:h-[53px] xs:flex xs:items-center xs:justify-center
							'>
                                  <span className='lg:font-manirope lg:text-xl'>{props.data.property_name}</span> 
							</div>
							<div className='xl:w-[157px] xl:h-[41px] xl:flex xl:items-center xl:justify-center
								lg:w-[115px] lg:h-[41px] lg:flex lg:items-center lg:justify-center
								sm:w-[115px] sm:h-[41px] sm:flex sm:items-center sm:justify-center
								xs:w-[115px] xs:h-[41px] xs:flex xs:items-center xs:justify-center
							'>
                                  <button className='
								  xl:bg-transparent xl:border xl:border-primaryColor1 xl:rounded-xl xl:text-gray-300 xl:px-6 xl:py-1 xl:font-manirope xl:text-md
								  lg:bg-transparent lg:border lg:border-primaryColor1 lg:rounded-xl lg:text-gray-300 lg:px-6 lg:py-1 lg:font-manirope lg:text-md
								  sm:bg-transparent sm:border sm:border-primaryColor1 sm:rounded-xl sm:text-gray-300 sm:px-6 sm:py-1 sm:font-manirope sm:text-sm
								  xs:bg-transparent xs:border xs:border-primaryColor1 xs:rounded-xl xs:text-gray-300 xs:px-6 xs:py-1 xs:font-manirope xs:text-sm
								  
								  '>See More</button>
							</div>

					  </div>
					  <div className='
					  lg:flex lg:items-center lg:justify-center
					  xl:flex xl:items-center xl:justify-center
					  sm:flex sm:items-center sm:justify-center
					  xs:flex xs:items-center xs:justify-centxs
					 
					 '>
                             <div className='
							 xl:w-[173px] xl:h-[40.45px] xl:flex xl:items-center xl:justify-center
							 lg:w-[125.6px] lg:h-[2.69px] lg:flex lg:items-center lg:justify-center
							 sm:w-[125.6px] sm:h-[2.69px] sm:flex sm:items-center sm:justify-center  xs:mr-2
							 xs:w-[125.6px] xs:h-[2.69px] xs:flex xs:items-center xs:justify-center
							 
							 '>
						    	 <span className='
								 lg:font-manirope lg:text-md lg:text-primaryColor1 lg:font-bold
								 xl:font-manirope xl:text-md xl:text-primaryColor1 xl:font-bold
								 sm:font-manirope sm:text-md sm:text-primaryColor1 sm:font-bold xs:line-through
								 xs:font-manirope xs:text-md xs:text-primaryColor1 xs:font-bold
								 
								 '>NGN {" "}{props.data.previous_price.toLocaleString()} </span> 
							 </div>
							 <div className='
							 xl:w-[168px] xl:h-[40.45px] xl:flex xl:items-center xl:justify-center
							 lg:w-[123px] lg:h-[40px] lg:flex lg:items-center lg:justify-center
							 sm:w-[123px] sm:h-[40px] sm:flex sm:items-center sm:justify-center
							 xs:w-[123px] xs:h-[40px] xs:flex xs:items-center xs:justify-center
							 
							 '>
							     <span className='
								 lg:font-manirope lg:text-md lg:text-primaryColor2 lg:font-bold
								 xl:font-manirope xl:text-md xl:text-primaryColor2 xl:font-bold
								 sm:font-manirope sm:text-md sm:text-primaryColor2 sm:font-bold
								 xs:font-manirope xs:text-md xs:text-primaryColor2 xs:font-bold
								 
								 '>NGN {" "}{props.data.current_price.toLocaleString()} </span> 
							 </div>
					  </div>
			</div>
			{/* <div className ="
			xl:w-[124px] xl:h-[123px] xl:rounded-[100%] xl:shadow-2xl xl:absolute xl:bottom-4 xl:right-3 
			xl:bg-white xl:flex xl:flex-col xl:items-center xl:justify-center 
			sm:w-[115px] sm:h-[115px] sm:rounded-[100%] sm:shadow-2xl sm:absolute sm:bottom-[6.4rem] sm:right-4
			sm:bg-white sm:flex sm:flex-col sm:items-center sm:justify-center 
			lg:w-[115px] lg:h-[115px] lg:rounded-[100%] lg:shadow-2xl lg:absolute lg:bottom-[6.4rem] lg:right-4
			lg:bg-white lg:flex lg:flex-col lg:items-center lg:justify-center 
			xs:w-[115px] xs:h-[115px] xs:rounded-[100%] xs:shadow-2xl xs:absolute xs:bottom-[6.4rem] xs:right-4
			xs:bg-white xs:flex xs:flex-col xs:items-center xs:justify-center 
			">
                 <div className='
				 xl:w-[99px] xl:h-[30px] xl:flex xl:items-center xl:justify-center
				 sm:w-[91.81px] sm:h-[25.29px] sm:flex sm:items-center sm:justify-center
				 xs:w-[91.81px] xs:h-[25.29px] xs:flex xs:items-center xs:justify-center
				 lg:w-[91.81px] lg:h-[25.29px] lg:flex lg:items-center lg:justify-center
				 
				 '>
                   <span className='
				   lg:text-primaryColor1 lg:text-sm lg:font-manrope lg:font-bold
				   xl:text-primaryColor1 xl:text-sm xl::font-manrope xl::font-bold
				   sm:text-primaryColor1 sm:text-sm sm:font-manrope sm:font-bold
				   xs:text-primaryColor1 xs:text-sm xs:font-manrope xs:font-bold
				   
				   '>20% 0FF</span>
				 </div>
				 <div className='
				 lg:w-[99px] lg:h-[14px] lg:flex lg:items-center lg:justify-center
				 xl:w-[99px] xl:h-[14px] xl:flex xl:items-center xl:justify-center
				 sm:w-[99px] sm:h-[13.09px] sm:flex sm:items-center sm:justify-center 
				 xs:w-[99px] xs:h-[13.09px] xs:flex xs:items-center xs:justify-center 
				 
				 '>
				 <span className='
				 lg:text-primaryColor2 lg:text-xs lg:font-manrope lg:font-bold
				 xl:text-primaryColor2 xl:text-xs xl:font-manrope xl:font-bold
				 sm:text-primaryColor2 sm:text-xs sm:font-manrope sm:font-bold
				 xs:text-primaryColor2 xs:text-xs xs:font-manrope xs:font-boxs
				 
				 '>25TH-26TH DEC</span>
				 </div>
			</div> */}
			{/* <div className='
			lg:absolute lg:top-0 lg:left-0
			sm:absolute sm:top-0 sm:left-0
			xs:absolute xs:top-0 xs:left-0
			xl:absolute xl:top-0 xl:left-0
			
			'>
		     	<img src={greenBarge} className="
				
				xl:w-[110px] xl:h-[165px]
				lg:w-[84px] lg:h-[126px]
				sm:w-[84px] sm:h-[126px]
				xs:w-[84px] xs:h-[126px]
				
				
				" alt="" />
				<div className='
				xl:absolute xl:top-7 xl:left-10
				lg:absolute lg:top-4 lg:left-6
				sm:absolute sm:top-4 sm:left-6
				xs:absolute xs:top-4 xs:left-6
				
				'>
                    <span className='
					lg:text-primaryColor1 lg:font-bold lg:font-manrope lg:text-md
					xl:text-primaryColor1 xl:font-bold xl:font-manrope xl:text-md
					sm:text-primaryColor1 sm:font-bold sm:font-manrope sm:text-md
					xs:text-primaryColor1 xs:font-bold xs:font-manrope xs:text-md
					
					'>{props?.data?.discount}% {" "}<br/> OFF</span>
				</div>
			</div> */}

			</Link>

  </div>
  )
}


