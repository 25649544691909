import { useState } from "react";
import Nav from "../components/Nav";
import generalshopAboutLogo from "../assets/landshopAboutLogo.png";
import generalshopAboutFaceBook from "../assets/landshopAboutFaceBookWhite.png";
import generalshopAboutTwitter from "../assets/landshopAboutTwitterWhite.png";
import generalshopAboutYoutube from "../assets/landshopAboutYoutubeWhite.png";
import generalshopAboutPinterest from "../assets/landshopAboutPinterestWhite.png";
import search from "../assets/search.png";
import plus from "../assets/plus.png";
import minus from "../assets/minus.png";
import FAQ from "../assets/FAQs.gif";
import General from "../assets/general_faq.png";
import Grow from "../assets/landshop_grow_logo.png";
import Invest from "../assets/Invest_1.png";
import Develop from "../assets/develop_one.png";
import Thrift from "../assets/thrift_logo.png";
import {
  faFacebookF,
  faPinterest,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { FAQdata } from "../data";
import { FooterBlack } from "../components/FooterBlack";

export default function FAQClone() {
  const [step, setStep] = useState(1);
  const [general1, setgeneral1] = useState(false);
  const [general2, setgeneral2] = useState(false);
  const [general3, setgeneral3] = useState(false);
  const [general4, setgeneral4] = useState(false);
  const [general5, setgeneral5] = useState(false);
  const [general6, setgeneral6] = useState(false);
  const [general7, setgeneral7] = useState(false);
  const [general8, setgeneral8] = useState(false);
  const [general9, setgeneral9] = useState(false);
  const [general10, setgeneral10] = useState(false);
  const [general11, setgeneral11] = useState(false);
  const [general12, setgeneral12] = useState(false);
  const [general13, setgeneral13] = useState(false);
  const [general14, setgeneral14] = useState(false);
  const [general15, setgeneral15] = useState(false);
  const [general16, setgeneral16] = useState(false);
  const [general17, setgeneral17] = useState(false);
  const [general18, setgeneral18] = useState(false);
  const [general19, setgeneral19] = useState(false);
  const [general20, setgeneral20] = useState(false);
  const [general21, setgeneral21] = useState(false);
  const [grow1, setgrow1] = useState(false);
  const [grow2, setgrow2] = useState(false);
  const [grow3, setgrow3] = useState(false);
  const [grow4, setgrow4] = useState(false);
  const [grow5, setgrow5] = useState(false);
  const [grow6, setgrow6] = useState(false);
  const [grow7, setgrow7] = useState(false);
  const [grow8, setgrow8] = useState(false);
  const [invest1, setinvest1] = useState(false);
  const [invest2, setinvest2] = useState(false);
  const [invest3, setinvest3] = useState(false);
  const [invest4, setinvest4] = useState(false);
  const [invest5, setinvest5] = useState(false);
  const [invest6, setinvest6] = useState(false);
  const [invest7, setinvest7] = useState(false);
  const [invest8, setinvest8] = useState(false);
  const [invest9, setinvest9] = useState(false);
  const [invest10, setinvest10] = useState(false);
  const [develop1, setdevelop1] = useState(false);
  const [develop2, setdevelop2] = useState(false);
  const [develop3, setdevelop3] = useState(false);
  const [develop4, setdevelop4] = useState(false);
  const [develop5, setdevelop5] = useState(false);
  const [develop6, setdevelop6] = useState(false);
  const [develop7, setdevelop7] = useState(false);
  const [develop8, setdevelop8] = useState(false);
  const [thrift1, setthrift1] = useState(false);
  const [thrift2, setthrift2] = useState(false);
  const [thrift3, setthrift3] = useState(false);
  const [thrift4, setthrift4] = useState(false);
  const [thrift5, setthrift5] = useState(false);
  const [thrift6, setthrift6] = useState(false);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const getgeneral = () => {
    setStep(1);
  };

  const getgrow = () => {
    setStep(2);
  };
  const getinvest = () => {
    setStep(3);
  };
  const getdevelop = () => {
    setStep(4);
  };
  const getthrifts = () => {
    setStep(5);
  };
  const getSearch = () => {
    setStep(6);
  };

  const getInfogeneral1 = () => {
    setgeneral1(!general1);
  };

  const getInfogeneral2 = () => {
    setgeneral2(!general2);
  };

  const getInfogeneral3 = () => {
    setgeneral3(!general3);
  };
  const getInfogeneral4 = () => {
    setgeneral4(!general4);
  };
  const getInfogeneral5 = () => {
    setgeneral5(!general5);
  };
  const getInfogeneral6 = () => {
    setgeneral6(!general6);
  };
  const getInfogeneral7 = () => {
    setgeneral7(!general7);
  };
  const getInfogeneral8 = () => {
    setgeneral8(!general8);
  };
  const getInfogeneral9 = () => {
    setgeneral9(!general9);
  };
  const getInfogeneral10 = () => {
    setgeneral10(!general10);
  };
  const getInfogeneral11 = () => {
    setgeneral11(!general11);
  };
  const getInfogeneral12 = () => {
    setgeneral12(!general12);
  };
  const getInfogeneral13 = () => {
    setgeneral13(!general13);
  };
  const getInfogeneral14 = () => {
    setgeneral14(!general14);
  };
  const getInfogeneral15 = () => {
    setgeneral15(!general15);
  };
  const getInfogeneral16 = () => {
    setgeneral16(!general16);
  };
  const getInfogeneral17 = () => {
    setgeneral17(!general17);
  };
  const getInfogeneral18 = () => {
    setgeneral18(!general18);
  };
  const getInfogeneral19 = () => {
    setgeneral19(!general19);
  };
  const getInfogeneral20 = () => {
    setgeneral20(!general20);
  };
  const getInfogeneral21 = () => {
    setgeneral21(!general21);
  };

  const getInfogrow1 = () => {
    setgrow1(!grow1);
  };
  const getInfogrow2 = () => {
    setgrow2(!grow2);
  };

  const getInfogrow3 = () => {
    setgrow3(!grow3);
  };

  const getInfogrow4 = () => {
    setgrow4(!grow4);
  };
  const getInfogrow5 = () => {
    setgrow5(!grow5);
  };
  const getInfogrow6 = () => {
    setgrow6(!grow6);
  };
  const getInfogrow7 = () => {
    setgrow7(!grow7);
  };
  const getInfogrow8 = () => {
    setgrow8(!grow8);
  };

  const getInfoinvest1 = () => {
    setinvest1(!invest1);
  };
  const getInfoinvest2 = () => {
    setinvest2(!invest2);
  };
  const getInfoinvest3 = () => {
    setinvest3(!invest3);
  };
  const getInfoinvest4 = () => {
    setinvest4(!invest4);
  };
  const getInfoinvest5 = () => {
    setinvest5(!invest5);
  };
  const getInfoinvest6 = () => {
    setinvest6(!invest6);
  };
  const getInfoinvest7 = () => {
    setinvest7(!invest7);
  };
  const getInfoinvest8 = () => {
    setinvest8(!invest8);
  };
  const getInfoinvest9 = () => {
    setinvest9(!invest9);
  };
  const getInfoinvest10 = () => {
    setinvest10(!invest10);
  };

  const getInfodevelop1 = () => {
    setdevelop1(!develop1);
  };
  const getInfodevelop2 = () => {
    setdevelop2(!develop2);
  };
  const getInfodevelop3 = () => {
    setdevelop3(!develop3);
  };
  const getInfodevelop4 = () => {
    setdevelop4(!develop4);
  };
  const getInfodevelop5 = () => {
    setdevelop5(!develop5);
  };
  const getInfodevelop6 = () => {
    setdevelop6(!develop6);
  };
  const getInfodevelop7 = () => {
    setdevelop7(!develop7);
  };
  const getInfodevelop8 = () => {
    setdevelop8(!develop8);
  };

  const getInfothrift1 = () => {
    setthrift1(!thrift1);
  };
  const getInfothrift2 = (id: number) => {
    setthrift2(!thrift2);
  };
  const getInfothrift3 = () => {
    setthrift3(!thrift3);
  };
  const getInfothrift4 = () => {
    setthrift4(!thrift4);
  };
  const getInfothrift5 = () => {
    setthrift5(!thrift5);
  };
  const getInfothrift6 = () => {
    setthrift6(!thrift6);
  };
  const controlShowButton = (id: number) => {
    FAQdata.map((item) => {
      if (item.id === id) {
        // console.log(id);
        // setthrift2(!thrift2);
        // getInfothrift2(id)
        setOpen((id) => !open);
      }
    });
  };
  const [seeContent, setSeeContent] = useState(true);
  let findCon: any;
  // let check = FAQdata.every(element => element.isChecked === false);
  const checkItem = () => {
    // console.log(findCon);
    if (findCon === -1) {
      setSeeContent(false);
    }
  };

  return (
    <div className="sm:h-[170rem] md:h-[150rem] xl:h-full lg:h-[130rem] xs: h-[180rem]">
      <Nav />

      <div className="bg-white h-[auto] w-screen">
        <div
          className="
			xl:w-[100%] xl:h-[400px] xl:flex xl:items-center xl:justify-center
			lg:w-[100%] lg:h-[400px] lg:flex lg:items-center lg:justify-center
			md:w-[100%] md:h-[400px] md:flex md:items-center md:justify-center
			sm:w-[100%] sm:h-[400px] sm:flex sm:items-center sm:justify-center sm:mb-3
      xs:w-[100%] xs:h-[400px] xs:flex xs:items-center xs:justify-center 
			
			"
        >
          <img src={FAQ} alt="" />
        </div>

        <div className="w-screen sm:h-[20rem] flex flex-col items-center justify-center xs:h-[34rem]">
          {/* Esther */}

          {/* search bar */}

          <div
            className="
			xl:flex xl:items-center xl:justify-between xl:bg-white xl:rounded-lg xl:shadow-2xl xl:w-[1086px] xl:h-[69px] xl:gap-x-10
			lg:flex lg:items-center lg:justify-between lg:bg-white lg:rounded-lg lg:shadow-2xl lg:w-[866px] lg:h-[69px] lg:gap-x-10
			md:flex md:items-center md:justify-between md:bg-white md:rounded-lg md:shadow-2xl md:w-[610px] md:h-[69px] md:gap-x-10 md:mt-20
			sm:flex sm:items-center sm:justify-between sm:bg-white sm:rounded-lg sm:shadow-2xl sm:w-[450px] sm:h-[69px] sm:gap-x-10 sm:mt-32
			xs:bg-white xs:rounded-lg xs:shadow-2xl xs:w-[90%] xs:h-[69px] xs:gap-x-1 
			
			"
          >
            <div
              className="
				lg:flex-3 lg:px-4 md:ml-2 sm:ml-2 xs:float-left  sm:pt-0 xs:pl-3  xs:pt-6
			                                        
				
				"
            >
              <img src={search} alt="" />
            </div>

            <div
              className="
				lg:flex-1
				md:block
				sm:block sm:pt-0
				xs:block xs:float-left xs:pt-4 pl-3 xs:w-[60%]
				"
            >
              <input
                type="text"
                className="
					xl:w-[50rem]  xl:font-manrope xl:outline-none focus:ring-0 xl:border-none
					lg:w-[40rem]  lg:font-manrope lg:outline-none lg:border-none 
					md:w-[24rem] md:font-manrope md:outline-none md:border-none
					sm:w-[14rem] sm:font-manrope sm:outline-none sm:border-none
          xs:w-[80%] xs:font-manrope xs:outline-none xs:border-none
					
					
					
					
					"
                placeholder="Ask a Question...."
                onChange={(event) => {
                  setQuery(event.target.value);
                  setSeeContent(true);
                }}
              />
            </div>
            <div className="xs:w-[20%] xs:float-right">
              <button
                onClick={() => {
                  getSearch();
                  checkItem();
                }}
                className="
					xl:px-4 xl:bg-primaryColor2 xl:text-white xl:border xl:rounded-r-lg xl:h-[69px] xl:font-manrope xl:cursor-pointer xl:hover:bg-primaryColor1 
					lg:px-4 lg:bg-primaryColor2 lg:text-white lg:border lg:rounded-r-lg lg:h-[69px] lg:font-manrope lg:cursor-pointer lg:hover:bg-primaryColor1 
					md:px-4 md:bg-primaryColor2 md:text-white md:border 
					md:rounded-r-lg md:h-[69px] md:font-manrope md:cursor-pointer md:hover:bg-primaryColor1 
					sm:px-4 sm:bg-primaryColor2 sm:text-white sm:border 
					sm:rounded-r-lg sm:h-[69px] sm:font-manrope sm:cursor-pointer sm:hover:bg-primaryColor1 
					xs:px-4 xs:bg-primaryColor2 xs:text-white xs:border 
					xs:rounded-r-lg xs:h-[69px] xs:font-manrope xs:cursor-pointer float-right xs:hover:bg-primaryColor1 
			
					"
              >
                Search
              </button>
            </div>
          </div>

          {/* Answers to frequently asked Questions */}

          <div className="flex flex-col items-center justify-center gap-y-[2rem] pt-10 pb-5">
            <div className="mt-5 pb-10 xs:text-center px-3">
              <span className="font-manirope text-lg text-black text-opacity-50 font-medium ">
                Find answers to frequently asked questions
              </span>
            </div>
            <div
              className="
				lg:grid lg:grid-cols-5 lg:place-content-center lg:gap-x-4 lg:gap-y-4
				md:grid md:grid-cols-3 md:place-content-center md:gap-x-4  md:gap-y-4
				sm:grid sm:grid-cols-3 sm:place-content-center sm:gap-x-4  sm:gap-y-4
				xs:grid xs:grid-cols-2 xs:place-content-center xs:gap-x-4  xs:gap-y-4
			
				
				"
            >
              <div
                className={
                  step === 1
                    ? "bg-white shadow-2xl w-[131px] h-[131px] rounded-md border  border-primaryColor1  flex flex-col items-center justify-center  hover:cursor-pointer bg-primaryColor1"
                    : "bg-white shadow-2xl w-[131px] h-[131px] rounded-md border border-primaryColor2 flex flex-col items-center justify-center hover:cursor-pointer"
                }
                onClick={() => {
                  getgeneral();
                  // setSeeContent(true)
                }}
              >
                <div className="">
                  <span>
                    <img src={General} alt="" />
                  </span>
                </div>
                <div>
                  <span
                    className={
                      step === 1
                        ? "text-lg font-manirope font-bold text-white"
                        : "text-lg font-manirope font-bold text-black text-opacity-25"
                    }
                  >
                    General
                  </span>
                </div>
              </div>
              <div
                className={
                  step === 2
                    ? "bg-white shadow-2xl w-[131px] h-[131px] rounded-md border border-primaryColor1  flex flex-col items-center justify-center  hover:cursor-pointer bg-primaryColor1"
                    : "bg-white shadow-2xl w-[131px] h-[131px] rounded-md border border-primaryColor2 flex flex-col items-center justify-center hover:cursor-pointer"
                }
                onClick={() => {
                  getgrow();
                  setSeeContent(true);
                }}
              >
                <div>
                  <img src={Grow} alt="" />
                </div>
                <div className="pt-2">
                  <span
                    className={
                      step === 2
                        ? "text-lg font-manirope font-bold text-white"
                        : "text-lg font-manirope font-bold text-black text-opacity-25"
                    }
                  >
                    {/* Grow */}
                  </span>
                </div>
              </div>
              <div
                className={
                  step === 3
                    ? "bg-white shadow-2xl w-[131px] h-[131px] border-primaryColor1  rounded-md border flex flex-col items-center justify-center  hover:cursor-pointer bg-primaryColor1"
                    : "bg-white shadow-2xl w-[131px] h-[131px] rounded-md border border-primaryColor2 flex flex-col items-center justify-center hover:cursor-pointer"
                }
                onClick={() => {
                  getinvest();
                  setSeeContent(true);
                }}
              >
                <div>
                  <img src={Invest} alt="" />
                </div>
                <div className="pt-2">
                  <span
                    className={
                      step === 3
                        ? "text-lg font-manirope font-bold text-white"
                        : "text-lg font-manirope font-bold text-black text-opacity-25"
                    }
                  >
                    {/* Invest */}
                  </span>
                </div>
              </div>
              <div
                className={
                  step === 4
                    ? "bg-white shadow-2xl w-[131px] h-[131px] border-primaryColor1  rounded-md border  flex flex-col items-center justify-center  hover:cursor-pointer bg-primaryColor1"
                    : "bg-white shadow-2xl w-[131px] h-[131px] rounded-md border border-primaryColor2 flex flex-col items-center justify-center hover:cursor-pointer"
                }
                onClick={() => {
                  getdevelop();
                  setSeeContent(true);
                }}
              >
                <div>
                  <img src={Develop} alt="" />
                </div>
                <div className="pt-2">
                  <span
                    className={
                      step === 4
                        ? "text-lg font-manirope font-bold text-white"
                        : "text-lg font-manirope font-bold text-black text-opacity-25"
                    }
                  >
                    {/* Develop */}
                  </span>
                </div>
              </div>
              <div
                className={
                  step === 5
                    ? "bg-white shadow-2xl w-[131px] h-[131px] border-primaryColor1  rounded-md border flex flex-col items-center justify-center  hover:cursor-pointer bg-primaryColor1"
                    : "bg-white shadow-2xl w-[131px] h-[131px] rounded-md border border-primaryColor2 flex flex-col items-center justify-center hover:cursor-pointer"
                }
                onClick={() => {
                  getthrifts();
                  setSeeContent(true);
                }}
              >
                <div>
                  <img src={Thrift} alt="" />
                </div>
                <div className="pt-2">
                  <span
                    className={
                      step === 5
                        ? "text-lg font-manirope font-bold text-white"
                        : "text-lg font-manirope font-bold text-black text-opacity-25"
                    }
                  >
                    {/* Thrift */}
                  </span>
                </div>
              </div>
              {/* <div
                className={
                  step === 6
                    ? "bg-white shadow-2xl w-[131px] h-[131px] border-primaryColor1  rounded-md border flex flex-col items-center justify-center  hover:cursor-pointer bg-primaryColor1"
                    : "bg-white shadow-2xl w-[131px] h-[131px] rounded-md border border-primaryColor2 flex flex-col items-center justify-center hover:cursor-pointer"
                }
                onClick={getSearch}
              >
                <div>
                  <img src={Thrift} alt="" />
                </div>
                <div className="pt-2">
                  <span
                    className={
                      step === 5
                        ? "text-lg font-manirope font-bold text-white"
                        : "text-lg font-manirope font-bold text-black text-opacity-25"
                    }
                  >
                    Search
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* General */}

        {step === 1 ? (
          <div
            className="
		xl:flex xl:flex-col xl:items-center xl:justify-center xl:gap-y-4 xl:mt-[5rem] 
		lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-4 lg:mt-[5rem]
		md:flex md:flex-col md:items-center md:justify-center md:gap-y-4 md:mt-[12rem]
		sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-4 sm:mt-[12rem]
    xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-4 xs:mt-[10rem]
		"
          >
            <div className="mb-5">
              <span className="font-bold font-manrope text-4xl text-black">
                General
              </span>
            </div>

            <div
              className="
			xl:bg-white xl:border xl:border-primaryColor2 xl:shadow-2xl xl:w-[1086px] xl:h-[679px] xl:rounded-md overflow-y-auto
			lg:bg-white lg:border lg:border-primaryColor2 lg:shadow-2xl lg:w-[991px] lg:h-[679px] lg:rounded-md overflow-y-auto
			md:bg-white  md:border md:border-primaryColor2 md:shadow-2xl md:w-[700px] md:h-[623px] md:rounded-md overflow-y-auto
			sm:bg-white sm:border sm:border-primaryColor2 sm:shadow-2xl sm:w-[450px] sm:h-[623px] sm:rounded-md overflow-y-auto
		    xs:bg-white xs:shadow-2xl xs:w-[330px] xs:h-[623px] xs:rounded-md overflow-y-auto"
            >
              <div className="flex flex-col items-center justify-center gap-y-9 p-4 mt-10 mb-20">
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general1 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What is Landshop?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What is Landshop?
                        </span>
                      )}
                    </div>
                    {!general1 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer "
                          src={plus}
                          alt=""
                          onClick={getInfogeneral1}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className="xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral1}
                        />
                      </div>
                    )}
                  </div>

                  {general1 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center ">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Landshop is Nigeria’s premier real estate
                          co-investment property development company that offers
                          a wide berth of property solutions in acquisition,
                          mortgage and investments. <br /> <br />
                          With a driving philosophy that’s centered around
                          enabling access for Nigerians of all stripes,
                          Landshop’s innovative proprietaries: GROW, INVEST,
                          DEVELOP…and THRIFT are designed to address hot-button
                          concerns of potential property owners.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {!general1 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general2 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Where is Landshop and how do I reach you?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Where is Landshop and how do I reach you?
                        </span>
                      )}
                    </div>
                    {!general2 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral2}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral2}
                        />
                      </div>
                    )}
                  </div>

                  {general2 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Landshop headquarters is located in Lagos, Nigeria.
                          And we can be reached at the following address.
                          <br /> <br />
                          Address: Suite 1, 3rd floor, king’s deck, alternative
                          route, off chevron drive, Lekki-Epe expressway, Lagos
                          <br />
                          Email: care@landshop.ng
                          <br />
                          Phone:+234 1 888 0063
                          <br />
                          WhatsApp Only: +234 818 886 6772
                          <br />
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general2 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general3 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          How Do I Know Landshop is a Legit Real Estate Company?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          How Do I Know Landshop is a Legit Real Estate Company?
                        </span>
                      )}
                    </div>
                    {!general3 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral3}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral3}
                        />
                      </div>
                    )}
                  </div>

                  {general3 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Landshop’s board of directors and management team
                          comprise industry experts with over 5 decades of
                          combined industry expertise across relevant fields.
                          Landshop has proven over 5 years of clients’
                          satisfaction, augmented by industry achievement awards
                          and verifiable government certifications. We are
                          certified to practice by the Lagos State Real Estate
                          Regulatory Authority: and are members of the Real
                          Estate Developers Association of Nigeria. <br />
                          Landshop Limited is registered at the Federal
                          Government’s Corporate Affairs Commission with RC No.
                          144734.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general3 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general4 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What Titles do properties on Landshop Have?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What Titles do properties on Landshop Have?
                        </span>
                      )}
                    </div>
                    {!general4 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral4}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral4}
                        />
                      </div>
                    )}
                  </div>

                  {general4 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Depending on the location and property, titles range
                          between Certificate of Occupancy (C of O), Governor’s
                          Consent, Excision and Registered Survey.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general4 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]]">
                    <div className="">
                      {!general5 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Please Explain the Differences Between the Titles.
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Please Explain the Differences Between the Titles.
                        </span>
                      )}
                    </div>
                    {!general5 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral5}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral5}
                        />
                      </div>
                    )}
                  </div>

                  {general5 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          C of O is a leasehold type of title that confers a 99
                          years lease on the property. Governor’s Consent is a
                          free hold type of title that confers indefinite lease
                          on said property. Excision is a type of title that, by
                          government’s approval, delineates a portion of general
                          to a communal settlement, which in turn has been
                          bought and used for a real estate development by
                          generalshop and with the consent of the State.
                          Registered Survey is a type of title that basically
                          shows the property has been registered for the
                          applicable use.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general5 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general6 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Does Landshop Accept Foreign or Crypto Currency?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Does Landshop Accept Foreign or Crypto Currency?
                        </span>
                      )}
                    </div>
                    {!general6 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral6}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral6}
                        />
                      </div>
                    )}
                  </div>

                  {general6 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes. We accept foreign currency, based on prevailing
                          exchange rates. We are currently working on adopting
                          cryptocurrency; this mode of payment should be
                          available in a couple of months.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general6 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general7 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          How Can I Make Payment on any Property Transaction?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          How Can I Make Payment on any Property Transaction?
                        </span>
                      )}
                    </div>
                    {!general7 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral7}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral7}
                        />
                      </div>
                    )}
                  </div>

                  {general7 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          You can make your payment either by using your debit
                          (ATM) card, do an online transfer, a bank deposit or
                          USSD code
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general7 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general8 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What Happens After I Complete A Payment And How Do I
                          Get My Land Documents?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What Happens After I Complete A Payment And How Do I
                          Get My Land Documents?
                        </span>
                      )}
                    </div>
                    {!general8 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral8}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral8}
                        />
                      </div>
                    )}
                  </div>

                  {general8 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Upon confirmation of payment, you will receive a
                          payment acknowledgment by email. Hard copies could be
                          picked up at our office or sent to you by registered
                          mail or courier service at your expense
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general8 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general9 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Do I Get Any Commission If I Refer People to Buy,
                          Sell, Mortgage or Invest in Landshop?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Do I Get Any Commission If I Refer People to Buy,
                          Sell, Mortgage or Invest in Landshop?
                        </span>
                      )}
                    </div>
                    {!general9 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral9}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral9}
                        />
                      </div>
                    )}
                  </div>

                  {general9 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes, but you have to register as an independent
                          consultant on our portal. Please send an email to
                          care@landshop.ng for more information.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general9 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general10 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Do I Have To Pay Anything Else For Documentation Or
                          Development Levy?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Do I Have To Pay Anything Else For Documentation Or
                          Development Levy?
                        </span>
                      )}
                    </div>
                    {!general10 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral10}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral10}
                        />
                      </div>
                    )}
                  </div>

                  {general10 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Depends. Most properties are all-inclusive of
                          documentation fee and development levy. Please confirm
                          with your sales rep or contact our customer support
                          for details on specific transactions/estate.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general10 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general11 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Is There Any Restriction Regarding The Type Of
                          Building I Can Construct On The Estate I Buy From
                          Landshop?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Is There Any Restriction Regarding The Type Of
                          Building I Can Construct On The Estate I Buy From
                          Landshop?
                        </span>
                      )}
                    </div>
                    {!general11 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral11}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral11}
                        />
                      </div>
                    )}
                  </div>

                  {general11 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes. Constructions are subject to the rules and
                          guidelines of every estate project. Please refer to
                          the individual estate client manual for full details.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general11 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general12 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          How Do I Distinguish Between a Residential, Commercial
                          or Dual Purpose Property?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          How Do I Distinguish Between a Residential, Commercial
                          or Dual Purpose Property?
                        </span>
                      )}
                    </div>
                    {!general12 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral12}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral12}
                        />
                      </div>
                    )}
                  </div>

                  {general12 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          All property descriptions indicate its property use.
                          This info can be gleaned under all properties. Or
                          confirm with your sales rep or contact our customer
                          support for details on specific estate’s terms and
                          conditions
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general12 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general13 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Can My Property Be Resold or If I Begin Payment On The
                          Land And Cannot Complete or Wish To Get A Refund, What
                          Happens?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Can My Property Be Resold or If I Begin Payment On The
                          Land And Cannot Complete or Wish To Get A Refund, What
                          Happens?
                        </span>
                      )}
                    </div>
                    {!general13 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral13}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral13}
                        />
                      </div>
                    )}
                  </div>

                  {general13 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes, you can re-sell your property and can request a
                          refund in writing. Both are possible, but in line with
                          the resale/refund policies stipulated in the property
                          sales agreement
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general13 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general14 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Can I Build On My Land When I Buy Land from Landshop?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Can I Build On My Land When I Buy Land from Landshop?
                        </span>
                      )}
                    </div>
                    {!general14 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral14}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral14}
                        />
                      </div>
                    )}
                  </div>

                  {general14 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes you can build but in conformity with the
                          guidelines for infrastructure development within the
                          Landshop location.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general14 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general15 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Do I Automatically Own The Land When I Purchase From
                          Landshop?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Do I Automatically Own The Land When I Purchase From
                          Landshop?
                        </span>
                      )}
                    </div>
                    {!general15 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral15}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral15}
                        />
                      </div>
                    )}
                  </div>

                  {general15 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes, the land belongs to you and can be passed on to
                          your successors in title.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general15 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general16 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Can I Visit the Property of Choice Before or After I
                          Buy?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Can I Visit the Property of Choice Before or After I
                          Buy?
                        </span>
                      )}
                    </div>
                    {!general16 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral16}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral16}
                        />
                      </div>
                    )}
                  </div>

                  {general16 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes. You can visit any of the sites by booking a
                          physical or virtual site tour on the website or we can
                          provide location details and you visit at your
                          convenience.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general16 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general17 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What Other Payments Do I Make Apart from Land Payment?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What Other Payments Do I Make Apart from Land Payment?
                        </span>
                      )}
                    </div>
                    {!general17 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral17}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral17}
                        />
                      </div>
                    )}
                  </div>

                  {general17 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          To facilitate your documentation (Contract of Sale,
                          Deeds of Assignment & Surveys) we charge a
                          documentation fee of 10% (for Lagos) & 5% (outside
                          Lagos) of the total value of Land purchased
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general17 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general18 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Do I Have To Pay For Documentation Whilst Paying For
                          The Land?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Do I Have To Pay For Documentation Whilst Paying For
                          The Land?
                        </span>
                      )}
                    </div>
                    {!general18 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral18}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral18}
                        />
                      </div>
                    )}
                  </div>

                  {general18 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          No. To achieve our target of making land ownership
                          available to everyone, you can choose to make payment
                          for your documentation at a later date (depending on
                          your budget).
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general18 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]]">
                    <div className="">
                      {!general19 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Can I Pay Cash To An Agent of Landshop?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Can I Pay Cash To An Agent of Landshop?
                        </span>
                      )}
                    </div>
                    {!general19 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral19}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral19}
                        />
                      </div>
                    )}
                  </div>

                  {general19 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          No. All payments must be made to Landshop limited or
                          designated accounts only or accounts of property
                          owners verified and published by Landshop.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general19 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general20 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          As an Estate Agent or Developer, Can I Upload my
                          Property for Sale on Landshop?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          As an Estate Agent or Developer, Can I Upload my
                          Property for Sale on Landshop?
                        </span>
                      )}
                    </div>
                    {!general20 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral20}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral20}
                        />
                      </div>
                    )}
                  </div>

                  {general20 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          No, Landshop only accepts properties from the owner or
                          agent with registered interest/ instructions of the
                          owner to sell.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general20 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!general21 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s a PAC Trustee?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s a PAC Trustee?
                        </span>
                      )}
                    </div>
                    {!general21 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogeneral21}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogeneral21}
                        />
                      </div>
                    )}
                  </div>

                  {general21 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          This is Landshop’s official securities partner that
                          provides fiduciary coverage for all clients’
                          investment assets.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!general21 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Grow */}

        {step === 2 ? (
          <div
            className="
            xl:flex xl:flex-col xl:items-center xl:justify-center xl:gap-y-4 xl:mt-[5rem] 
            lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-4 lg:mt-[5rem]
            md:flex md:flex-col md:items-center md:justify-center md:gap-y-4 md:mt-[12rem]
            sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-4 sm:mt-[12rem]
            xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-4 xs:mt-[10rem]
		"
          >
            <div className="mb-5">
              <span className="font-bold font-manrope text-4xl text-black">
                Grow
              </span>
            </div>

            <div
              className="
              xl:bg-white xl:border xl:border-primaryColor2 xl:shadow-2xl xl:w-[1086px] xl:h-[679px] xl:rounded-md overflow-y-auto
              lg:bg-white lg:border lg:border-primaryColor2 lg:shadow-2xl lg:w-[991px] lg:h-[679px] lg:rounded-md overflow-y-auto
              md:bg-white  md:border md:border-primaryColor2 md:shadow-2xl md:w-[700px] md:h-[623px] md:rounded-md overflow-y-auto
              sm:bg-white sm:border sm:border-primaryColor2 sm:shadow-2xl sm:w-[450px] sm:h-[623px] sm:rounded-md overflow-y-auto
                xs:bg-white xs:shadow-2xl xs:w-[330px] xs:h-[623px] xs:rounded-md overflow-y-auto"
            >
              <div className="flex flex-col items-center justify-center gap-y-9 p-4 mt-10 mb-20">
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!grow1 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s GROWPROPERTY?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s GROWPROPERTY?
                        </span>
                      )}
                    </div>
                    {!grow1 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogrow1}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogrow1}
                        />
                      </div>
                    )}
                  </div>

                  {grow1 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          GrowProperty is one of Landshop’s proprietary that
                          provides MORTGAGE loans to finance
                          construction/ownership of homes with highly reduced
                          interest rates and extended repayment periods. It
                          comprises: Beta Mortgage & Mortgage Xtra.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {!grow1 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!grow2 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s Your Mortgage Financial Institution?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s Your Mortgage Financial Institution?
                        </span>
                      )}
                    </div>
                    {!grow2 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogrow2}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogrow2}
                        />
                      </div>
                    )}
                  </div>

                  {grow2 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Currently, Living Trust Mortgage Bank Plc is
                          Landshop’s official banking partner. It provides the
                          funding for all Landshop’s mortgaging services.
                          Landshop is working to onboard more Mortgage
                          Institutional partners to be able to cover any part of
                          Nigeria.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!grow2 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!grow3 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          How Do I Qualify for GrowProperty (Mortgage)?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          How Do I Qualify for GrowProperty (Mortgage)?
                        </span>
                      )}
                    </div>
                    {!grow3 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogrow3}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogrow3}
                        />
                      </div>
                    )}
                  </div>

                  {grow3 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          All applicants for GROW (Beta Mortgage & Mortgage
                          Xtra) are assessed for credit worthiness, job type,
                          property size/type etc, in order to duly profile their
                          ability to repay. All eligible applicants are
                          INSTANTLY provided with the necessary funds/access to
                          their property of choice.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!grow3 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!grow4 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s NHF and Mortgage Account Number?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s NHF and Mortgage Account Number?
                        </span>
                      )}
                    </div>
                    {!grow4 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogrow4}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogrow4}
                        />
                      </div>
                    )}
                  </div>

                  {grow4 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          NHF is the federally-mandated National Housing Fund
                          number that is assigned to all Nigerians who
                          contribute to the NHF in order to provide them access
                          to the national mortgage funds. Because of Landshop’s
                          unique mortgage-licencing status, NHF numbers will be
                          provided to all Landshop’s potential mortgage clients.{" "}
                          <br /> <br />
                          Mortgage Account number is the bank account number
                          that is required by every mortgage applicant. It is
                          provided by a mortgage bank, and will be provided to
                          our clients by Living Trust Mortgage Bank.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!grow4 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!grow5 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s BETA MORTGAGE?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s BETA MORTGAGE?
                        </span>
                      )}
                    </div>
                    {!grow5 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogrow5}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogrow5}
                        />
                      </div>
                    )}
                  </div>

                  {grow5 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          This is a mortgage product that provides loans for the
                          construction of homes from scratch. Living Trust Bank
                          provides 70% of the funds to build the thrift you
                          want. While you, the client, deposit 30% of the
                          property value upfront within 1 – 3 months. And also
                          pay 2.5% of your monthly earnings to your Living Trust
                          Mortgage Bank account. Repayment of the 70% borrowed
                          is done over the next 30 years at an interest rate of
                          6% yearly.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!grow5 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!grow6 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What properties are eligible for BETA MORTGAGE?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What properties are eligible for BETA MORTGAGE?
                        </span>
                      )}
                    </div>
                    {!grow6 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogrow6}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogrow6}
                        />
                      </div>
                    )}
                  </div>

                  {grow6 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          All the listed properties are available for Beta
                          Mortgage [insert all properties]
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!grow6 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!grow7 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s MORTGAGE XTRA?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s MORTGAGE XTRA?
                        </span>
                      )}
                    </div>
                    {!grow7 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogrow7}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogrow7}
                        />
                      </div>
                    )}
                  </div>

                  {grow7 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          This is a 0% interest RENT TO OWN mortgage product.
                          Once approved for this mortgage, you simply pack into
                          the apartment immediately, and then use your rent as
                          the repayment plan. You pay 7% of the property value
                          as rent annually.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!grow7 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!grow8 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What properties are eligible for MORTGAGE XTRA?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What properties are eligible for MORTGAGE XTRA?
                        </span>
                      )}
                    </div>
                    {!grow8 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfogrow8}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfogrow8}
                        />
                      </div>
                    )}
                  </div>

                  {grow8 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          All the listed properties are available for Mortgage
                          Xtra [insert all properties]
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!grow8 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Invest */}
        {step === 3 ? (
          <div
            className="
            xl:flex xl:flex-col xl:items-center xl:justify-center xl:gap-y-4 xl:mt-[5rem] 
            lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-4 lg:mt-[5rem]
            md:flex md:flex-col md:items-center md:justify-center md:gap-y-4 md:mt-[12rem]
            sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-4 sm:mt-[12rem]
            xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-4 xs:mt-[10rem]
		"
          >
            <div className="mb-5">
              <span className="font-bold font-manrope text-4xl text-black">
                Invest
              </span>
            </div>

            <div
              className="
              xl:bg-white xl:border xl:border-primaryColor2 xl:shadow-2xl xl:w-[1086px] xl:h-[679px] xl:rounded-md overflow-y-auto
              lg:bg-white lg:border lg:border-primaryColor2 lg:shadow-2xl lg:w-[991px] lg:h-[679px] lg:rounded-md overflow-y-auto
              md:bg-white  md:border md:border-primaryColor2 md:shadow-2xl md:w-[700px] md:h-[623px] md:rounded-md overflow-y-auto
              sm:bg-white sm:border sm:border-primaryColor2 sm:shadow-2xl sm:w-[450px] sm:h-[623px] sm:rounded-md overflow-y-auto
                xs:bg-white xs:shadow-2xl xs:w-[330px] xs:h-[623px] xs:rounded-md overflow-y-auto"
            >
              <div className="flex flex-col items-center justify-center gap-y-9 p-4 mt-10 mb-20">
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest1 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s INVEST?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s INVEST?
                        </span>
                      )}
                    </div>
                    {!invest1 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest1}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest1}
                        />
                      </div>
                    )}
                  </div>

                  {invest1 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          This is another proprietary that allows clients to
                          earn returns by investing rather than acquiring or
                          owning real estate. An ROI of 70% flat is guaranteed
                          on any property investment. Clients can invest in
                          plots/sqm of lands or units/slots of thrifts.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {!invest1 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest2 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          How Do I Invest?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          How Do I Invest?
                        </span>
                      )}
                    </div>
                    {!invest2 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest2}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest2}
                        />
                      </div>
                    )}
                  </div>

                  {invest2 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          You can invest by choosing a property of any size in
                          plots/sqm or unit/slot. Investments are available for
                          lands and thrifts. Choose an investment plan, and earn
                          pay outs in at least a year.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest2 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[400px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[400px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest3 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s the Duration of an Investment?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s the Duration of an Investment?
                        </span>
                      )}
                    </div>
                    {!invest3 ? (
                      <div className="text-primaryColor1 float-right">
                        <img src={plus} alt="" onClick={getInfoinvest3} />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img src={minus} alt="" onClick={getInfoinvest3} />
                      </div>
                    )}
                  </div>

                  {invest3 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Investments can last for as long as five years.
                          However, payouts are only possible on an annual basis.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest3 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className=" xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest4 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          How much can I invest?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          How much can I invest?
                        </span>
                      )}
                    </div>
                    {!invest4 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest4}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest4}
                        />
                      </div>
                    )}
                  </div>

                  {invest4 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          There is no top-end limit to what you can invest.
                          However, you can only invest in a minimum of 1 bundle
                          (30 sqm for land, 1 slot for thrift).
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest4 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest5 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          70% ROI Flat Means What?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          70% ROI Flat Means What?
                        </span>
                      )}
                    </div>
                    {!invest5 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest5}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest5}
                        />
                      </div>
                    )}
                  </div>

                  {invest5 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          It means clients are guaranteed a fixed 70% returns
                          irrespective of any circumstance. If, for example, you
                          invest N1,000,000 in Year 1, in a property valued at
                          N1,000,000, and the property appreciates by 60% in
                          Year 2 or 3 to N1,600,000, it means you’d earn a
                          guaranteed 70% of the N600,000 value appreciation on
                          the property. I.e N420,000 (ROI) = your seed capital
                          of N1m.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest5 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest6 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s an Investment Bundle?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s an Investment Bundle?
                        </span>
                      )}
                    </div>
                    {!invest6 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest6}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest6}
                        />
                      </div>
                    )}
                  </div>

                  {invest6 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          An investment bundle is the MINIMUM investment
                          permissible per property. 30 sqm for land. 1 slot for
                          thrift.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest6 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest7 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          How Do I Know My Investment is Confirmed/Started?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          How Do I Know My Investment is Confirmed/Started?
                        </span>
                      )}
                    </div>
                    {!invest7 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest7}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest7}
                        />
                      </div>
                    )}
                  </div>

                  {invest7 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          All confirmed clients will receive instant
                          personalised details confirmation of their
                          transaction.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest7 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest8 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s a Square Metre (sqm)?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s a Square Metre (sqm)?
                        </span>
                      )}
                    </div>
                    {!invest8 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest8}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest8}
                        />
                      </div>
                    )}
                  </div>

                  {invest8 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          This is the smallest unit of a landed property. A full
                          plot of land is made of 600 sqm. A sqm is 1/600 of a
                          plot. Therefore, a sqm can be thought of as one square
                          cutout of a land.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest8 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest9 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s a Slot?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s a Slot?
                        </span>
                      )}
                    </div>
                    {!invest9 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest9}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest9}
                        />
                      </div>
                    )}
                  </div>

                  {invest9 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          This is the smallest unit of a thrift. A slot is like
                          one tile cutout from an apartment floor. It’s 1/20 of
                          the value of a full housing unit.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest9 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!invest10 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          How Do I Retrieve My ROI Payout?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          How Do I Retrieve My ROI Payout?
                        </span>
                      )}
                    </div>
                    {!invest10 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfoinvest10}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfoinvest10}
                        />
                      </div>
                    )}
                  </div>

                  {invest10 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Payouts are made on an annual basis (clients can only
                          get payouts 12 months post-investments). Once your
                          investment is confirmed. You have the option to choose
                          your payout timeline.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!invest10 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Develop */}
        {step === 4 ? (
          <div
            className="
            xl:flex xl:flex-col xl:items-center xl:justify-center xl:gap-y-4 xl:mt-[5rem] 
            lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-4 lg:mt-[5rem]
            md:flex md:flex-col md:items-center md:justify-center md:gap-y-4 md:mt-[12rem]
            sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-4 sm:mt-[12rem]
            xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-4 xs:mt-[10rem]
		"
          >
            <div className="mb-5">
              <span className="font-bold font-manrope text-4xl text-black">
                Develop
              </span>
            </div>

            <div
              className="
              xl:bg-white xl:border xl:border-primaryColor2 xl:shadow-2xl xl:w-[1086px] xl:h-[679px] xl:rounded-md overflow-y-auto
              lg:bg-white lg:border lg:border-primaryColor2 lg:shadow-2xl lg:w-[991px] lg:h-[679px] lg:rounded-md overflow-y-auto
              md:bg-white  md:border md:border-primaryColor2 md:shadow-2xl md:w-[700px] md:h-[623px] md:rounded-md overflow-y-auto
              sm:bg-white sm:border sm:border-primaryColor2 sm:shadow-2xl sm:w-[450px] sm:h-[623px] sm:rounded-md overflow-y-auto
                xs:bg-white xs:shadow-2xl xs:w-[330px] xs:h-[623px] xs:rounded-md overflow-y-auto"
            >
              <div className="flex flex-col items-center justify-center gap-y-9 p-4 mt-10 mb-20">
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!develop1 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s DEVELOP?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s DEVELOP?
                        </span>
                      )}
                    </div>
                    {!develop1 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfodevelop1}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfodevelop1}
                        />
                      </div>
                    )}
                  </div>

                  {develop1 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          This is the product that offers clients outright
                          property acquisition. With DEVELOP, you can procure
                          full plots and unit thrifts across the country.
                          Payment plans are also available.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {!develop1 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!develop2 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Are credit facilities available for potential buyers?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Are credit facilities available for potential buyers?
                        </span>
                      )}
                    </div>
                    {!develop2 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfodevelop2}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfodevelop2}
                        />
                      </div>
                    )}
                  </div>

                  {develop2 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes, they are. Landshop will provide credit/loans to
                          qualified clients, through our partner financial
                          institutions..
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!develop2 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!develop3 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Can I buy now and pay in installments?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Can I buy now and pay in installments?
                        </span>
                      )}
                    </div>
                    {!develop3 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfodevelop3}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfodevelop3}
                        />
                      </div>
                    )}
                  </div>

                  {develop3 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Yes, you can. It comes at a premium/interest rate
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!develop3 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!develop4 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What payment plans are there?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What payment plans are there?
                        </span>
                      )}
                    </div>
                    {!develop4 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfodevelop4}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfodevelop4}
                        />
                      </div>
                    )}
                  </div>

                  {develop4 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          For outright purchase, clients can access payment
                          plans of up to [insert amount], with up to 5 years
                          period and at an interest of 15% for annual, 3.8% for
                          quarterly, 1.3% for monthly, 0.3% for weekly and 0.04%
                          for daily.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!develop4 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!develop5 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Where Does Landshop Have Properties?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Where Does Landshop Have Properties?
                        </span>
                      )}
                    </div>
                    {!develop5 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfodevelop5}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfodevelop5}
                        />
                      </div>
                    )}
                  </div>

                  {develop5 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          Landshop has properties across major cities in
                          Nigeria. Lagos, Abuja, Oyo, Ogun, Delta, Edo Akwa
                          Ibom, Anambra etc
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!develop5 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!develop6 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What are PRIME properties?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What are PRIME properties?
                        </span>
                      )}
                    </div>
                    {!develop6 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfodevelop6}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfodevelop6}
                        />
                      </div>
                    )}
                  </div>

                  {develop6 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          These are landed and housing properties that retail
                          from the low to mid millions. Usually from 1.5 million
                          to 40 million. The following are some of our PRIME
                          properties. Green Island, Merit Park, Blue Diamond,
                          Heritage Court.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!develop6 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!develop7 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What are LUXURY properties?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What are LUXURY properties?
                        </span>
                      )}
                    </div>
                    {!develop7 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfodevelop7}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfodevelop7}
                        />
                      </div>
                    )}
                  </div>

                  {develop7 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          These are highly exclusive and opulently sited lands
                          and homes, with proximity to lavish scenery. They
                          retail between forty to hundreds of millions. The
                          following are some of our LUXURY properties. The
                          Pearl, Maiyegun, Oasis, Atlantic City, The Groove.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!develop7 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!develop8 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          Can I Buy a Square Metre of Land or Slot of a thrift?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          Can I Buy a Square Metre of Land or Slot of a thrift?
                        </span>
                      )}
                    </div>
                    {!develop8 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfodevelop8}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfodevelop8}
                        />
                      </div>
                    )}
                  </div>

                  {develop8 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          No. You can only buy full plots or unit thrifts. Sqm
                          and slots are only available as investment options.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {!develop8 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Thrift */}
        {step === 5 ? (
          <div
            className="
            xl:flex xl:flex-col xl:items-center xl:justify-center xl:gap-y-4 xl:mt-[5rem] 
            lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-4 lg:mt-[5rem]
            md:flex md:flex-col md:items-center md:justify-center md:gap-y-4 md:mt-[12rem]
            sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-4 sm:mt-[12rem]
            xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-4 xs:mt-[10rem]
		"
          >
            <div className="mb-5">
              <span className="font-bold font-manrope text-4xl text-black">
                Thrift
              </span>
            </div>

            <div
              className="
              xl:bg-white xl:border xl:border-primaryColor2 xl:shadow-2xl xl:w-[1086px] xl:h-[679px] xl:rounded-md overflow-y-auto
              lg:bg-white lg:border lg:border-primaryColor2 lg:shadow-2xl lg:w-[991px] lg:h-[679px] lg:rounded-md overflow-y-auto
              md:bg-white  md:border md:border-primaryColor2 md:shadow-2xl md:w-[700px] md:h-[623px] md:rounded-md overflow-y-auto
              sm:bg-white sm:border sm:border-primaryColor2 sm:shadow-2xl sm:w-[450px] sm:h-[623px] sm:rounded-md overflow-y-auto
                xs:bg-white xs:shadow-2xl xs:w-[330px] xs:h-[623px] xs:rounded-md overflow-y-auto"
            >
              <div className="flex flex-col items-center justify-center gap-y-9 p-4 mt-10 mb-20">
                <div className="flex flex-col items-center justify-center gap-y-7">
                  <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                    <div className="">
                      {!thrift1 ? (
                        <span className="font-black font-manrope text-base text-black float-left">
                          What’s LANDSHOP THRIFT?
                        </span>
                      ) : (
                        <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                          What’s LANDSHOP THRIFT?
                        </span>
                      )}
                    </div>
                    {!thrift1 ? (
                      <div className="text-primaryColor1 float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={plus}
                          alt=""
                          onClick={getInfothrift1}
                        />
                      </div>
                    ) : (
                      <div className="bg-gray float-right">
                        <img
                          className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                          src={minus}
                          alt=""
                          onClick={getInfothrift1}
                        />
                      </div>
                    )}
                  </div>

                  {thrift1 === true ? (
                    <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                      <span className="text-xs font-thin text-gray-400">
                        <p className="text-sm font-medium text-black text-justify">
                          This is a specialised service created for clients with
                          unique, unified desires. Clients like cooperative
                          societies, churches, mosques, social clubs, august
                          meetings, old school associations etc., looking to
                          acquire property for a unified purpose. <br /> <br />
                          Property sales are tailored to their demands and
                          expectations. Contact us for a personal quote.
                        </p>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {!thrift1 ? (
                    <div
                      className="
							lg:border lg:border-gray lg:w-[800px] lg:h-[0]
							sm:border sm:border-gray sm:w-[400px] sm:h-[0]
							md:border md:border-gray md:w-[600px] md:h-[0]
							xs:border xs:border-gray xs:w-[290px] xs:h-[0]
							
							"
                    ></div>
                  ) : (
                    <div
                      className="
						 lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
						 sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
						 md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
						 xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
						 
						 "
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {step === 6 ? (
          <div
            className="
        xl:flex xl:flex-col xl:items-center xl:justify-center xl:gap-y-4 xl:mt-[5rem] 
        lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-4 lg:mt-[5rem]
        md:flex md:flex-col md:items-center md:justify-center md:gap-y-4 md:mt-[12rem]
        sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-4 sm:mt-[12rem]
        xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-4 xs:mt-[10rem]
    "
          >
            <div className="mb-5">
              <span className="font-bold font-manrope text-4xl text-black">
                Frequently asked questions
              </span>
            </div>

            <div
              className="
          xl:bg-white xl:border xl:border-primaryColor2 xl:shadow-2xl xl:w-[1086px] xl:h-[679px] xl:rounded-md overflow-y-auto
          lg:bg-white lg:border lg:border-primaryColor2 lg:shadow-2xl lg:w-[991px] lg:h-[679px] lg:rounded-md overflow-y-auto
          md:bg-white  md:border md:border-primaryColor2 md:shadow-2xl md:w-[700px] md:h-[623px] md:rounded-md overflow-y-auto
          sm:bg-white sm:border sm:border-primaryColor2 sm:shadow-2xl sm:w-[450px] sm:h-[623px] sm:rounded-md overflow-y-auto
            xs:bg-white xs:shadow-2xl xs:w-[330px] xs:h-[623px] xs:rounded-md overflow-y-auto"
            >
              {seeContent ? (
                <div className="flex flex-col items-center justify-center gap-y-9 p-4 mt-10 mb-20">
                  {FAQdata.filter((faq) => {
                    // let findCon = 0
                    // findCon = faq.question.search(query);

                    // console.log(findCon);
                    // if(){
                    //     return  <p className = "text-black font-bold text-6xl">No current Result </p>
                    // }

                    if (query === "") {
                      setStep(1);
                      setSeeContent(true);
                      // return <p>No current Result </p>;
                    } else if (
                      faq.question.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return faq;
                    }
                  }).map((question) => (
                    <>
                      <Question key={question.id} {...question} />
                    </>
                  ))}

                  {/* {FAQdata.map((faq, index) =>  <div key={faq.id} className="flex flex-col items-center justify-center gap-y-7">
              <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px]">
                <div onClick={()=>setExpanded(!expanded)} className="">
                  { !expanded ?(
                    <span className="font-black font-manrope text-base text-black float-left">
                    {faq.question}
                    </span>
                  ) : (
                    <span className="font-black font-manrope text-base text-primaryColor1 float-left">
                      {faq.question}
                    </span>
                  )}
                </div> */}
                  {/* {!thrift2 ? (
                  <div className="text-primaryColor1 float-right">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                      onClick={()=>controlShowButton(faq.id)}
                      // onClick={()=>setOpen((id) => !open)}
                    />
                  </div>
                ) : (
                  <div className="bg-gray float-right">
                    <img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={minus}
                      alt=""
                       onClick={()=>controlShowButton(faq.id)}
                    />
                  </div>
                )} */}
                  {/* <button onClick={()=>setExpanded(!expanded)}>
                  {!expanded ?<img
                      className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                      src={plus}
                      alt=""
                      onClick={()=>controlShowButton(faq.id)}
                      
                    /> : <img
                    className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                    src={minus}
                    alt=""
                     onClick={()=>controlShowButton(faq.id)}
                  /> }
                </button>
              </div>
 
              {expanded && (
                <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xl:flex xl:items-center">
                  <span className="text-xs font-thin text-gray-400">
                    <p className="text-sm font-medium text-black text-justify">
                    {faq.answer}
                    </p>
                  </span>
                </div>
              )}
              {!expanded ? (
                <div
                  className="
                        lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                        sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                        md:border md:border-gray md:w-[600px] md:h-[0]
                        xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                        
                        "
                ></div>
              ) : (
                <div
                  className="
                     lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                     sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                     md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                     xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                     
                     "
                ></div>
              )}
            </div> )} */}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-y-9 p-4 mt-10 mb-20"></div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {seeContent === false ? (
          <div className="seecontent">No result</div>
        ) : (
          ""
        )}

        {/* footer */}
        <FooterBlack />
        {/* <div
          className="
          lg:flex lg:justify-between lg:align-center lg:mt-[5rem]  lg:w-full lg:h-[15rem] lg:px-20  
          md:grid md:grid-cols-2 md:place-content-around md:gap-x-1 md:mt-[10rem]  md:w-screen md:h-[15rem] md:gap-y-[4rem]
          sm:grid sm:grid-cols-1 sm:place-content-around sm:gap-x-1 sm:mt-[22rem]  sm:w-screen sm:h-[15rem] sm:gap-y-[4rem]
          xs:grid xs:grid-cols-1 xs:place-content-center  xs:gap-x-1 xs:mt-[20rem]  xs:w-screen xs:h-[15rem] xs:gap-y-[4rem]
          
          
          "
        >
          <div className="flex flex-col gap-y-4  items-center justify-center lg:w-[20%] ">
            <div>
              <span className="text-gray-400 font-bold font-urbanist text-xl ml-4">
                ABOUT US
              </span>
            </div>
            <div className="flex flex-col gap-y-2">
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Trust By
              </span>
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Our Team
              </span>
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Privacy Policy
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-4  items-center justify-center lg:w-[20%] ">
            <div>
              <span className="text-gray-400 font-bold font-urbanist text-xl ml-4">
                Our Projects
              </span>
            </div>
            <div className="flex flex-col gap-y-2  xs:mr-9">
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Grow
              </span>
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Develop
              </span>
              <span className="text-gray-400 font-bold font-urbanist text-sm hover:text-primaryColor2">
                Invest
              </span>
            </div>
          </div>

          <div
            className="
          lg:flex lg:flex-col lg:gap-y-4  lg:items-center lg:justify-center lg:w-[40%] lg:ml-0
          md:flex md:flex-col md:gap-y-4  md:items-center md:justify-center md:ml-14
          sm:flex sm:flex-col sm:gap-y-4  sm:items-center sm:justify-center sm:px-10
          xs:flex xs:flex-col xs:gap-y-4  xs:items-center xs:justify-center xs:px-10
          
          
          "
          >
            <div>
              <span
                className="
					
					lg:text-gray-400 lg:font-bold lg:font-urbanist lg:text-xl lg:text-left
					sm:text-gray-400 sm:font-bold sm:font-urbanist sm:text-xl 
					md:text-gray-400 md:font-bold md:font-urbanist md:text-xl 
					xs:text-gray-400 xs:font-bold xs:font-urbanist xs:text-xl 
					
					"
              >
                Contact Us
              </span>
            </div>
            <div className="flex flex-col gap-y-2">
              <div className="flex gap-x-3">
                <span className="text-gray-400 font-bold font-urbanist text-sm">
                  Address:
                </span>
                <span className="text-gray-400 font-bold font-urbanist text-sm">
                  Suite 1. 3rd Floor, Kings Deck, Alternative Route, Off Chevron
                  Drive Lekki-Epe Expressway Lagos
                </span>
              </div>
              <div className="flex gap-x-3">
                <span className="text-gray-400 font-bold font-urbanist text-sm">
                  Contact Info:
                </span>
                <span className="text-gray-400 font-bold font-urbanist text-sm">
                  +234 1 888 0063
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4  items-center justify-center lg:w-[20%]">
            <div>
              <img
                src={generalshopAboutLogo}
                className="w-[126px] h-[70px]"
                alt=""
              />
            </div>
            <div className=" relative top-[-20px] flex flex-col gap-y-4  items-center justify-center">
              <span className="text-gray-400 font-bold font-urbanist text-sm">
                Call us now at:
              </span>
              <span className="text-gray-400 font-bold font-urbanist text-sm">
                +234 1 888 0063
              </span>
              <div className="flex gap-x-4">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  icon={faPinterest}
                  className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
                ></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
interface ViewFAQProps {
  question: String;
  answer: String;
  id: number;
  content?: string;
  email?: string;
  phone?: number;
  whatsapp?: number;
}

export const Question: React.FC<ViewFAQProps> = ({
  question,
  answer,
  id,
  content,
  email,
  phone,
  whatsapp,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [empty, setEmpty] = useState(false);
  return (
    <div className="flex flex-col items-center justify-center gap-y-7">
      <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:w-[290px] xs:float-left">
        <div onClick={() => setExpanded(!expanded)} className="">
          {!expanded ? (
            <span className="font-black font-manrope text-base text-black float-left">
              {question}
            </span>
          ) : (
            <span className="font-black font-manrope text-base text-primaryColor1 float-left">
              {question}
            </span>
          )}
        </div>
        <button className="float-right" onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <div className="text-primaryColor1 float-right mb-6">
              <img
                className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                src={minus}
                alt=""
              />
            </div>
          ) : (
            <div className="text-primaryColor1 float-right mb-6">
              <img
                className=" xs: w-[20px] xs:h-[20px] sm:w-[26px] sm:h-[26px] cursor-pointer"
                src={plus}
                alt=""
              />
            </div>
          )}
        </button>
        {expanded && (
          <div className="xl:w-[800px] lg:w-[800px] md:w-[600px] sm:w-[400px] xs:float-left xs:w-[290px] xl:flex xl:items-center">
            <span className="text-xs font-thin text-gray-400">
              <div className="text-sm font-medium text-black text-justify mb-5">
                {answer}
                <p className="pt-3"> {content}</p>
                <p> {email} </p>
                <p>{phone} </p>
                <p> {whatsapp}</p>
              </div>
            </span>
          </div>
        )}
        {!expanded ? (
          <div
            className="
                     lg:border lg:border-gray lg:w-[800px] lg:h-[0]
                     sm:border sm:border-gray sm:w-[400px] sm:h-[0]
                     md:border md:border-gray md:w-[600px] md:h-[0]
                     xs:border xs:border-gray xs:w-[290px] xs:h-[0]
                     xs:float-left
                     "
          ></div>
        ) : (
          <div
            className="
                  lg:border lg:border-primaryColor1 lg:w-[800px] lg:h-[0]
                  sm:border sm:border-primaryColor1 sm:w-[400px] sm:h-[0]
                  md:border md:border-primaryColor1 md:w-[600px] md:h-[0]
                  xs:border xs:border-primaryColor1 xs:w-[290px] xs:h-[0]
                  xs:float-left
                  "
          ></div>
        )}
      </div>
      {/* {expanded && <p>{answer}</p>} */}
    </div>
  );
};
