import { ENDPOINTS } from "./endpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeUnauthorizedRequestWithHeadersAndPayload,
} from "../../common/service/request";

const service = {
  async registerUser(data: any) {
    const { method, url } = ENDPOINTS.register;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async logUserIn(data: any) {
    const { method, url } = ENDPOINTS.login;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async logUserOut() {
    const { method, url } = ENDPOINTS.logout;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateUserProfile(data: any) {
    const { method, url } = ENDPOINTS.update_profile;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async fetchUserProfile() {
    const { method, url } = ENDPOINTS.fetch_profile;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateUserPassword(data: any) {
    const { method, url } = ENDPOINTS.update_password;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async forgotUserPassword(data: any) {
    const { method, url } = ENDPOINTS.forgot_password;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async resetUserPassword(data: any) {
    const { method, url } = ENDPOINTS.reset_password;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default service;
