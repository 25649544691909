import React, { useEffect, useRef, useState } from "react";
import { BsPencilFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { uploadFiles } from "../store/api/thunk";
import { updateUserProfile } from "../store/auth/thunk";
import Swal from "sweetalert2";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import routes from "../common/routes";

import Avatar from "../components/Avatar";

const infoData = [
  {
    title: "Full Name (First, Middle, Last)",
    name: "full_name",
  },
  {
    title: "Phone",
    name: "phone_number",
  },
  {
    title: "Email",
    name: "email",
  },
  {
    title: "Date Of Birth",
    name: "date_of_birth",
  },
  {
    title: "State of Residence",
    name: "state",
  },
  {
    title: "Local Government",
    name: "local_government",
  },
  {
    title: "Occupation",
    name: "occupation",
  },
  {
    title: "NHF Number",
    name: "nhf_number",
  },
  {
    title: "Mortgage Bank Account Name (Skip If Unavailable)",
    name: "account_name",
  },
  {
    title: "Mortgage Bank Account Number (Skip If Unavailable)",
    name: "account_number",
  },
  {
    title: "Mortgage Bank Name (Skip If Unavailable)",
    name: "bank_name",
  },
  {
    title: "Primary Source of Income (Personal Income)",
    name: "primary_income_source",
  },
  {
    title: "Secondary Source(s) of income",
    name: "secondary_income_source",
  },
];

function EditProfile() {
  const [inData, setInData] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  // const { state } = useLocation();
  const { user, loading: isLoading } = useAppSelector((state) => state.auth);
  // const [updateProfile, { isLoading, isSuccess, data }] =
  //   useUpdateProfileMutation();
  // const [fetchTrigger] = useLazyFetchProfileQuery();
  const dispatch = useAppDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const imageRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    infoData.forEach(({ name }) => {
      if (name === "full_name") {
        setInData((prev) => ({
          ...prev,
          [name]: `${user["first_name"] ? user["first_name"] : ""}${
            user["middle_name"] ? ` ${user["middle_name"]} ` : " "
          }${user["last_name"] ? user["last_name"] : ""}`,
        }));
      }
      if (name === "date_of_birth" && user[name]) {
        const [unformated_dob]: string[] = user[name].split("T");
        setInData((prev) => ({ ...prev, [name]: unformated_dob }));
      }
      if (name !== "date_of_birth" && name !== "full_name") {
        setInData((prev) => ({ ...prev, [name]: user[name] }));
      }
    });
  }, []); //eslint-disable-line

  // useEffect(() => {
  //   if (isSuccess) {
  //     infoData.forEach(({ name }) => {
  //       if (name === "full_name") {
  //         setInData((prev) => ({
  //           ...prev,
  //           [name]: `${data["first_name"] ? data["first_name"] : ""}${
  //             data["middle_name"] ? ` ${data["middle_name"]} ` : " "
  //           }${data["last_name"] ? data["last_name"] : ""}`,
  //         }));
  //       }
  //       if (name === "date_of_birth" && data[name]) {
  //         const [unformated_dob]: string[] = data[name].split("T");
  //         setInData((prev) => ({ ...prev, [name]: unformated_dob }));
  //       }
  //       if (name !== "date_of_birth" && name !== "full_name") {
  //         setInData((prev) => ({ ...prev, [name]: data[name] }));
  //       }
  //     });
  //   }
  // }, [isSuccess]); //eslint-disable-line

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));
  };

  const imageChangehandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files?.item(0);

    if (!files) return;
    setIsUploading(true);
    dispatch(uploadFiles({ files }))
      .unwrap()
      .then((res) => {
        const [profile_picture]: string[] = res?.filePaths;
        setInData((prev) => ({ ...prev, profile_picture }));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error Encountered while uploading image",
        });
      })
      .finally(() => setIsUploading(false));
  };
  const refClickHandler = () => {
    imageRef.current?.click();
  };

  const sumbitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const full_name = inData?.full_name?.split(" ");
    if (full_name.length <= 1) {
      Swal.fire({
        icon: "error",
        title: "There Must Be at least a First Name and Last Name",
      });
      return;
    }
    const tempData = { ...inData };
    tempData.first_name = full_name[0];
    tempData.last_name = full_name[full_name.length - 1];
    if (full_name.length === 3) {
      tempData.middle_name = full_name[1];
    }
    delete tempData.full_name;

    dispatch(updateUserProfile(tempData))
      .unwrap()
      .finally(() => {
        navigate(routes.profile);
      });
  };
  return (
    <div className="md:py-24 sm:px-9 py-12 px-4 border border-gray-40 rounded-2.5 mt-6">
      <form onSubmit={sumbitHandler}>
        <div className="grid place-content-center  ">
          <div className="relative">
            <Avatar
              hassBorder
              isLoading={isUploading}
              sourceImage={inData.profile_picture}
            />
            <button
              type="button"
              className="bg-primaryColor1 text-white absolute bottom-0 right-0 p-1.5 rounded"
              onClick={refClickHandler}
            >
              <BsPencilFill />
            </button>
          </div>
          <input
            ref={imageRef}
            className="sr-only"
            type="file"
            accept="image/png, image/jpeg"
            onChange={imageChangehandler}
          />
        </div>
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 pt-20">
          {infoData.map((itm) => (
            <CustomInput
              type={itm.name === "date_of_birth" ? "date" : "text"}
              key={itm.name}
              name={itm.name}
              label={itm.title}
              value={inData[itm.name]}
              onChange={changeHandler}
              disabled={isLoading}
            />
          ))}
          <CustomButton
            className="col-span-2 lg:justify-self-center self-center mt-7"
            disabled={isLoading || isUploading}
          >
            Confirm
          </CustomButton>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
