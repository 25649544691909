import PropertyCard from "../common/components/PropertyCard";
import { localCurrency } from "../common/utils/helper";
import { developHouseCard } from "../typings";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

export const DevelopCards = ({ data }: developHouseCard) => {
  const navigate = useNavigate();

  const navigateGreenExtra = () => {
    console.log("i am here");
    localStorage.setItem("developId", data._id);
    navigate(`/20daysofchristmas/${data._id}`);
  };
  return (
    <PropertyCard
      features={data.estate_features}
      format={localCurrency(parseInt(data.current_price))}
      images={data.images}
      location={data.city}
      name={data.property_name}
      size={data.size}
      cardBtnClick={navigateGreenExtra}
      type="develop"
    />

    // <div
    //   className="

    //           lg:bg-white lg:rounded-lg lg:shadow-2xl lg:place-content-center lg:pb-[3rem]
    //           md:bg-white md:rounded-lg md:shadow-2xl md:place-content-center
    //           sm:bg-white sm:rounded-lg sm:shadow-2xl sm:place-content-center
    //           xs:bg-white xs:rounded-lg xs:shadow-2xl xs:place-content-center xs:pb-[1rem]
    //           "
    //   key={data._id}
    // >
    //   <div>
    //     <img className="w-full max-h-[220px] object-cover object-center" src={data.images[0]} alt="" />
    //   </div>
    //   <div className="flex items-center justify-center mt-4 bg-gray-50">
    //     <span className="font-bold text-lg text-center">
    //       {data.address}
    //     </span>
    //   </div>
    //   {/* Information */}
    //   <div className="flex items-center justify-between p-4">
    //     <div>
    //       <span className="font-urbanist text-xs text-primaryColor2">
    //         {parseInt(data.previous_price)?localCurrency(parseInt(data.previous_price)):null}
    //       </span>
    //     </div>
    //     <div>
    //       <span className="font-urbanist text-xs text-primaryColor1">
    //         {parseInt(data.current_price)?localCurrency(parseInt(data.current_price)):null}
    //       </span>
    //     </div>
    //   </div>

    //   {/* Price */}

    //   <div className="flex items-center justify-center p-4 w-full">
    //     <div className="w-[48%]">
    //     <button
    //         className="bg-transparent border border-black w-[100%] justify-center py-2 rounded-xl text-black font-bold font-urbanist  flex items-center gap-x-4"
    //         onClick={navigateGreenExtra}
    //       >
    //       Develop
    //       </button>
    //     </div>
    //     {/* <div className="w-[48%]"> */}
    //       {/* <Button myStyle="InvestButtonLand2">Add to Cart</Button> */}
    //       {/* <button
    //         className="bg-transparent border border-black w-[100%] justify-center py-2 rounded-xl text-black font-bold font-urbanist  flex items-center gap-x-4"
    //         // onClick={addToCart}
    //       >
    //       Add To Cart
    //       </button> */}

    //     {/* </div> */}
    //   </div>
    // </div>
  );
};
