import React from "react";

type Props = {
  bannerBg: string;
  pageLogo: string;
  title: string;
  description: string;
};

function PageHero({ bannerBg, description, pageLogo, title }: Props) {
  return (
    <div className="w-screen 2xl:h-[70rem] xl:[95rem] lg:h-[600px] xl:h-[650px] md:h-[430px] relative">
      <img
        src={bannerBg}
        alt=""
        className="sm:h-[400px] block w-screen h-[350px] md:mx-auto md:h-[500px] lg:h-[600px] xl:h-[650px] object-cover md:object-fill"
      />

      <img
        className="absolute top-0 sm:left-[2rem] xs:left-[0rem] md:w-[280px] xs:w-[250px] lg:w-[339px]"
        src={pageLogo}
        alt=""
      />
      <div className="absolute top-28 left-[2rem] p-3 flex flex-col items-center justify-center gap-y-5 sm:left-[4rem] md:top-40 md:left-[5rem]">
        <span className="text-sm text-white font-poppins">
          <p className="leading-10 md:text-5xl sm:text-4xl lg:text-6xl lg:font-bold lg:w-[330px] lg:mt-12 xl:mt-24 md:w-[300px] w-[280px]  xs:text-3xl font-bold text-4xl text-primaryColor1">
            {title}
          </p>
          <br /> <br />
          <p className="lg:w-[300px] lg:mt-0 xl:mt-4 w-[280px] font-bold sm:text-3xl text-2xl text-primaryColor2">
            {description}
          </p>
        </span>
      </div>
    </div>
  );
}

export default PageHero;
