const routes = {
  nonprotected: {
    login: "/login",
    register: "/register",
    forgot_password: "/forgot-password",
  },
  public: {
    home: "/",
    projects: "/projects",
    about: "/about",
    tribe: "/tribe",
    blog: "/blog",
    thift: "/thift",
    contact: "/contact",
    faq: "/faq",
    develop: "/develop",
    invest: "/invest",
    grow: "/grow",
    _20daysofchristmas: "/20daysofchristmas",
    termsandcondition: "/termsandcondition",
    termsandcondition2: "/termsandcondition2",
    termsandcondition3: "/termsandcondition3",
    _20daysofchristmas_product: {
      index: "/20daysofchristmas/:id",
      product: (id: string) => `20daysofchristmas/${id}`,
    },
    buy20daysofchristmas: "/buy20daysofchristmas",
    slideshow: "/slideshow",
    bannerslide: "/bannerslide",
    main: "/main",
    search: "/search",
    faqclone: "/faqclone",
    devpro: "devpro",
    devtermsconditions: "/devtermsconditions",
    devtermsconditions2: "/devtermsconditions2",
    estatetermsconditions: "/estatetermsconditions",
    estatetermsconditions2: "/estatetermsconditions2",
  },
  dashboard: {
    index: "/dashboard",
    grow: "/dashboard/grow",
    invest: "/dashboard/invest",
    develop: "/dashboard/develop",
  },
  profile: "/profile",
  profile_edit: "/profile/edit",
  inbox: "/inbox",
  notification: "/notification",
  terms: "/terms",
};

export default routes;
