import { useState } from "react";
import Button from "./Button";
import { InvestHouseCards } from "./InvestHouseCards";

import { InvestLandCards } from "./InvestLandCards";

export default function InvestGallery() {
  const [step, setStep] = useState<number>(1);

  //changes
  const goLand = () => {
    setStep(1);
    // console.log(step);
  };

  const goHouse = () => {
    setStep(2);
    // console.log(step);
  };

  return (
    <div
      className=" 
  
	mx-auto h-max flex flex-col items-center justify-center mt-16 p-5
	
	"
    >
      <div className="flex items-center justify-center w-[15%] h-[60px] border border-primaryColor1 rounded-t-lg z-[1]">
        <div className="self-center">
          {/* w-40 h-16 rounded-t-none bg-white shadow shdaow-lg flex items-center justify-center cursor-pointer */}
          <div
            className={
              step === 1
                ? "w-40 h-16 rounded-t-lg bg-primaryColor1 shadow shdaow-lg flex items-center justify-center cursor-pointer"
                : "w-40 h-16 rounded-t-lg bg-white shadow shdaow-lg flex items-center justify-center cursor-pointer"
            }
            onClick={goLand}
          >
            <span
              className={
                step === 1
                  ? "text-white font-urbanist font-bold"
                  : "text-primaryColor1 font-urbanist font-bold"
              }
            >
              Land
            </span>
          </div>
        </div>
        <div className="self-center">
          <div
            className={
              step === 2
                ? "w-40 h-16 rounded-t-lg bg-primaryColor1 shadow-lg flex items-center justify-center cursor-pointer"
                : "w-40 h-16 rounded-t-lg bg-white  shadow-lg flex items-center justify-center cursor-pointer"
            }
            onClick={goHouse}
          >
            <span
              className={
                step === 2
                  ? "text-white font-urbanist font-bold"
                  : "text-primaryColor1 font-urbanist font-bold"
              }
            >
              House
            </span>
          </div>
        </div>
      </div>
      <div
        className="lg:w-[95%]  xl:w-[90%] sm:p-6 bg-white border border-primaryColor1  shadow-3xl w-[100%] rounded-lg shadow-2xl p-6 relative oveflow-auto pb-5"
      >
        {/* <div className="right-[20px] z-10 text-[25px] top-[210px] h-[38px] pb-1 bg-black w-[38px] flex items-center justify-center rounded-full absolute cursor-pointer ">
          <span className="text-white text-[15px]">&#10093;</span>
        </div> */}
        {step === 1 ? (
          <InvestLandCards />
        ) : step === 2 ? (
          <InvestHouseCards />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
