import { PersonTeamProps } from '../typings'
import proper from '../assets/Vector_White.png'

export default function PersonCard( {pson ,open , index , handleClick} : PersonTeamProps) {
  return (
	<div className='bg-white  w-[192px] h-[292px] rounded-lg shadow-lg hover:scale-105  flex flex-col items-center justify-center gap-y-5' key={pson.id}>
		<div>
           <img src={pson.image} alt="" />
		</div>
		<div>
           <span className='font-bold text-sm font-urbanist'>{pson.name}</span>
		</div>
		<div>
           <span className='font-bold text-sm font-urbanist text-[#000000]'>{pson.position}</span>
		</div>
		<div>
		    <button className='bg-primaryColor1 font-urbanist flex items-center gap-x-4 text-white px-7 py-2 rounded-lg
			 hover:transition-all duration-300 ease-in-out  hover:border hover:border-primaryColor1 hover:bg-transparent hover:text-primaryColor1'
			
			 onClick = {() => handleClick(pson)}
			 
			 >
		    	Learn More
             
			<img src={proper} alt="" />
			
			</button>

		</div>
        
        
	</div>
  )
}
