import React, { useEffect, useState } from "react";

function useSliderCards({ items }: { items: any[] }) {
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(33.33);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the centerSlidePercentage based on screen width

      switch (true) {
        case window.innerWidth < 540:
          setCenterSlidePercentage(99);
          break;
        case window.innerWidth < 768:
          setCenterSlidePercentage(50);
          break;

        default:
          setCenterSlidePercentage(33.33);
          break;
      }
    };

    // Initial adjustment on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [items.length]);
  return centerSlidePercentage;
}

export default useSliderCards;
