import VisionPic from "../assets/landshop_vision.png";

function Vision() {
  return (
    <div
      className="lg:h-[30rem] xs:h-[60rem] mx-auto bg-white 
	xs:flex xs:flex-col-reverse xs:items-center xs:justify-center xs:mt-[6rem] xs:gap-y-9
	sm:flex sm:flex-col-reverse sm:items-center sm:justify-center sm:mt-[10rem] sm:gap-y-9 
	md:flex md:flex-col-reverse md:items-center md:justify-center md:mt-[12rem] md:gap-y-[6rem] 
	lg:flex lg:flex-row lg:items-center lg:justify-around lg:mt-[0] lg:mt-[-40px]
	
	
	"
    >
      <div
        className="
	    xs:ml-[1rem] xs:flex xs:flex-col xs:gap-y-4
	    sm:ml-[1rem] sm:flex sm:flex-col sm:gap-y-4
		md:ml-[5rem] md:flex md:flex-col md:gap-y-4 
		lg:flex lg:flex-col lg:gap-y-4 lg:w-[649px] lg:h-[340px] lg:mt-[50px]
	 
	 
	   "
      >
        <div className="lg:self-start xl:self-start md:self-center sm:self-center xs:self-center lg:text-left xs:textcent">
          <span className="font-black text-4xl font-urbanist">Our Vision</span>
        </div>
        <div className="lg:self-start xl:self-start md:self-center sm:self-center xs:self-center lg:text-left xs:text-center">
          <span className="text-2xl font-urbanist font-bold text-primaryColor2">
            Is to fill the real estate deficit for every generation of Nigeria’s
            growing population.
          </span>
        </div>
        <div className="lg:self-start xl:self-start md:self-center sm:self-center xs:self-center">
          <span className="text-sm font-urbanist">
            Landshop's ultimate goal is to address Nigeria's real estate deficit
            and provide property ownership opportunities for every generation.
            We envision a future with reduced homelessness and widespread access
            to property ownership.
          </span>
        </div>
      </div>

      <img
        src={VisionPic}
        alt=""
        className="
	 md:ml-[2rem] md:w-[424px] md:h-[424px]
	 lg:w-[424px] lg:h-[424px] lg:p-6 lg:pt-0 lg:mt-[-15px] lg:pl-0
	 xl:w-[424px] xl:h-[424px] xl:pt-0
	 sm:w-[424px] sm:h-[424px] 
	 xs:w-[424px] xs:h-[424px] 
   
	   "
      />
    </div>
  );
}

export default Vision;
