import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import * as tk from "./thunk";
import { clearUserDetails, storeUserToken } from "../../common/service/storage";

const initialState: Record<string, string | boolean | any> = {
  loading: false,
  error: null,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = { ...initialState.user };
    },

    resetAuthError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(tk.logUserOut.fulfilled, (state) => {
        clearUserDetails();
        state.user = initialState.user;
      })
      .addMatcher(
        isAnyOf(
          tk.logUserIn.fulfilled,
          tk.registerUser.fulfilled,
          tk.fetchUserProfile.fulfilled
        ),
        (state, { payload }) => {
          state.user = payload.user;
        }
      )
      .addMatcher(
        isAnyOf(tk.logUserIn.fulfilled, tk.registerUser.fulfilled),
        (_, { payload }) => {
          storeUserToken(payload?.jwt?.token);
        }
      )
      .addMatcher(
        isAnyOf(
          tk.logUserIn.pending,
          tk.registerUser.pending,
          tk.resetUserPassword.pending,
          tk.updateUserPassword.pending,
          tk.updateUserProfile.pending,
          tk.fetchUserProfile.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.logUserIn.rejected,
          tk.logUserIn.fulfilled,
          tk.registerUser.rejected,
          tk.registerUser.fulfilled,
          tk.resetUserPassword.rejected,
          tk.resetUserPassword.fulfilled,
          tk.updateUserPassword.rejected,
          tk.updateUserPassword.fulfilled,
          tk.updateUserProfile.rejected,
          tk.updateUserProfile.fulfilled,
          tk.fetchUserProfile.rejected,
          tk.fetchUserProfile.fulfilled
        ),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export const { resetAuthError, resetUser } = authSlice.actions;

export default authSlice.reducer;
