// Team
import person1 from "./assets/md.png";
import person2 from "./assets/coo.png";
import person3 from "./assets/david_edited.jpg";
import person4 from "./assets/cfm.png";
import person5 from "./assets/sbe.png";
import person6 from "./assets/vc.png";
import person7 from "./assets/ned1.png";
import person8 from "./assets/ned2.png";

// Grow
import grow1 from "./assets/growHouse1.png";
import grow2 from "./assets/growHouse2.png";
import grow3 from "./assets/growHouse3.png";
import grow4 from "./assets/growHouse4.png";
import grow5 from "./assets/growHouse5.png";
import grow6 from "./assets/growAds21.png";
import grow7 from "./assets/growAds22.png";
import grow8 from "./assets/growAds23.png";
import grow9 from "./assets/growAds24.png";
import grow10 from "./assets/growAds25.png";

//Land
import land1 from "./assets/land1.png";
import land2 from "./assets/land2.png";
import land3 from "./assets/land3.png";
import land4 from "./assets/land4.png";
import land5 from "./assets/land5.png";
import land6 from "./assets/land6.png";

//House
import house1 from "./assets/house1.png";
import house2 from "./assets/house2.png";
import house3 from "./assets/house3.png";
import house4 from "./assets/house4.png";
import house5 from "./assets/house5.png";
import house6 from "./assets/house6.png";

//testimony
import testimony1 from "./assets/testimony1.png";
import testimony2 from "./assets/testimony2.png";
import testimony3 from "./assets/testimony3.png";

//Green
import greenImage from "./assets/greenImage.png";

//Recent Stories
import recent1 from "./assets/recent1.png";
import recent2 from "./assets/recent2.png";
import recent3 from "./assets/recent3.png";
import recent4 from "./assets/recent4.png";

//popular stories
import popular1 from "./assets/popular1.png";
import popular2 from "./assets/popular2.png";
import popular3 from "./assets/popular3.png";

import {
  BoardTeamType,
  GrowData1Type,
  GrowDataType,
  InvestHouseType,
  InvestLandType,
  PersonTeamType,
  TestimonialType,
  FAQdataType,
  growCountryDataType,
} from "./typings";
import { Question } from "./pages/FAQClone";

export const PersonTeamData: PersonTeamType = [
  
  {
    id: 2,
    image: person5,
    name: "Oweh Eseoghene",
    position: "Lead, Business Development",
    context:
      "A certified business strategist with over 3 years of successful business development management in real estate and telecommunications.Ese focuses on fostering exceptional business relationships for the brand. She holds a BSc in accounting from All Nations University College, Ghana, a certificate from the International strategic management institute, and an MSc in view from the university of Lagos",
      link: "https://www.linkedin.com/in/eseoghene-oweh-cism-620a35167/"
  },
  {
    id: 3,
    image: person4,
    name: "Owolabi Olugbenga",
    position: "Accounts Manager",
    context:
      "A trained accountant, auditor, and financial expert in charge of the Group’s accounting and financial advisory.Having applied his accounting prowess across the oil & gas industry and with telecommunications giant; MTN. He brings with him a passion for the group’s accounting standards development. He holds degrees from Kwara Polytechnic, University of Ilorin, with ICAN in mind. He is a member of the National Institute of Management (NIM).",
  },

  {
    id: 4,
    image: person3,
    name: "David Afolabi",
    position: "Customer Experience Lead",
    context:
      "David is a Customer Experience Manager with over 8 years experience in delivering excellent customer service in the Telecommunication and Real Estate industry. He leads a team of talented, innovative, problem solving and self - driven individuals. He holds a Msc degree in Public and International affairs from University of Lagos with a Bsc in Communications and Media Studies from Afe Babalola University.His mission is to ensure a positive and seamless experience throughout a customers journey in the organization.",
  },
  
  
];

export const BoardTeam: BoardTeamType = [
  {
    id: 1,
    image: person6,
    name: "Prince David Omaghomi",
    position: "Vice Chairman",
    context:
      "He co-founded the Revelation Properties Group (RevPro) in 2016, as the parent company for Landshop and its other subsidiaries, and provides creative and managerial oversight to a combined team of almost 100 employees and over 2,000 independent consultants across various offices in Nigeria and in the diaspora.. ",
      link: "https://www.linkedin.com/in/davidomaghomi/"
  },
  {
    id: 2,
    image: person7,
    name: "Dr Edwin Eno",
    position: "Non Executive Director",
    context:
      "A medical doctor by training with over 10 years in the health sector, Dr Edwin took on real estate for its socio-economic opportunities, with a look to applying his background to the industry.",
  },

  {
    id: 3,
    image: person8,
    name: "Michael Ehiedu",
    position: "Non Executive Director",
    context:
      "He is an expert businessman, who garnered over 15 years sales experience across publishing and real estate. He then transcribed his business background into a career of product marketing. Which saw him not only market products like magazines for a top publisher, but for more than 10 years broker multi-million-naira leases, sales and acquisitions of small-scale and premium real estate across Nigeria.",
      link: "https://www.linkedin.com/in/michael-ehiedu-a5650516a/"
  },
  
];

export const GrowData: GrowDataType = [
  {
    id: 1,
    image: grow1,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦14,000,000",
  },
  {
    id: 2,
    image: grow2,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦23,000,000",
  },
  {
    id: 3,
    image: grow5,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦21,000,000",
  },
  {
    id: 4,
    image: grow3,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦35,000,000",
  },
  {
    id: 5,
    image: grow4,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦84,000,000",
  },
  {
    id: 6,
    image: grow5,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦21,000,000",
  },
];

export const GrowData2: GrowDataType = [
  {
    id: 1,
    image: grow6,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦36,000,000",
  },
  {
    id: 2,
    image: grow7,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦84,000,000",
  },
  {
    id: 3,
    image: grow8,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦55,000,000",
  },
];

export const GrowData1: GrowData1Type = [
  {
    id: 1,
    image: grow4,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦84,000,000",
  },
  {
    id: 2,
    image: grow5,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦3,000,000",
    mainPrice: "₦21,000,000",
  },
];

export const GrowData3: GrowData1Type = [
  {
    id: 1,
    image: grow9,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦23,000,000",
    mainPrice: "₦23,000,000",
  },
  {
    id: 2,
    image: grow10,
    flatType: "3 Bedroom Blocks of Flat",
    name: "MERIT MEWS",
    consent: "Governors Consent",
    intialPrice: "₦216,000,000",
    mainPrice: "₦216,000,000",
  },
];

export const InvestLandData1: InvestLandType = [
  {
    id: 1,
    image: land1,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "land",
  },
  {
    id: 2,
    image: land2,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "land",
  },
  {
    id: 3,
    image: land3,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "land",
  },
];

export const InvestLandData2 = [
  {
    id: 4,
    image: land4,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "land",
  },

  {
    id: 5,
    image: land5,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "land",
  },
  {
    id: 6,
    image: land6,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "land",
  },
];

export const InvestHouseData1: InvestHouseType = [
  {
    id: 11,
    image: house1,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "house",
  },
  {
    id: 12,
    image: house2,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "house",
  },
  {
    id: 13,
    image: house3,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "house",
  },
];

export const InvestHouseData2 = [
  {
    id: 14,
    image: house4,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "house",
  },
  {
    id: 15,
    image: house5,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "house",
  },
  {
    id: 16,
    image: house6,
    location: "Verified Real Estate Investment In Freedom Way, Lekki",
    intialDeposit: "Amount",
    finalPrice: "Amount",
    type: "house",
  },
];

export const TestimonialData: TestimonialType = [
  {
    id: 1,
    image: testimony1,
    testimony:
      "I have confidence in Landshop because I’m always contacted whilst I’m far away. And of course, I will recommend Landshop to friends and family. I am also excited about the value appreciation. That was indeed fast",
    name: "Precious Oluwapelumi Adeleye",
    location: "Maryland, Lagos",
  },
  {
    id: 2,
    image: testimony2,
    testimony:
      "Landshop is authentic and experienced. And your customer service is 100%. From social media to follow up calls. I was just informed by my account officer that my property has an appreciated price. Great.",
    name: "Emmanuel Sansi",
    location: "Ikotun, Lagos",
  },
  {
    id: 3,
    image: testimony3,
    testimony:
      "You guys are any property investor’s top option. You’ve made land purchase very easy and affordable for everyone, including me. Hearing about the growth of my property is awesome and knowing I can still continue my payment is just wonderful.",
    name: "Ojo Victor Oluwatimilehin",
    location: "Surulere, Lagos",
  },
  // {
  // 	id : 4,
  // 	image : ,
  // 	testimony : 'Verified Real Estate Investment In Freedom Way, Lekki',
  // 	name : 'Amount',
  // 	location : 'Amount'

  // },
  // {
  // 	id : 5,
  // 	image : ,
  // 	testimony : 'Verified Real Estate Investment In Freedom Way, Lekki',
  // 	name : 'Amount',
  // 	location : 'Amount'

  // },
  // {
  // 	id : 6,
  // 	image : ,
  // 	testimony : 'Verified Real Estate Investment In Freedom Way, Lekki',
  // 	name : 'Amount',
  // 	location : 'Amount'

  // }
];
export const GreenExtraLandGallery = [
  {
    id: 1,
    name: "Green Island",
    image: greenImage,
    price: 100000000,
    discount: 50,
  },

  {
    id: 2,
    name: "Green Island",
    image: greenImage,
    price: 100000000,
    discount: 50,
  },

  {
    id: 3,
    name: "Green Island",
    image: greenImage,
    price: 100000000,
    discount: 50,
  },
  {
    id: 4,
    name: "Green Island",
    image: greenImage,
    price: 100000000,
    discount: 50,
  },
];

export const GreenExtraHouseGallery = [
  {
    id: 1,
    name: "Green Island",
    image: greenImage,
    price: 100000000,
    discount: 50,
  },
];

export const data = [
  {
    id: 1,
    name: "Donny Jiang",
    category: "Architecture",
    desc: "Minneapolis, MN, USA",
    color: "#5f9199",
    url: "https://images.unsplash.com/photo-1583430999185-4c19b0c9636a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
  },
  {
    id: 2,
    name: "Francesco Ungaro",
    category: "Architecture",
    desc: "Mudec museum of culture, Milano, Italy",
    color: "#426555",
    url: "https://images.unsplash.com/photo-1534320309096-17ce1f77021d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2158&q=80",
  },
  {
    id: 3,
    name: "Dorien Monnens",
    category: "Architecture",
    desc: "Glasgow botanical garden",
    color: "#a2b3b0",
    url: "https://images.unsplash.com/photo-1597077864840-44f0d85011a6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
  },
  {
    id: 4,
    name: "Mehmet Turgut Kirkgoz",
    category: "Architecture",
    desc: "Vatican Museums, Vatikan",
    color: "#0d3d45",
    url: "https://images.unsplash.com/photo-1596432150438-3ef028cca147?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1867&q=80",
  },
  {
    id: 5,
    name: "Clarisse Croset",
    category: "Architecture",
    desc: "Paris, France",
    color: "#807f7e",
    url: "https://images.unsplash.com/photo-1597176924237-b228b1153c7b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
  },
  {
    id: 6,
    name: "Dylan Leagh",
    category: "Architecture",
    desc: "Amsterdam",
    color: "#8da2a9",
    url: "https://images.unsplash.com/photo-1596188773105-3bdfcf453495?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1439&q=80",
  },
  {
    id: 7,
    name: "Thomas de LUZE",
    category: "Architecture",
    desc: "Slovénie, Slovénie",
    color: "#48737d",
    url: "https://images.unsplash.com/photo-1596208091591-fc5c2f4a1022?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
  },
  {
    id: 8,
    name: "Nick Samoylov",
    category: "Architecture",
    color: "#d89e80",
    desc: "Dortheavej Residence BIG, Dortheavej, Copenhagen Municipality, Denmark",
    url: "https://images.unsplash.com/photo-1596572133110-277ff81c8b5c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80",
  },
  {
    id: 9,
    name: "Max Titov",
    category: "Architecture",
    desc: "Moscow City",
    color: "#6b0200",
    url: "https://images.unsplash.com/photo-1596513808047-b8c8d0cfd363?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
  },
  {
    id: 10,
    name: "Duncan Meyer",
    desc: "Hillcrest, South Africa",
    category: "Architecture",
    color: "#858585",
    url: "https://images.unsplash.com/photo-1579850996789-3537c09dc873?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80",
  },
  {
    id: 11,
    name: "Andrea Ferrario",
    category: "Architecture",
    desc: "milano, Italia",
    color: "#3d656f",
    url: "https://images.unsplash.com/photo-1556804608-fdecc1e43e84?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
  },
  {
    id: 12,
    name: "Franck V",
    desc: "Intricate",
    category: "Architecture",
    color: "#354578",
    url: "https://images.unsplash.com/photo-1590324579681-f74246a29f78?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=939&q=80",
  },
  {
    id: 13,
    name: "Eugene Lim",
    category: "Architecture",
    desc: "Hong Kong Island, Hong Kong",
    color: "#303030",
    url: "https://images.unsplash.com/photo-1497582114636-bea95b4c8f53?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
  },

  {
    id: 14,
    name: "Zheni Yaneva",
    category: "Architecture",
    desc: "Canary Wharf, London, UK",
    color: "#5996ea",
    url: "https://images.unsplash.com/photo-1596953814369-a2cde05939e3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80",
  },
  {
    id: 15,
    name: "Jonathan Kemper",
    category: "Architecture",
    desc: "Bonn, Deutschland",
    color: "#736558",
    url: "https://images.unsplash.com/photo-1597132708057-f7f57aefd651?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
  },
];

export const RecentPost = [
  {
    id: 1,
    image: recent1,
    postTitle: "IKEJA 250 ROOMS PROJECT",
    postAuthor: "Balogun Ademola",
    postContent:
      "Apa itu HTML ? Buat kamu yang pernah membuat sebuah website pastinya sudah tidak asing lagi dengan yang namanya HTML. S…",
    postDate: "22 August 2022",
  },
  {
    id: 2,
    image: recent2,
    postTitle: "LEKKI EXPRESS NEW ROUTE",
    postAuthor: "Kehinde Lafenwa",
    postContent:
      "Apa itu HTML ? Buat kamu yang pernah membuat sebuah website pastinya sudah tidak asing lagi dengan yang namanya HTML. S…",
    postDate: "25 June 2022",
  },
  {
    id: 3,
    image: recent3,
    postTitle: "Security at Night",
    postAuthor: "Fela Anikulapo",
    postContent:
      "Apa itu HTML ? Buat kamu yang pernah membuat sebuah website pastinya sudah tidak asing lagi dengan yang namanya HTML. S…",
    postDate: "10 January 2022",
  },
  {
    id: 4,
    image: recent4,
    postTitle: "Minimalism at its best",
    postAuthor: "Funke Adebayo",
    postContent:
      "Apa itu HTML ? Buat kamu yang pernah membuat sebuah website pastinya sudah tidak asing lagi dengan yang namanya HTML. S…",
    postDate: "5 December 2022",
  },
];

export const popularStories = [
  {
    id: 1,
    image: popular1,
    sale: "House 5 sold",
    date: "22 August 2022",
  },
  {
    id: 2,
    image: popular2,
    sale: "House 10 sold",
    date: "10 February 2022",
  },
  {
    id: 1,
    image: popular3,
    sale: "House 20 sold",
    date: "2 March 2022",
  },
];

// export const Merry = () => {
//   return(
//     Question
//   )

// }

export const FAQdata: FAQdataType = [
  {
    id: 1,
    question: "What is Landshop?",
    answer:
      " Landshop is Nigeria’s premier real estate co-investment property development company that offers a wide berth of property solutions in acquisition,mortgage and investments.",
    content:
      "With a driving philosophy that’s centered around enabling access for Nigerians of all stripes, Landshop’s innovative proprietaries: GROW, INVEST, DEVELOP…and THRIFT are designed to address hot-button concerns of potential property owners.",
  },
  {
    id: 2,
    question: "Where is Landshop and how do I reach you?",
    answer:
      "Landshop headquarters is located in Lagos, Nigeria. And we can be reached at the following address:",
    content:
      " Address: Suite 1, 3rd floor, king’s deck, alternative route, off chevron drive, Lekki-Epe expressway, Lagos",

    email: "Email: care@landshop.ng",
    phone: "Phone: +234 1 888 0063",
    whatsapp: "WhatsApp only: +234 818 886 6772",
  },
  {
    id: 3,
    question: "How Do I Know Landshop is a Legit Real Estate Company?",
    answer:
      "Landshop’s board of directors and management team comprise industry experts with over 5 decades of combined industry expertise across relevant fields. Landshop has proven over 5 years of clients’ satisfaction, augmented by industry achievement awards and verifiable government certifications. We are certified to practice by the Lagos State Real Estate Regulatory Authority: and are members of the Real Estate Developers Association of Nigeria.",
    content:
      "Landshop Limited is registered at the Federal Government’s Corporate Affairs Commission with RC No.144734.",
  },
  {
    id: 4,
    question: "What Titles do properties on Landshop Have?",
    answer:
      "Depending on the location and property, titles range between Certificate of Occupancy (C of O), Governor’s Consent, Excision and Registered Survey.",
  },
  {
    id: 5,
    question: "Please Explain the Differences Between the Titles.",
    answer: `C of O is a leasehold type of title that confers a 99 years lease on the property. Governor’s Consent is a
    free hold type of title that confers indefinite lease on said property. Excision is a type of title that, by
    government’s approval, delineates a portion of land to a communal settlement, which in turn has been
    bought and used for a real estate development by Landshop and with the consent of the State.
    Registered Survey is a type of title that basically shows the property has been registered for the
    applicable use.
    `,
  },
  {
    id: 6,
    question: "Does Landshop Accept Foreign or Crypto Currency?",
    answer: `Yes. We accept foreign currency, based on prevailing exchange rates. We are currently working on
    adopting cryptocurrency; this mode of payment should be available in a couple of months.`,
  },
  {
    id: 7,
    question: "How Can I Make Payment on any Property Transaction?",
    answer: `You can make your payment either by using your debit (ATM) card, do an online transfer, a bank deposit
      or USSD code`,
  },
  {
    id: 8,
    question:
      "What Happens After I Complete A Payment And How Do I Get My Land Documents?",
    answer: `Upon confirmation of payment, you will receive a payment acknowledgment by email. Hard copies could
      be picked up at our office or sent to you by registered mail or courier service at your expense`,
  },
  {
    id: 9,
    question:
      "Do I Get Any Commission If I Refer People to Buy, Sell, Mortgage or Invest in Landshop?",
    answer: `Yes, but you have to register as an independent consultant on our portal. Please send an email to
     care@landshop.ng for more information.`,
  },
  {
    id: 10,
    question:
      "Do I Have To Pay Anything Else For Documentation Or Development Levy?",
    answer: `Depends. Most properties are all-inclusive of documentation fee and development levy. Please confirm
      with your sales rep or contact our customer support for details on specific transactions/estate`,
  },
  {
    id: 11,
    question:
      "Is There Any Restriction Regarding The Type Of Building I Can Construct On The Estate I Buy From Landshop?",
    answer: `Yes. Constructions are subject to the rules and guidelines of every estate project. Please refer to the
      individual estate client manual for full details.`,
  },
  {
    id: 12,
    question:
      "How Do I Distinguish Between a Residential, Commercial or Dual Purpose Property?",
    answer: `All property descriptions indicate its property use. This info can be gleaned under all properties. Or
      confirm with your sales rep or contact our customer support for details on specific estate’s terms and
      conditions.`,
  },
  {
    id: 13,
    question:
      "Can My Property Be Resold or If I Begin Payment On The Land And Cannot Complete or Wish To Get A Refund, What Happens?",
    answer: `Yes, you can re-sell your property and can request a refund in writing. Both are possible, but in line with
      the resale/refund policies stipulated in the property sales agreement`,
  },
  {
    id: 14,
    question: "Can I Build On My Land When I Buy Land from Landshop?",
    answer: `Yes you can build but in conformity with the guidelines for infrastructure development within the Landshop
      location.`,
  },
  {
    id: 15,
    question: "Do I Automatically Own The Land When I Purchase From Landshop?",
    answer: `Yes, the land belongs to you and can be passed on to your successors in title.`,
  },
  {
    id: 16,
    question: "Can I Visit the Property of Choice Before or After I Buy?",
    answer: `Yes. You can visit any of the sites by booking a physical or virtual site tour on the website or we can
      provide location details and you visit at your convenience.`,
  },
  {
    id: 17,
    question: "What Other Payments Do I Make Apart from Land Payment?",
    answer: `To facilitate your documentation (Contract of Sale, Deeds of Assignment & Surveys) we charge a
      documentation fee of 10% (for Lagos) & 5% (outside Lagos) of the total value of Land purchased`,
  },
  {
    id: 18,
    question: "Do I Have To Pay For Documentation Whilst Paying For The Land?",
    answer: `No. To achieve our target of making land ownership available to everyone, you can choose to make
      payment for your documentation at a later date (depending on your budget).`,
  },
  {
    id: 19,
    question: "Can I Pay Cash To An Agent of Landshop?",
    answer: `No. All payments must be made to Landshop limited or designated accounts only or accounts of property
      owners verified and published by Landshop.`,
  },
  {
    id: 20,
    question:
      "As an Estate Agent or Developer, Can I Upload my Property for Sale on Landshop?",
    answer: `No, Landshop only accepts properties from the owner or agent with registered interest/ instructions of the
      owner to sell.`,
  },
  {
    id: 21,
    question: "What’s a PAC Trustee?",
    answer: `This is Landshop’s official securities partner that provides fiduciary coverage for all clients’ investment
      assets.`,
  },
  {
    id: 22,
    question: "What’s GROWPROPERTY?",
    answer: `GrowProperty is one of Landshop’s proprietary that provides MORTGAGE loans to finance
      construction/ownership of homes with highly reduced interest rates and extended repayment periods. It
      comprises: Beta Mortgage & Mortgage Xtra.`,
  },
  {
    id: 23,
    question: "What’s Your Mortgage Financial Institution?",
    answer: `Currently, Living Trust Mortgage Bank Plc is Landshop’s official banking partner. It provides the funding
      for all Landshop’s mortgaging services. Landshop is working to onboard more Mortgage Institutional
      partners to be able to cover any part of Nigeria.`,
  },
  {
    id: 24,
    question: "How Do I Qualify for GrowProperty (Mortgage)?",
    answer: `All applicants for GROW (Beta Mortgage & Mortgage Xtra) are assessed for credit worthiness, job type,
      property size/type etc, in order to duly profile their ability to repay. All eligible applicants are INSTANTLY
      provided with the necessary funds/access to their property of choice.`,
  },
  {
    id: 25,
    question: "What’s NHF and Mortgage Account Number?",
    answer: `NHF is the federally-mandated National Housing Fund number that is assigned to all Nigerians who
      contribute to the NHF in order to provide them access to the national mortgage funds. Because of
      Landshop’s unique mortgage-licencing status, NHF numbers will be provided to all Landshop’s potential
      mortgage clients.`,
    content: `Mortgage Account number is the bank account number that is required by every mortgage applicant. It is
      provided by a mortgage bank, and will be provided to our clients by Living Trust Mortgage Bank. `,
  },
  {
    id: 26,
    question: "What’s BETA MORTGAGE?",
    answer: `This is a mortgage product that provides loans for the construction of homes from scratch. Living Trust
      Bank provides 70% of the funds to build the house you want. While you, the client, deposit 30% of the
      property value upfront within 1 – 3 months. And also pay 2.5% of your monthly earnings to your Living
      Trust Mortgage Bank account. Repayment of the 70% borrowed is done over the next 30 years at an
      interest rate of 6% yearly.`,
  },
  {
    id: 27,
    question: "What properties are eligible for BETA MORTGAGE?",
    answer: `All the listed properties are available for Beta Mortgage
      [insert all properties]`,
  },
  {
    id: 28,
    question: "What’s MORTGAGE XTRA?",
    answer: `This is a 0% interest RENT TO OWN mortgage product. Once approved for this mortgage, you simply
      pack into the apartment immediately, and then use your rent as the repayment plan. You pay 7% of the
      property value as rent annually.`,
  },
  {
    id: 29,
    question: "What properties are eligible for MORTGAGE XTRA?",
    answer: `All the listed properties are available for Mortgage Xtra
      [insert all properties]`,
  },
  {
    id: 30,
    question: "What’s INVEST?",
    answer: `This is another proprietary that allows clients to earn returns by investing rather than acquiring or owning
      real estate. An ROI of 70% flat is guaranteed on any property investment. Clients can invest in plots/sqm
      of lands or units/slots of houses.`,
  },
  {
    id: 31,
    question: "How Do I Invest?",
    answer: `You can invest by choosing a property of any size in plots/sqm or unit/slot. Investments are available for
      lands and houses. Choose an investment plan, and earn pay outs in at least a year.`,
  },
  {
    id: 32,
    question: "What’s the Duration of an Investment?",
    answer: `Investments can last for as long as five years. However, payouts are only possible on an annual basis.`,
  },
  {
    id: 33,
    question: "How much can I invest?",
    answer: `There is no top-end limit to what you can invest. However, you can only invest in a minimum of 1 bundle
      (30 sqm for land, 1 slot for house).`,
  },
  {
    id: 34,
    question: "70% ROI Flat Means What?",
    answer: `It means clients are guaranteed a fixed 70% returns irrespective of any circumstance. If, for example, you
      invest N1,000,000 in Year 1, in a property valued at N1,000,000, and the property appreciates by 60% in
      Year 2 or 3 to N1,600,000, it means you’d earn a guaranteed 70% of the N600,000 value appreciation on
      the property. I.e N420,000 (ROI) = your seed capital of N1m.`,
  },
  {
    id: 35,
    question: "What’s an Investment Bundle?",
    answer: `An investment bundle is the MINIMUM investment permissible per property. 30 sqm for land. 1 slot for
      house.`,
  },
  {
    id: 36,
    question: "How Do I Know My Investment is Confirmed/Started?",
    answer: `All confirmed clients will receive instant personalised details confirmation of their transaction. `,
  },
  {
    id: 37,
    question: "What’s a Square Metre (sqm)?",
    answer: `This is the smallest unit of a landed property. A full plot of land is made of 600 sqm. A sqm is 1/600 of a
      plot. Therefore, a sqm can be thought of as one square cutout of a land.`,
  },
  {
    id: 38,
    question: "What’s a Slot? ",
    answer: `This is the smallest unit of a house. A slot is like one tile cutout from an apartment floor. It’s 1/20 of the
      value of a full housing unit.`,
  },
  {
    id: 39,
    question: "How Do I Retrieve My ROI Payout?",
    answer: `Payouts are made on an annual basis (clients can only get payouts 12 months post-investments). Once
      your investment is confirmed. You have the option to choose your payout timeline..`,
  },
  {
    id: 40,
    question: "What’s DEVELOP?",
    answer: `This is the product that offers clients outright property acquisition. With DEVELOP, you can procure full
      plots and unit houses across the country. Payment plans are also available. `,
  },
  {
    id: 41,
    question: "Are credit facilities available for potential buyers?",
    answer: `Yes, they are. Landshop will provide credit/loans to qualified clients, through our partner financial
      institutions.`,
  },
  {
    id: 42,
    question: "Can I buy now and pay in installments?",
    answer: `Yes, you can. It comes at a premium/interest rate.`,
  },
  {
    id: 43,
    question: "What payment plans are there?",
    answer: `For outright purchase, clients can access payment plans of up to [insert amount], with up to 5 years
      period and at an interest of 15% for annual, 3.8% for quarterly, 1.3% for monthly, 0.3% for weekly and
      0.04% for daily.`,
  },
  {
    id: 44,
    question: "Where Does Landshop Have Properties?",
    answer: `Landshop has properties across major cities in Nigeria. Lagos, Abuja, Oyo, Ogun, Delta, Edo Akwa Ibom,
      Anambra etc.`,
  },
  {
    id: 45,
    question: "What are PRIME properties?",
    answer: `These are landed and housing properties that retail from the low to mid millions. Usually from 1.5 million
      to 40 million. The following are some of our PRIME properties. Green Island, Merit Park, Blue Diamond,
      Heritage Court.`,
  },
  {
    id: 46,
    question: "What are LUXURY properties?",
    answer: `These are highly exclusive and opulently sited lands and homes, with proximity to lavish scenery. They
      retail between forty to hundreds of millions. The following are some of our LUXURY properties. The Pearl,
      Maiyegun, Oasis, Atlantic City, The Groove.`,
  },
  {
    id: 47,
    question: "Can I Buy a Square Metre of Land or Slot of a House?",
    answer: `No. You can only buy full plots or unit houses. Sqm and slots are only available as investment options..`,
  },
  {
    id: 48,
    question: "What’s LANDSHOP THRIFT?",
    answer: `This is a specialised service created for clients with unique, unified desires. Clients like cooperative
      societies, churches, mosques, social clubs, august meetings, old school associations etc., looking to
      acquire property for a unified purpose.`,
    content: `Property sales are tailored to their demands and expectations. Contact us for a personal quote.`,
  },
];

export const growCountryData: growCountryDataType = [
  {
    state: {
      name: "Abia State",
      id: 1,
      locals: [
        {
          name: "Aba South",
          id: 1,
        },
        {
          name: "Arochukwu",
          id: 2,
        },
        {
          name: "Bende",
          id: 3,
        },
        {
          name: "Ikwuano",
          id: 4,
        },
        {
          name: "Isiala Ngwa North",
          id: 5,
        },
        {
          name: "Isiala Ngwa South",
          id: 6,
        },
        {
          name: "Isuikwuato",
          id: 7,
        },
        {
          name: "Obi Ngwa",
          id: 8,
        },
        {
          name: "Ohafia",
          id: 9,
        },
        {
          name: "Osisioma",
          id: 10,
        },
        {
          name: "Ugwunagbo",
          id: 11,
        },
        {
          name: "Ukwa East",
          id: 12,
        },
        {
          name: "Ukwa West",
          id: 13,
        },
        {
          name: "Umuahia North",
          id: 14,
        },
        {
          name: "Umuahia South",
          id: 15,
        },
        {
          name: "Umu Nneochi",
          id: 16,
        },
      ],
    },
  },
  {
    state: {
      name: "Adamawa State",
      id: 2,
      locals: [
        {
          name: "Fufure",
          id: 1,
        },
        {
          name: "Ganye",
          id: 2,
        },
        {
          name: "Gayuk",
          id: 3,
        },
        {
          name: "Gombi",
          id: 4,
        },
        {
          name: "Grie",
          id: 5,
        },
        {
          name: "Hong",
          id: 6,
        },
        {
          name: "Jada",
          id: 7,
        },
        {
          name: "Lamurde",
          id: 8,
        },
        {
          name: "Madagali",
          id: 9,
        },
        {
          name: "Maiha",
          id: 10,
        },
        {
          name: "Mayo Belwa",
          id: 11,
        },
        {
          name: "Michika",
          id: 12,
        },
        {
          name: "Mubi North",
          id: 13,
        },
        {
          name: "Mubi South",
          id: 14,
        },
        {
          name: "Numan",
          id: 15,
        },
        {
          name: "Shelleng",
          id: 16,
        },
        {
          name: "Song",
          id: 17,
        },
        {
          name: "Toungo",
          id: 18,
        },
        {
          name: "Yola North",
          id: 19,
        },
        {
          name: "Yola South",
          id: 20,
        },
      ],
    },
  },
  {
    state: {
      name: "Akwa Ibom State",
      id: 3,
      locals: [
        {
          name: "Eastern Obolo",
          id: 1,
        },
        {
          name: "Eket",
          id: 2,
        },
        {
          name: "Esit Eket",
          id: 3,
        },
        {
          name: "Essien Udim",
          id: 4,
        },
        {
          name: "Etim Ekpo",
          id: 5,
        },
        {
          name: "Etinan",
          id: 6,
        },
        {
          name: "Ibeno",
          id: 7,
        },
        {
          name: "Ibesikpo Asutan",
          id: 8,
        },
        {
          name: "Ibiono-Ibom",
          id: 9,
        },
        {
          name: "Ika",
          id: 10,
        },
        {
          name: "Ikono",
          id: 11,
        },
        {
          name: "Ikot Abasi",
          id: 12,
        },
        {
          name: "Ikot Ekpene",
          id: 13,
        },
        {
          name: "Ini",
          id: 14,
        },
        {
          name: "Itu",
          id: 15,
        },
        {
          name: "Mbo",
          id: 16,
        },
        {
          name: "Mkpat-Enin",
          id: 17,
        },
        {
          name: "Nsit-Atai",
          id: 18,
        },
        {
          name: "Nsit-Ibom",
          id: 19,
        },
        {
          name: "Nsit-Ubium",
          id: 20,
        },
        {
          name: "Obot Akara",
          id: 21,
        },
        {
          name: "Okobo",
          id: 22,
        },
        {
          name: "Onna",
          id: 23,
        },
        {
          name: "Oron",
          id: 24,
        },
        {
          name: "Oruk Anam",
          id: 25,
        },
        {
          name: "Udung-Uko",
          id: 26,
        },
        {
          name: "Ukanafun",
          id: 27,
        },
        {
          name: "Uruan",
          id: 28,
        },
        {
          name: "Urue-Offong/Oruko",
          id: 29,
        },
        {
          name: "Uyo",
          id: 30,
        },
      ],
    },
  },
  {
    state: {
      name: "Anambra State",
      id: 4,
      locals: [
        {
          name: "Anambra East",
          id: 1,
        },
        {
          name: "Anambra West",
          id: 2,
        },
        {
          name: "Anaocha",
          id: 3,
        },
        {
          name: "Awka North",
          id: 4,
        },
        {
          name: "Awka South",
          id: 5,
        },
        {
          name: "Ayamelum",
          id: 6,
        },
        {
          name: "Dunukofia",
          id: 7,
        },
        {
          name: "Ekwusigo",
          id: 8,
        },
        {
          name: "Idemili North",
          id: 9,
        },
        {
          name: "Idemili South",
          id: 10,
        },
        {
          name: "Ihiala",
          id: 11,
        },
        {
          name: "Njikoka",
          id: 12,
        },
        {
          name: "Nnewi North",
          id: 13,
        },
        {
          name: "Nnewi South",
          id: 14,
        },
        {
          name: "Ogbaru",
          id: 15,
        },
        {
          name: "Onitsha North",
          id: 16,
        },
        {
          name: "Onitsha South",
          id: 17,
        },
        {
          name: "Orumba North",
          id: 18,
        },
        {
          name: "Orumba South",
          id: 19,
        },
        {
          name: "Oyi",
          id: 20,
        },
      ],
    },
  },
  {
    state: {
      name: "Bauchi State",
      id: 5,
      locals: [
        {
          name: "Bauchi",
          id: 1,
        },
        {
          name: "Bogoro",
          id: 2,
        },
        {
          name: "Damban",
          id: 3,
        },
        {
          name: "Darazo",
          id: 4,
        },
        {
          name: "Dass",
          id: 5,
        },
        {
          name: "Gamawa",
          id: 6,
        },
        {
          name: "Ganjuwa",
          id: 7,
        },
        {
          name: "Giade",
          id: 8,
        },
        {
          name: "Itas/Gadau",
          id: 9,
        },
        {
          name: "Jama'are",
          id: 10,
        },
        {
          name: "Katagum",
          id: 11,
        },
        {
          name: "Kirfi",
          id: 12,
        },
        {
          name: "Misau",
          id: 13,
        },
        {
          name: "Ningi",
          id: 14,
        },
        {
          name: "Shira",
          id: 15,
        },
        {
          name: "Tafawa Balewa",
          id: 16,
        },
        {
          name: "Toro",
          id: 17,
        },
        {
          name: "Warji",
          id: 18,
        },
        {
          name: "Zaki",
          id: 19,
        },
      ],
    },
  },
  {
    state: {
      name: "Bayelsa State",
      id: 6,
      locals: [
        {
          name: "Ekeremor",
          id: 1,
        },
        {
          name: "Kolokuma/Opokuma",
          id: 2,
        },
        {
          name: "Nembe",
          id: 3,
        },
        {
          name: "Ogbia",
          id: 4,
        },
        {
          name: "Sagbama",
          id: 5,
        },
        {
          name: "Southern Ijaw",
          id: 6,
        },
        {
          name: "Yenagoa",
          id: 7,
        },
      ],
    },
  },
  {
    state: {
      name: "Benue State",
      id: 7,
      locals: [
        {
          name: "Apa",
          id: 1,
        },
        {
          name: "Ado",
          id: 2,
        },
        {
          name: "Buruku",
          id: 3,
        },
        {
          name: "Gboko",
          id: 4,
        },
        {
          name: "Guma",
          id: 5,
        },
        {
          name: "Gwer East",
          id: 6,
        },
        {
          name: "Gwer West",
          id: 7,
        },
        {
          name: "Katsina-Ala",
          id: 8,
        },
        {
          name: "Konshisha",
          id: 9,
        },
        {
          name: "Kwande",
          id: 10,
        },
        {
          name: "Logo",
          id: 11,
        },
        {
          name: "Makurdi",
          id: 12,
        },
        {
          name: "Obi",
          id: 13,
        },
        {
          name: "Ogbadibo",
          id: 14,
        },
        {
          name: "Ohimini",
          id: 15,
        },
        {
          name: "Oju",
          id: 16,
        },
        {
          name: "Okpokwu",
          id: 17,
        },
        {
          name: "Oturkpo",
          id: 18,
        },
        {
          name: "Tarka",
          id: 19,
        },
        {
          name: "Ukum",
          id: 20,
        },
        {
          name: "Ushongo",
          id: 21,
        },
        {
          name: "Vandeikya",
          id: 22,
        },
      ],
    },
  },
  {
    state: {
      name: "Borno State",
      id: 8,
      locals: [
        {
          name: "Askira/Uba",
          id: 1,
        },
        {
          name: "Bama",
          id: 2,
        },
        {
          name: "Bayo",
          id: 3,
        },
        {
          name: "Biu",
          id: 4,
        },
        {
          name: "Chibok",
          id: 5,
        },
        {
          name: "Damboa",
          id: 6,
        },
        {
          name: "Dikwa",
          id: 7,
        },
        {
          name: "Gubio",
          id: 8,
        },
        {
          name: "Guzamala",
          id: 9,
        },
        {
          name: "Gwoza",
          id: 10,
        },
        {
          name: "Hawul",
          id: 11,
        },
        {
          name: "Jere",
          id: 12,
        },
        {
          name: "Kaga",
          id: 13,
        },
        {
          name: "Kala/Balge",
          id: 14,
        },
        {
          name: "Konduga",
          id: 15,
        },
        {
          name: "Kukawa",
          id: 16,
        },
        {
          name: "Kwaya Kusar",
          id: 17,
        },
        {
          name: "Mafa",
          id: 18,
        },
        {
          name: "Magumeri",
          id: 19,
        },
        {
          name: "Maiduguri",
          id: 20,
        },
        {
          name: "Marte",
          id: 21,
        },
        {
          name: "Mobbar",
          id: 22,
        },
        {
          name: "Monguno",
          id: 23,
        },
        {
          name: "Ngala",
          id: 24,
        },
        {
          name: "Nganzai",
          id: 25,
        },
        {
          name: "Shani",
          id: 26,
        },
      ],
    },
  },
  {
    state: {
      name: "Cross River State",
      id: 9,
      locals: [
        {
          name: "Akamkpa",
          id: 1,
        },
        {
          name: "Akpabuyo",
          id: 2,
        },
        {
          name: "Bakassi",
          id: 3,
        },
        {
          name: "Bekwarra",
          id: 4,
        },
        {
          name: "Biase",
          id: 5,
        },
        {
          name: "Boki",
          id: 6,
        },
        {
          name: "Calabar Municipal",
          id: 7,
        },
        {
          name: "Calabar South",
          id: 8,
        },
        {
          name: "Etung",
          id: 9,
        },
        {
          name: "Ikom",
          id: 10,
        },
        {
          name: "Obanliku",
          id: 11,
        },
        {
          name: "Obubra",
          id: 12,
        },
        {
          name: "Obudu",
          id: 13,
        },
        {
          name: "Odukpani",
          id: 14,
        },
        {
          name: "Ogoja",
          id: 15,
        },
        {
          name: "Yakuur",
          id: 16,
        },
        {
          name: "Yala",
          id: 17,
        },
      ],
    },
  },
  {
    state: {
      name: "Delta State",
      id: 10,
      locals: [
        {
          name: "Aniocha South",
          id: 1,
        },
        {
          name: "Bomadi",
          id: 2,
        },
        {
          name: "Burutu",
          id: 3,
        },
        {
          name: "Ethiope East",
          id: 4,
        },
        {
          name: "Ethiope West",
          id: 5,
        },
        {
          name: "Ika North East",
          id: 6,
        },
        {
          name: "Ika South",
          id: 7,
        },
        {
          name: "Isoko North",
          id: 8,
        },
        {
          name: "Isoko South",
          id: 9,
        },
        {
          name: "Ndokwa East",
          id: 10,
        },
        {
          name: "Ndokwa West",
          id: 11,
        },
        {
          name: "Okpe",
          id: 12,
        },
        {
          name: "Oshimili North",
          id: 13,
        },
        {
          name: "Oshimili South",
          id: 14,
        },
        {
          name: "Patani",
          id: 15,
        },
        {
          name: "Sapele",
          id: 16,
        },
        {
          name: "Udu",
          id: 17,
        },
        {
          name: "Ughelli North",
          id: 18,
        },
        {
          name: "Ughelli South",
          id: 19,
        },
        {
          name: "Ukwuani",
          id: 20,
        },
        {
          name: "Uvwie",
          id: 21,
        },
        {
          name: "Warri North",
          id: 22,
        },
        {
          name: "Warri South",
          id: 23,
        },
        {
          name: "Warri South West",
          id: 24,
        },
      ],
    },
  },
  {
    state: {
      name: "Ebonyi State",
      id: 11,
      locals: [
        {
          name: "Afikpo North",
          id: 1,
        },
        {
          name: "Afikpo South",
          id: 2,
        },
        {
          name: "Ebonyi",
          id: 3,
        },
        {
          name: "Ezza North",
          id: 4,
        },
        {
          name: "Ezza South",
          id: 5,
        },
        {
          name: "Ikwo",
          id: 6,
        },
        {
          name: "Ishielu",
          id: 7,
        },
        {
          name: "Ivo",
          id: 8,
        },
        {
          name: "Izzi",
          id: 9,
        },
        {
          name: "Ohaozara",
          id: 10,
        },
        {
          name: "Ohaukwu",
          id: 11,
        },
        {
          name: "Onicha",
          id: 12,
        },
      ],
    },
  },
  {
    state: {
      name: "Edo State",
      id: 12,
      locals: [
        {
          name: "Egor",
          id: 1,
        },
        {
          name: "Esan Central",
          id: 2,
        },
        {
          name: "Esan North-East",
          id: 3,
        },
        {
          name: "Esan South-East",
          id: 4,
        },
        {
          name: "Esan West",
          id: 5,
        },
        {
          name: "Etsako Central",
          id: 6,
        },
        {
          name: "Etsako East",
          id: 7,
        },
        {
          name: "Etsako West",
          id: 8,
        },
        {
          name: "Igueben",
          id: 9,
        },
        {
          name: "Ikpoba Okha",
          id: 10,
        },
        {
          name: "Orhionmwon",
          id: 11,
        },
        {
          name: "Oredo",
          id: 12,
        },
        {
          name: "Ovia North-East",
          id: 13,
        },
        {
          name: "Ovia South-West",
          id: 14,
        },
        {
          name: "Owan East",
          id: 15,
        },
        {
          name: "Owan West",
          id: 16,
        },
        {
          name: "Uhunmwonde",
          id: 17,
        },
      ],
    },
  },
  {
    state: {
      name: "Ekiti State",
      id: 13,
      locals: [
        {
          name: "Efon",
          id: 1,
        },
        {
          name: "Ekiti East",
          id: 2,
        },
        {
          name: "Ekiti South-West",
          id: 3,
        },
        {
          name: "Ekiti West",
          id: 4,
        },
        {
          name: "Emure",
          id: 5,
        },
        {
          name: "Gbonyin",
          id: 6,
        },
        {
          name: "Ido Osi",
          id: 7,
        },
        {
          name: "Ijero",
          id: 8,
        },
        {
          name: "Ikere",
          id: 9,
        },
        {
          name: "Ikole",
          id: 10,
        },
        {
          name: "Ilejemeje",
          id: 11,
        },
        {
          name: "Irepodun/Ifelodun",
          id: 12,
        },
        {
          name: "Ise/Orun",
          id: 13,
        },
        {
          name: "Moba",
          id: 14,
        },
        {
          name: "Oye",
          id: 15,
        },
      ],
    },
  },
  {
    state: {
      name: "Enugu State",
      id: 14,
      locals: [
        {
          name: "Awgu",
          id: 1,
        },
        {
          name: "Enugu East",
          id: 2,
        },
        {
          name: "Enugu North",
          id: 3,
        },
        {
          name: "Enugu South",
          id: 4,
        },
        {
          name: "Ezeagu",
          id: 5,
        },
        {
          name: "Igbo Etiti",
          id: 6,
        },
        {
          name: "Igbo Eze North",
          id: 7,
        },
        {
          name: "Igbo Eze South",
          id: 8,
        },
        {
          name: "Isi Uzo",
          id: 9,
        },
        {
          name: "Nkanu East",
          id: 10,
        },
        {
          name: "Nkanu West",
          id: 11,
        },
        {
          name: "Nsukka",
          id: 12,
        },
        {
          name: "Oji River",
          id: 13,
        },
        {
          name: "Udenu",
          id: 14,
        },
        {
          name: "Udi",
          id: 15,
        },
        {
          name: "Uzo Uwani",
          id: 16,
        },
      ],
    },
  },
  {
    state: {
      name: "FCT",
      id: 15,
      locals: [
        {
          name: "Bwari",
          id: 1,
        },
        {
          name: "Gwagwalada",
          id: 2,
        },
        {
          name: "Kuje",
          id: 3,
        },
        {
          name: "Kwali",
          id: 4,
        },
        {
          name: "Municipal Area Council",
          id: 5,
        },
      ],
    },
  },
  {
    state: {
      name: "Gombe State",
      id: 16,
      locals: [
        {
          name: "Balanga",
          id: 1,
        },
        {
          name: "Billiri",
          id: 2,
        },
        {
          name: "Dukku",
          id: 3,
        },
        {
          name: "Funakaye",
          id: 4,
        },
        {
          name: "Gombe",
          id: 5,
        },
        {
          name: "Kaltungo",
          id: 6,
        },
        {
          name: "Kwami",
          id: 7,
        },
        {
          name: "Nafada",
          id: 8,
        },
        {
          name: "Shongom",
          id: 9,
        },
        {
          name: "Yamaltu/Deba",
          id: 10,
        },
      ],
    },
  },
  {
    state: {
      name: "Imo State",
      id: 17,
      locals: [
        {
          name: "Ahiazu Mbaise",
          id: 1,
        },
        {
          name: "Ehime Mbano",
          id: 2,
        },
        {
          name: "Ezinihitte",
          id: 3,
        },
        {
          name: "Ideato North",
          id: 4,
        },
        {
          name: "Ideato South",
          id: 5,
        },
        {
          name: "Ihitte/Uboma",
          id: 6,
        },
        {
          name: "Ikeduru",
          id: 7,
        },
        {
          name: "Isiala Mbano",
          id: 8,
        },
        {
          name: "Isu",
          id: 9,
        },
        {
          name: "Mbaitoli",
          id: 10,
        },
        {
          name: "Ngor Okpala",
          id: 11,
        },
        {
          name: "Njaba",
          id: 12,
        },
        {
          name: "Nkwerre",
          id: 13,
        },
        {
          name: "Nwangele",
          id: 14,
        },
        {
          name: "Obowo",
          id: 15,
        },
        {
          name: "Oguta",
          id: 16,
        },
        {
          name: "Ohaji/Egbema",
          id: 17,
        },
        {
          name: "Okigwe",
          id: 18,
        },
        {
          name: "Orlu",
          id: 19,
        },
        {
          name: "Orsu",
          id: 20,
        },
        {
          name: "Oru East",
          id: 21,
        },
        {
          name: "Oru West",
          id: 22,
        },
        {
          name: "Owerri Municipal",
          id: 23,
        },
        {
          name: "Owerri North",
          id: 24,
        },
        {
          name: "Owerri West",
          id: 25,
        },
        {
          name: "Unuimo",
          id: 26,
        },
      ],
    },
  },
  {
    state: {
      name: "Jigawa State",
      id: 18,
      locals: [
        {
          name: "Babura",
          id: 1,
        },
        {
          name: "Biriniwa",
          id: 2,
        },
        {
          name: "Birnin Kudu",
          id: 3,
        },
        {
          name: "Buji",
          id: 4,
        },
        {
          name: "Dutse",
          id: 5,
        },
        {
          name: "Gagarawa",
          id: 6,
        },
        {
          name: "Garki",
          id: 7,
        },
        {
          name: "Gumel",
          id: 8,
        },
        {
          name: "Guri",
          id: 9,
        },
        {
          name: "Gwaram",
          id: 10,
        },
        {
          name: "Gwiwa",
          id: 11,
        },
        {
          name: "Hadejia",
          id: 12,
        },
        {
          name: "Jahun",
          id: 13,
        },
        {
          name: "Kafin Hausa",
          id: 14,
        },
        {
          name: "Kazaure",
          id: 15,
        },
        {
          name: "Kiri Kasama",
          id: 16,
        },
        {
          name: "Kiyawa",
          id: 17,
        },
        {
          name: "Kaugama",
          id: 18,
        },
        {
          name: "Maigatari",
          id: 19,
        },
        {
          name: "Malam Madori",
          id: 20,
        },
        {
          name: "Miga",
          id: 21,
        },
        {
          name: "Ringim",
          id: 22,
        },
        {
          name: "Roni",
          id: 23,
        },
        {
          name: "Sule Tankarkar",
          id: 24,
        },
        {
          name: "Taura",
          id: 25,
        },
        {
          name: "Yankwashi",
          id: 26,
        },
      ],
    },
  },
  {
    state: {
      name: "Kaduna State",
      id: 19,
      locals: [
        {
          name: "Chikun",
          id: 1,
        },
        {
          name: "Giwa",
          id: 2,
        },
        {
          name: "Igabi",
          id: 3,
        },
        {
          name: "Ikara",
          id: 4,
        },
        {
          name: "Jaba",
          id: 5,
        },
        {
          name: "Jema'a",
          id: 6,
        },
        {
          name: "Kachia",
          id: 7,
        },
        {
          name: "Kaduna North",
          id: 8,
        },
        {
          name: "Kaduna South",
          id: 9,
        },
        {
          name: "Kagarko",
          id: 10,
        },
        {
          name: "Kajuru",
          id: 11,
        },
        {
          name: "Kaura",
          id: 12,
        },
        {
          name: "Kauru",
          id: 13,
        },
        {
          name: "Kubau",
          id: 14,
        },
        {
          name: "Kudan",
          id: 15,
        },
        {
          name: "Lere",
          id: 16,
        },
        {
          name: "Makarfi",
          id: 17,
        },
        {
          name: "Sabon Gari",
          id: 18,
        },
        {
          name: "Sanga",
          id: 19,
        },
        {
          name: "Soba",
          id: 20,
        },
        {
          name: "Zangon Kataf",
          id: 21,
        },
        {
          name: "Zaria",
          id: 22,
        },
      ],
    },
  },
  {
    state: {
      name: "Kano State",
      id: 20,
      locals: [
        {
          name: "Albasu",
          id: 1,
        },
        {
          name: "Bagwai",
          id: 2,
        },
        {
          name: "Bebeji",
          id: 3,
        },
        {
          name: "Bichi",
          id: 4,
        },
        {
          name: "Bunkure",
          id: 5,
        },
        {
          name: "Dala",
          id: 6,
        },
        {
          name: "Dambatta",
          id: 7,
        },
        {
          name: "Dawakin Kudu",
          id: 8,
        },
        {
          name: "Dawakin Tofa",
          id: 9,
        },
        {
          name: "Doguwa",
          id: 10,
        },
        {
          name: "Fagge",
          id: 11,
        },
        {
          name: "Gabasawa",
          id: 12,
        },
        {
          name: "Garko",
          id: 13,
        },
        {
          name: "Garun Mallam",
          id: 14,
        },
        {
          name: "Gaya",
          id: 15,
        },
        {
          name: "Gezawa",
          id: 16,
        },
        {
          name: "Gwale",
          id: 17,
        },
        {
          name: "Gwarzo",
          id: 18,
        },
        {
          name: "Kabo",
          id: 19,
        },
        {
          name: "Kano Municipal",
          id: 20,
        },
        {
          name: "Karaye",
          id: 21,
        },
        {
          name: "Kibiya",
          id: 22,
        },
        {
          name: "Kiru",
          id: 23,
        },
        {
          name: "Kumbotso",
          id: 24,
        },
        {
          name: "Kunchi",
          id: 25,
        },
        {
          name: "Kura",
          id: 26,
        },
        {
          name: "Madobi",
          id: 27,
        },
        {
          name: "Makoda",
          id: 28,
        },
        {
          name: "Minjibir",
          id: 29,
        },
        {
          name: "Nasarawa",
          id: 30,
        },
        {
          name: "Rano",
          id: 31,
        },
        {
          name: "Rimin Gado",
          id: 32,
        },
        {
          name: "Rogo",
          id: 33,
        },
        {
          name: "Shanono",
          id: 34,
        },
        {
          name: "Sumaila",
          id: 35,
        },
        {
          name: "Takai",
          id: 36,
        },
        {
          name: "Tarauni",
          id: 37,
        },
        {
          name: "Tofa",
          id: 38,
        },
        {
          name: "Tsanyawa",
          id: 39,
        },
        {
          name: "Tudun Wada",
          id: 40,
        },
        {
          name: "Ungogo",
          id: 41,
        },
        {
          name: "Warawa",
          id: 42,
        },
        {
          name: "Wudil",
          id: 43,
        },
      ],
    },
  },
  {
    state: {
      name: "Katsina State",
      id: 21,
      locals: [
        {
          name: "Batagarawa",
          id: 1,
        },
        {
          name: "Batsari",
          id: 2,
        },
        {
          name: "Baure",
          id: 3,
        },
        {
          name: "Bindawa",
          id: 4,
        },
        {
          name: "Charanchi",
          id: 5,
        },
        {
          name: "Dandume",
          id: 6,
        },
        {
          name: "Danja",
          id: 7,
        },
        {
          name: "Dan Musa",
          id: 8,
        },
        {
          name: "Daura",
          id: 9,
        },
        {
          name: "Dutsi",
          id: 10,
        },
        {
          name: "Dutsin Ma",
          id: 11,
        },
        {
          name: "Faskari",
          id: 12,
        },
        {
          name: "Funtua",
          id: 13,
        },
        {
          name: "Ingawa",
          id: 14,
        },
        {
          name: "Jibia",
          id: 15,
        },
        {
          name: "Kafur",
          id: 16,
        },
        {
          name: "Kaita",
          id: 17,
        },
        {
          name: "Kankara",
          id: 18,
        },
        {
          name: "Kankia",
          id: 19,
        },
        {
          name: "Katsina",
          id: 20,
        },
        {
          name: "Kurfi",
          id: 21,
        },
        {
          name: "Kusada",
          id: 22,
        },
        {
          name: "Mai'Adua",
          id: 23,
        },
        {
          name: "Malumfashi",
          id: 24,
        },
        {
          name: "Mani",
          id: 25,
        },
        {
          name: "Mashi",
          id: 26,
        },
        {
          name: "Matazu",
          id: 27,
        },
        {
          name: "Musawa",
          id: 28,
        },
        {
          name: "Rimi",
          id: 29,
        },
        {
          name: "Sabuwa",
          id: 30,
        },
        {
          name: "Safana",
          id: 31,
        },
        {
          name: "Sandamu",
          id: 32,
        },
        {
          name: "Zango",
          id: 33,
        },
      ],
    },
  },
  {
    state: {
      name: "Kebbi State",
      id: 22,
      locals: [
        {
          name: "Arewa Dandi",
          id: 1,
        },
        {
          name: "Argungu",
          id: 2,
        },
        {
          name: "Augie",
          id: 3,
        },
        {
          name: "Bagudo",
          id: 4,
        },
        {
          name: "Birnin Kebbi",
          id: 5,
        },
        {
          name: "Bunza",
          id: 6,
        },
        {
          name: "Dandi",
          id: 7,
        },
        {
          name: "Fakai",
          id: 8,
        },
        {
          name: "Gwandu",
          id: 9,
        },
        {
          name: "Jega",
          id: 10,
        },
        {
          name: "Kalgo",
          id: 11,
        },
        {
          name: "Koko/Besse",
          id: 12,
        },
        {
          name: "Maiyama",
          id: 13,
        },
        {
          name: "Ngaski",
          id: 14,
        },
        {
          name: "Sakaba",
          id: 15,
        },
        {
          name: "Shanga",
          id: 16,
        },
        {
          name: "Suru",
          id: 17,
        },
        {
          name: "Wasagu/Danko",
          id: 18,
        },
        {
          name: "Yauri",
          id: 19,
        },
        {
          name: "Zuru",
          id: 20,
        },
      ],
    },
  },
  {
    state: {
      name: "Kogi State",
      id: 23,
      locals: [
        {
          name: "Ajaokuta",
          id: 1,
        },
        {
          name: "Ankpa",
          id: 2,
        },
        {
          name: "Bassa",
          id: 3,
        },
        {
          name: "Dekina",
          id: 4,
        },
        {
          name: "Ibaji",
          id: 5,
        },
        {
          name: "Idah",
          id: 6,
        },
        {
          name: "Igalamela Odolu",
          id: 7,
        },
        {
          name: "Ijumu",
          id: 8,
        },
        {
          name: "Kabba/Bunu",
          id: 9,
        },
        {
          name: "Kogi",
          id: 10,
        },
        {
          name: "Lokoja",
          id: 11,
        },
        {
          name: "Mopa Muro",
          id: 12,
        },
        {
          name: "Ofu",
          id: 13,
        },
        {
          name: "Ogori/Magongo",
          id: 14,
        },
        {
          name: "Okehi",
          id: 15,
        },
        {
          name: "Okene",
          id: 16,
        },
        {
          name: "Olamaboro",
          id: 17,
        },
        {
          name: "Omala",
          id: 18,
        },
        {
          name: "Yagba East",
          id: 19,
        },
        {
          name: "Yagba West",
          id: 20,
        },
      ],
    },
  },
  {
    state: {
      name: "Kwara State",
      id: 24,
      locals: [
        {
          name: "Baruten",
          id: 1,
        },
        {
          name: "Edu",
          id: 2,
        },
        {
          name: "Ekiti",
          id: 3,
        },
        {
          name: "Ifelodun",
          id: 4,
        },
        {
          name: "Ilorin East",
          id: 5,
        },
        {
          name: "Ilorin South",
          id: 6,
        },
        {
          name: "Ilorin West",
          id: 7,
        },
        {
          name: "Irepodun",
          id: 8,
        },
        {
          name: "Isin",
          id: 9,
        },
        {
          name: "Kaiama",
          id: 10,
        },
        {
          name: "Moro",
          id: 11,
        },
        {
          name: "Offa",
          id: 12,
        },
        {
          name: "Oke Ero",
          id: 13,
        },
        {
          name: "Oyun",
          id: 14,
        },
        {
          name: "Pategi",
          id: 15,
        },
      ],
    },
  },
  {
    state: {
      name: "Lagos State",
      id: 25,
      locals: [
        {
          name: "Ajeromi-Ifelodun",
          id: 1,
        },
        {
          name: "Alimosho",
          id: 2,
        },
        {
          name: "Amuwo-Odofin",
          id: 3,
        },
        {
          name: "Apapa",
          id: 4,
        },
        {
          name: "Badagry",
          id: 5,
        },
        {
          name: "Epe",
          id: 6,
        },
        {
          name: "Eti Osa",
          id: 7,
        },
        {
          name: "Ibeju-Lekki",
          id: 8,
        },
        {
          name: "Ifako-Ijaiye",
          id: 9,
        },
        {
          name: "Ikeja",
          id: 10,
        },
        {
          name: "Ikorodu",
          id: 11,
        },
        {
          name: "Kosofe",
          id: 12,
        },
        {
          name: "Lagos Island",
          id: 13,
        },
        {
          name: "Lagos Mainland",
          id: 14,
        },
        {
          name: "Mushin",
          id: 15,
        },
        {
          name: "Ojo",
          id: 16,
        },
        {
          name: "Oshodi-Isolo",
          id: 17,
        },
        {
          name: "Shomolu",
          id: 18,
        },
        {
          name: "Surulere",
          id: 19,
        },
      ],
    },
  },
  {
    state: {
      name: "Nasarawa State",
      id: 26,
      locals: [
        {
          name: "Awe",
          id: 1,
        },
        {
          name: "Doma",
          id: 2,
        },
        {
          name: "Karu",
          id: 3,
        },
        {
          name: "Keana",
          id: 4,
        },
        {
          name: "Keffi",
          id: 5,
        },
        {
          name: "Kokona",
          id: 6,
        },
        {
          name: "Lafia",
          id: 7,
        },
        {
          name: "Nasarawa",
          id: 8,
        },
        {
          name: "Nasarawa Egon",
          id: 9,
        },
        {
          name: "Obi",
          id: 10,
        },
        {
          name: "Toto",
          id: 11,
        },
        {
          name: "Wamba",
          id: 12,
        },
      ],
    },
  },
  {
    state: {
      name: "Niger State",
      id: 27,
      locals: [
        {
          name: "Agwara",
          id: 1,
        },
        {
          name: "Bida",
          id: 2,
        },
        {
          name: "Borgu",
          id: 3,
        },
        {
          name: "Bosso",
          id: 4,
        },
        {
          name: "Chanchaga",
          id: 5,
        },
        {
          name: "Edati",
          id: 6,
        },
        {
          name: "Gbako",
          id: 7,
        },
        {
          name: "Gurara",
          id: 8,
        },
        {
          name: "Katcha",
          id: 9,
        },
        {
          name: "Kontagora",
          id: 10,
        },
        {
          name: "Lapai",
          id: 11,
        },
        {
          name: "Lavun",
          id: 12,
        },
        {
          name: "Magama",
          id: 13,
        },
        {
          name: "Mariga",
          id: 14,
        },
        {
          name: "Mashegu",
          id: 15,
        },
        {
          name: "Mokwa",
          id: 16,
        },
        {
          name: "Moya",
          id: 17,
        },
        {
          name: "Paikoro",
          id: 18,
        },
        {
          name: "Rafi",
          id: 19,
        },
        {
          name: "Rijau",
          id: 20,
        },
        {
          name: "Shiroro",
          id: 21,
        },
        {
          name: "Suleja",
          id: 22,
        },
        {
          name: "Tafa",
          id: 23,
        },
        {
          name: "Wushishi",
          id: 24,
        },
      ],
    },
  },
  {
    state: {
      name: "Ogun State",
      id: 28,
      locals: [
        {
          name: "Abeokuta South",
          id: 1,
        },
        {
          name: "Ado-Odo/Ota",
          id: 2,
        },
        {
          name: "Egbado North",
          id: 3,
        },
        {
          name: "Egbado South",
          id: 4,
        },
        {
          name: "Ewekoro",
          id: 5,
        },
        {
          name: "Ifo",
          id: 6,
        },
        {
          name: "Ijebu East",
          id: 7,
        },
        {
          name: "Ijebu North",
          id: 8,
        },
        {
          name: "Ijebu North East",
          id: 9,
        },
        {
          name: "Ijebu Ode",
          id: 10,
        },
        {
          name: "Ikenne",
          id: 11,
        },
        {
          name: "Imeko Afon",
          id: 12,
        },
        {
          name: "Ipokia",
          id: 13,
        },
        {
          name: "Obafemi Owode",
          id: 14,
        },
        {
          name: "Odeda",
          id: 15,
        },
        {
          name: "Odogbolu",
          id: 16,
        },
        {
          name: "Ogun Waterside",
          id: 17,
        },
        {
          name: "Remo North",
          id: 18,
        },
        {
          name: "Shagamu",
          id: 19,
        },
      ],
    },
  },
  {
    state: {
      name: "Ondo State",
      id: 29,
      locals: [
        {
          name: "Akoko North-West",
          id: 1,
        },
        {
          name: "Akoko South-West",
          id: 2,
        },
        {
          name: "Akoko South-East",
          id: 3,
        },
        {
          name: "Akure North",
          id: 4,
        },
        {
          name: "Akure South",
          id: 5,
        },
        {
          name: "Ese Odo",
          id: 6,
        },
        {
          name: "Idanre",
          id: 7,
        },
        {
          name: "Ifedore",
          id: 8,
        },
        {
          name: "Ilaje",
          id: 9,
        },
        {
          name: "Ile Oluji/Okeigbo",
          id: 10,
        },
        {
          name: "Irele",
          id: 11,
        },
        {
          name: "Odigbo",
          id: 12,
        },
        {
          name: "Okitipupa",
          id: 13,
        },
        {
          name: "Ondo East",
          id: 14,
        },
        {
          name: "Ondo West",
          id: 15,
        },
        {
          name: "Ose",
          id: 16,
        },
        {
          name: "Owo",
          id: 17,
        },
      ],
    },
  },
  {
    state: {
      name: "Osun State",
      id: 30,
      locals: [
        {
          name: "Atakunmosa West",
          id: 1,
        },
        {
          name: "Aiyedaade",
          id: 2,
        },
        {
          name: "Aiyedire",
          id: 3,
        },
        {
          name: "Boluwaduro",
          id: 4,
        },
        {
          name: "Boripe",
          id: 5,
        },
        {
          name: "Ede North",
          id: 6,
        },
        {
          name: "Ede South",
          id: 7,
        },
        {
          name: "Ife Central",
          id: 8,
        },
        {
          name: "Ife East",
          id: 9,
        },
        {
          name: "Ife North",
          id: 10,
        },
        {
          name: "Ife South",
          id: 11,
        },
        {
          name: "Egbedore",
          id: 12,
        },
        {
          name: "Ejigbo",
          id: 13,
        },
        {
          name: "Ifedayo",
          id: 14,
        },
        {
          name: "Ifelodun",
          id: 15,
        },
        {
          name: "Ila",
          id: 16,
        },
        {
          name: "Ilesa East",
          id: 17,
        },
        {
          name: "Ilesa West",
          id: 18,
        },
        {
          name: "Irepodun",
          id: 19,
        },
        {
          name: "Irewole",
          id: 20,
        },
        {
          name: "Isokan",
          id: 21,
        },
        {
          name: "Iwo",
          id: 22,
        },
        {
          name: "Obokun",
          id: 23,
        },
        {
          name: "Odo Otin",
          id: 24,
        },
        {
          name: "Ola Oluwa",
          id: 25,
        },
        {
          name: "Olorunda",
          id: 26,
        },
        {
          name: "Oriade",
          id: 27,
        },
        {
          name: "Orolu",
          id: 28,
        },
        {
          name: "Osogbo",
          id: 29,
        },
      ],
    },
  },
  {
    state: {
      name: "Oyo State",
      id: 31,
      locals: [
        {
          name: "Akinyele",
          id: 1,
        },
        {
          name: "Atiba",
          id: 2,
        },
        {
          name: "Atisbo",
          id: 3,
        },
        {
          name: "Egbeda",
          id: 4,
        },
        {
          name: "Ibadan North",
          id: 5,
        },
        {
          name: "Ibadan North-East",
          id: 6,
        },
        {
          name: "Ibadan North-West",
          id: 7,
        },
        {
          name: "Ibadan South-East",
          id: 8,
        },
        {
          name: "Ibadan South-West",
          id: 9,
        },
        {
          name: "Ibarapa Central",
          id: 10,
        },
        {
          name: "Ibarapa East",
          id: 11,
        },
        {
          name: "Ibarapa North",
          id: 12,
        },
        {
          name: "Ido",
          id: 13,
        },
        {
          name: "Irepo",
          id: 14,
        },
        {
          name: "Iseyin",
          id: 15,
        },
        {
          name: "Itesiwaju",
          id: 16,
        },
        {
          name: "Iwajowa",
          id: 17,
        },
        {
          name: "Kajola",
          id: 18,
        },
        {
          name: "Lagelu",
          id: 19,
        },
        {
          name: "Ogbomosho North",
          id: 20,
        },
        {
          name: "Ogbomosho South",
          id: 21,
        },
        {
          name: "Ogo Oluwa",
          id: 22,
        },
        {
          name: "Olorunsogo",
          id: 23,
        },
        {
          name: "Oluyole",
          id: 24,
        },
        {
          name: "Ona Ara",
          id: 25,
        },
        {
          name: "Orelope",
          id: 26,
        },
        {
          name: "Ori Ire",
          id: 27,
        },
        {
          name: "Oyo",
          id: 28,
        },
        {
          name: "Oyo East",
          id: 29,
        },
        {
          name: "Saki East",
          id: 30,
        },
        {
          name: "Saki West",
          id: 31,
        },
        {
          name: "Surulere",
          id: 32,
        },
      ],
    },
  },
  {
    state: {
      name: "Plateau State",
      id: 32,
      locals: [
        {
          name: "Barkin Ladi",
          id: 1,
        },
        {
          name: "Bassa",
          id: 2,
        },
        {
          name: "Jos East",
          id: 3,
        },
        {
          name: "Jos North",
          id: 4,
        },
        {
          name: "Jos South",
          id: 5,
        },
        {
          name: "Kanam",
          id: 6,
        },
        {
          name: "Kanke",
          id: 7,
        },
        {
          name: "Langtang South",
          id: 8,
        },
        {
          name: "Langtang North",
          id: 9,
        },
        {
          name: "Mangu",
          id: 10,
        },
        {
          name: "Mikang",
          id: 11,
        },
        {
          name: "Pankshin",
          id: 12,
        },
        {
          name: "Qua'an Pan",
          id: 13,
        },
        {
          name: "Riyom",
          id: 14,
        },
        {
          name: "Shendam",
          id: 15,
        },
        {
          name: "Wase",
          id: 16,
        },
      ],
    },
  },
  {
    state: {
      name: "Rivers State",
      id: 33,
      locals: [
        {
          name: "Ahoada East",
          id: 1,
        },
        {
          name: "Ahoada West",
          id: 2,
        },
        {
          name: "Akuku-Toru",
          id: 3,
        },
        {
          name: "Andoni",
          id: 4,
        },
        {
          name: "Asari-Toru",
          id: 5,
        },
        {
          name: "Bonny",
          id: 6,
        },
        {
          name: "Degema",
          id: 7,
        },
        {
          name: "Eleme",
          id: 8,
        },
        {
          name: "Emuoha",
          id: 9,
        },
        {
          name: "Etche",
          id: 10,
        },
        {
          name: "Gokana",
          id: 11,
        },
        {
          name: "Ikwerre",
          id: 12,
        },
        {
          name: "Khana",
          id: 13,
        },
        {
          name: "Obio/Akpor",
          id: 14,
        },
        {
          name: "Ogba/Egbema/Ndoni",
          id: 15,
        },
        {
          name: "Ogu/Bolo",
          id: 16,
        },
        {
          name: "Okrika",
          id: 17,
        },
        {
          name: "Omuma",
          id: 18,
        },
        {
          name: "Opobo/Nkoro",
          id: 19,
        },
        {
          name: "Oyigbo",
          id: 20,
        },
        {
          name: "Port Harcourt",
          id: 21,
        },
        {
          name: "Tai",
          id: 22,
        },
      ],
    },
  },
  {
    state: {
      name: "Sokoto State",
      id: 34,
      locals: [
        {
          name: "Bodinga",
          id: 1,
        },
        {
          name: "Dange Shuni",
          id: 2,
        },
        {
          name: "Gada",
          id: 3,
        },
        {
          name: "Goronyo",
          id: 4,
        },
        {
          name: "Gudu",
          id: 5,
        },
        {
          name: "Gwadabawa",
          id: 6,
        },
        {
          name: "Illela",
          id: 7,
        },
        {
          name: "Isa",
          id: 8,
        },
        {
          name: "Kebbe",
          id: 9,
        },
        {
          name: "Kware",
          id: 10,
        },
        {
          name: "Rabah",
          id: 11,
        },
        {
          name: "Sabon Birni",
          id: 12,
        },
        {
          name: "Shagari",
          id: 13,
        },
        {
          name: "Silame",
          id: 14,
        },
        {
          name: "Sokoto North",
          id: 15,
        },
        {
          name: "Sokoto South",
          id: 16,
        },
        {
          name: "Tambuwal",
          id: 17,
        },
        {
          name: "Tangaza",
          id: 18,
        },
        {
          name: "Tureta",
          id: 19,
        },
        {
          name: "Wamako",
          id: 20,
        },
        {
          name: "Wurno",
          id: 21,
        },
        {
          name: "Yabo",
          id: 22,
        },
      ],
    },
  },
  {
    state: {
      name: "Taraba State",
      id: 35,
      locals: [
        {
          name: "Bali",
          id: 1,
        },
        {
          name: "Donga",
          id: 2,
        },
        {
          name: "Gashaka",
          id: 3,
        },
        {
          name: "Gassol",
          id: 4,
        },
        {
          name: "Ibi",
          id: 5,
        },
        {
          name: "Jalingo",
          id: 6,
        },
        {
          name: "Karim Lamido",
          id: 7,
        },
        {
          name: "Kumi",
          id: 8,
        },
        {
          name: "Lau",
          id: 9,
        },
        {
          name: "Sardauna",
          id: 10,
        },
        {
          name: "Takum",
          id: 11,
        },
        {
          name: "Ussa",
          id: 12,
        },
        {
          name: "Wukari",
          id: 13,
        },
        {
          name: "Yorro",
          id: 14,
        },
        {
          name: "Zing",
          id: 15,
        },
      ],
    },
  },
  {
    state: {
      name: "Yobe State",
      id: 36,
      locals: [
        {
          name: "Bursari",
          id: 1,
        },
        {
          name: "Damaturu",
          id: 2,
        },
        {
          name: "Fika",
          id: 3,
        },
        {
          name: "Fune",
          id: 4,
        },
        {
          name: "Geidam",
          id: 5,
        },
        {
          name: "Gujba",
          id: 6,
        },
        {
          name: "Gulani",
          id: 7,
        },
        {
          name: "Jakusko",
          id: 8,
        },
        {
          name: "Karasuwa",
          id: 9,
        },
        {
          name: "Machina",
          id: 10,
        },
        {
          name: "Nangere",
          id: 11,
        },
        {
          name: "Nguru",
          id: 12,
        },
        {
          name: "Potiskum",
          id: 13,
        },
        {
          name: "Tarmuwa",
          id: 14,
        },
        {
          name: "Yunusari",
          id: 15,
        },
        {
          name: "Yusufari",
          id: 16,
        },
      ],
    },
  },
  {
    state: {
      name: "Zamfara State",
      id: 37,
      locals: [
        {
          name: "Bakura",
          id: 1,
        },
        {
          name: "Birnin Magaji/Kiyaw",
          id: 2,
        },
        {
          name: "Bukkuyum",
          id: 3,
        },
        {
          name: "Bungudu",
          id: 4,
        },
        {
          name: "Gummi",
          id: 5,
        },
        {
          name: "Gusau",
          id: 6,
        },
        {
          name: "Kaura Namoda",
          id: 7,
        },
        {
          name: "Maradun",
          id: 8,
        },
        {
          name: "Maru",
          id: 9,
        },
        {
          name: "Shinkafi",
          id: 10,
        },
        {
          name: "Talata Mafara",
          id: 11,
        },
        {
          name: "Chafe",
          id: 12,
        },
        {
          name: "Zurmi",
          id: 13,
        },
      ],
    },
  },
];
