import {popularStories} from '../data'
import PopularEach from './PopularEach'




const PopularCard = () => {
  return (
	<div className='lg:grid lg:grid-cols-1 lg:gap-y-2 lg:place-content-center xs:flex xs:items-center xs:justify-center xs:flex-col'>
       {popularStories && popularStories.map(data => (
          <PopularEach new = {data}/>

	   ))}

	</div>
  )
}

export default PopularCard