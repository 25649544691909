// import Banner from "../components/Banner";
import Nav from "../components/Nav";
import MissionPic from "../assets/houseWords.png";
import VisionPic from "../assets/bannerhome.png";
import clients from "../assets/clients.png";
import client1 from "../assets/client1.png";
import client2 from "../assets/client2.png";
import client3 from "../assets/client3.png";
import client4 from "../assets/client4.png";
import client5 from "../assets/providus.png";
import client6 from "../assets/dutum.png";
import client7 from "../assets/verifyme.png";
import client8 from "../assets/livingtrust.png";

import { Testimonial } from "../components/Testimonial";
import { useNavigate } from "react-router-dom";
// import left from '../assets/left.png'
// import right from '../assets/right.png'
import Award1 from "../assets/Award1.png";
import Award2 from "../assets/Award2.png";
import Award3 from "../assets/Award3.png";
import { FooterBlack } from "../components/FooterBlack";
import { useState } from "react";
import proper from "../assets/Vector.png";
import BlubOne from "../assets/blub1.png";
import BlubTwo from "../assets/blub2.png";
import HomeBannerImage from "../assets/mobileHome.jpg";
import GifMobileImage from "../assets/mobileBan.gif";
import PromoMobileImage from "../assets/mobileBan2.jpg";
import "../styles/customStyles.css";
import SlideShow from "./SlideShow";
import HomeBanner from "../assets/HomePageBanner.png";
import BannerSlide from "./BannerSlide";
import CustomButton from "../components/CustomButton";

// widgets

export default function Home() {
  // creating state for the change of tabs
  const [step, setStep] = useState(1);

  //changes
  const goGrow = () => {
    setStep(1);
  };

  const goInvest = () => {
    setStep(2);
  };

  const goDevelop = () => {
    setStep(3);
  };
  const gotoGrow = () => {
    navigate("/grow");
  };
  const gotoInvest = () => {
    navigate("/invest");
  };
  const gotoDevelop = () => {
    navigate("/develop");
  };

  const navigate = useNavigate();

  const goToPromoPage = () => {
    navigate("/20daysofchristmas");
  };

  const projectNavigateHandler = () => {
    navigate("/projects");
  };

  return (
    // <div className="lg:h-[275rem] sm:h-[auto] md:h-[277rem] xs:h-[auto]  ">
    <div className="h-auto  ">
      {/* Ads */}
      {/* <div
        className="lg:w-[100%] lg:h-[80px] lg:bg-bgadChris lg:mx-auto
		 xl:w-[1134px] xl:h-[80px] xl:bg-bgadChris xl:mx-auto
		"
        onClick={goToPromoPage}
      ></div> */}
      {/* <div className="nav-slideshow">
        <div onClick={goToPromoPage} className="mover-1"></div>
      </div> */}
      {/* Navigation */}
      <Nav />

      {/* Banner */}
      {/* Desktop View */}
      {/* <SlideShow /> */}
      <div
        className="relative flex flex-col-reverse lg:justify-between items-center justify-center h-auto
      md:flex-row lg:w-screen  lg:bg-white"
      >
        <div className="lg:pl-[2rem] homeContent md:pl-1 md:absolute sm:left-[30px] lg:left-[60px] xs:py-[4rem] md:py-0 xs:px-[15px] sm:ox-[0] flex flex-col gap-y-2 ">
          <p className=" lg:text-5xl md:text-4xl sm:text-4xl xs:text-3xl xs:text-center sm:text-left font-urbanist tracking-wider md:w-[350px] lg:w-[500px]">
            <span className="text-primaryColor2 font-bold"> Everyone</span>
            <span className=""> deserves</span>
          </p>
          <p className="lg:text-5xl md:text-4xl sm:text-4xl xs:text-3xl font-urbanist mb-1 tracking-wider xs:text-center sm:text-left">
            <span className="text-primaryColor1 font-bold">Affordable </span>
            <span>property</span>
          </p>
          <p className="pt-5  text-md sm:w-[400px] xs:text-center sm:text-left font-poppins">
            The smartest real estate products (affordable properties, high ROI
            investments, opulently luxurious properties)
          </p>
          <CustomButton onClick={projectNavigateHandler} className="md:hidden self-center">
            Get Started
          </CustomButton>
        </div>
        <div className="w-full">
          <BannerSlide />
        </div>

        {/* {step === 1 ? (
          <div onClick={goToPromoPage} className="cursor-pointer bg-bgHomePage w-full h-[840px] bg-no-repeat bg-cover xs:hidden sm:block">
           
          </div>
        ) : (
          ""
        )}
        {step === 2 ? (
          <div className="bg-bgHomeGif w-full h-[840px] bg-no-repeat bg-cover xs:hidden sm:block">
           
          </div>
        ) : (
          ""
        )}
        {step === 3 ? (
          <div className="bg-bgHomeDevelop w-full h-[840px] bg-no-repeat bg-cover xs:hidden sm:block">
           
          </div>
        ) : (
          ""
        )} */}

        {/* Mobile Screen */}

        {/* <div
          className="bg-bgHomeMobile w-full h-[716px] bg-no-repeat bg-cover 	   hidden
		lg:hidden xl:hidden
		   xs:block xs:w-full xs:h-[600px]
		   md:hidden sm:hidden "
        ></div> */}
        {/* {step === 1 ? (
          <div onClick={goToPromoPage}>
            <img
              src={HomeBannerImage}
              alt="Background-1"
              className=" cursor-pointer
		   sm:hidden
		   xs:block xs:w-[500px] lg:h-[716px] 
	
		
		   "
            />
          </div>
        ) : (
          ""
        )}
        {step === 2 ? (
          <div>
            <img
              src={GifMobileImage}
              alt="Background-2"
              className="
		   sm:hidden
		   xs:block xs:w-[500px] lg:h-[716px] xs:h-[auto]
	
		
		   "
            />
          </div>
        ) : (
          ""
        )}

        {step === 3 ? (
          <div>
            <img
              src={PromoMobileImage}
              alt="Background-3"
              className="
		   sm:hidden
		   xs:block xs:w-[500px] lg:h-[716px] 
	
		
		   "
            />
          </div>
        ) : (
          ""
        )} */}
      </div>
      {/* widget */}
      <div>
        <div
          className="
	xs:hidden xs:flex-col xs:items-center xs:justify-center xs:mt-[-2rem] xs:w-[325px] xs:mx-auto relative top-[-20px] 
  sm:flex sm:flex-col sm:items-center sm:justify-center sm:z-30
  md:flex md:flex-col md:items-center md:justify-center md:z-30
  lg:flex lg:flex-col lg:items-center lg:justify-center lg:mt-[-3rem] lg:z-30
  xl:flex xl:flex-col xl:items-center xl:justify-center xl:mt-[-3rem] xl:z-30
  2xl:flex 2xl:flex-col 2xl:items-center 2xl:justify-center 2xl:mt-[-3rem] 2xl:z-30
 "
        >
          <div className="self-center flex items-center justify-center">
            <div
              onClick={goGrow}
              className={
                step === 1
                  ? "w-40 h-16 lg:rounded-t-lg lg:bg-white lg:shadow lg:shadow-lg lg:flex lg:items-center lg:justify-center lg:cursor-pointer xs:rounded-t-lg xs:bg-white xs:shadow xs:shadow-lg xs:flex xs:items-center xs:justify-center xs:cursor-pointer  md:bg-white md:shadow md:shadow-lg md:flex md:items-center md:justify-center md:cursor-pointer sm:w-32 sm:h-14   sm:bg-white sm:shadow sm:shadow-lg sm:flex sm:items-center sm:justify-center sm:cursor-pointer xs:w-24 xs:h-14 "
                  : "w-40 h-16 lg:rounded-t-lg lg:bg-primaryColor1 lg:shadow lg:shadow-lg lg:flex lg:items-center lg:justify-center lg:cursor-pointer xs:rounded-t-lg xs:bg-primaryColor1 xs:shadow xs:shadow-lg xs:flex xs:items-center xs:justify-center xs:cursor-pointer md:bg-primaryColor1 md:shadow md:shadow-lg md:flex md:items-center md:justify-center md:cursor-pointer sm:w-32 sm:h-14   sm:bg-primaryColor1 sm:shadow sm:shadow-lg sm:flex sm:items-center sm:justify-center sm:cursor-pointer xs:w-24 xs:h-14 "
              }
            >
              <span
                className={
                  step === 1
                    ? "text-primaryColor2 font-urbanist font-bold"
                    : "text-white font-urbanist font-bold"
                }
              >
                Grow
              </span>
            </div>
            <div
              onClick={goInvest}
              className={
                step === 2
                  ? "w-40 h-16 lg:rounded-t-lg lg:bg-white lg:shadow lg:shdaow-lg lg:flex lg:items-center lg:justify-center lg:cursor-pointer   md:bg-white md:shadow md:shdaow-lg md:flex md:items-center md:justify-center md:cursor-pointer sm:w-32 sm:h-14    sm:bg-white sm:shadow sm:shadow-lg sm:flex sm:items-center sm:justify-center sm:cursor-pointer xs:rounded-t-lg xs:bg-white xs:shadow xs:shadow-lg xs:flex xs:items-center xs:justify-center xs:cursor-pointer xs:w-24 xs:h-14 "
                  : "w-40 h-16 lg:rounded-t-lg lg:bg-primaryColor1 lg:shadow lg:shdaow-lg lg:flex lg:items-center lg:justify-center lg:cursor-pointer  md:bg-primaryColor1 md:shadow md:shdaow-lg md:flex md:items-center md:justify-center md:cursor-pointer sm:w-32 sm:h-14    sm:bg-primaryColor1 sm:shadow sm:shadow-lg sm:flex sm:items-center sm:justify-center sm:cursor-pointer xs:rounded-t-lg xs:bg-primaryColor1  xs:shadow xs:shadow-lg xs:flex xs:items-center xs:justify-center xs:cursor-pointer xs:w-24 xs:h-14  "
              }
            >
              <span
                className={
                  step === 2
                    ? "text-primaryColor2 font-urbanist font-bold"
                    : "text-white font-urbanist font-bold"
                }
              >
                Incubate
              </span>
            </div>
            <div
              onClick={goDevelop}
              className={
                step === 3
                  ? "w-40 h-16 lg:rounded-t-lg lg:bg-white lg:shadow lg:shdaow-lg lg:flex lg:items-center lg:justify-center lg:cursor-pointer  md:bg-white md:shadow-lg md:flex md:items-center md:justify-center md:cursor-pointer sm:w-32 sm:h-14   sm:bg-white sm:shadow sm:shdaow-lg sm:flex sm:items-center sm:justify-center sm:cursor-pointer xs:rounded-t-lg xs:bg-white xs:shadow xs:shadow-lg xs:flex xs:items-center xs:justify-center xs:cursor-pointer xs:w-24 xs:h-14 "
                  : "w-40 h-16 lg:rounded-t-lg lg:bg-primaryColor1 lg:shadow lg:shdaow-lg lg:flex lg:items-center lg:justify-center lg:cursor-pointer  md:bg-primaryColor1  md:shadow-lg md:flex md:items-center md:justify-center md:cursor-pointer sm:w-32 sm:h-14  sm:bg-primaryColor1 sm:shadow  sm:flex sm:items-center sm:justify-center sm:cursor-pointer xs:rounded-t-lg xs:bg-primaryColor1 xs:shadow xs:shadow-lg xs:flex xs:items-center xs:justify-center xs:cursor-pointer xs:w-24 xs:h-14  "
              }
            >
              <span
                className={
                  step === 3
                    ? "text-primaryColor2 font-urbanist font-bold"
                    : "text-white font-urbanist font-bold"
                }
              >
                Develop
              </span>
            </div>
          </div>
          {step === 1 ? (
            <div
              className="sm:w-[325px] sm:h-[344px] sm:flex sm:flex-col sm:bg-white sm:shadow-lg sm:rounded md:w-[325px] md:h-[344px] md:flex md:flex-col md:bg-white md:shadow-lg md:rounded-r-none lg:bg-white lg:shadow-lg lg:rounded lg:w-[871px] lg:h-[160.5px] lg:mb-1.5 lg:flex
   
   xl:bg-white xl:shadow-lg xl:rounded xl:w-[871px] xl:h-[160.5px] xl:mb-1.5 xl:flex  xs:h-[344px] xs:flex xs:bg-white xs:shadow-lg xs:rounded
 
 "
            >
              <div
                className="
			  	xs:hidden xs:flex-col xs:justify-center  xs:items-center xs:gap-y-[4rem] xs:h-[344px]
			   sm:flex sm:flex-col sm:justify-center  sm:items-center sm:gap-y-[4rem] sm:w-[325px] sm:h-[344px]
			   md:flex md:flex-col md:justify-center  md:items-center md:gap-y-[4rem] md:w-[325px] md:h-[344px]
			   lg:flex-row lg:justify-around  lg:items-center lg:w-[871px] lg:h-[160.5px]
			   xl:flex-row xl:justify-around  xl:items-center xl:w-[871px] xl:h-[160.5px]
		 "
              >
                <div className="xs:pl-3">
                  <li className="marker:text-primaryColor2 font-poppins">
                    Pay 7% of total property value in{" "}
                    <span className="xs:ml-5 lg:ml-0"> annual rent</span>
                  </li>
                  <li className="marker:text-primaryColor2 font-poppins">
                    Natural disaster insurance{" "}
                    <span className="xs:ml-5 lg:ml-0">coverage</span>
                  </li>
                  <li className="marker:text-primaryColor2 font-poppins">
                    Repayment duration: 30 years
                  </li>
                </div>
                <div>
                  <button
                    className="bg-transparent border border-primaryColor2 px-12 py-2 rounded-lg text-primaryColor2 font-bold font-poppins  flex items-center gap-x-4"
                    onClick={gotoGrow}
                  >
                    Learn More
                    <img src={proper} alt="" className="" />
                  </button>
                </div>
              </div>
            </div>
          ) : step === 2 ? (
            <div
              className="sm:w-[325px] sm:h-[344px] sm:flex sm:bg-white sm:shadow-lg sm:rounded-r-none md:w-[325px] md:h-[344px] md:flex md:flex-col md:bg-white md:shadow-lg md:rounded-r-none lg:bg-white lg:shadow-lg lg:rounded-r-none lg:w-[871px] lg:h-[160.5px] lg:mb-1.5 lg:flex
   
            xl:bg-white xl:shadow-lg xl:rounded-r-none xl:w-[871px] xl:h-[160.5px] xl:mb-1.5 xl:flex xs:w-[325px] xs:h-[344px] xs:flex xs:bg-white xs:shadow-lg xs:rounded-r-none "
            >
              <div
                className="
				xs:flex xs:flex-col xs:justify-center  xs:items-center xs:gap-y-[4rem] xs:w-[325px] xs:h-[344px]
		  lg:flex-row lg:justify-around  lg:items-center lg:w-[871px] lg:h-[160.5px]
		  xl:flex-row xl:justify-around  xl:items-center xl:w-[871px] xl:h-[160.5px]
		 sm:flex sm:flex-col sm:justify-center  sm:items-center sm:gap-y-[4rem] sm:w-[325px] sm:h-[344px]
		 md:flex md:flex-col md:justify-center  md:items-center md:gap-y-[4rem] md:w-[325px] md:h-[344px]
		 
		 "
              >
                <div className="xs:pl-4">
                  <li className="marker:text-primaryColor2 font-poppins">
                    {" "}
                    Super competitive investment{" "}
                    <span className="xs:ml-5 lg:ml-0">instrument</span>
                  </li>
                  <li className="marker:text-primaryColor2 font-poppins">
                    Safe Smart Secure
                  </li>
                  <li className="marker:text-primaryColor2 font-poppins">
                    High Returns (70% Flat)
                  </li>
                </div>
                <div>
                  <button
                    className="bg-transparent border border-primaryColor2 px-12 py-2 rounded-lg text-primaryColor2 font-bold font-poppins  flex items-center gap-x-4"
                    onClick={gotoInvest}
                  >
                    Learn More
                    <img src={proper} alt="" className="" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="sm:w-[325px] sm:h-[344px] sm:flex sm:bg-white sm:shadow-lg sm:rounded-r-none md:w-[325px] md:h-[344px] md:flex md:flex-col md:bg-white md:shadow-lg md:rounded-r-none lg:bg-white lg:shadow-lg lg:rounded-r-none lg:w-[871px] lg:h-[160.5px] lg:mb-1.5 lg:flex
   
  xl:bg-white xl:shadow-lg xl:rounded-r-none xl:w-[871px] xl:h-[160.5px] xl:mb-1.5 xl:flex xs:w-[325px] xs:h-[344px] xs:flex xs:bg-white xs:shadow-lg xs:rounded-r-none "
            >
              <div
                className="
				xs:flex xs:flex-col xs:justify-center  xs:items-center xs:gap-y-[4rem] xs:w-[325px] xs:h-[344px]
		 lg:flex-row lg:justify-around  lg:items-center lg:w-[871px] lg:h-[160.5px]
		 xl:flex-row xl:justify-around  xl:items-center xl:w-[871px] xl:h-[160.5px]
		 sm:flex sm:flex-col sm:justify-center  sm:items-center sm:gap-y-[4rem] sm:w-[325px] sm:h-[344px]
		 md:flex md:flex-col md:justify-center  md:items-center md:gap-y-[4rem] md:w-[325px] md:h-[344px]
		 
		 "
              >
                <div className="">
                  <li className="marker:text-primaryColor2 font-poppins">
                    Wide properties assortments
                  </li>
                  <li className="marker:text-primaryColor2 font-poppins">
                    Flexible payment plans
                  </li>
                  <li className="marker:text-primaryColor2 font-poppins">
                    Up to 5 years payment plans.
                  </li>
                </div>
                <div>
                  <button
                    className="bg-transparent border border-primaryColor2 px-12 py-2 rounded-lg text-primaryColor2 font-bold font-poppins  flex items-center gap-x-4"
                    onClick={gotoDevelop}
                  >
                    Learn More
                    <img src={proper} alt="" className="" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Home Page Information 1 */}
      <div
        //     className="xs:flex xs:flex-col-reverse xs:gap-y-9 xs:h-[50rem]
        //  sm:flex sm:flex-col-reverse sm:mt-[-6rem] sm:ml-[-3rem] sm:gap-y-10
        //  md:flex md:flex-col-reverse md:mt-[3rem]
        //  lg:h-[30rem] lg:mx-auto lg:bg-white lg:flex lg:flex-row lg:items-center lg:justify-around lg:mt-[3rem]

        //  "
        //   >
        className="flex flex-col-reverse gap-y-9 
		  sm:ml-[-3rem] sm:gap-y-10 
		 md:mt-[3rem]
		 lg:h-[30rem] lg:mx-auto lg:bg-white lg:flex lg:flex-row lg:items-center lg:justify-around lg:mt-[3rem]
		 
		 "
      >
        <div className="self-center">
          <img
            src={MissionPic}
            alt=""
            className="
			  md:ml-[2rem] md:w-[350px] md:h-[350px]
		      lg:w-[450px] lg:h-[450px] lg:ml-[1rem]
			  xl:w-[450px] xl:h-[450px] 
			  sm:w-[300px] sm:h-[300px]
			  xs:w-[280px] xs:h-[280px] xs:mt-3
			  "
          />
        </div>

        <div
          className="xs:pl-3 xs:pr-2 xs:flex xs:flex-col  xs:gap-y-4 
			 sm:p-8 sm:ml-[4rem] sm:mb-[1.5rem]  sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-8 sm:mt-[32rem] sm:pt-[22rem]
			 md:flex md:items-center md:justify-center md:p-[5rem] md:ml-[7rem] md:mb-[2rem] 
			 lg:flex lg:flex-col lg:gap-y-4 lg:w-[550px] lg:h-[390px] lg:mt-[0rem]
			 xl:flex xl:flex-col xl:gap-y-4 xl:w-[550px] xl:h-[390px] relative
			"
        >
          <div className="self-center">
            <span className="text-3xl font-urbanist">
              <span className="font-bold text-primaryColor1">
                Everything Real Estate
              </span>{" "}
              In One Place
            </span>
          </div>
          <div className="absolute top-[50px] sm:top-[300px] md:top-[50px]">
            <img src={BlubOne} alt="" />
          </div>

          <div className="self-center">
            <span className="text-md font-poppins text-justify">
              <p>
                Unlike every other real estate brand, we offer more than just
                property siting, sales and acquisition. We also liked you to
                earn serious bucks with our real estate <b>INVESTMENT</b>{" "}
                offerings such as the <b>GrowProperty</b> that eases your
                financial burden of outright housing ownership through{" "}
                <b>Beta Mortgage</b> and <b>Mortgage Xtra</b> .
              </p>
              <br />
              <br /> So,{" "}
              <span className="text-primaryColor1 font-bold">
                GROW | INCUBATE | DEVELOP.
              </span>
            </span>
          </div>
        </div>
      </div>

      {/* Home Information 2 */}

      <div
        className="xs:p-3 xs:mb-[1.5rem] xs:flex xs:flex-col xs:gap-y-8  
		 sm:ml-[-1rem] sm:mb-[1.5rem] sm:flex sm:flex-col sm:items-center sm:justify-center sm:p-[3rem] sm:gap-y-8 sm:mb-[6rem]
		 md:ml-[1rem] md:flex md:items-center md:justify-center md:mb-[6rem] mt-[5rem] md:gap-y-[5rem]
	    lg:bg-white lg:flex-row lg:items-center lg:justify-around lg:mb-[4rem] lg:gap-x-[9rem] lg:mb-0
		xl:bg-white xl:flex-row xl:items-center xl:justify-around xl:mb-[4rem] xl:gap-x-[9rem] relative
		
		"
      >
        <div
          className="
		  xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-4
		sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-4
		md:flex md:flex-col  md:gap-y-4
		lg:w-[649px] lg:h-[402px] lg:flex lg:flex-col lg:gap-y-4 
		xl:w-[649px] xl:h-[402px] xl:flex xl:flex-col xl:gap-y-3
		
		"
        >
          <div className="self-start text-center">
            <span className="text-3xl font-urbanist">
              <span className="font-bold text-primaryColor2">
                Budget-Friendly
              </span>{" "}
              vs Outsized Opulence
            </span>
          </div>
          <div className="absolute top-[170px] md:top-[50px] lg:top-[170px] xl:top-[170px]">
            <img src={BlubTwo} alt="" />
          </div>

          <div className="self-center text-justify">
            <span className="text-md font-poppins">
              <p>
                {" "}
                Whether you are on the bend for fast, authentic mortgage
                services, Omo onile proof landed properties, highly desirable
                homes sited in serene, safe, green-conscious neighborhoods or
                you desire the most affordable real estate company in Nigeria,
                <b> Landshop</b> is the right answer. <br /> <br />
                Because we understand the motivation of resources versus desire,
                we have curated PRIME properties for clients who expect
                beautifully situated and built real estate on a stiff budget.
                Properties prices across a spectrum (from only a few to mid
                million) with even the longest mortgage payment plan in the
                country.
              </p>
            </span>
          </div>
        </div>
        <div className="self-center md:w-[450px]">
          <img
            src={VisionPic}
            alt=""
            className="border border-primaryColor2 p-[20px] rounded-[20px]
				  md:ml-[2rem] md:w-[350px] md:h-[350px]
				  lg:w-[450px] lg:h-[450px] lg:ml-[-2rem]
				  xl:w-[450px] xl:h-[450px] xl:ml-[-2rem]
				  sm:w-[300px] sm:h-[300px] sm:ml-[0rem]
				  xs:w-[280px] xs:h-[280px] xs:mt-3
				  "
          />
        </div>
      </div>

      {/* Clients */}

      <div
        className="
	   lg:mx-auto lg:h-[45rem] lg:flex lg:items-center lg:justify-center lg:relative lg:mt-[-15rem]
	   sm:mx-auto sm:h-[45rem] sm:flex sm:items-center sm:justify-center sm:relative sm:mt-[-16rem]
	   md:mx-auto md:h-[45rem] md:flex md:items-center md:justify-center md:relative 
	   xs:mx-auto xs:h-[30rem] xs:flex xs:items-center xs:justify-center xs:relative 
	   
	 
	 "
      >
        <img className="xs:w-full xs:h-[24rem]" src={clients} alt="" />
        <div
          className="xs:absolute xs:top-[13rem] xs:p-1
		  xs:items-center xs:justify-center xs:gap-y-7 xs:mt-[0.5rem] xs:w-full
		     sm:absolute sm:top-[21.6rem]  sm:p-1 sm:flex  sm:flex-col
			 sm:items-center sm:justify-center sm:gap-y-7 sm:mt-[0.5rem]
			 md:absolute md:top-[23rem] md:left-[0rem] md:p-1
             lg:flex lg:flex-col lg:gap-y-9 lg:items-center lg:justify-center
			 lg:absolute lg:top-[22rem] lg:right-[9rem] lg:p-1
			 xl:flex xl:flex-col xl:gap-x-6  xl:gap-y-9 xl:items-center xl:justify-center
			 xl:absolute xl:top-[25rem] xl:right-[9rem] xl:p-1
			 "
        >
          <div className="xs:flex xs:justify-center xs:align-center">
            <span className="xl:font-bold xl:text-black xl:text-5xl xl:font-manrope lg:font-bold lg:text-black lg:text-5xl lg:font-manrope sm:font-bold sm:text-black sm:text-4xl sm:font-manrope sm:ml-0 xs:font-bold xs:text-black xs:text-2xl xs:font-manrope ">
              We are <span className="text-primaryColor1">Truly Trusted</span>
            </span>
          </div>
          <div className="w-[498px] h-[137px] sm:w-[498px] sm:h-[137px] sm:ml-[3rem] lg:ml-0  xs:w-full xs:h-[137px] xs:flex xs:justify-center xs:align-center ">
            <span
              className="lg:font-bold lg:font-poppins lg:text-gray-500 lg:text-xs
				   sm:font-poppins sm:text-gray-500 sm:text-[10px]   xs:font-poppins xs:text-gray-500 xs:text-[10px] 
				 "
            >
              <p className="hidden sm:block sm:mt-[-1rem] sm:ml-[1rem] md:ml-0 sm:mb-[2rem]  text-sm lg:mt-[-1.2rem] lg:ml-[0] lg:font-light xl:mt-[0] ">
                Having your best interest in mind, we work directly with clients
                in order to build a strong career relationship and need our
                clients to trust us
              </p>
              <p className="sm:mt-[-1rem] sm:ml-[1rem] sm:mb-[2rem] lg:mt-[-1.2rem] lg:ml-[2.3rem] lg:font-light xl:mt-[-1.3rem] xs:mt-[1rem] xs:text-[14px] xs:ml-[1rem] xs:mb-[2rem] sm:hidden">
                Having your best interest in mind, we work directly with clients
                in order to build a strong career relationship and need our
                clients to trust us
              </p>
            </span>
          </div>
          <div
            className="xs:ml-[0] xs:mt-[-3rem] xs:flex xs:items-center xs:justify-center xs:gap-x-[1.2rem] xs:flex-col xs:pt-[10px]
			    sm:ml-[1.5rem] sm:mt-[-9rem] sm:flex sm:items-center sm:justify-center sm:gap-x-[1.2rem] sm:flex-col sm:pt-[35px]
			    md:ml-[-2rem] md:mt-[-7rem] md:flex md:items-center md:justify-center  md:gap-x-[2.5rem] md:pt-[20px]
				lg:flex lg:items-center lg:justify-center lg:gap-x-[2.5rem] lg:pt-0"
          >
            <div className="xs:flex xs:items-center xs:justify-center xs:gap-x-[2rem] md:gap-x-[2.5rem]">
              {" "}
              <img
                src={client1}
                alt=""
                className="xs:w-[60px] xs:h-[20px] sm:w-[80px]  sm:h-[25px] md:w-[150px] md:h-[30px] lg:w-[180px] lg:h-[50px]"
              />
              <img
                src={client6}
                alt=""
                className="xs:w-[45px] xs:h-[17px] sm:w-[60px]  sm:h-[20px] md:w-[90px] md:h-[30px] lg:w-[120px] lg:h-[30px]"
              />
              <img
                src={client4}
                alt=""
                className="xs:w-[60px] xs:h-[45px] sm:w-[70px]  sm:h-[55px] md:w-[100px] md:h-[61px] lg:w-[145px] lg:h-[81px]"
              />
              <img
                src={client5}
                alt=""
                className="xs:w-[55px] xs:h-[35px] sm:w-[70px]  sm:h-[40px] md:w-[100px] md:h-[45px] lg:w-[132px] lg:h-[69px]"
              />
            </div>
            <div className="xs:flex xs:items-center xs:justify-center  xs:gap-x-[1.5rem] md:gap-x-[2.5rem]">
              <img
                src={client2}
                alt=""
                className="xs:w-[60px] xs:h-[19px] sm:w-[70px]  sm:h-[25px] md:w-[110px] md:h-[35px] lg:w-[120px] lg:h-[50px] lg:pr-[2rem]"
              />
              <img
                src={client3}
                alt=""
                className="xs:w-[60px] xs:h-[20px] sm:w-[90px]  sm:h-[25px] md:w-[130px] md:h-[35px] lg:w-[160px] lg:h-[50px]"
              />
              <img
                src={client8}
                alt=""
                className="xs:w-[65px] xs:h-[25px] sm:w-[70px]  sm:h-[25px] md:w-[100px] md:h-[35px] lg:w-[130px] lg:h-[50px]"
              />
              <img
                src={client7}
                alt=""
                className="xs:w-[60px] xs:h-[25px] sm:w-[70px]  sm:h-[25px] md:w-[110px] md:h-[35px] lg:w-[140px] lg:h-[50px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="mx-auto w-screen h-[50rem] flex flex-col gap-y-[5rem] items-center justify-center">
        <div>
          <span className="font-bold text-black text-3xl font-manrope">
            What Our <span className="text-primaryColor2">Clients Say</span>
          </span>
        </div>

        <Testimonial />
      </div>

      {/* Awards */}
      <div
        className="
      xl:h-[30rem] xl:w-screen xl:bg-bgAwards xl:flex xl:flex-col xl:gap-y-5 xl:items-center xl:justify-center
      lg:h-[30rem] lg:w-screen lg:bg-bgAwards lg:flex lg:flex-col lg:gap-y-5 lg:items-center lg:justify-center
      sm:h-[52rem] sm:w-screen sm:bg-bgAwards sm:flex sm:flex-col sm:gap-y-5 sm:items-center sm:justify-center
      md:h-[30rem] md:w-screen md:bg-bgAwards md:flex md:flex-col md:gap-y-5 md:items-center md:justify-center
      xs:h-[54rem] xs:w-screen xs:bg-bgAwards xs:flex xs:flex-col xs:gap-y-5 xs:items-center xs:justify-center
      "
      >
        <div className="xs:text-center">
          <span
            className="
          xl:text-[#D1A44D] xl:font-bold xl:text-4xl xl:font-manrope
          lg:text-[#D1A44D] lg:font-bold lg:text-4xl lg:font-manrope
          sm:text-[#D1A44D] sm:font-bold sm:text-4xl sm:font-manrope sm:text-center
          md:text-[#D1A44D] md:font-bold md:text-4xl md:font-manrope md:text-center
          xs:text-[#D1A44D] xs:font-bold xs:text-4xl xs:font-manrope 
          "
          >
            Awards and Recognition
          </span>
        </div>

        <div
          className="
        xl:flex xl:flex-row xl:items-center xl:justify-around xl:space-x-5  xl:w-screen
        lg:flex lg:flex-row lg:items-center lg:justify-around lg:space-x-5  lg:w-screen
        md:flex md:flex-row md:items-center md:justify-around md:space-x-5  md:w-screen
        sm:flex sm:flex-col sm:items-center sm:justify-around sm:space-y-4  sm:w-screen
        xs:flex xs:flex-col xs:items-center xs:justify-around xs:space-y-4  xs:w-screen

        "
        >
          <div
            className="
          xl:grid xl:grid-cols-3 xl:gap-x-12 xl:mt-12
          lg:grid lg:grid-cols-3 lg:gap-x-12 lg:mt-12
          md:grid md:grid-cols-3 md:gap-x-12 md:mt-12
          sm:grid sm:grid-cols-1 sm:gap-y-4 sm:mt-4
          xs:grid xs:grid-cols-1 xs:gap-y-4 xs:mt-4
          "
          >
            <img
              className="w-[206px] h-[227px]  cursor-pointer"
              src={Award1}
              alt=""
            />
            <img
              className="w-[206px] h-[227px]  cursor-pointer"
              src={Award2}
              alt=""
            />
            <img
              className="w-[206px] h-[227px]  cursor-pointer"
              src={Award3}
              alt=""
            />
          </div>
        </div>
      </div>

      {/* footer */}
      <FooterBlack />
    </div>
  );
}
