import { TestimonialCardType } from "../typings";
import star from "../assets/stars.png";

export const TestimonialCard = (props: TestimonialCardType) => {
  return (
    <div
      className="bg-white w-[340px] h-[480px]  border border-primaryColor2 rounded-lg shadow-2xl flex flex-col items-center justify-center  gap-y-9 relative hover:bg-primaryColor2 hover:text-white"
      key={props.data.id}
    >
      <div className="absolute top-[0px] left-[7rem] ">
        <img src={props.data.image} alt="" />
      </div>

      <div className="p-3 mt-[5rem]">
        <span className="text-xs font-light font-poppins w-[218px] h-[120px]">
          {props.data.testimony}
        </span>
      </div>

      <div className="">
        <span className="text-xl font-bold font-poppins w-[218px] h-[120px]">
          {props.data.name}
        </span>
      </div>

      <div className="">
        <span className="text-xs font-thin font-poppins w-[218px] h-[120px]">
          {props.data.location}
        </span>
      </div>
      <div>
        <img src={star} alt="" />
      </div>
    </div>
  );
};
