import React from "react";
import { tail } from "../common/utils/helper";

type Props = {
  close: () => void;
  children: React.ReactNode;
  className?: string;
};

export const ModalConfirmItem = ({ close, children, className }: Props) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-start overflow-auto z-50">
      <div
        className={tail(
          "md:w-[744px] md:h-[732px] sm:w-[444px] sm:h-[333px] w-[100%] h-[432px] flex flex-col",
          className
        )}
      >
        <button
          className="text-black text-lg place-self-end relative top-8 right-[0.625rem] lg:top-10 lg:right-10"
          onClick={close}
        >
          X
        </button>
        <div className="bg-white p-2 rounded">{children}</div>
      </div>
    </div>
  );
};
