import React from "react";
import { Outlet } from "react-router-dom";

function Dashboard() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-3  xl:grid-cols-4 p-4 gap-5 h-[calc(100vh-142px)] overflow-y-scroll">
      <Outlet />
    </div>
  );
}

export default Dashboard;
