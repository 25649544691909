import { useRef, useEffect, useState } from "react";
import HomeBannerImage from "../assets/homeBackground.png";
import GifMobileImage from "../assets/Christmas.gif"
import PromoMobileImage from "../assets/homeDevelop.jpg"
import { useNavigate } from "react-router-dom";

import React from "react";

const SlideShow = () => {
  const colors = [HomeBannerImage, GifMobileImage, PromoMobileImage];
  const delay = 3500;
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  const navigate = useNavigate();

  const goToPromoPage = () => {
    navigate("/20daysofchristmas");
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider w-full"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {colors.map((backgroundColor, index) => (
          <div
            className="slide w-full h-[735px] bg-no-repeat bg-cover cursor-pointer"
            key={index}
            style={{ backgroundImage: `url(${backgroundColor})` }}
            onClick={goToPromoPage}
          >
    
          </div>
        ))}
      </div>

      {/* <div className="slideshowDots">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div> */}
    </div>
  );
};
export default SlideShow;
