import React from 'react'

type Props = {
	close : () => void,
	children : React.ReactNode
}

export const ModalLuxury = ({close , children} : Props) => {

  return (
    <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-40'>
    <div className='xl:w-[1144px] xl:h-[700px] xl:flex xl:flex-col
    xs:w-[1144px] xs:h-[700px] xs:flex xs:flex-col overflow-scroll
    bg-white
    z-10
    '>
       <button className='xs:text-black xs:text-sm xs:place-self-end xs:text-3xl xs:relative xs:top-[40px] xs:right-[30px]' onClick = {close}>
           X
	   </button>
	   <div className='bg-white h-[900px] p-2 rounded oveflow-scroll'>
         {children}
      </div>
	</div>
	

</div>
  )
}
