import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../common/service/request";
import ENDPOINTS from "./endpoint";

export const api = createApi({
  baseQuery: axiosBaseQuery({}),

  endpoints: (builder) => ({
    // login: builder.mutation({
    //   query: (data) => ({
    //     ...ENDPOINTS.login,
    //     data,
    //   }),
    // }),
    // register: builder.mutation({
    //   query: (data) => ({
    //     ...ENDPOINTS.register,
    //     data,
    //   }),
    // }),
    // updateProfile: builder.mutation({
    //   query: (data) => ({
    //     ...ENDPOINTS.update_profile,
    //     data,
    //   }),
    // }),
    // fetchProfile: builder.query({
    //   query: () => ({
    //     ...ENDPOINTS.fetch_profile,
    //   }),
    //   // @ts-expect-error
    //   providesTags: ["profile"],
    // }),
    // logout: builder.query({
    //   query: () => ({
    //     ...ENDPOINTS.logout,
    //   }),
    // }),
    updatePassword: builder.mutation({
      query: (data) => ({
        ...ENDPOINTS.update_password,
        data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        ...ENDPOINTS.forgot_password,
        data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        ...ENDPOINTS.reset_password,
        data,
      }),
    }),
    userGrows: builder.query({
      query: (user_id: string) => ({
        ...ENDPOINTS.fetch_user_grow_properties,
        url: ENDPOINTS.fetch_user_grow_properties.url(user_id),
      }),
    }),
    userDevelops: builder.query({
      query: (user_id: string) => ({
        ...ENDPOINTS.fetch_user_develop_properties,
        url: ENDPOINTS.fetch_user_develop_properties.url(user_id),
      }),
    }),
    userInvests: builder.query({
      query: (user_id: string) => ({
        ...ENDPOINTS.fetch_user_invest_properties,
        url: ENDPOINTS.fetch_user_invest_properties.url(user_id),
      }),
    }),
    refGrowProperies: builder.mutation({
      query: ({ data, id }: Record<string, any>) => ({
        ...ENDPOINTS.purchase_ref_grow_properties,
        url: ENDPOINTS.purchase_ref_grow_properties.url(id),
        data,
      }),
    }),
    refDevelopProperies: builder.mutation({
      query: ({ data, id }: Record<string, any>) => ({
        ...ENDPOINTS.purchase_ref_develop_properties,
        url: ENDPOINTS.purchase_ref_develop_properties.url(id),
        data,
      }),
    }),
    refInvestProperies: builder.mutation({
      query: ({ data, id }: Record<string, any>) => ({
        ...ENDPOINTS.purchase_ref_invest_properties,
        url: ENDPOINTS.purchase_ref_invest_properties.url(id),
        data,
      }),
    }),
  }),
});

export const {
  // useLoginMutation,
  // useRegisterMutation,
  // useUpdateProfileMutation,
  // useFetchProfileQuery,
  // useLazyFetchProfileQuery,
  // useLazyLogoutQuery,
  useUpdatePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUserDevelopsQuery,
  useUserGrowsQuery,
  useUserInvestsQuery,
  useRefDevelopProperiesMutation,
  useRefGrowProperiesMutation,
  useRefInvestProperiesMutation,
} = api;
