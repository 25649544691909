import aboutBackground from "../assets/aboutBackground.png";
import aboutBanner from "../assets/landshop_about.png";
import Mission from "../components/Mission";
import Nav from "../components/Nav";
import Team from "../components/Team";
import Vision from "../components/Vision";

import BannerTwo from "../assets/aboutBanner2.png";
import { FooterBlack } from "../components/FooterBlack";

export default function About() {
  return (
    <div
      //     className="
      //    xl:h-[242rem]
      //    lg:h-[242rem]
      //    md:h-[276rem]
      //    sm:h-[278rem]
      //    xs:h-[278rem]

      //  "
      className="lg:h-[auto] sm:h-[auto] md:h-[auto] xs:h-[auto]  "
    >
      <Nav />

      {/* Mobile Screens */}
      <div
        className="
		  lg:w-screen lg:h-[30rem] lg:relative lg:hidden
		  sm:w-screen sm:h-[33rem] sm:relative sm:block
		  md:w-screen md:h-[35rem] md:relative md:block
		  xs:w-screen xs:h-[30rem] xs:relative xs:block
		  "
      >
        <img src={aboutBackground} alt="" className="w-screen h-[536px]" />

        <div
          className="xs:absolute xs:top-[10rem] xs:left-[20px] xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-5
			 sm:absolute sm:top-[10rem] sm:left-[27px] sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-5
			 md:absolute md:top-[10rem] md:left-[80px] md:flex md:flex-col md:items-center md:justify-center md:gap-y-5
			 lg:absolute lg:top-40 lg:left-[250px] lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-5"
        >
          <span className="text-4xl text-white font-urbanist font-bold">
            About Us
          </span>
          <span className="text-sm text-white font-urbanist ">
            Landshop is Nigeria’s premier full service co-investment real estate
            company that offers a wide berth of property solutions in
            acquisition, mortgage and investments.
            <br />
            With a driving philosophy that’s centered around enabling access for
            Nigerians of all stripes, <br />
            Landshop creates innovative proprietaries like GROW, INVEST,
            DEVELOP…and THRIFT to resolve hot-button concerns of potential
            property owners.
          </span>
        </div>
      </div>

      {/* Desktop Screens */}
      <div
        className="
			 xs:flex-col xs:items-center xs:justify-center xs:gap-y-5 xs:hidden
			 sm:flex-col sm:items-center sm:justify-center sm:gap-y-5 sm:hidden
			 md:flex-col md:items-center md:justify-center md:gap-y-5 md:hidden
			 xl:flex xl:flex-col xl:items-center xl:justify-center xl:gap-y-6 xl:bg-bgabout  xl:h-[735px]
			 lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-6 lg:bg-bgabout  lg:h-[735px]"
      >
        <div>
          <img src={aboutBanner} alt="" className="w-[1390px] h-[402px]" />
        </div>
        <div className="flex  flex-col items-center justify-center gap-y-5">
          <span className="text-4xl text-white font-urbanist font-bold">
            About Us
          </span>
          <span className="text-sm text-white font-urbanist ">
            Welcome to LANDSHOP, Nigeria’s premier full service co-investment
            real estate company that offers a wide berth of property solutions
            in acquisition, mortgage and investments. <br />
            With a driving philosophy that’s centered around enabling access for
            Nigerians of all stripes, Landshop creates innovative proprietaries
            like GROW, INVEST, DEVELOP…and THRIFT to resolve hot-button concerns
            of potential property owners.
          </span>
        </div>
      </div>

      <Mission />
      <Vision />
      <Team />
      <FooterBlack />
    </div>
  );
}
