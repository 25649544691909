
import MissionPic from '../assets/aboutImage2.png';
import BannerOne from "../assets/landshop_mission.png"

function Mission() {
  return (
	<div className='h-[30rem] mx-auto bg-white 
	xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-9 xs:mt-[16.5rem]
	sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-9 sm:mt-[14rem]
	md:flex md:flex-col md:items-center md:justify-center  md:gap-y-[6rem] md:mt-[17rem]
	lg:flex lg:flex-row lg:items-center lg:justify-between lg:mt-[6rem] lg:ml-[3rem] lg:mb-[6rem] '>

    <div className='self-center'>
		<img src={BannerOne} alt="" className='
			  md:ml-[2rem] md:w-[100%] md:h-[auto]
		      lg:w-[100%] lg:h-[462px] lg:p-6 lg:pt-0 lg:mt-[-15px] lg:pl-0
			  xl:w-[100%] xl:h-[462px] xl:pt-0
			  sm:w-[100%] sm:h-[auto] 
			  xs:w-[100%] xs:h-[auto] 
		 
		' />


	</div>
     
	   <div className=' xs:ml-[1rem] xs:mb-[1.5rem]  xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-8 
			 sm:mb-[1.5rem]  sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-8 
			 md:flex md:items-center md:justify-center  md:ml-[7rem] md:mb-[2rem]
			 lg:flex lg:flex-col lg:gap-y-4 lg:w-[550px] lg:h-[390px] lg:mr-16' 
			 >
            <div className='lg:self-start xl:self-start md:self-center sm:self-center xs:self-center '>
				<span className='font-black text-4xl font-urbanist'>
				 Our Mission
				</span>
				
			</div>
			<div className='lg:self-start xl:self-start md:self-center sm:self-center lg:text-left xs:text-center'>
			<span className='text-2xl font-urbanist text-primaryColor1 font-bold '>
			Is to be the country’s most trusted and affordable repository of real estate
			</span>
			 
            </div>
	
			<div className='lg:self-start xl:self-start md:self-center sm:self-center'>

			<span className='text-sm font-poppins'>
			Landshop's mission is to inspire property ownership for all, irrespective of status or credit rating. We offer diverse, authentic opportunities with flexible payment models. Trust, transparency, and inclusivity drive our commitment to revolutionize the real estate landscape in Nigeria.
			</span>
			
			</div>
			
		
	   </div>
	</div>
  )
}

export default Mission