import React from "react";
import Nav from "../components/Nav";
import growLink from "../assets/Grow_1.png";
import investLink from "../assets/Invest_1.png";
import developLink from "../assets/Develop_1.png";
import devProLink from "../assets/Dev_1.png";
import { Link } from "react-router-dom";

export default function Projects() {
  return (
    <div>
      <Nav />
      <div className="bg-white h-[100rem] flex flex-col items-center justify-center gap-y-7">
        <Link to="/develop">
          <div className="bg-bgDevelop flex items-center justify-center w-[348px] h-[344px] mr-3">
            <img src={developLink} alt="" />
          </div>
        </Link>
        <Link to="/grow">
          <div className="bg-bgGrow flex items-center justify-center w-[348px] h-[344px] mr-3">
            <img src={growLink} alt="" />
          </div>
        </Link>
        <Link to="/invest">
          <div className="bg-bgInvest flex items-center justify-center w-[343px] h-[344px] mr-1">
            <img src={investLink} alt="" />
          </div>
        </Link>
        <Link to="/devpro">
          <div className="bg-bgDevPro flex items-center justify-center w-[343px] h-[344px]">
            <img src={devProLink} alt="" />
          </div>
        </Link>
      </div>
    </div>
  );
}
