import { TestimonialData } from "../data";
import { TestimonialCard } from "./TestimonialCard";
import left from "../assets/left.png";
import right from "../assets/right.png";

export const Testimonial = () => {
  return (
    <div className="flex items-center justify-around gap-y-8  w-screen">
      <div className="">
        <img src={left} alt="" />
      </div>

      <div
        className="
			lg:gap-x-[6rem] lg:mt-12 
			  md:gap-x-[20rem]
			  grid grid-cols-3 overflow-x-auto gap-x-[22rem] max-w-screen
			  "
      >
        {TestimonialData?.map((testis, index) => (
          <TestimonialCard key={index} data={testis} />
        ))}
      </div>

      <div className="">
        <img src={right} alt="" />
      </div>
    </div>
  );
};
