import React from 'react'

type Props = {
	close : () => void,
	children : React.ReactNode
}

export const ModalTribeForm = ({close , children} : Props) => {

  return (
 <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50'>
    <div className='w-[400px] h-[532px] flex flex-col'>
    <button className='xs:text-black xs:text-xl xs:place-self-end xs:text-2xl xs:relative xs:top-[40px] xs:right-[30px]' onClick = {close}>
           X
	   </button>
	   <div className='bg-white px-4 py-4 rounded'>
         {children}
      </div>
	</div>
	

</div>
  )
}
