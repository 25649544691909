import React from "react";
import landshopAboutFaceBook from "../assets/landshopAboutFaceBook.png";
import landshopAboutTwitter from "../assets/landshopAboutTwitter.png";
import landshopAboutYoutube from "../assets/landshopAboutYoutube.png";
import landshopAboutPinterest from "../assets/landshopAboutPinterest.png";
import landshopAboutLogo from "../assets/landshopAboutLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export const FooterBlack = () => {
  return (
    <div>
      <div
        //     className="
        //   lg:grid-cols-4 lg:h-[20rem]
        //   md:h-[29rem] md:pb-0
        //   grid grid-cols-2 place-content-around gap-x-1 bg-black h-[35rem] py-[3rem]
        //  "
        className="
		  lg:grid-cols-4    
		  grid sm:grid-cols-2 grid-cols-1 place-content-start sm:place-content-around bg-black py-12 gap-4 px-4 sm:px-0 font-urbanist
		 "
      >
        <div className="flex flex-col sm:gap-y-4 gap-y-1  sm:items-center items-start justify-center">
          <div>
            <span className="text-white font-bold text-xl sm:ml-4 hover:text-primaryColor2">
              ABOUT US
            </span>
          </div>
          <div className="flex flex-col gap-y-2">
            <Link to="/">
              <span className="text-white font-bold text-sm hover:text-primaryColor2">
                Trust By
              </span>
            </Link>
            <Link to="/about">
              <span className="text-white font-bold text-sm hover:text-primaryColor2">
                Our Team
              </span>
            </Link>
            <Link to="#">
              <span className="text-white font-bold text-sm hover:text-primaryColor2">
                Privacy Policy
              </span>
            </Link>
          </div>
        </div>
        <div className="flex flex-col sm:gap-y-4 gap-y-1  sm:items-center items-start justify-center">
          <div>
            <span className="text-white font-bold text-xl sm:ml-12 hover:text-primaryColor2">
              Our Projects
            </span>
          </div>
          <div className="flex flex-col gap-y-2">
            <Link to="/grow">
              <span className="text-white font-bold text-sm hover:text-primaryColor2">
                Grow
              </span>
            </Link>
            <Link to="/invest">
              <span className="text-white font-bold text-sm hover:text-primaryColor2">
              Incubate
              </span>
            </Link>
            <Link to="/develop">
              <span className="text-white font-bold text-sm hover:text-primaryColor2">
                Develop
              </span>
            </Link>
          </div>
        </div>
        <div
          className="
          sm:pl-6 lg:pl-0
			flex flex-col sm:gap-y-4 gap-y-1  sm:items-center items-start justify-center
			
			
			"
        >
          <div className="lg:self-start">
            <span
              className="
					text-white font-bold text-xl hover:text-primaryColor2
					
					"
            >
              Contact Us
            </span>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col min-[412px]:flex-row gap-x-3 gap-y-1">
              <span className="text-primaryColor2 font-bold text-sm">
                Address:
              </span>
              <span className="text-white font-bold text-sm">
                Suite 1. 3rd Floor, Kings Deck, Alternative Route, Off Chevron
                Drive Lekki-Epe Expressway Lagos
              </span>
            </div>
            <div className="flex flex-col min-[412px]:flex-row gap-x-3 gap-y-1">
              <span className="text-primaryColor2 font-bold text-sm">
                Contact Info:
              </span>
              <span className="text-white font-bold text-sm">
              +234 708 067 2040
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-1  items-center justify-center">
          <div>
            <img
              src={landshopAboutLogo}
              className="w-[126px] h-[70px]"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-y-4  items-center justify-center">
            <span className="text-white font-bold text-sm">
              Call us now at:
            </span>
            <span className="text-white font-bold text-sm">
            +234 708 067 2040
            </span>
            <div className="flex gap-x-4">
              <a
                href="https://www.facebook.com/landshoplimited/?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
                ></FontAwesomeIcon>
              </a>
              <a
                href="https://www.linkedin.com/company/landshoplimited/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
                ></FontAwesomeIcon>
              </a>

              <a
                href="https://twitter.com/Landshop_ng?t=S3LVVBUQa5e5WcBq6D20VQ&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
                ></FontAwesomeIcon>
              </a>
              <a
                href="https://instagram.com/landshoplimited?igshid=Mzc0YWU1OWY="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-gray-400 hover:text-primaryColor2 w-[24px] h-[24px]"
                ></FontAwesomeIcon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
