import React, { useState } from "react";
// import { FAQdata } from "../data";
import { FAQdata } from "../data";

const Search = () => {
  const [query, setQuery] = useState("");
  const [showFAQ, setShowFAQ] = useState(false);
  let Metage = "No current view"

  return (
    <>
      <input
        placeholder="Enter faq Title"
        onChange={(event) => {
          setQuery(event.target.value);
          setShowFAQ(false)
        }}
      />
      {console.log(FAQdata[0].id)}
      <button onClick={() => setShowFAQ(true)}>Search</button>

      <div>
        {showFAQ
          ? FAQdata &&
            FAQdata.filter((faq) => {
              if (query === "") {
                return
                // return <p>No current Result </p>;
              } else if (
                faq.question.toLowerCase().includes(query.toLowerCase())
              ) {
                return faq;
              }
            }).map((faq, index) => (
              <div className="box" key={index}>
                <p>{faq.question}</p>
                <p>{faq.answer}</p>
              </div>
            ))
          : FAQdata.filter((FAQdata) => FAQdata.id === 6).map((faq, index) => (
              <div key={index}>
                <p>{faq.question}</p>
                <p>{faq.answer}</p>
              </div>
            ))}
      </div>

      {/* {FAQdata.filter(FAQdata => FAQdata.id === 6 )

        
      } */}

      {/* {FAQdata.map((subArray, index) => {
         {console.log(subArray)}
        return (
          <div key={index}>
           
            {subArray.map((subitem, i) => {
              return (
                
                <ul key={i}>
                  <li>{subitem.question}</li>
                </ul>
              );
            })}
            
          </div>
          
        );
      })} */}
      {/* {FAQdata.map((childArray) => {

       {
        return (
          childArray.map((childElement) => 
          {{console.log(childElement)}
            return (
              
                <div>
                  <p>{childElement.question}</p>
                </div>
              
            )
          })
        )
       }
        
      })} */}
      {/* {FAQItem.map((faq, index) => 
     
    {    {console.log(faq.children)}
      return(
    
        (
          <div className="box" key={index}>
            
              <p>{}</p>
                <p>{}</p>
          </div>
          
        )
      )
    }
        )} */}
      {}
      {/* {nestedArrays.map((faq, index) => (
          
          <div className="box" key={index}>
              <p>{faq[0]}</p>
                <p>{faq.answer}</p>
          </div>
         
        ))} */}
    </>
  );
};

export default Search;
