import { popularType } from "../typings";

type Props = {
  new: popularType;
};

const PopularEach = (props: Props) => {
  return (
    <div
      className="xl:bg-white xl:shadow-2xl xl:rounded-xl xl:w-[387.92px] xl:h-[100.73px]
	lg:bg-white lg:shadow-2xl lg:rounded-xl lg:w-[287.12px] lg:h-[100.73px] lg:mb-0
	sm:bg-white sm:shadow-2xl sm:rounded-xl sm:w-[400px] sm:h-[150.73px] sm:mb-5
	xs:bg-white xs:shadow-2xl xs:rounded-xl xs:w-[100%] xs:h-[150.73px] xs:mb-5
	
	"
    >
      <div className="flex items-center">
        <div className="lg:w-[150.24px] xs:w-[240px] lg:h-[100.73px] xs:h-[150px] p-4">
          <img
            className="xs:h-[100px] lg:h-[70px] lg:w-[150px] xs:mt-2.5 lg:mt-0"
            src={props.new.image}
            alt=""
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="w-[185.34px] h-[17.73px] lg:w-[140px] xs:w-[120px]">
            <h6 className="text-xs font-poppins font-bold">{props.new.sale}</h6>
          </div>
          <div className="w-[185.34px] h-[17.73px] lg:w-[140px] xs:w-[120px]">
            <h6 className="text-xs font-poppins font-normal">
              {props.new.date}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularEach;
