import React, { useState } from "react";

interface IInvoiceListProps {
  invoiceData: InvoiceDataType;
  logo?: string;
}

type InvoiceDataType = {
  customerName: string;
  invoices: InvoiceType[];
  later: LaterType[];
};

type PaymentStatusType = "paid" | "pending" | "late";

type InvoiceType = {
  id: number;
  name: string;
  total: string;
  paymentStatus: PaymentStatusType;
};

type LaterType = {
  id: number;
  name: string;
  total: string;
  paymentStatus: PaymentStatusType;
};

const InvoiceList = (props: IInvoiceListProps) => {
  const { customerName, invoices, later } = props.invoiceData;

  const itemStyleContainer = {
    display: "flex",
    justifyContent: "space-between",
  };

  return (
    <div>
      <h1>{customerName}</h1>
      <input placeholder="Search here" />
      <hr />
      {}

      <div>
        {invoices.map((invoice) => (
          <div key={invoice.id} style={itemStyleContainer}>
            <div>{invoice.name}</div>

            <div>
              {invoice.total} - {invoice.paymentStatus}
            </div>
          </div>
        ))}
      </div>
      <div>
        {later.map((invoice) => (
          <div key={invoice.id} style={itemStyleContainer}>
            <div>{invoice.name}</div>

            <div>
              {invoice.total} - {invoice.paymentStatus}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Main = () => {
  const data: InvoiceDataType = {
    customerName: "Google",
    invoices: [
      { id: 123, name: "Dev work", total: "20.00", paymentStatus: "paid" },
      {
        id: 456,
        name: "More Dev work",
        total: "50.00",
        paymentStatus: "pending",
      },
      {
        id: 789,
        name: "Something different",
        total: "100.00",
        paymentStatus: "paid",
      },
    ],
    later: [
      { id: 123, name: "Dev work", total: "20.00", paymentStatus: "paid" },
      {
        id: 456,
        name: "More Dev work",
        total: "50.00",
        paymentStatus: "pending",
      },
      {
        id: 789,
        name: "Something different",
        total: "100.00",
        paymentStatus: "paid",
      },
    ],
  };
  console.log(data);
  const [query, setQuery] = useState("");

  return (

    <div>
       <input
        placeholder="Enter faq Title"
        onChange={(event) => setQuery(event.target.value)}
      />
      <InvoiceList invoiceData={data} />
      <div>
        {/* {data && data.filter(faq => {
          if (query === '') {
            return faq;
          } else if (faq.question.toLowerCase().includes(query.toLowerCase())) {
            return faq;
          }
        }).map((faq, index) => (
          <div className="box" key={index}>
              <p>{faq.question}</p>
                <p>{faq.answer}</p>
          </div>
        ))} */}
        {
          Object.entries(data).filter(faq => {
            if (query === '') {
              return faq;
            } else if (faq[0][1].toLowerCase().includes(query.toLowerCase())) {
              return faq;
            }
          }).map((faq, index) => (
            <div className="box" key={index}>
                <p>{faq[0][1]}</p>
                  
            </div>
          ))}
        
      </div>
    </div>
  );
};

export default Main;
