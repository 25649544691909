import axios from "axios";
import { useEffect, useState } from "react";
import { InvestLandData1 } from "../data";
import { InvestGenType, loadType } from "../typings";
import { InvestEachLand } from "./InvestEachLand";
import { InvestLandTwo } from "./InvestLandTwo";
import { API } from "../utils/backend";
import Button from "./Button";
import CustomButton from "./CustomButton";
import { PropagateLoader } from "react-spinners";

const initialPageData = {
  hasNextPage: false,
  hasPreviousPage: false,
  itemCount: 0,
  page: 1,
  pageCount: 0,
};

export const InvestLandCards = () => {
  const [cardsData, setCardsData] = useState<any[]>([]);
  const [showMore, setShowMore] = useState(initialPageData);
  const [loading, setLoading] = useState({ initial: false, sub: false });

  const getInvest = async (page: number, type: loadType) => {
    setLoading((prev) => ({ ...prev, [type]: true }));
    try {
      const InvestResponse: InvestGenType = await axios.get(
        `${API}invests?property_type=land&page=${page}&take=20`
      );

      if (type === "initial") {
        setCardsData(InvestResponse?.data.data.invests.data);
      } else {
        setCardsData((prev) => [
          ...prev,
          ...InvestResponse?.data.data.invests.data,
        ]);
      }
      setShowMore(InvestResponse.data.data.invests.meta);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, [type]: false }));
    }
  };
  useEffect(() => {
    getInvest(showMore.page, "initial");
  }, []);

  const showMoreHandler = () => {
    getInvest(showMore.page + 1, "sub");
  };

  return (
    <div className="">
      <div>
        <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 h-96 overflow-y-scroll lg:h-auto lg:overflow-y-auto">
          {loading.initial ? (
            <div className="flex items-center justify-center col-span-full">
              <PropagateLoader color="#17B28D" size={30} />
            </div>
          ) : cardsData.length === 0 ? (
            <p className="text-center col-span-full">* No Property Available</p>
          ) : (
            cardsData.map((land, index) => (
              <InvestEachLand key={index} data={land} />
            ))
          )}
        </div>
        {showMore.hasNextPage ? (
          <div className="block w-[100%] items-center justify-center mt-9">
            <CustomButton
              onClick={showMoreHandler}
              className="InvestSeeMore"
              isloading={loading.sub}
            >
              See More
            </CustomButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};
