import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Avatar from "../Avatar";
import logo from "../../assets/logo.png";
import menuIcon from "../../assets/MenuIcon.png";
import routes from "../../common/routes";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SideNav from "../SideNav";

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const switchNavigation = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <header className="relative z-10 grid grid-cols-12 bg-white w-full lg:h-[6rem] py-5 items-center shadow-lg  px-4 lg:px-10">
      <div className="col-span-3">
        <Link to="/">
          <img src={logo} alt="logo" className="w-[80px] h-[45px]" />
        </Link>
      </div>
      <nav className="flex-1 lg:flex justify-end items-center col-span-9 hidden">
        {/* <form
          onSubmit={(e) => e.preventDefault()}
          className="flex items-center gap-x-4"
        >
          <label
            htmlFor="search"
            className="block pr-6 border-r cursor-pointer"
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3592 12.1808L16.9283 15.7492L15.7492 16.9283L12.1808 13.3592C10.8531 14.4235 9.20167 15.0024 7.5 15C3.36 15 0 11.64 0 7.5C0 3.36 3.36 0 7.5 0C11.64 0 15 3.36 15 7.5C15.0024 9.20167 14.4235 10.8531 13.3592 12.1808ZM11.6875 11.5625C12.7451 10.4749 13.3357 9.01702 13.3333 7.5C13.3333 4.27667 10.7225 1.66667 7.5 1.66667C4.27667 1.66667 1.66667 4.27667 1.66667 7.5C1.66667 10.7225 4.27667 13.3333 7.5 13.3333C9.01702 13.3357 10.4749 12.7451 11.5625 11.6875L11.6875 11.5625Z"
                className="fill-gray-10"
              />
            </svg>
          </label>
          <input
            type="search"
            name=""
            id="search"
            placeholder="Search here"
            className="border-none placeholder:text-gray-10 focus:outline-none outline-none"
          />
        </form> */}
        <ul className="flex items-center gap-x-6">
          {/* <li>
            <Link to={routes.notification}>
              <svg
                width="20"
                height="24"
                viewBox="0 0 20 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99687 24C9.33404 24 8.69835 23.7471 8.22966 23.2971C7.76097 22.847 7.49766 22.2365 7.49766 21.6H12.4961C12.4961 22.2365 12.2328 22.847 11.7641 23.2971C11.2954 23.7471 10.6597 24 9.99687 24ZM19.9937 20.4H0V18L2.49922 16.8V10.2C2.43339 8.50694 2.83153 6.82695 3.65386 5.328C4.05853 4.64073 4.61035 4.04366 5.27291 3.57618C5.93548 3.10871 6.69374 2.78145 7.49766 2.616V5.00053e-06H12.0537C11.2621 0.849615 10.7708 1.91803 10.6502 3.05221C10.5295 4.18638 10.7858 5.32812 11.3823 6.31386C11.9788 7.29959 12.8848 8.07875 13.9707 8.53972C15.0565 9.00068 16.2664 9.1198 17.427 8.88C17.4708 9.3084 17.492 9.7524 17.492 10.2V16.8L19.9912 18V20.4H19.9937ZM16.2449 7.2C15.7526 7.19922 15.2653 7.10532 14.8108 6.92367C14.3563 6.74203 13.9435 6.47619 13.5959 6.14135C13.2484 5.8065 12.9729 5.40919 12.7853 4.97212C12.5977 4.53505 12.5015 4.06676 12.5023 3.594C12.5032 3.12125 12.6009 2.65327 12.7901 2.2168C12.9792 1.78033 13.2561 1.38391 13.6048 1.05018C13.9535 0.716444 14.3672 0.451932 14.8223 0.271743C15.2775 0.0915537 15.7651 -0.000782938 16.2574 5.00053e-06C17.2517 0.00159631 18.2045 0.382407 18.9064 1.05866C19.6083 1.73492 20.0017 2.65123 20 3.606C19.9983 4.56078 19.6018 5.47583 18.8976 6.14983C18.1934 6.82384 17.2392 7.2016 16.2449 7.2Z"
                  fill="#17B28D"
                />
              </svg>
            </Link>
          </li> */}
          {/* <li>
            <Link to={routes.inbox}>
              <svg
                width="26"
                height="18"
                viewBox="0 0 26 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 0C1.344 0 0 1.344 0 3V15C0 16.656 1.344 18 3 18H23C24.656 18 26 16.656 26 15V3C26 1.344 24.656 0 23 0H3ZM3 2H23C23.551 2 24 2.449 24 3V3.5L13 9.438L2 3.5V3C2 2.449 2.449 2 3 2ZM2 3.781L8.531 8.875L2.125 15.438L9.938 9.875L13 11.844L16.063 9.875L23.875 15.438L17.469 8.875L24 3.781V15C23.9945 15.154 23.9516 15.3043 23.875 15.438C23.71 15.763 23.389 16 23 16H3C2.611 16 2.29 15.764 2.125 15.438C2.04859 15.3046 2.00569 15.1536 2 15V3.781Z"
                  fill="#17B28D"
                />
              </svg>
            </Link>
          </li> */}
          <li>
            <Link to={routes.profile}>
              {/* {profile_picture ? (
                <img
                  src={profile_picture}
                  alt="user profile"
                  className={tail(
                    "w-11 h-11 rounded-full bg-red-200 object-cover"
                  )}
                />
              ) : (
                <FaUserCircle className="w-11 h-11 rounded-full" />
              )} */}
              <Avatar className="w-11 h-11" />
            </Link>
          </li>
        </ul>
      </nav>
      <button className="lg:hidden col-start-12 col-end-13 justify-self-end flex justify-end">
        <img
          className="h-8 w-8 m-0 text-primaryColor1 cursor-pointer object-contain"
          onClick={switchNavigation}
          src={menuIcon}
          alt=""
        />
      </button>

      {showMenu ? (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.3,
          }}
          className=" top-0 right-0 z-10 fixed bg-white shadow-lg
		 w-screen h-screen transition-all duration-700 ease-in-out 
		 flex flex-col items-center justify-center
		 lg:hidden "
        >
          <div className="absolute top-0 right-0 p-5">
            <XMarkIcon
              className="h-12 w-12 text-black cursor-pointer"
              onClick={switchNavigation}
            />
          </div>

          <SideNav />
        </motion.div>
      ) : null}
    </header>
  );
}

export default Header;
