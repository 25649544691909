import React , {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import image2 from '../assets/miniTandC.png'
import Button from './Button'
import { ModalPreview } from './ModalPreview';
import { ModalSuccess } from './ModalSuccess';
import SuccessGif from "../assets/DevPro/Rectangle.gif"



const EstateTermsConditions2 = () => {

     const navigate = useNavigate()
	const [modal, setModal] = useState<boolean>(false);

	const[success, setSuccess] = useState<boolean>(false);

	const handleModal = () => setModal(true)
	const handleCloseModal = () => setModal(false);


	const handleSuccess = () => setSuccess(true)
	const handleCloseSuccess = () => setSuccess(false);

	const decline = () => {
		setModal(false)
	}

	const homePage = () => {
		navigate('/devpro')
	}

	const goToLogin = () => {
        navigate('/devpro') 
	} 

  return (
	<div className=' flex items-center justify-center bg-bgTerms w-full h-[100vh]'>
         
         {/* <img src={image} alt="" /> */}
	

		 <div className='flex items-center justify-center bg-white shadow-2xl rounded-md xs:w-[100%] md:w-[900px] h-[585px]'>
                <div className='flex flex-col items-center justify-center gap-y-9'>
                    <div>
                        <img src={image2} alt="" />
					</div>
					<div>
						<h5 className='text-md font-poppins text-[#808191]'>Terms and Conditions</h5>
					</div>
					<div className='border border-[#808191] rounded-md xs:w-[340px] sm:w-[500px]  md:w-[691px] h-[279px] flex flex-col justify-start gap-y-5 p-2 overflow-y-auto'>
                          <div className='w-[197px] h-[20px]'>
							<h5 className='text-black text-xs font-bold font-poppins'>Last Updated: Jan 24, 2023</h5>
						  </div>

						  <div className='h-[380px]'>
							  <h6 className='font-poppins text-[#808191] text-sm'>
							  Lorem ipsum dolor sit amet consectetur. Elementum quis egestas ornare sit in purus sit. Potenti sem velit quisque ultrices in nibh. Cras amet varius morbi tristique amet nunc. Viverra sit vitae pulvinar amet. Id mauris a sed arcu nullam amet tortor senectus. Dignissim augue orci imperdiet sagittis tincidunt pretium vitae nunc felis. Enim vulputate enim platea massa dignissim elit justo sed sed. Mi odio tempor ut placerat amet sem. Fermentum eget diam sed sed at enim a pharetra etiam. Sed nisl neque scelerisque justo adipiscing suspendisse. Vitae egestas velit varius sem faucibus. Euismod viverra netus vitae venenatis enim risus. Posuere nibh magnis donec tristique at. Facilisis maecenas nunc et scelerisque mauris vitae mattis sed. Metus nam sit rhoncus sed ultrices elementum. Cum vitae sagittis ut tristique dui ac. A morbi felis fermentum nulla congue mauris proin augue. Molestie rhoncus nullam hendrerit cras ac ipsum. Sit placerat posuere mus aenean neque laoreet potenti aliquet. Accumsan integer consectetur sed a aliquet. Libero magna proin dolor hendrerit vestibulum. Imperdiet felis vestibulum cras imperdiet varius eu amet a metus. Mauris tortor nunc ultricies vulputate vitae. Venenatis viverra nulla vel sit. Viverra imperdiet pellentesque nec ante enim faucibus volutpat natoque mollis. Rhoncus ipsum pellentesque tortor lacus odio risus rhoncus volutpat. Eget arcu ridiculus nunc tristique dictum orci sit. Fermentum sit aliquam tellus cras vitae pretium. Lorem ipsum dolor sit amet consectetur. Elementum quis egestas ornare sit in purus sit. Potenti sem velit quisque ultrices in nibh. Cras amet varius morbi tristique amet nunc. Viverra sit vitae pulvinar amet.
							  Id mauris a sed arcu nullam amet tortor senectus. Dignissim augue orci imperdiet sagittis tincidunt pretium vitae nunc felis.vitae pretium.
							  Lorem ipsum dolor sit amet consectetur. Elementum quis egestas ornare sit in purus sit. Potenti sem velit quisque ultrices in nibh. Cras amet varius morbi tristique amet nunc. Viverra sit vitae pulvinar amet. Id mauris a sed arcu nullam amet tortor senectus. Dignissim augue orci imperdiet sagittis tincidunt pretium vitae nunc felis. Enim vulputate enim platea massa dignissim elit justo sed sed. Mi odio tempor ut placerat amet sem. Fermentum eget diam sed sed at enim a pharetra etiam. Sed nisl neque scelerisque justo adipiscing suspendisse. Vitae egestas velit varius sem faucibus. Euismod viverra netus vitae venenatis enim risus. Posuere nibh magnis donec tristique at. Facilisis maecenas nunc et scelerisque mauris vitae mattis sed. Metus nam sit rhoncus sed ultrices elementum. Cum vitae sagittis ut tristique dui ac. A morbi felis fermentum nulla congue mauris proin augue. Molestie rhoncus nullam hendrerit cras ac ipsum. Sit placerat posuere mus aenean neque laoreet potenti aliquet. Accumsan integer consectetur sed a aliquet. Libero magna proin dolor hendrerit vestibulum. Imperdiet felis vestibulum cras imperdiet varius eu amet a metus. Mauris tortor nunc ultricies vulputate vitae. Venenatis viverra nulla vel sit. Viverra imperdiet pellentesque nec ante enim faucibus volutpat natoque mollis. Rhoncus ipsum pellentesque tortor lacus odio risus rhoncus volutpat. Eget arcu ridiculus nunc tristique dictum orci sit. Fermentum sit aliquam tellus cras vitae pretium. Lorem ipsum dolor sit amet consectetur. Elementum quis egestas ornare sit in purus sit. Potenti sem velit quisque ultrices in nibh. Cras amet varius morbi tristique amet nunc. Viverra sit vitae pulvinar amet.
							  Id mauris a sed arcu nullam amet tortor senectus. Dignissim augue orci imperdiet sagittis tincidunt pretium vitae nunc felis.vitae pretium.
							  Lorem ipsum dolor sit amet consectetur. Elementum quis egestas ornare sit in purus sit. Potenti sem velit quisque ultrices in nibh. Cras amet varius morbi tristique amet nunc. Viverra sit vitae pulvinar amet. Id mauris a sed arcu nullam amet tortor senectus. Dignissim augue orci imperdiet sagittis tincidunt pretium vitae nunc felis. Enim vulputate enim platea massa dignissim elit justo sed sed. Mi odio tempor ut placerat amet sem. Fermentum eget diam sed sed at enim a pharetra etiam. Sed nisl neque scelerisque justo adipiscing suspendisse. Vitae egestas velit varius sem faucibus. Euismod viverra netus vitae venenatis enim risus. Posuere nibh magnis donec tristique at. Facilisis maecenas nunc et scelerisque mauris vitae mattis sed. Metus nam sit rhoncus sed ultrices elementum. Cum vitae sagittis ut tristique dui ac. A morbi felis fermentum nulla congue mauris proin augue. Molestie rhoncus nullam hendrerit cras ac ipsum. Sit placerat posuere mus aenean neque laoreet potenti aliquet. Accumsan integer consectetur sed a aliquet. Libero magna proin dolor hendrerit vestibulum. Imperdiet felis vestibulum cras imperdiet varius eu amet a metus. Mauris tortor nunc ultricies vulputate vitae. Venenatis viverra nulla vel sit. Viverra imperdiet pellentesque nec ante enim faucibus volutpat natoque mollis. Rhoncus ipsum pellentesque tortor lacus odio risus rhoncus volutpat. Eget arcu ridiculus nunc tristique dictum orci sit. Fermentum sit aliquam tellus cras vitae pretium. Lorem ipsum dolor sit amet consectetur. Elementum quis egestas ornare sit in purus sit. Potenti sem velit quisque ultrices in nibh. Cras amet varius morbi tristique amet nunc. Viverra sit vitae pulvinar amet.
							  Id mauris a sed arcu nullam amet tortor senectus. Dignissim augue orci imperdiet sagittis tincidunt pretium vitae nunc felis.vitae pretium.
							  

							  </h6>
						  </div>
					</div>
					<div className='flex items-center justify-center gap-x-9'>
                       <Button  myStyle="decline" onClick={handleModal} >Decline </Button>
					   <Button  myStyle="growButton2" onClick={()=> {
						handleSuccess()
					   }} >Accept</Button>
					</div>
				</div>
		 </div>

		 {modal ? (
        <ModalPreview close={handleCloseModal}>
		   <div className='bg-white shadow-sm xs:w-[100%] lg:w-[657px] h-[501px] rounded-sm flex flex-col items-center justify-center gap-y-9'>
                  <div>
                     <img src={image2} alt="" />
				  </div>
				  <div>
                    <h6 className='text-md font-poppins text-[#C4C4C4]'>Wait for real?</h6>
				  </div>
				  <div>
                      <h6>Are you sure you want to decline this sweet package? </h6>  
				  </div>
				  <div>
					<button
					className="w-[240px] h-[60px] bg-primaryColor1 rounded-lg text-white"
					onClick = {homePage}
					>
					Yes
					</button>
				  </div>
				  <div>
				   <button
					className="w-[240px] h-[60px] bg-[#C4C4C4] rounded-lg text-white"
					onClick = {handleCloseModal}
					>
					Cancel
					</button>
				  </div>
		   </div>
		
		</ModalPreview>
	 ) : ""}

{success ? (
        <ModalSuccess close={handleCloseSuccess}>
		   <div className='bg-white shadow-sm xs:w-[100%] md:w-[458px] h-[501px] rounded-sm flex flex-col items-center justify-center gap-y-9'>
                 <div className='w-[150px] h-[150px]'>
					<img src={SuccessGif} />
					</div>
				 
				  <div className='font-poppins text-center text-lg '>
				  We’ve received your <span className=' font-medium text-primaryColor2'>EstatePro</span> pre-application.  Please check your email for an update 
				  </div>

				
				  <div>
					<button
					className="w-[200px] h-[60px] bg-primaryColor1 rounded-lg font-poppins font-medium text-white"
					onClick = {homePage}
					>
					Done
					</button>
				  </div>
				 
		   </div>
		
		</ModalSuccess>
	 ) : ""}
	</div>
  )
}

export default EstateTermsConditions2