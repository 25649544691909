import Routing from "./components/Routing";

function App() {
  return (
    <div className="overflow-hidden">
       <Routing />
     </div>
  );
}

export default App;
