import Nav from "../components/Nav";
import develop from "../assets/landshop_develop.png";
import developOne from "../assets/develop_one.png";
import land from "../assets/land.png";
import Lagos from "../assets/Lagos.png";
// import Jos from "../assets/Jos.png";
import PortHarcourt from "../assets/PortHarcourt.png";
import Abuja from "../assets/Abuja.png";
// import Kaduna from "../assets/Kaduna.png";
import Realtor from "../assets/Realtor.png";
import { useState, useEffect } from "react";
import { ModalLuxury } from "../components/ModalLuxury";
import BlubTwo from "../assets/blub2.png";
import { FooterBlack } from "../components/FooterBlack";
import axios from "axios";
import { API } from "../utils/backend";
import { developGenType } from "../typings";
import { DevelopCards } from "../components/DevelopCards";
import { tail } from "../common/utils/helper";

type dev = {
  city: string;
  type: string;
  format: string;
};

const propertyLocations = [
  {
    name: "Lagos",
    propertyTypes: ["Luxury", "Prime"],
    showTypes: true,
    image: Lagos,
  },
  {
    name: "Port Harcourt",
    propertyTypes: [],
    image: PortHarcourt,
  },
  // {
  //   name: "Jos",
  //   propertyTypes: [],
  //   image: Jos,
  // },
  {
    name: "Abuja",
    propertyTypes: ["Luxury", "Prime"],
    showTypes: true,
    image: Abuja,
  },
  // {
  //   name: "Kaduna",
  //   propertyTypes: [],
  //   image: Kaduna,
  // },
];

export default function Develop() {
  const [developedProperty, setDevelopedProperty] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState<Boolean>(false);
  const [luxuryModal, setLuxuryModal] = useState<Boolean>(false);

  //http response for develop
  const getDevelops = async ({ city, format, type }: dev) => {
    setLoading(true);
    try {
      const developResponse: developGenType = await axios.get(
        `${API}develops?state=${city}&type=${type}&format=${format}`
      );
      setDevelopedProperty(developResponse?.data?.data?.develops.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleLuxuryModal = () => {
    setLuxuryModal(true);
  };
  const handleCloseLuxuryModal = () => {
    setLuxuryModal(false);
  };

  const handleClick = ({ city, format, type }: dev) => {
    if (loading) return;
    getDevelops({ city, format, type }).then(() => {
      if (format === "luxury") {
        handleLuxuryModal();
      }
      if (format === "prime") {
        handleOpenModal();
      }
    });

    // handleLuxuryModal()
  };

  return (
    <div className="h-auto">
      {/* Ads */}
      <Nav />
      <div className="w-screen 2xl:h-[70rem] xl:[95rem] lg:[70rem] lg:h-[600px] xl:h-[650px] md:h-[430px] relative">
        <img
          src={develop}
          alt=""
          className="lg:h-[600px] xl:h-[650px] md:mx-auto md:h-[500px] sm:h-[400px] block w-screen h-[350px]"
        />
        <img
          src={developOne}
          alt=""
          className="absolute top-0 sm:left-8 xs:left-0 md:w-[280px] xs:w-[250px] lg:w-[339px]"
        />
        <div className="absolute top-28 left-8 p-3 flex flex-col items-center justify-center gap-y-5 sm:left-[4rem] md:top-40 md:left-[5rem]">
          <span className="text-sm text-white font-poppins">
            <p className="leading-[3rem] md:text-5xl sm:text-4xl lg:text-6xl lg:font-bold lg:w-[370px] lg:mt-[3rem] xl:mt-[6rem] md:w-[300px] sm:w-[280px] xs:w-[280px] xs:text-3xl font-bold text-4xl text-primaryColor1">
              <span className="text-black">Buy</span> <span>Land</span>
              <span className="text-black"> or</span>
            </p>{" "}
            <p className="md:text-5xl sm:text-4xl lg:text-6xl lg:font-bold lg:w-[330px] lg:mt-[1rem] xl:mt-[1rem] md:w-[300px] sm:w-[280px] xs:w-[280px]  xs:text-3xl font-bold text-4xl text-primaryColor2">
              <span>House</span>
            </p>{" "}
            <br /> <br />
            <p className="lg:block lg:text-bold lg:mt-[0] xl:block xl:mt-[1rem] xl:text-bold  sm:block sm:text-bold sm:text-3xl  text-primaryColor1 xs:hidden">
              pay outright or pay for 10 years.
            </p>
          </span>
        </div>
      </div>
      <div className="lg:gap-y-2 lg:pt-20 bg-white mx-auto h-[auto] relative flex flex-col items-center justify-center gap-x-6">
        {/* words */}
        <div
          style={{
            background: `url(${BlubTwo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="flex flex-col items-center justify-center gap-y-8 p-4 py-20"
        >
          <h5 className="text-4xl font-urbanist font-bold">
            <span className="text-primaryColor1">Luxury </span>or Prime
          </h5>
          <p className="text-sm font-poppins font-light max-w-2xl mx-auto text-center">
            How do you want your property? Discover our highly
            competitively-priced, innovative range of PRIME and LUXURY
            properties across Nigeria. Benchmarked against prevailing market
            value to match budget and exceed expectations.
          </p>
        </div>

        {/* Land */}
        <div className="lg:flex  lg:justfy-center lg:align-center lg:flex-col lg:w-full  lg:px-20 lg:py-20 xl:px-40 xs:pb-16">
          <div
            className="
            
            lg:w-full lg:justify-between lg:items-center
            md:w-[689px] md:p-md
            flex flex-col bg-white w-[389px] shadow-2xl rounded-xl p-10 "
          >
            <div className="lg:flex-row lg:justify-between md:justify-center lg:self-stretch sm:justify-start flex flex-col-reverse items-center justify-center p-2 bg-white">
              {/* upper */}
              <div className="flex flex-col items-center justify-center gap-y-2 bg-white">
                <div className="self-start">
                  <span className="text-3xl font-bold font-urbanist">Land</span>
                </div>
              </div>
              {/* lower */}
              {/* <div className="bg-white">
                <img src={land} alt="" className="w-[200px] h-[200px]" />
              </div> */}
            </div>

            <div className="md:flex md:flex-col md:items-center md:justify-center md:gap-y-8 md:mt-8 md:mb-[3rem]">
              {/* upper */}
              <div className="grid sm:grid-cols-2 md:grid-cols-6 gap-6  relative">
                {propertyLocations.map((itm, idx) => (
                  <div
                    key={idx}
                    className={tail(
                      "relative group md:col-span-2",
                      idx === 3
                        ? "md:col-start-2 md:col-end-4 justify-self-center md:row-start-2 md:row-end-3"
                        : "",
                      idx === 4
                        ? "md:col-start-4 md:col-end-6 justify-self-center md:row-start-2 md:row-end-3"
                        : ""
                    )}
                  >
                    <img src={itm.image} alt={itm.name} className="w-fullh" />
                    {itm.showTypes ? (
                      <div className=" absolute bottom-0 left-1/2 -translate-x-1/2 hidden group-hover:flex items-center font-bold font-urbanist text-xl text-white">
                        <button
                          className="bg-transparent border text-inherit border-white rounded-l-lg sm:w-full p-2.5"
                          onClick={() =>
                            handleClick({
                              city: itm.name,
                              format: "luxury",
                              type: "land",
                            })
                          }
                        >
                          Luxury
                        </button>
                        <button
                          className="bg-transparent border text-inherit border-white rounded-r-lg sm:w-full p-2.5 "
                          onClick={() => {
                            handleClick({
                              city: itm.name,
                              format: "prime",
                              type: "land",
                            });
                          }}
                        >
                          Prime
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* House */}
        <div className="lg:flex  lg:justfy-center lg:align-center lg:flex-col lg:w-full  lg:px-20 lg:py-20 xl:px-40 xs:pb-16">
          <div className="lg:w-full lg:justify-between lg:items-center md:w-[689px] md:p-md flex flex-col bg-white w-[389px] shadow-2xl rounded-xl p-10 ">
            <div className="lg:flex-row lg:justify-between md:justify-center lg:self-stretch sm:justify-start flex flex-col-reverse items-center justify-center p-2 bg-white">
              {/* upper */}
              <div className="flex flex-col items-center justify-center gap-y-2 bg-white">
                <div className="self-start">
                  <span className="text-3xl font-bold font-urbanist">
                    House
                  </span>
                </div>
              </div>
              {/* lower */}
              {/* <div className="bg-white">
                <img src={Realtor} alt="" className="w-[200px] h-[200px]" />
              </div> */}
            </div>

            <div className="md:flex md:flex-col md:items-center md:justify-center md:gap-y-8 md:mt-8 md:mb-[3rem]">
              {/* upper */}
              <div className="grid sm:grid-cols-2 md:grid-cols-6 gap-6  relative">
                {propertyLocations.map((itm, idx) => (
                  <div
                    key={idx}
                    className={tail(
                      "relative group md:col-span-2",
                      idx === 3
                        ? "md:col-start-2 md:col-end-4 justify-self-center md:row-start-2 md:row-end-3"
                        : "",
                      idx === 4
                        ? "md:col-start-4 md:col-end-6 justify-self-center md:row-start-2 md:row-end-3"
                        : ""
                    )}
                  >
                    <img src={itm.image} alt={itm.name} className="w-fullh" />
                    {itm.showTypes ? (
                      <div className=" absolute bottom-0 left-1/2 -translate-x-1/2 hidden group-hover:flex items-center font-bold font-urbanist text-xl text-white">
                        <button
                          className="bg-transparent border text-inherit border-white rounded-l-lg sm:w-full p-2.5"
                          onClick={() =>
                            handleClick({
                              city: itm.name,
                              format: "luxury",
                              type: "house",
                            })
                          }
                        >
                          Luxury
                        </button>
                        <button
                          className="bg-transparent border text-inherit border-white rounded-r-lg sm:w-full p-2.5 "
                          onClick={() => {
                            handleClick({
                              city: itm.name,
                              format: "prime",
                              type: "house",
                            });
                          }}
                        >
                          Prime
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <FooterBlack />
      {/* Modal For Develop */}
      {openModal ? (
        <ModalLuxury close={handleCloseModal}>
          <div className="flex flex-col items-center justify-center gap-x-4 h-full z-[10]">
            <div className="flex flex-col items-center justify-center gap-y-3 p-4">
              <div className="flex items-center justify-center">
                <h3 className="text-3xl text-primaryColor1 font-urbanist font-bold">
                  Prime
                </h3>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-base font-poppins">
                  PRIME properties are Landshop's range , priced at low to the
                  mid millions. And go beyond the standard fare of just offering
                  diverse choices of location , accessibility , value and
                  quality real estate. They are sited and constructed with all
                  Nigerians in mind, so that even when/if funding becomes a
                  hindrance, low interest payment timelines are provided to
                  lighten the burden.
                </span>
              </div>
            </div>
            {developedProperty.length > 0 ? (
              <div className="w-[90%] overflow-x-auto ">
                <div className="grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-2 lg:gap-0 lg:grid-cols-3 relative">
                  {developedProperty.map((develop) => (
                    <DevelopCards data={develop} />
                  ))}
                </div>
              </div>
            ) : (
              <p className="flex-1 flex items-center justify-center">No Data</p>
            )}
          </div>
        </ModalLuxury>
      ) : (
        ""
      )}
      {luxuryModal ? (
        <ModalLuxury close={handleCloseLuxuryModal}>
          <div className="flex flex-col items-center justify-center h-full gap-x-4 z-[5]">
            <div className="flex flex-col items-center justify-center gap-y-3 p-4">
              <div className="flex items-center justify-center">
                <h3 className="text-3xl text-primaryColor1 font-urbanist font-bold">
                  Luxury
                </h3>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-base font-poppins">
                  Our LUXURY range is an acquired taste; for the exclusive club
                  of Nigerians who prefer the aspirational excess of
                  sophisticated real estate. From remote-controlled apartments
                  to landed properties with scenic views of the Atlantic horizon
                  , these are priced to offer the very best quality money can
                  buy
                </span>
              </div>
            </div>
            {developedProperty.length > 0 ? (
              <div className="w-[90%] overflow-x-auto ">
                <div className="grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-2 lg:gap-0 lg:grid-cols-3 relative">
                  {developedProperty.map((develop) => (
                    <DevelopCards data={develop} />
                  ))}
                </div>
              </div>
            ) : (
              <p className="flex-1 flex items-center justify-center">No Data</p>
            )}
          </div>
        </ModalLuxury>
      ) : (
        ""
      )}
    </div>
  );
}
