import Nav from "../components/Nav";
import promo from "../assets/promo.png";
import { GreenLandGallery } from "../components/GreenLandGallery";
import { GreenHouseGallery } from "../components/GreenHouseGallery";
import develop from "../assets/Paid_banner.jpg";
import { useNavigate } from "react-router-dom";

const GreenExtra = () => {
  const navigate = useNavigate();
  const goToPromoPage = () => {
    navigate("/20daysofchristmas");
  };
  return (
    <div
      className="w-screen
	 xl:overflow-y-auto xl:overflow-x-hidden
	 lg:overflow-y-auto
	 sm:overflow-y-auto
	 xs:overflow-y-auto
	 md:overflow-y-auto xs:pb-[10rem]
	 "
    >
      <div className="nav-slideshow">
        <div onClick={goToPromoPage} className="mover-1"></div>
      </div>
      <Nav />
      {/* banner */}
      <div className="w-screen h-[30rem] relative mb-10">
        <img
          src={promo}
          alt=""
          className="
				lg:block lg:w-[1512px] lg:h-[716px]
				md:hidden
				sm:hidden
                xs:hidden
				
				"
        />

        <img
          src={develop}
          alt=""
          className="
				lg:hidden
				md:block md:w-screen md:h-[536px]
				sm:block sm:w-screen sm:h-[536px]
				xs:block xs:w-screen xs:h-[536px]
                
				
				"
        />

        <div
          className="
			lg:hidden 
			sm:absolute sm:top-40 sm:left-[1rem] sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-5 
			md:absolute md:top-40 md:left-[1rem] md:flex md:flex-col md:items-center md:justify-center md:gap-y-5
			xs:absolute xs:top-40 xs:left-[1rem] xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-5 
			
			
			"
        >
          <img src="" alt="" />
         
        
        </div>
      </div>
      <div
        className="
		xl:bg-primaryColor1 xl:w-screen xl:h-[70px] xl:mt-[14.5rem] xl:flex xl:items-center xl:justify-start
		lg:bg-primaryColor1 lg:w-screen lg:h-[70px] lg:mt-[3rem] lg:flex lg:items-center lg:justify-start
		md:bg-primaryColor1 md:w-screen md:h-[70px] md:mt-[2rem] md:flex md:items-center md:justify-center
		sm:bg-primaryColor1 sm:w-screen sm:h-[70px] sm:mt-[2rem] sm:flex sm:items-center sm:justify-center
		xs:bg-primaryColor1 xs:w-screen xs:h-[70px] xs:mt-[2rem] xs:flex xs:items-center xs:justify-center
		"
      >
        <span
          className="
			lg:text-white lg:font-bold lg:text-4xl lg:font-manrope lg:ml-[3rem]
			md:text-white md:font-bold md:text-xl md:font-manrope md:ml-4
			sm:text-white sm:font-bold sm:text-xl sm:font-manrope sm:ml-4
			xs:text-white xs:font-bold xs:text-xl xs:font-manrope xs:ml-4
			
			
			"
        >
          LAND OFFERS
        </span>
      </div>

      {/* Green Land Cards */}

      <div className="xl:mt-[10rem] lg:mt-[10rem] md:mt-[5rem] sm:mt-[5rem] xs:mt-[5rem]">
        <GreenLandGallery />
      </div>

      <div
        className="
		lg:bg-primaryColor2 lg:w-screen lg:h-[70px] lg:mt-[3rem] lg:flex lg:items-center lg:justify-start
		md:bg-primaryColor2 md:w-screen md:h-[70px] md:mt-[2rem] md:flex md:items-center md:justify-center
		sm:bg-primaryColor2 sm:w-screen sm:h-[90px] sm:mt-[2rem] sm:flex sm:items-center sm:justify-center
		xs:bg-primaryColor2 xs:w-screen xs:h-[90px] xs:mt-[2rem] xs:flex xs:items-center xs:justify-center
		"
      >
        <span
          className="
			lg:text-white lg:font-bold lg:text-4xl lg:font-manrope lg:ml-[3rem]
			sm:text-white sm:font-bold sm:text-xl sm:font-manrope md:ml-4
			md:text-white md:font-bold md:text-xl md:font-manrope sm:ml-4
			xs:text-white xs:font-bold xs:text-xl xs:font-manrope xs:ml-4
			
			"
        >
          HOUSE OFFERS
        </span>
      </div>

      {/* Green House Card  */}

      <div>
        <GreenHouseGallery />
      </div>
    </div>
  );
};

export default GreenExtra;
