import React, { useState } from "react";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import routes from "../common/routes";
// @ts-ignore
import { StatefulPinInput } from "react-input-pin-code";
import {
  useForgotPasswordMutation,
  useResetPasswordMutation,
} from "../store/api";
import Swal from "sweetalert2";
import { useCountDown } from "../common/hooks/useCountDown";

type iStage =
  | "forgot-password"
  | "enter-otp"
  | "enter-new-password"
  | "success";
type Props = {
  title: string;
  subTitle: string;
  isSuccessful?: boolean;
};

const AuthFormLabel = ({ title, subTitle, isSuccessful }: Props) => {
  return (
    <div className="flex flex-col items-center gap-y-8">
      {isSuccessful ? (
        <svg
          width="165"
          height="164"
          viewBox="0 0 165 164"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M120.595 53.1457C122.887 55.4284 122.895 59.1369 120.612 61.4289L71.2597 110.982C68.977 113.274 65.2687 113.281 62.9766 110.999L44.978 93.075C42.6859 90.7924 42.6782 87.0839 44.9608 84.7918C47.2434 82.4997 50.9519 82.492 53.2441 84.7746L67.0927 98.5657L112.312 53.1625C114.595 50.8705 118.303 50.863 120.595 53.1457Z"
            fill="#244A84"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M82.1586 16.7143C51.1114 16.7143 25.065 38.3937 18.379 67.4361L18.379 67.4362C17.2906 72.1635 16.7143 77.0909 16.7143 82.1586C16.7143 118.275 46.0421 147.603 82.1586 147.603C118.275 147.603 147.603 118.275 147.603 82.1586C147.603 46.0421 118.275 16.7143 82.1586 16.7143ZM6.96331 64.808C14.8455 30.5697 45.53 5 82.1586 5C124.745 5 159.317 39.5725 159.317 82.1586C159.317 124.745 124.745 159.317 82.1586 159.317C39.5725 159.317 5 124.745 5 82.1586C5 76.1999 5.67796 70.3909 6.96331 64.808Z"
            fill="#C2CDE0"
          />
        </svg>
      ) : (
        <img src={logo} alt="landshop" className="w-20 object-contain" />
      )}

      <h3 className="text-2xl leading-6  text-[#808191] font-semibold">
        {title}
      </h3>
      <p className="text-base font-normal text-[#828282]">{subTitle}</p>
    </div>
  );
};

const resetPasswordState = {
  code: "",
  new_password: "",
  confirm_password: "",
};

function ForgotPassword() {
  const [stage, setStage] = useState<iStage>("forgot-password");
  const navigate = useNavigate();
  const [submitGetOtpForm, { isLoading: getOtpIsLoading }] =
    useForgotPasswordMutation();
  const [submitResetPasswordForm, { isLoading: ResetPasswordIsLoading }] =
    useResetPasswordMutation();

  const {
    start: startCounter,
    count,
    counter,
    reset: resetCounter,
  } = useCountDown(90);

  const [forgotPassword, setForgotPassword] = useState({ email: "" });
  const [pin, setPin] = useState(["", "", "", ""]);
  const [resetPassword, setResetPassword] = useState(resetPasswordState);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "email") {
      setForgotPassword((prev) => ({ ...prev, [name]: value }));
    } else {
      setResetPassword((prev) => ({ ...prev, [name]: value }));
    }
  };

  const pinHandler = (e: string | string[], i: number, val: string[]) => {
    const temp = pin.map((itm, index) => {
      if (i !== index) {
        return itm;
      }
      if (e[0] !== undefined) {
        return e[0];
      }
      return "";
    });
    setPin([...temp]);
    setResetPassword((prev) => ({ ...prev, code: temp.join("") }));
  };

  const requestOtpHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitGetOtpForm(forgotPassword)
      .unwrap()
      .then((res) => {
        if (res && stage === "forgot-password") {
          Swal.fire({
            icon: "success",
            title:
              "A one time password has been sent to the specified email address.",
          });
          setStage("enter-otp");
          startCounter();
        }
        if (res && stage === "enter-otp") {
          resetCounter();
          Swal.fire({
            icon: "success",
            title:
              "A New one time password has been resent to the specified email address.",
          }).then(() => {
            startCounter();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error Encountered",
        });
      });
  };
  const enterOtpHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStage("enter-new-password");
  };

  const enterNewPasswordHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitResetPasswordForm(resetPassword)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res) {
          setStage("success");
        }
      })
      .catch((err) => {
        resetCounter();
        Swal.fire({
          icon: "error",
          title: err.message,
        })
        submitGetOtpForm(forgotPassword)
          .unwrap()
          .then(() => {
            setStage("enter-otp");
            startCounter();
          });
      });
  };
  const successHandler = () => {
    navigate(routes.nonprotected.login);
  };

  return (
    <>
      {stage === "forgot-password" ? (
        <form
          className="bg-white px-15 py-20 rounded-5 max-w-xl flex flex-col gap-y-8"
          onSubmit={requestOtpHandler}
        >
          <AuthFormLabel
            title="Forgot password"
            subTitle="Enter your email for the verification proccess,we will send 4 digits code to your email."
          />
          <div className="flex flex-col gap-y-6">
            <CustomInput
              label="Email"
              type="email"
              name="email"
              value={forgotPassword.email}
              onChange={changeHandler}
              disabled={getOtpIsLoading}
            />
            <CustomButton
              className="self-start"
              disabled={forgotPassword.email === ""}
              isloading={getOtpIsLoading}
            >
              Continue
            </CustomButton>
          </div>
        </form>
      ) : null}
      {stage === "enter-otp" ? (
        <form
          className="bg-white px-15 py-20 rounded-5 max-w-xl flex flex-col gap-y-8"
          onSubmit={enterOtpHandler}
        >
          <AuthFormLabel
            title="Verification"
            subTitle="Enter your 4 digits code that you received on your email."
          />
          <div className="flex flex-col items-center gap-y-6">
            <StatefulPinInput
              required
              placeholder=""
              containerClassName="pinInput"
              length={4}
              initialValue=""
              onChange={pinHandler}
            />
            {counter > 0 ? (
              <div className="text-[#F2451C]">
                {count.minutes}:{count.seconds}
              </div>
            ) : null}
            <CustomButton
              className=""
              disabled={resetPassword.code.length !== 4}
            >
              Continue
            </CustomButton>
          </div>
          <div className="text-center text-[#828282]">
            <p>If you didn’t receive a code!</p>
            <button
              className="text-[#F2451C] disabled:cursor-not-allowed"
              disabled={getOtpIsLoading || counter > 0}
            >
              Resend
            </button>
          </div>
        </form>
      ) : null}
      {stage === "enter-new-password" ? (
        <form
          className="bg-white px-15 py-20 rounded-5 max-w-xl flex flex-col gap-y-8"
          onSubmit={enterNewPasswordHandler}
        >
          <AuthFormLabel
            title="New Password"
            subTitle="Set the new password for your account so you can login and access all featuress."
          />
          <div className="flex flex-col gap-y-6">
            <CustomInput
              label="Enter new password"
              type="password"
              name="new_password"
              value={resetPassword.new_password}
              onChange={changeHandler}
              disabled={ResetPasswordIsLoading}
            />
            <CustomInput
              label="Confirm password"
              type="password"
              name="confirm_password"
              value={resetPassword.confirm_password}
              onChange={changeHandler}
              disabled={ResetPasswordIsLoading}
            />
            <CustomButton
              className="self-start"
              disabled={
                resetPassword.confirm_password !== resetPassword.new_password ||
                Object.values(resetPassword).includes("")
              }
              isloading={ResetPasswordIsLoading}
            >
              Update password
            </CustomButton>
          </div>
        </form>
      ) : null}
      {stage === "success" ? (
        <div className="bg-white px-7 md:px-15 py-20 rounded-5 max-w-xl flex flex-col items-center gap-y-8">
          <AuthFormLabel
            title="Successfully"
            subTitle="Your password has been reset successfully"
            isSuccessful
          />
          <CustomButton className="" onClick={successHandler}>
            Continue
          </CustomButton>
        </div>
      ) : null}
    </>
  );
}

export default ForgotPassword;
