import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeAuthorizedRequestWithFormData } from "../../common/service/request";
import ENDPOINTS from "./endpoint";

const service = {
  async uploadFiles(data: any) {
    const { method, url } = ENDPOINTS.upload_files;
    return makeAuthorizedRequestWithFormData(method, url, data);
  },
};

export const uploadFiles = createAsyncThunk(
  "general/upload",
  async (req: Record<string, File>, { rejectWithValue, fulfillWithValue }) => {
    return service
      .uploadFiles(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
