import React from 'react'

type Props = {
	close : () => void,
	children : React.ReactNode
}

export const ModalEstatePro = ({close , children} : Props) => {

  return (
 <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-start overflow-auto' >
    <div className='
    xl:w-[744px] xl:h-[732px] xl:flex xl:flex-col
    lg:w-[744px] lg:h-[732px] lg:flex lg:flex-col
    md:w-[744px] md:h-[732px] md:flex md:flex-col
    sm:w-[100%] sm:h-[432px] sm:flex sm:flex-col
    xs:w-[100%] xs:h-[432px] xs:flex xs:flex-col
    
    '>
       <button  className="xs:text-black xs:text-lg xs:place-self-end  xs:relative xs:top-[30px] xs:right-[10px] lg:top-[40px]  lg:right-[40px]" onClick = {close}>
           X
	   </button>
	   <div className='bg-white p-2 rounded'>
         {children}
      </div>
	</div>
	

</div>
  )
}
