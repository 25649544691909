import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "./config";
import Swal from "sweetalert2";

export const logUserIn = createAsyncThunk(
  "auth/logUserIn",
  async (req: Record<string, any>, { rejectWithValue, fulfillWithValue }) => {
    return service
      .logUserIn(req)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: res.status,
        });
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err.status,
        });
        return rejectWithValue(err);
      });
  }
);
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (req: Record<string, any>, { rejectWithValue, fulfillWithValue }) => {
    return service
      .registerUser(req)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: res.status,
        });
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err.status,
        });
        return rejectWithValue(err);
      });
  }
);
export const logUserOut = createAsyncThunk(
  "auth/logUserOut",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    return service
      .logUserOut()
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (
    req: Record<string, any>,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    return service
      .updateUserProfile(req)
      .then((res) => {
        dispatch(fetchUserProfile());
        Swal.fire({
          icon: "success",
          title: res.status,
        });
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err.status,
        });
        return rejectWithValue(err);
      });
  }
);
export const fetchUserProfile = createAsyncThunk(
  "auth/fetchUserProfile",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    return service
      .fetchUserProfile()
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const updateUserPassword = createAsyncThunk(
  "auth/updateUserPassword",
  async (req: Record<string, any>, { rejectWithValue, fulfillWithValue }) => {
    return service
      .updateUserPassword(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const forgotUserPassword = createAsyncThunk(
  "auth/forgotUserPassword",
  async (req: Record<string, any>, { rejectWithValue, fulfillWithValue }) => {
    return service
      .forgotUserPassword(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const resetUserPassword = createAsyncThunk(
  "auth/resetUserPassword",
  async (req: Record<string, any>, { rejectWithValue, fulfillWithValue }) => {
    return service
      .resetUserPassword(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
