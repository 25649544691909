import React from "react";
import { ModalConfirmItem } from "../ModalConfirmItem";
import { localCurrency } from "../../common/utils/helper";

type Props = {
  closeConfirmModalHandler: VoidFunction;
  firstName: string;
  middleName: string;
  lastName: string;
  propertyName: string;
  price: number;
  quantity: number;
  duration: string;
  selected: string;
  closeModalHandler: VoidFunction;
  checkoutHandler: VoidFunction;
  checkoutDisabled: boolean;
};

function InvestmentPaymentDetails({
  closeConfirmModalHandler,
  firstName,
  lastName,
  middleName,
  propertyName,
  price,
  quantity,
  duration,
  selected,
  closeModalHandler,
  checkoutHandler,
  checkoutDisabled,
}: Props) {
  return (
    <ModalConfirmItem close={closeConfirmModalHandler}>
      <div className="flex items-center justify-center font-poppins p-4">
        <div className="flex flex-col gap-y-6 text-xs sm:text-lg">
          <h5 className="font-bold text-lg sm:text-2xl">
            Kindly Confirm Your Details
          </h5>
          <div className="flex flex-col gap-y-3 sm:gap-y-6">
            <p>
              Full Name:
              {` ${firstName} ${lastName} ${middleName}`}
            </p>
            <p>Property Name: {propertyName}</p>
            <p>Amount: {localCurrency(price)}</p>
            <p>Quantity: {quantity}</p>
            <p>Duration: {`${duration} `} Years</p>
            <p>How often do you want your payout: {` ${selected}`} </p>
          </div>

          <div className="flex flex-col gap-y-3 sm:gap-y-6">
            <h5 className="text-base sm:text-xl font-bold">
              Make your Payments into this Account
            </h5>
            <p className="">Landshop Limited</p>
            <p className="text-primaryColor1 font-bold text-lg sm:text-2xl">
              0070474359
            </p>
            <p className="">Sterling Bank Plc</p>
            <p className="">
              We would contact you shortly for further information
            </p>
          </div>
          <div className="flex items-center justify-center flex-col lg:flex-row gap-x-2">
            <button
              onClick={closeModalHandler}
              className="px-7 py-3 bg-orange-10 rounded-lg text-white font-bold"
            >
              Done
            </button>

            <button
              onClick={checkoutHandler}
              disabled={checkoutDisabled}
              className="px-7 py-3 bg-primaryColor1 rounded-lg text-white font-bold disabled:bg-gray-60 disabled:text-gray-20 "
            >
              Pay With Fluterwave
            </button>
          </div>
        </div>
      </div>
    </ModalConfirmItem>
  );
}

export default InvestmentPaymentDetails;
