import React from 'react'
// import image from '../assets/IMAGE.png'
import image2 from '../assets/miniTandC.png'
import Button from './Button'
import { useNavigate } from 'react-router-dom'


const EstateTermsConditions = () => {

	const navigate = useNavigate()
 
	const sendToNext = () => {
         navigate('/estatetermsconditions2')
	}
	
	
  return (
	<div className=' flex items-center justify-center bg-bgTerms w-full h-[100vh]'>
         
         {/* <img src={image} alt="" /> */}
	

		 <div className='flex items-center justify-center bg-white shadow-2xl rounded-md xs:w-[100%] md:w-[657px] h-[433px]'>
                <div className='flex flex-col items-center justify-center gap-y-7'>
                    <div>
                        <img src={image2} alt="" />
					</div>
					<div>
						<h5 className='text-md font-poppins text-[#808191]'>Terms and Agreement</h5>
					</div>
					<div className='self-center terms w-[537px] h-[48px]'>
                       <h5 className='text-md text-center font-poppins'>Welcome to <span className='text-primaryColor1'>Estate Pro </span>, please take your time to peruse the terms of the agreement before confirming offer.</h5>
					</div>
					<div className='xs:mt-[1rem]'>
                       <Button onClick={sendToNext}  myStyle="growButton2">Read T&C</Button>
					</div>
				</div>
		 </div>
	</div>
  )
}

export default EstateTermsConditions