import React from 'react'

type Props = {
	close : () => void,
	children : React.ReactNode
}

export const ModalTribeFormAlert = ({close , children} : Props) => {

  return (
 <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50'>
    <div className='w-[344px] h-[532px] flex flex-col'>
       <button className='text-white text-xl place-self-end' onClick = {close}>
           X
	   </button>
	   <div className='bg-white p-2 rounded'>
         {children}
      </div>
	</div>
	

</div>
  )
}
