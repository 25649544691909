import React, { useEffect } from "react";
import Nav from "../components/Nav";
import invest from "../assets/landshop_invest.png";
import InvestLogo from "../assets/invest_one.png";
import InvestBa from "../assets/InvestBa.png";
import InvestGallery from "../components/InvestGallery";
import { FooterBlack } from "../components/FooterBlack";
import BlubOne from "../assets/blub1.png";

const Invest = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Nav />
      {/* banner */}
      <div className="w-screen 2xl:h-[70rem] xl:[95rem] lg:[70rem] lg:h-[600px] xl:h-[650px] md:h-[430px] relative">
        <img
          src={invest}
          alt=""
          className="
          lg:block lg:w-screen lg:mx-auto 
          md:block md:w-screen md:mx-auto md:h-[500px] lg:h-[600px] xl:h-[650px]
			    sm:block sm:w-screen sm:h-[400px]
			 xs:block xs:w-screen xs:h-[350px]
			 "
        />
        <img
          src={InvestLogo}
          alt=""
          className="
          absolute top-0 sm:left-[2rem] xs:left-0 md:w-[280px] xs:w-[250px] lg:w-[339px]
			
			 "
        />
        <div
          className="
			  xs:absolute xs:top-28 xs:left-[2rem] xs:p-3 xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-5
			  sm:absolute sm:top-28 sm:left-[4rem] sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-5
			  md:absolute md:top-40 md:left-[5rem] md:flex md:flex-col md:items-center md:justify-center md:gap-y-5
			  lg:absolute lg:top-40 lg:left-[5rem] lg:flex lg:flex-col lg:items-center lg:justify-center lg:gap-y-5
			 
			 
			 "
        >
          {/* <span className="text-6xl relative top-[-70px] text-white font-urbanist font-bold self-start">
            Grow
          </span> */}
          <span className="text-sm text-white font-poppins">
            <p className="leading-[3rem] md:text-5xl sm:text-4xl lg:text-6xl lg:font-bold lg:w-[330px] lg:mt-[3rem] xl:mt-[6rem] md:w-[300px] sm:w-[280px] xs:w-[280px]  xs:text-3xl font-bold text-4xl text-primaryColor1">
              <span>Invest</span>
              <span className="text-black"> In a</span>
            </p>{" "}
            <p className="md:text-5xl sm:text-4xl lg:text-6xl lg:font-bold lg:w-[330px] lg:mt-[1rem] xl:mt-[1rem] md:w-[300px] sm:w-[280px] xs:w-[280px]  xs:text-3xl font-bold text-4xl text-primaryColor2">
              <span>Property</span>
            </p>{" "}
            <br /> <br />
            <p className="lg:mt-[0] xl:mt-[1rem] text-bold sm:text-3xl xs:text-2xl text-primaryColor2">
              <span className="text-primaryColor1">Earn </span>{" "}
              <span className="text-black font-bold text-opacity-50">
                70% RETURNS
              </span>{" "}
              <span> in a Year</span>
            </p>
          </span>
        </div>

        {/* <img
          src={InvestBa}
          alt=""
          className="
			 lg:hidden
			 md:hidden
			 sm:block sm:w-screen sm:h-[600px]
			 xs:block xs:w-screen xs:h-[700px]
			 "
        /> */}

        {/* <div
          className="
			 xs:absolute xs:top-20 xs:left-[1rem] xs:flex xs:flex-col xs:items-center xs:justify-center xs:gap-y-5
			  sm:absolute sm:top-20 sm:left-[1rem] sm:flex sm:flex-col sm:items-center sm:justify-center sm:gap-y-5
			 md:hidden
			  lg:hidden
			 
			 
			 "
        >
          <span className="text-4xl text-white font-urbanist font-bold self-start">
            Invest
          </span>
          <div className="xs:flex xs:flex-col xs:items-center xs:justify-center">
            <span className="text-2xl text-black font-bold font-urbanist">
              <span className="text-primaryColor1">
                Smartest , Safest, Most Secure{" "}
              </span>
              Investments
            </span>

            <h3
              className="
 text-md font-poppins mt-[2rem] h-[171px] text-white
"
            >
              <p>
                Stocks, bonds, fixed deposits…will not offer such eye-popping
                returns. Discover why our list of real estate remains the
                unshakeable trove of high profits. Invest in a land or housing
                bundle and rake in better value with our surely guaranteed huge
                returns.
              </p>
              <br />
              <p>
                {" "}
                Your investment powers the exponential developments of our vast
                assortments of properties. And in turn, earns you a handsome
                return of 70% returns flat. Your returns are priced at the
                prevailing appreciation value of the property at your exit
                point. It’s safe because of real estate’s assured inflationary
                reputation.
              </p>
              <br />
            </h3>
          </div>
        </div> */}
      </div>

      {/* InvestInfo */}
      <div
        className="
		lg:flex   lg:h-[171px] lg:p-5 lg:mt-[6rem]  lg:items-center lg:justify-center
		xl:flex   xl:h-[230px] xl:p-5 xl:mt-[9rem]  xl:items-center xl:justify-center 
		2xl:flex  2xl:h-[230px] 2xl:p-5 2xl:mt-[20rem] 2xl:ml-[29rem] 2xl:items-center 2xl:justify-center 
		md:hidden 
		sm:hidden
		xs:hidden
		
		"
      >
        <div className="lg:flex lg:flex-col lg:items-center lg:justify-center p-3 relative">
          <h3 className="text-4xl text-black font-bold font-urbanist">
            <span className="text-primaryColor1">
              Smartest , Safest, Most Secure{" "}
            </span>
            Investments
          </h3>

          <p
            className="
					 text-md font-poppins mt-[2rem]  max-w-2xl mx-auto text-center
					"
          >
            Invest in our land or housing bundle and experience the unshakeable
            trove of high profits that our real estate list offers. Our surely
            guaranteed huge returns provide better value for your investment,
            with a handsome flat return of 70%. It’s safe because of real
            estate’s assured inflationary reputation, and secure because your
            funds are held in a trust with our securities partner: PAC Trustees;
            a SEC-controlled entity that provides strong fiduciary protections
            and securities oversight.
          </p>
          <div className="absolute top-[0px]">
            <img src={BlubOne} alt="" />
          </div>
        </div>
      </div>

      {/* Land / House */}
      <InvestGallery />

      {/* Invest instruction */}
      {/* <div className='
		  sm:flex sm:flex-col sm:h-[60rem] sm:items-center sm:justify-center sm:gap-y-9 sm:mt-[-10rem]
		  lg:flex lg:flex-col lg:h-[40rem] lg:items-center lg:justify-center lg:gap-y-3 lg:mt-[7rem]
		  md:flex md:flex-col md:h-[60rem] md:items-center md:justify-center md:gap-y-9 md:mt-[-25rem]
		  xs:flex xs:flex-col xs:h-[60rem] xs:items-center xs:justify-center xs:gap-y-9 xs:mt-[8rem]
		
		
		'>
			<div>
              <span className='text-6xl font-urbanist font-bold'>How it works</span>
			</div>
			<div>
              <span className='text-sm font-poppins font-light w-[865px] h-[294px]'>Discover how Landshop is reverse-engineering real estate in order to make home ownership simpler.</span>
			</div>
			<div>
              <img 
			   src={investInstruction} 
			   alt="" 
			   />
			</div>
			<div className='mb-10'>
               <span className='text-xs font-poppins font-light' > 
			     <p>With this option, you “GROW a HOUSE” by buying in a slot or accumulating it into a bigger portion that enables you to become a co-owner of an apartment,
				 <br/>keep it as an investment property or earn rental income — sharing profits with co-investors based on the size of slots owned. 
				 <br/>A slot costs 1% of the apartment’s selling price.</p> </span>
			</div>
             
		</div> */}
      <FooterBlack />
    </div>
  );
};

export default Invest;
