import { BoardTeam } from "../data";
import BoardCard from "./BoardCard";
import { useState } from "react";
import { ModalBoard } from "./ModalBoard";

export default function BoardTeamm() {
  const [openModal, setOpenModal] = useState(false);
  const [activeMember, setActiveMember] = useState(BoardTeam[0]);

  const handleModalOpen = (board: any) => {
    handleOpenModal();
    setActiveMember(board);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div
      className="
	xs:grid xs:grid-cols-1 xs:gap-y-7 xs:bg-white xs:p-4 xs:mt-3
	sm:grid sm:grid-cols-1 sm:gap-y-7 sm:bg-white sm:p-4 sm:mt-3
	md:grid md:grid-cols-1md:gap-y-7 md:bg-white md:p-4 md:mt-3
	lg:grid lg:grid-cols-2 lg:gap-x-7 lg:bg-white lg:p-4 lg:mt-3
	
	
	"
    >
      <div
        className="
			xs:flex xs:flex-col xs:mx-auto xs:justify-center xs:items-center xs:gap-y-5 xs:bg-white
			sm:flex sm:flex-col sm:mx-auto sm:justify-center sm:items-center sm:gap-y-5 sm:bg-white
			md:flex md:flex-col md:mx-auto md:justify-center md:items-center md:gap-y-5 md:bg-white
			lg:flex lg:flex-col lg:mx-auto lg:justify-center lg:items-center lg:gap-y-5 lg:bg-white lg:ml-[1rem] lg:w-[498px] lg:h-[137px]
			xl:flex xl:flex-col xl:mx-auto xl:justify-center xl:items-center xl:gap-y-5 xl:bg-white xl:ml-[1rem] xl:w-[498px] xl:h-[137px] mt-[3rem]
		
		
		"
      >
        <div className="xl:self-start lg:self-start md:self-start sm:self-center">
          <span className="font-bold font-manrope text-4xl  text-black">
            A <span className="text-primaryColor2">Sagacious </span>Board
          </span>
        </div>
        <div className="lg:self-start md:self-start sm:self-center">
          <span className="font-manrope text-sm">
            The powerhouse of the brand, the board not only provides advisory
            and oversight direction. They are the authoritative industry
            insiders with the know-how, industry insight and brand wisdom to
            steer the company to its assured destination.
          </span>
        </div>
      </div>

      <div
        className="
		xs:grid xs:grid-cols-5 xs:gap-x-[13.2rem] xs:overflow-x-auto xs:gap-y-3 xs:col-end 
		sm:grid sm:grid-cols-5 sm:gap-x-[13.2rem] sm:overflow-x-auto sm:gap-y-3 sm:col-end 
		md:grid md:grid-cols-5 md:gap-x-[13.2rem] md:ml-[6rem] md:gap-y-3 md:overflow-hidden
		lg:grid lg:grid-cols-3 lg:gap-x-[12rem] lg:gap-y-3 lg:overflow-x-auto lg:ml-[0.4rem]
		xl:grid xl:grid-cols-3 xl:gap-x-[3rem] xl:gap-y-3 xl:overflow-x-auto xl:ml-[0.4rem]
		
		
		"
      >
        {BoardTeam &&
          BoardTeam.map((board, i) => (
            <>
              <BoardCard
                brd={board}
                index={i}
                open={handleOpenModal}
                handleClick={handleModalOpen}
              />
              {openModal ? (
                <ModalBoard
                  index={i}
                  brd={activeMember}
                  close={handleCloseModal}
                />
              ) : (
                ""
              )}
            </>
          ))}
      </div>
    </div>
  );
}
