import React, { useId, useState } from "react";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import { tail } from "../common/utils/helper";

interface props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  lclassName?: string;
  containerClassName?: string;
  errorLabel?: string;
  cref?: React.LegacyRef<HTMLInputElement>;
  tip?: string;
  rules?: RegExp;
  haserror?: string;
}

function CustomInput(props: props) {
  const {
    label,
    type,
    placeholder,
    name,
    value,
    onChange,
    required,
    onBlur,
    className,
    containerClassName,
    errorLabel,
    lclassName,
    cref,
    tip,
    rules,
    haserror,
  } = props;
  const id = useId();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div
      className={`form_group ${containerClassName ? containerClassName : ""}`}
    >
      <label
        className={`text-gray-30/80 mb-2 inline-block text-sm font-normal ${
          lclassName ? lclassName : ""
        }`}
        htmlFor={`${id}-${name}`}
      >
        {label}
        {/* {tip && <CustomTooltip tip={tip} />} */}
      </label>
      <div className="relative">
        {type === "password" && (
          <div
            className="absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer text-black"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
          </div>
        )}
        <input
          {...props}
          className={tail(
            "border-[#D9D9D9] h-11 w-full rounded border pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600",
            name === "password" ? "pr-12" : "",
            haserror === "true" ? "border-red-400" : "",
            className
          )}
          id={`${id}-${name}`}
          type={showPassword ? "text" : type}
          placeholder={placeholder}
          onBlur={onBlur}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          ref={cref}
          data-rules={rules}
        />
        {haserror === "true" || errorLabel ? (
          <p className="mt-1 text-right text-xs text-red-40">
            *{errorLabel ? errorLabel : "Invalid Input Value"}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default CustomInput;
