import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { logUserIn } from "../store/auth/thunk";
import { useAppDispatch, useAppSelector } from "../store";
import routes from "../common/routes";
import lastImage from "../assets/lastImage.png";

const initialstate = {
  email: "",
  password: "",
};

const SignIn = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(initialstate);
  const { loading: isLoading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.data) return;

    (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
      setData((prev) => ({
        ...prev,
        [key]: state?.data[key] ? state?.data[key] : initialstate[key],
      }));
    });
  }, []); //eslint-disable-line

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body: any = { ...data };

    dispatch(logUserIn(body))
      .unwrap()
      .then((res) => {
        if (state?.prevPathname) {
          navigate(state?.prevPathname, { replace: true });
          state?.action();
        } else {
          navigate(routes.profile);
        }
      });
  };

  return (
    <form
      className="flex flex-col justify-center gap-y-4.5 lg:py-8 py-12 h-screen overflow-y-scroll px-8 lg:pr-20"
      onSubmit={onSubmit}
    >
      <h4 className="text-3xl font-bold self-start pb-4.5">Sign in</h4>

      <div className="flex flex-col gap-y-2">
        <div className="">
          <label className="font-poppins text-xs font-normal after:content-['_*'] after:text-red-600">
            Username or email address
          </label>
        </div>
        <div>
          <input
            type=""
            className="w-full px-2 rounded-lg border border-[#482121] h-14"
            value={data.email}
            name="email"
            onChange={changeHandler}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="">
          <label className="font-poppins text-xs font-normal after:content-['_*'] after:text-red-600">
            Your Password
          </label>
        </div>
        <div>
          <input
            type="password"
            className="w-full px-2 rounded-lg border border-[#482121] h-14"
            value={data.password}
            name="password"
            onChange={changeHandler}
            disabled={isLoading}
          />
        </div>
        <div className="self-end">
          <Link
            to={routes.nonprotected.forgot_password}
            className="text-xs underline text-primaryColor2 font-poppins"
          >
            Forgot your Password
          </Link>
        </div>
      </div>
      <div className="flex flex-col justify-start gap-y-2">
        {isLoading ? (
          <PropagateLoader color="#17B28D" size={30} />
        ) : (
          <button className="text-white bg-primaryColor1 lg:self-start  py-4 px-28 rounded-2xl font-poppins font-bold">
            Sign in
          </button>
        )}
        <p className="font-poppins text-xs">
          Don't have an account?{" "}
          <Link
            to={routes.nonprotected.register}
            className="text-primaryColor2 "
          >
            Sign Up
          </Link>
        </p>
      </div>
      <div
        className="
			flex flex-col justify-start
				"
      >
        <img src={lastImage} alt="" className="w-[324px] h-40" />
      </div>
    </form>
  );
};
export default SignIn;
