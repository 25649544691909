import { useState, useEffect } from "react";
import Nav from "../components/Nav";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { developType } from "../typings";
import { API /* PAYMENT_PUBLIC */ } from "../utils/backend";
import { PropagateLoader } from "react-spinners";
import { ModalDevelop } from "../components/ModalDevelop";
import Swal from "sweetalert2";
import Special from "../assets/special.png";
import Button from "../components/Button";
import { localCurrency, validationRules } from "../common/utils/helper";
import { useAppSelector } from "../store";
import Amenities from "../common/components/Amenities";

const initialstate = {
  develop_id: "",
  email: "",
  name: "",
  phone_number: "",
  inspection: "",
  quantity: 0,
  date: "",
  time: "",
};
// const initialConfig = {
//   public_key: PAYMENT_PUBLIC,
//   tx_ref: "",
//   amount: 0,
//   currency: "NGN",
//   payment_options: "card,mobilemoney,ussd",
//   customer: {
//     email: "",
//     phone_number: "",
//     name: "",
//   },
//   customizations: {
//     title: "LandShop",
//     description: "",
//     logo: "https://www.landshop.ng/img/landshoplogo.png",
//   },
// };

export const GreenExtraProduct = () => {
  const [inData, setInData] = useState(initialstate);
  const [loading, setLoading] = useState<boolean>(false);
  const [developInfo, setDevelopInfo] = useState<developType | null>(null);
  const [imgLink, setImgLink] = useState<string>("");
  const [imgStatus, setImgStatus] = useState<boolean>(true);
  const [modal, setModal] = useState<boolean>(false);
  const devId = localStorage.getItem("developId");
  const params = useParams();
  // const [generateRef, { isLoading }] = useRefDevelopProperiesMutation();
  const user = useAppSelector((state) => state.auth?.user);
  // const [config, setConfig] = useState(initialConfig);

  // const handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    if (!user?._id) return;
    (Object.keys(inData) as Array<keyof typeof inData>).forEach((key) => {
      switch (key) {
        case "name":
          setInData((prev) => ({
            ...prev,
            [key]: `${user.first_name}${
              user.middle_name ? ` ${user.middle_name}` : ""
            } ${user.last_name}`,
          }));
          break;

        default:
          setInData((prev) => ({
            ...prev,
            [key]: user[key] ? user[key] : "",
          }));
          break;
      }
    });
  }, [user?._id]); //eslint-disable-line

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // console.log(params.id, devId);

  // useEffect(() => {
  //   const customer = config.customer;
  //   (Object.keys(customer) as Array<keyof typeof customer>).forEach((key) => {
  //     setConfig((prev) => ({
  //       ...prev,
  //       customer: { ...prev.customer, [key]: inData[key] },
  //     }));
  //   });
  // }, [JSON.stringify(inData)]);

  const navigate = useNavigate();
  //eslint-disable-next-line
  const goToPromoPage = () => {
    navigate("/20daysofchristmas");
  };

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "phone_number":
        if (value.length > 0 && validationRules.number.test(value)) {
          setInData((prev) => ({ ...prev, [name]: value }));
        }
        if (value.length === 0) {
          setInData((prev) => ({ ...prev, [name]: value }));
        }
        break;

      default:
        setInData((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };
  const funcIncrease = () => {
    setInData((prev) => ({ ...prev, quantity: prev.quantity + 1 }));
  };

  const funcDecrease = () => {
    if (inData.quantity === 0) return;
    setInData((prev) => ({ ...prev, quantity: prev.quantity - 1 }));
  };

  //eslint-disable-next-line
  const handleGenerateRef = () => {
    // if (!devId) return;
    // setLoading(true);
    // generateRef({ id: devId })
    //   .unwrap()
    //   .then((refRes) => {
    //     setConfig((prev) => ({ ...prev, tx_ref: refRes.reference }));
    //     handleFlutterPayment({
    //       callback: (response) => {
    //         console.log(response);
    //         setModal(false);
    //         setInData({ ...initialstate });
    //       },
    //       onClose: () => {
    //         setModal(false);
    //         setInData({ ...initialstate });
    //       },
    //     });
    //   })
    //   .catch((err) => console.error(err))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  useEffect(() => {
    if (typeof devId === "string") {
      setInData((prev) => ({ ...prev, develop_id: devId }));
    }
  }, [devId, modal]);

  const submitDevelopForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${API}submit/develop/form`, inData);
      if (response.status === 200) {
        // handleGenerateRef();
        setModal(false);
        setInData({ ...initialstate });
        Swal.fire({
          icon: "success",
          title: response.data.status,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
      });
    } finally {
      setLoading(false);
    }
  };

  // const goToBuyProducts = () => {
  //    navigate('/buygreenproducts')
  // }

  const handleModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => setModal(false);

  const handleClick = (imageLink: string) => {
    console.log(imageLink);
    setImgLink(imageLink);
    setImgStatus(false);
  };

  const getDevelopInfo = async () => {
    setLoading(true);
    try {
      const response: developType = await axios.get(
        `${API}develops/${params.id}`
      );

      if (response.data.data.develop) {
        setDevelopInfo(response);
        // setConfig((prev) => ({
        //   ...prev,
        //   customizations: {
        //     ...prev.customizations,
        //     description: `Payment for LandShop ${response.data.data.develop.property_name} @ ${response.data.data.develop.address}`,
        //   },
        //   amount: parseInt(response.data.data.develop.current_price),
        // }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const slideLeft = () => {
  // 	console.log("i am here")
  // 	let slider: HTMLElement = document.getElementById('slider')!
  // 	slider.scrollLeft = slider.scrollLeft - 500
  //   }

  //   const slideRight = () => {
  // 	let slider: HTMLElement = document.getElementById('slider')!
  // 	slider.scrollLeft = slider.scrollLeft + 500
  //   }

  useEffect(() => {
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
    getDevelopInfo();
  }, []); //eslint-disable-line

  return (
    <div className="h-auto ">
      {/* <div className="nav-slideshow">
        <div onClick={goToPromoPage} className="mover-1"></div>
      </div> */}
      <Nav />
      <div className="px-4 md:px-8">
        <Link to="/develop">
          <Button myStyle="m-3 bg-primaryColor1 px-6 py-2 rounded-md text-white">
            Back
          </Button>
        </Link>

        <div className="lg:mt-[6rem] mt-[2rem] w-full">
          <div className="relative">
            <div className="absolute top-0 xs:left-[2px]  lg:left-[122px] ">
              <img
                className="xs:w-[70px] xs:h-[auto] lg:w-[131px] "
                src={Special}
                alt=""
              />
            </div>
            {imgStatus ? (
              <img
                className=" xl:h-[45rem] lg:h-[39rem] md:h-[22rem] object-cover w-full h-[15rem] cursor-pointer"
                src={developInfo?.data?.data?.develop?.images[0]}
                alt=""
              />
            ) : (
              ""
            )}

            {!imgStatus ? (
              <img
                className="xl:w-[80%] xl:h-[39rem]  lg:w-[100%] lg:h-[39rem]  md:w-[100%] md:mx-auto md:h-[22rem] w-[401px] h-[237px] cursor-pointer "
                src={imgLink}
                alt=""
              />
            ) : (
              ""
            )}
          </div>

          <div className="flex items-center justify-center relative">
            <div
              id="slider"
              className=" flex justify-start gap-x-3 mt-[3rem] ml-[0.5rem] overflow-auto"
            >
              {loading ? (
                <PropagateLoader color="#17B28D" size={30} />
              ) : (
                developInfo?.data?.data?.develop?.images
                  .slice(1)
                  .map((image, i) => (
                    <img
                      key={i}
                      onClick={() => handleClick(image)}
                      className="xl:w-[187px] xl:h-[120px] xl:cursor-pointer 
										lg:w-[187px] lg:h-[120px] lg:cursor-pointer 
										md:w-[187px] md:h-[120px] md:cursor-pointer 
										sm:w-[162.96px] sm:h-[100px]  
										xs:w-[162.96px] xs:h-[100px] 
										"
                      src={image}
                      alt=""
                    />
                  ))
              )}
            </div>
          </div>
        </div>

        <div className="xl:flex-row xl:h-[70rem] xl:mt-[1rem] xl:justify-center lg:mt-[9rem] sm:mt-[5rem] flex flex-col gap-y-4 gap-x-6 py-11">
          <div className="xl:h-[879px] bg-white p-3 shadow-2xl rounded-md">
            {" "}
            {/* w-[623.59px] h-[1180px] */}
            <div className=" xl:mt-[-5rem] flex flex-col gap-y-3 items-center justify-center mt-[2rem] ml-[-2.4rem]">
              {" "}
              {/* w-[540.59px] */}
              <div className="flex flex-col items-center justify-start gap-y-[0.1rem] ml-6 mb-3 relative top-[-20px]">
                <div className="xl:self-start sm:self-center self-center lg:self-center">
                  <span className=" text-sm md:text-2xl font-bold text-primaryColor2 font-manrope ">
                    Before :{" "}
                    <span className="text-black line-through">
                      {developInfo?.data?.data?.develop?.previous_price &&
                      parseInt(developInfo?.data?.data?.develop?.previous_price)
                        ? localCurrency(
                            parseInt(
                              developInfo?.data?.data?.develop?.previous_price
                            )
                          )
                        : null}{" "}
                      /Plot
                    </span>
                  </span>
                </div>
                <div className="xl:self-start sm:self-center xs:self-center  lg:self-center mb-4">
                  <span className="xs:text-sm md:text-2xl font-bold text-primaryColor1 font-manrope">
                    Now :{" "}
                    <span className="xs:text-black">
                      {developInfo?.data?.data?.develop?.current_price &&
                      parseInt(developInfo?.data?.data?.develop?.current_price)
                        ? localCurrency(
                            parseInt(
                              developInfo?.data?.data?.develop?.current_price
                            )
                          )
                        : null}{" "}
                      /Plot
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-y-2 lg:px-10">
              <div className="xl:flex-row xl:justify-around xl:gap-x-[20rem] flex flex-col items-center justify-center gap-y-4 ">
                <div>
                  <button className="bg-primaryColor1 text-white font-urbanist py-2 px-2 rounded-md">
                    Promo Sale
                  </button>
                </div>
                {/* <div>
										<img src={greenBarge} className ="lg:block sm:hidden" alt="" />
									</div> */}
              </div>
              <div className="flex flex-col items-center justify-center gap-y-3 mt-5">
                <div className="xl:self-start self-center">
                  <span className="text-2xl font-bold text-primaryColor2 font-poppins">
                    {developInfo?.data?.data?.develop?.property_name}
                  </span>
                </div>
                <div className="xl:w-[584px] xl:h-[209px] w-full h-[209px] ">
                  <div className="mb-2 font-poppins text-xl">Description</div>
                  <span className="xl:text-sm text-xs font-normal text-black-300 font-poppins">
                    {developInfo?.data?.data?.develop?.description}
                  </span>
                </div>
              </div>

              <div className="xl:w-[623.59px]lg:ml-[-1rem] lg:mt-0 sm:mt-[7rem] flex flex-col items-start justify-start gap-y-1 self-start">
                <span className="font-bold md:text-lg xs:text-sm font-poppins">
                  Title:
                  <span className="ml-2">
                    {" "}
                    {developInfo?.data?.data?.develop?.title}
                  </span>
                </span>

                <div className="xl:flex-row xl:gap-x-[9rem] xl:mt-[-2rem] lg:gap-y-[2rem] sm:gap-y-[1rem] flex flex-col items-center justify-center gap-y-[1rem]">
                  <div className="xl:mt-[3.5rem] bg-white  ">
                    <div className="font-bold text-lg lg:text-2xl font-manrope mb-2">
                      Estate Features
                    </div>
                    <div className="grid grid-cols-4 md:px-6 px-3 ">
                      {developInfo?.data.data.develop.estate_features?.slice(0, 4)?.map((feature, id) => (
                        <div key={id} className="flex flex-col items-center ">
                          <Amenities type={feature} />
                          <p className="text-[10px] text-center capitalize whitespace-pre-wrap">
                            {feature}
                          </p>
                        </div>
                      ))}
                    </div>
                    {/* {developInfo?.data.data.develop.estate_features?.map(
                      (happy, idx) => {
                        return (
                          <li
                            key={idx}
                            className="font-thin text-sm font-poppins"
                          >
                            {happy}
                          </li>
                        );
                      }
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center gap-y-1 p-2">
            <div className="w-full xl:h-[304.46px] bg-white shadow-2xl rounded-md flex flex-col items-center justify-center space-y-3 p-3">
              <div className="self-center">
                <span className="font-bold text-xl font-manrope ">
                  Neighbourhood Attraction
                </span>
              </div>

              <div>
                <div className="xl:rounded-md xl:h-[17rem] bg-white overflow-auto ">
                  {developInfo?.data.data.develop.neighborhood?.map(
                    (houses, idx) => (
                      <li key={idx} className="font-thin text-sm font-poppins">
                        {houses}
                      </li>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="xl:w-[621.55px] xl:h-[249.13px] xl:space-y-5  w-full  bg-white shadow-2xl rounded-md flex flex-col items-center justify-center space-y-10 p-3 ">
              <div className="xl:w-[92.85px] self-center w-[86.14px]">
                <span className="font-bold text-2xl font-manrope mt-2">
                  Location
                </span>
              </div>

              <div className="xl:flex-row xl:gap-x-[5rem] lg:gap-y-6 md:gap-y-3 flex flex-col items-center justify-center gap-y-6">
                <div className="flex flex-col items-center justify-center gap-y-[1.4rem]">
                  <div className="flex items-center justify-center gap-x-10">
                    <div>
                      <span className="font-thin text-sm font-manrope">
                        Address:
                      </span>
                    </div>
                    <div>
                      <span className="font-thin text-sm font-poppins">
                        {developInfo?.data.data.develop?.address}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-center gap-x-10 ml-[-3rem]">
                    <div>
                      <span className="font-thin text-sm font-manrope">
                        Country:
                      </span>
                    </div>
                    <div>
                      <span className="font-thin text-sm font-poppins">
                        {/* {developInfo?.data.data.develop?.city} */}Nigeria
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-center gap-x-10 ml-[-3rem]">
                    <div>
                      <span className="font-thin text-sm font-manrope">
                        City/Town:
                      </span>
                    </div>
                    <div>
                      <span className="font-thin text-sm font-poppins">
                        {developInfo?.data.data.develop?.city}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>

            <div className="xl:h-[312.89px] xl:space-y-10 lg:space-y-12 w-full bg-white shadow-2xl rounded-md flex flex-col items-center justify-center space-y-10">
              <div className="self-center">
                <span className="font-bold text-2xl font-manrope">
                  Features
                </span>
              </div>

              <div className="flex flex-col items-center justify-center gap-x-[5rem]">
                <div className="flex flex-col items-center justify-center gap-y-[1.4rem]">
                  <div className="flex items-center justify-center gap-x-5">
                    <div>
                      <span className="font-thin text-sm font-manrope ml-4">
                        Size:
                      </span>
                    </div>
                    <div>
                      <span className="font-thin text-sm font-manrope">
                        {developInfo?.data?.data?.develop?.size}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-center gap-x-10">
                    <div>
                      <span className="font-thin text-sm font-manrope">
                        Price:
                      </span>
                    </div>
                    <div>
                      <span className="font-thin text-sm font-poppins">
                        {developInfo?.data?.data?.develop?.current_price &&
                        parseInt(
                          developInfo?.data?.data?.develop?.current_price
                        )
                          ? localCurrency(
                              parseInt(
                                developInfo?.data?.data?.develop?.current_price
                              )
                            )
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-center gap-x-5">
                    <div>
                      <span className="font-thin text-sm font-poppins ml-4">
                        Use:
                      </span>
                    </div>
                    <div>
                      <span className="font-thin text-sm fontpoppinse">
                        {developInfo?.data?.data?.develop?.use}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-center gap-x-10">
                    <div>
                      <span className="font-thin text-sm font-poppins">
                        Property Status:
                      </span>
                    </div>
                    <div>
                      <span className="font-thin text-sm font-poppins">
                        {developInfo?.data?.data?.develop?.property_status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:mt-[-8rem] lg:mt-[5rem] md:mb-[2rem] flex items-center justify-center mt-[-16rem] mb-[5rem]">
          <button
            onClick={handleModal}
            className='lg:mt-[4rem] data-modal-toggle="defaultModal sm:mt-[19rem] bg-transparent border border-primaryColor1 text-black py-2 px-5 rounded-md cursor-pointer mt-[24rem]'
          >
            Book an Inspection 
          </button>
        </div>

        {/* Form */}

        {modal ? (
          <ModalDevelop close={handleCloseModal}>
            <form
              className="flex items-center justify-center flex-col"
              onSubmit={submitDevelopForm}
            >
              <div className="flex items-center justify-center pt-5">
                {developInfo?.data?.data?.develop?.type === "land" ? (
                  <h3 className="text-2xl font-urbanist text-[#494E50] font-bold">
                    Land Development Form
                  </h3>
                ) : (
                  <h3 className="text-2xl font-urbanist text-[#494E50] font-bold">
                    House Development Form
                  </h3>
                )}
              </div>

              <div className=" xl:w-[647.41px] md:w-[400.41px] flex items-center justify-center w-[247.41px] h-[106.33px] after:content-['_*'] after:text-red-600 after:self-start">
                <input
                  type="text"
                  className=" border-[#494E50] border-t-0 border-r-0 border-l-0 focus:ring-0 w-[548px] h-[66px]"
                  value={inData.name}
                  name="name"
                  onChange={changeHandler}
                  placeholder="Name"
                  disabled={loading}
                />
              </div>

              <div className=" xl:w-[647.41px] md:w-[400.41px] flex items-center justify-center w-[247.41px] h-[106.33px] after:content-['_*'] after:text-red-600 after:self-start">
                <input
                  type="email"
                  className="border-[#494E50] border-t-0 border-r-0 border-l-0 focus:ring-0 w-[548px] h-[66px]"
                  value={inData.email}
                  name="email"
                  onChange={changeHandler}
                  placeholder="Email"
                  disabled={loading}
                />
              </div>
              <div className=" xl:w-[647.41px] md:w-[400.41px] flex items-center justify-center w-[247.41px] h-[106.33px] after:content-['_*'] after:text-red-600 after:self-start">
                <input
                  type="text"
                  className="border-[#494E50] border-t-0 border-r-0 border-l-0 focus:ring-0 w-[548px] h-[66px]"
                  value={inData.phone_number}
                  name="phone_number"
                  onChange={changeHandler}
                  placeholder="Phone Number"
                  disabled={loading}
                />
              </div>
              <div className="lg:ml-[-4rem] sm:flex-row flex flex-col items-start justify-start gap-y-[2rem]">
                <div className="flex flex-col items-center justify-center gap-y-2 mr-12">
                  <div className="self-start">
                    <h6 className="text-md font-poppins">Inspection</h6>
                  </div>
                  <div className="self-start">
                    <select
                      className="bg-transparent  py-3 w-[190px] border border-primaryColor2 focus:ring-0 rounded-md text-poppins"
                      onChange={changeHandler}
                      value={inData.inspection}
                      name="inspection"
                      disabled={loading}
                    >
                      <option
                        className="text-md font-poppins"
                        value="Select an Inspection"
                      >
                        Select an Inspection
                      </option>
                      <option
                        className="text-md font-poppins"
                        value="Book Inspection"
                      >
                        Book Inspection
                      </option>
                      <option
                        className="text-md font-poppins"
                        value="Virtual Inspection"
                      >
                        Virtual Inspection
                      </option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center justify-center gap-x-4 ">
                  <div className="self-start ">
                    <div className="self-start">
                      <h6 className="text-md font-poppins">Quantity</h6>
                    </div>
                    <div className="w-[90px] h-[30px] bg-primaryColor2 rounded-2xl flex items-center justify-center gap-x-3 mt-[0.8rem]">
                      <div className="bg-white w-[24px] h-[24px] rounded-[100%] flex items-center justify-center">
                        <button
                          className="text-lg font-poppins text-primaryColor2 font-bold"
                          onClick={funcIncrease}
                          type="button"
                          disabled={loading}
                        >
                          +
                        </button>
                      </div>
                      <div>
                        <span className="text-lg font-poppins text-white font-bold">
                          {inData.quantity}
                        </span>
                      </div>
                      <div className="bg-white w-[24px] h-[24px] rounded-[100%] flex items-center justify-center">
                        <button
                          className="text-lg font-poppins text-primaryColor2 font-bold"
                          onClick={funcDecrease}
                          type="button"
                          disabled={loading}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span className="text-lg font-poppins text-black font-bold">
                      Units
                    </span>
                  </div>
                </div>
              </div>

              <div className=" lg:ml-[-3.3rem] sm:flex-row sm:ml-[-1.3rem] flex flex-col items-start justify-start mt-[4rem] ml-[1rem] gap-y-[3rem]">
                <div className="after:content-['_*'] after:text-red-600 after:self-start">
                  <input
                    type="date"
                    placeholder="Date"
                    className="px-4 py-2 mr-12 bg-transparent border border-gray-500 w-[170.41px] ml-[-1.2rem]  outline-none"
                    value={inData.date}
                    name="date"
                    onChange={changeHandler}
                    disabled={loading}
                  />
                </div>
                <div className="after:content-['_*'] after:text-red-600 after:self-start">
                  <input
                    type="time"
                    placeholder="Time"
                    className="px-4 py-2 bg-transparent border border-gray-500 w-[170.41px] ml-[-1.2rem]  outline-none"
                    value={inData.time}
                    name="time"
                    onChange={changeHandler}
                    disabled={loading}
                  />
                </div>
              </div>

              <div className="flex items-center justify-center mt-[4rem] pb-5">
                {loading ? (
                  <PropagateLoader color="#17B28D" size={30} />
                ) : (
                  <button
                    className="w-[161px] h-[50px] bg-primaryColor1 rounded-lg text-white disabled:bg-gray-10 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={Object.values(inData).includes("")}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </ModalDevelop>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
