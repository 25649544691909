import React from "react";
import { ModalInvestItem } from "../ModalInvestItem";
import { localCurrency } from "../../common/utils/helper";

type Props = {
  handleClose: VoidFunction;
  img: string;
  propertyName: string;
  propertyPrice: number;
  propertyDescription: string;
  handleContinue: VoidFunction;
};

function ConfirmInvestItem({
  handleClose,
  handleContinue,
  img,
  propertyDescription,
  propertyName,
  propertyPrice,
}: Props) {
  return (
    <ModalInvestItem close={handleClose}>
      <div className="flex items-center justify-center flex-col px-4 py-8">
        <div className="flex items-center justify-between gap-x-8 flex-col gap-y-10 md:flex-row ">
          <div className="w-80 h-80 flex">
            <img className="!w-full !h-full" src={img} alt="property" />
          </div>
          <div className="font-poppins flex flex-col gap-y-2 tracking-wide font-bold">
            <p className="opacity-50">{propertyName}</p>
            <p className="text-primaryColor1">{localCurrency(propertyPrice)}</p>
            <p className="opacity-50 text-justify tracking-normal">
              {propertyDescription}
            </p>
            <div className="flex items-center gap-x-3 tracking-normal text-white [&>button]:px-5 [&>button]:py-3 [&>button]:rounded-lg">
              <button onClick={handleContinue} className="bg-primaryColor1">
                Confirm
              </button>
              <button onClick={handleClose} className="bg-red-600 font-medium">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalInvestItem>
  );
}

export default ConfirmInvestItem;
