import TeamLogo from "../assets/team.png";
import BoardTeamm from "./BoardTeamm";
import FooterAbout from "./FooterAbout";
// import { FooterBlack } from "./FooterBlack";
import PersonTeam from "./PersonTeam";

function Team() {
  return (
    <div
  //     className="
	// xs:bg-white xs:h-[115rem]
	// sm:bg-white sm:h-[120rem]
	// md:bg-white md:h-[115rem]
	// lg:bg-white lg:h-[130rem]
	// xl:bg-white xl:h-[130rem]
	// "
  className="
	xs:bg-white xs:h-[115rem]
	sm:bg-white sm:h-[120rem]
	md:bg-white md:h-[115rem]
	lg:bg-white lg:h-[130rem]
	xl:bg-white xl:h-full
	"
    >
      <div
        className="
		 flex flex-col justify-center items-center gap-y-10"
      >
        <img
          src={TeamLogo}
          alt=""
          className="w-[700.13px] h-[158px] xs:mt-[3rem]"
        />

        <span className="font-bold font-urbanist text-black text-3xl">
          Our Team
        </span>

        <PersonTeam />
        <BoardTeamm />
        {/* <div
          className="
			lg:mt-[18rem]
			"
        >
          <FooterBlack />
        </div> */}
      </div>
    </div>
  );
}

export default Team;
