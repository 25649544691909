import { useEffect, useState } from "react";
import { FooterBlack } from "../components/FooterBlack";
import Nav from "../components/Nav";
import PopularCard from "../components/PopularCard";
import RecentCard from "../components/RecentCard";
import WPAPI from 'wpapi';
import parse from 'html-react-parser';
import { PropagateLoader } from "react-spinners";

interface Post {
  title: { rendered: string }
  content: { rendered: string }
  rttpg_author: { display_name: string }
  excerpt: { rendered: string }
  date: string,
  link: string,
  rttpg_featured_image_url: string
}

export default function Blog() {

  const [posts, setPosts] = useState<Post[]>();
  const [latestPost, setLatestPost] = useState<Post>();
  const [loading, setLoading] = useState<boolean>(true);


  const fetchBlog = () => {

    const apiPromise = new WPAPI({
      username: 'admin',
      password: 'Administrator1!',
      endpoint: 'https://blog.landshop.ng/wp-json'
    })

    // site is now configured to use authentication
    apiPromise.posts().get(function (err, data) {
      if (err) {
        console.log(err)
      }
      setPosts(data);
      setLatestPost(data[0]);
      setLoading(false);

    });

  }
  useEffect(() => {
    fetchBlog()
  }, [])
  return (
    <div className=" lg:h-[123rem] xl:h-[124rem] xs:h-[187rem] md:h-[184rem]">
      <Nav />

      {/* Latest Post */}
      {loading === true ?
        <div className='col-span-full' >
          <div className="h-full col-span-full flex items-center justify-center">
            <PropagateLoader />
          </div>
          <span className='lg:text-primaryColor1 lg:text-xl lg:font-manrope lg:font-bold'>Fetching ....</span>

        </div>


        : (
          <div>
            <div className="w-[90%] mx-auto h-[auto] rounded-md mt-4 z-10">
              <div className="h-[4rem] border border-primaryColor1 bg-white rounded-md shadow-2xl flex items-center justify-between">
                <div>
                  <span className="text-black font-manrope text-base font-bold ml-5">
                    Lastest Post
                  </span>
                </div>

              </div>
              {latestPost ? (
                <div className="flex ">
                  <div className="lg:bg-bgBlog lg:w-[538px] h-[396px] bg-no-repeat mb-4 mt-[-0.5rem] ml-[-1.3rem] z-15 "></div>
                  <div className=" lg:bg-bgBlogMain lg:w-[1175px] h-[388px] xs:w-full xs:ml-[1.3rem] lg:ml-[0] xs:bg-bgBlogSmall">
                    <div className="flex flex-col items-start lg:ml-[3rem] justify-center gap-y-6 xs:ml-[0] xs:px-3 lg:px-0">
                      <div className="flex flex-col items-center justify-start gap-y-3 mt-[3rem]">
                        <div className=" self-start w-[143.14px] h-[50.14px] mt-4">

                        </div>
                        <div className="self-start">
                          <a href={latestPost.link} target="_blank">
                            <h3 className="text-white text-2xl font-poppins font-bold ">
                              {" "}
                              {latestPost?.title?.rendered}
                            </h3>
                          </a>
                        </div>
                        <div className="self-start">
                          <h3 className="text-white text-xs font-poppins font-normal ">
                            {" "}
                            {latestPost?.rttpg_author.display_name}
                          </h3>
                        </div>
                        <div className="self start lg:w-[612px] h-[114px] xs:w-full">
                          <h6 className="text-white text-sm font-poppins font-thin ">
                            <p>
                              {" "}
                              {parse(latestPost.excerpt.rendered)}
                            </p>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : ""}

            </div>

            {/* Recent Posts */}
            <div
              className="xl:flex xl:items-center xl:justify-evenly xl:gap-x-3 xl:mt-[5rem] xl:flex-row lg:flex-row
		 lg:flex lg:items-center lg:justify-evenly lg:gap-x-1 lg:mt-[5rem] md:flex-col
		"
            >
              <div className="self-start lg:w-[744px] h-[48px] bg-white rounded-md shadow-2xl border border-primaryColor2 lg:mt-[0] lg:ml-[0] xs:ml-[5%] xs:w-[90%] xs:mt-[4rem]">
                <div className="flex items-center justify-between mt-2 px-4">
                  <div className="self-center w-[203px] h-[24px]">
                    <h6 className="text-gray-500 font-poppins font-bold text-base">
                      Recent Posts
                    </h6>
                  </div>

                </div>
                <div className="lg:ml-[0] xs:w-[90%] lg:w-full  xs:ml-[5%] xs:h-[auto] xs:overflow-x-auto xs:mt-[5rem] lg:mt-[0] lg:overflow-x-hidden" >
                  <RecentCard posts={posts} />
                </div>
              </div>

              <div
                className="
		  xl:w-[389px] xl:h-[956px] xl:flex xl:flex-col xl:items-center xl:justify-start xl:gap-y-[3rem] 
		  lg:w-[389px] lg:h-[956px] lg:flex lg:flex-col lg:items-center lg:justify-start lg:gap-y-[3rem] 
		  xs:w-full
		  
		  "
              >
                <div
                  className="xl:w-[387.92px] xl:h-[56.73px] xl:bg-white xl:rounded-md xl:shadow-2xl xl:border xl:border-primaryColor2 xl:flex xl:items-center xl:justify-center
			 lg:w-[287.92px] lg:h-[46.73px]  lg:bg-white lg:rounded-md lg:shadow-2xl lg:border lg:border-primaryColor2 lg:flex lg:items-center lg:justify-center lg:ml-[-1rem]
			 xs:w-full
			 "
                >
                  <div className="xs:mt-[38rem] lg:mt-[0] xs:py-3 lg:py-0 xs:mx-[5%] sm:mx-[20%] xs:mb-5 lg:mb-0 lg:mx-[0] xs:bg-white xs:rounded-md xs:shadow-2xl xs:border xs:border-primaryColor2 xs:flex xs:items-center xs:justify-center lg:border-none">
                    <h5 className="font-urbanist text-lg text-gray-600 font-bold">
                      Popular Stories
                    </h5>
                  </div>
                </div>
                <div className="xs:px-5 md:px-0">
                  <PopularCard />
                </div>
                <div
                  className="xl:w-[389px] xl:h-[450px] xl:bg-white xl:shadow-xl xl:rounded-md xl:mt-[2rem]
			 lg:w-[289px] lg:h-[440px] lg:bg-white lg:shadow-xl lg:rounded-md lg:mt-[2rem] lg:mx-[0]
			 xs:w-[auto] xss:h-[440px] xs:bg-white xs:shadow-xl xs:rounded-md xs:mt-[2rem] xs:mx-[5%] sm:mx-[20%]
			 
			 "
                >
                  <div
                    className="xl:w-[389px] xl:h-[48px] xl:bg-white xl:shadow-2xl xl:rounded-lg xl:flex xl:items-center xl:justify-center
				lg:w-[289px] lg:h-[48px] lg:mx-[0] lg:bg-white lg:shadow-2xl lg:rounded-lg lg:flex lg:items-center lg:justify-center border border-primaryColor1 lg:mt-0
				xs:w-[auto]  xs:h-[48px] xs:bg-white xs:shadow-2xl xs:rounded-lg xs:flex xs:items-center xs:justify-center xs:mt-12
				"
                  >
                    <h3 className="text-gray-500 text-lg font-urbanist font-bold">
                      Category
                    </h3>
                  </div>
                  <div className=" flex flex-col gap-y-12">
                    <div
                      className="xl:flex xl:items-center xl:justify-between xl:mt-4
				  lg:flex lg:items-center lg:justify-between lg:mt-4 px-3
				  xs:flex xs:items-center xs:justify-between xs:mt-4
				  
				  "
                    >
                      <div className="flex items-center justify-center">
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            {">"}
                          </h6>
                        </div>
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            Label1
                          </h6>
                        </div>
                      </div>
                      <div className="">
                        <h6 className="text-gray-600 font-bold font-poppins">(1)</h6>
                      </div>
                    </div>

                    <div
                      className="xl:flex xl:items-center xl:justify-between xl:mt-4
				  lg:flex lg:items-center lg:justify-between lg:mt-4 px-3
				  xs:flex xs:items-center xs:justify-between xs:mt-4
 				  
				  "
                    >
                      <div className="flex items-center justify-center">
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            {">"}
                          </h6>
                        </div>
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            Label1
                          </h6>
                        </div>
                      </div>
                      <div className="">
                        <h6 className="text-gray-600 font-bold font-poppins">(1)</h6>
                      </div>
                    </div>

                    <div
                      className="xl:flex xl:items-center xl:justify-between xl:mt-4
				  lg:flex lg:items-center lg:justify-between lg:mt-4 px-3
				  xs:flex xs:items-center xs:justify-between xs:mt-4 
				  
				  "
                    >
                      <div className="flex items-center justify-center">
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            {">"}
                          </h6>
                        </div>
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            Label1
                          </h6>
                        </div>
                      </div>
                      <div className="">
                        <h6 className="text-gray-600 font-bold font-poppins">(1)</h6>
                      </div>
                    </div>

                    <div
                      className="xl:flex xl:items-center xl:justify-between xl:mt-4
				  lg:flex lg:items-center lg:justify-between lg:mt-4 px-3
				  xs:flex xs:items-center xs:justify-between xs:mt-4
				  
				  "
                    >
                      <div className="flex items-center justify-center">
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            {">"}
                          </h6>
                        </div>
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            Label1
                          </h6>
                        </div>
                      </div>
                      <div className="">
                        <h6 className="text-gray-600 font-bold font-poppins">(1)</h6>
                      </div>
                    </div>

                    <div
                      className="xl:flex xl:items-center xl:justify-between xl:mt-4
				  lg:flex lg:items-center lg:justify-between lg:mt-4 px-3 mb-6
				  xs:flex xs:items-center xs:justify-between xs:mt-4 xs:mb-10
				  
				  "
                    >
                      <div className="flex items-center justify-center">
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            {">"}
                          </h6>
                        </div>
                        <div>
                          <h6 className="text-gray-600 font-bold font-poppins">
                            Label1
                          </h6>
                        </div>
                      </div>
                      <div className="">
                        <h6 className="text-gray-600 font-bold font-poppins">(1)</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }




      <br /><br /><br /><br />
      <FooterBlack />
    </div>
  );
}
