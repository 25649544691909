import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import { PropagateLoader } from "react-spinners";
import DatePicker from "react-date-picker";
import Button from "./Button";
import { ModalConfirmItem } from "./ModalConfirmItem";
import { ModalInvest } from "./ModalInvest";
import { ModalInvestItem } from "./ModalInvestItem";
import { API, PAYMENT_PUBLIC } from "../utils/backend";
import { localCurrency, validationRules } from "../common/utils/helper";
import { InvestHouseCardType, InvestType } from "../typings";
import { useRefInvestProperiesMutation } from "../store/api";
import logo from "../assets/logo.png";
import { useFlutterwave } from "flutterwave-react-v3";
import { storeUserToken } from "../common/service/storage";
import { useAppSelector } from "../store";
import PropertyCard from "../common/components/PropertyCard";

interface IFormInputs {
  first_name: string;
  last_name: string;
}

const initialstate = {
  email: "",
  name: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  phone_number: "",
  date_of_birth: new Date(),
  quantity: 0,
  account_name: "",
  account_number: "",
  bank_name: "",
  duration: 5,
};

const initialConfig = {
  public_key: PAYMENT_PUBLIC,
  tx_ref: "",
  amount: 0,
  currency: "NGN",
  payment_options: "card,mobilemoney,ussd",
  customer: {
    email: "",
    phone_number: "",
    name: "",
  },
  customizations: {
    title: "LandShop",
    description: "",
    logo: "https://www.landshop.ng/img/landshoplogo.png",
  },
};

export const InvestEachHouse = (props: InvestType) => {
  const [instate, setInstate] = useState(initialstate);
  const [modal, setModal] = useState<boolean>(false);
  const [picmodal, setPicModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");
  const user = useAppSelector((state) => state.auth?.user);
  const [generateRef, { isLoading }] = useRefInvestProperiesMutation();
  const [config, setConfig] = useState(initialConfig);
  const handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    if (!user?._id) return;
    (Object.keys(instate) as Array<keyof typeof instate>).forEach((key) => {
      switch (key) {
        case "name":
          setInstate((prev) => ({
            ...prev,
            [key]: `${user.first_name} ${user.middle_name} ${user.last_name}`,
          }));
          break;
        case "date_of_birth":
          if (user[key]) {
            const [unformated_dob]: any[] = user[key].split("T");
            setInstate((prev) => ({ ...prev, [key]: unformated_dob }));
          }
          break;

        default:
          setInstate((prev) => ({
            ...prev,
            [key]: user[key] ? user[key] : "",
          }));
          break;
      }
    });
  }, [user?._id]);

  useEffect(() => {
    const customer = config.customer;
    (Object.keys(customer) as Array<keyof typeof customer>).forEach((key) => {
      setConfig((prev) => ({
        ...prev,
        customer: { ...prev.customer, [key]: instate[key] },
      }));
    });
  }, [JSON.stringify(instate)]);

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "phone_number":
      case "account_number":
        if (value.length > 0 && validationRules.number.test(value)) {
          setInstate((prev) => ({ ...prev, [name]: value }));
        }
        if (value.length === 0) {
          setInstate((prev) => ({ ...prev, [name]: value }));
        }
        break;

      default:
        setInstate((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const counterHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value, name } = e.currentTarget;

    if (name === "quantity") {
      switch (true) {
        case value === "+":
          setInstate((prev) => ({ ...prev, [name]: prev[name] + 1 }));
          break;
        case value === "-" && instate.quantity !== 0:
          setInstate((prev) => ({ ...prev, [name]: prev[name] - 1 }));
          break;
        default:
          break;
      }
    }
  };

  const handleSelect = (e: any) => {
    setSelected(e.target.value);
    console.log(selected);
  };

  const handleModal = () => setModal(true);
  const handleCloseModal = () => {
    setModal(false);
    setConfirmModal(false);
    setInstate({ ...initialstate });
  };

  const handlePicModal = () => setPicModal(true);
  const handleClosePicModal = () => setPicModal(false);

  const handleConfirmModal = () => setConfirmModal(true);
  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
    setInstate({ ...initialstate });
  };

  const closeModalHandler = () => {
    setConfirmModal(false);
    setInstate({ ...initialstate });
  };

  const handleGenerateRef = () => {
    if (!props.data._id) return;
    setLoading(true);
    generateRef({ id: props.data._id })
      .unwrap()
      .then((refRes) => {
        setConfig((prev) => ({
          ...prev,
          tx_ref: refRes.reference,
          amount: props.data.current_price,
          customizations: {
            ...prev.customizations,
            description: `Payment for LandShop ${props.data.property_name} @ ${props.data.address}`,
          },
        }));
        handleFlutterPayment({
          callback: (response) => {
            console.log(response);
            setModal(false);
            setPicModal(false);
            setConfirmModal(false);
            setInstate({ ...initialstate });
          },
          onClose: () => {
            setModal(false);
            setPicModal(false);
            setConfirmModal(false);
            setInstate({ ...initialstate });
          },
        });
      })
      .catch((err) => {
        console.error(err);
        closeModalHandler();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExternalCheckout = async () => {
    if (!user?._id) {
      setLoading(true);
      try {
        const body: any = { ...instate };
        delete body.loading;
        const response = await axios.post(`${API}auth/register/`, body);
        if (response.status === 201) {
          storeUserToken(response?.data?.data?.jwt?.token);
          handleGenerateRef();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
      return;
    }
    handleGenerateRef();
  };

  const onSubmitInvestHouseForm: SubmitHandler<IFormInputs> = async (data) => {
    setLoading(true);
    try {
      const body = {
        ...instate,
        invest_id: props.data._id,
        name: `${instate.first_name} ${instate.middle_name} ${instate.last_name}`,
        // duration: parseInt(instate.duration),
      };

      const response = await axios.post(`${API}submit/invest/form/`, body);
      if (response.status === 200) {
        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Successful",
        });
        setConfirmModal(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>();

  return (
    <>
      {/* <div
        className="lg:pb-12 bg-white rounded-lg shadow-2xl place-content-center pb-4"
        key={props.data._id}
      >
        <div>
          <img
            className="w-full max-h-56 object-cover"
            src={props.data.images[0]}
            alt=""
          />
        </div>
        <div className="flex items-center justify-center mt-4 bg-gray-50">
          <span className="font-bold text-lg text-center">
            {props.data.title}
          </span>
        </div>

        <div className="flex items-center font-urbanist justify-between p-4 font-medium text-sm">
          <p className="text-inherit">
            Previous
            <span className="text-primaryColor2">
              {props.data.previous_price
                ? ` ${localCurrency(props.data.previous_price)}`
                : ` ???`}
            </span>
          </p>
          <p className="text-inherit">
            Current
            <span className="text-primaryColor1">
              {props.data.current_price
                ? ` ${localCurrency(props.data.current_price)}`
                : ` ???`}
            </span>
          </p>
        </div>

        <div className="flex items-center justify-center p-4">
          <Button
            onClick={handlePicModal}
            myStyle="bg-transparent border w-full border-black justify-center py-2 rounded-xl text-black font-bold font-urbanist  flex items-center gap-x-4"
          >
            Invest
          </Button>
        </div>
      </div> */}
      <PropertyCard
        features={props.data.estate_features}
        format={props.data.format}
        images={props.data.images}
        location={props.data.city}
        name={props.data.property_name}
        size={props.data.size}
        cardBtnClick={handlePicModal}
      />

      {modal ? (
        <ModalInvest close={handleCloseModal}>
          <div className="flex items-center justify-center flex-col">
            <form onSubmit={handleSubmit(onSubmitInvestHouseForm)}>
              <div className="flex items-center justify-center pt-5 pb-5">
                <h3 className="text-xl font-poppins text-[#494E50] font-medium opacity-50 px-4 py-8">
                  Please Complete your profile to proceed
                </h3>
              </div>

              <div className="flex items-center justify-center px-4 flex-col gap-y-4">
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    First Name
                  </label>
                  <input
                    required
                    type="text"
                    className=" border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                    value={instate.first_name}
                    name="first_name"
                    onChange={changeHandler}
                  />
                </div>
                <div className="flex w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 ">
                    Middle Name
                  </label>
                  <input
                    type="text"
                    className=" border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                    value={instate.middle_name}
                    name="middle_name"
                    onChange={changeHandler}
                  />
                </div>
                <div className="flex w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Last Name
                  </label>
                  <input
                    required
                    type="text"
                    className=" border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                    value={instate.last_name}
                    name="last_name"
                    onChange={changeHandler}
                  />
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Email
                  </label>
                  <input
                    required
                    type="email"
                    className=" border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                    value={instate.email}
                    name="email"
                    onChange={changeHandler}
                  />
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Phone
                  </label>
                  <input
                    required
                    type="text"
                    className=" border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                    value={instate.phone_number}
                    name="phone_number"
                    onChange={changeHandler}
                  />
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Date of Birth
                  </label>
                  <div>
                    {/* <DatePicker onChange={dateChange} value={dateValue} /> */}
                    <input
                      type="date"
                      value={instate.date_of_birth as any}
                      name="date_of_birth"
                      onChange={changeHandler}
                      className="placeholder-opacity-50 border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                    />
                  </div>
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Duration of Investment (in years)
                  </label>
                  <select
                    id="countries"
                    name="duration"
                    value={instate.duration}
                    onChange={changeHandler}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-3 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option disabled value=""></option>
                    <option value="5">5</option>
                    {/* <option value="6">6</option> */}
                  </select>
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Quantity
                  </label>
                  <div className="bg-primaryColor2 w-48 h-10 rounded-full flex items-center justify-center gap-x-8">
                    <div>
                      <button
                        className="bg-white  p-3 text-lg"
                        onClick={counterHandler}
                        value="-"
                        name="quantity"
                        type="button"
                      >
                        -
                      </button>
                    </div>
                    <div>
                      <span className="text-white">{instate.quantity}</span>
                    </div>
                    <div>
                      <button
                        className=" bg-white  p-3 text-lg"
                        onClick={counterHandler}
                        value="+"
                        name="quantity"
                        type="button"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    How often would you like to get your payout?
                  </label>
                  <select
                    required
                    onChange={handleSelect}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-3 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Choose an Option</option>
                    <option value="Yearly">Yearly</option>
                    <option value="2 Years">2 Years</option>
                    <option value="3 Years">3 Years</option>
                    <option value="4 Years">4 Years</option>
                    <option value="5 Years">5 Years</option>
                  </select>
                </div>
                <p className="text-[#494E50] font-poppins opacity-50 font-medium sm:text-lg text-sm">
                  Please provide details of your financial institution where you
                  would like to receive your investment returns
                </p>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Account Name
                  </label>
                  <input
                    required
                    type="text"
                    className=" border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                    value={instate.account_name}
                    name="account_name"
                    onChange={changeHandler}
                  />
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Account Number
                  </label>
                  <input
                    required
                    type="text"
                    className=" border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                    value={instate.account_number}
                    name="account_number"
                    onChange={changeHandler}
                  />
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Bank Name
                  </label>
                  <input
                    required
                    type="text"
                    className=" border-[#D9D9D9] border focus:ring-0 w-full px-3 py-4 rounded-md"
                    value={instate.bank_name}
                    name="bank_name"
                    onChange={changeHandler}
                  />
                </div>
                <div className="flex  w-full flex-col gap-y-2">
                  <label className="text-[#494E50] font-poppins opacity-50 after:content-['_*'] after:text-red-600">
                    Mode of Identification
                  </label>
                  <select
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-3 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Choose a mode of identification</option>
                    <option value="2">NIN</option>
                    <option value="3">International Passport</option>
                    <option value="4">Driver's License</option>
                  </select>
                </div>
                <div className="flex items-center justify-center gap-x-3">
                  <input
                    required
                    type="checkbox"
                    className="border-primaryColor1 rounded-sm"
                  />
                  <p className="inline-flex items-center text-[8px] gap-x-0.5 md:text-[10px] lg:text-base lg:gap-x-1">
                    By creating an account, you agree to the
                    <span className="text-primaryColor1 underline">
                      Terms of use
                    </span>
                    and
                    <span className="text-primaryColor2 underline">
                      Privacy Policy
                    </span>
                  </p>
                </div>
                <div className="flex items-center justify-center pt-8 pb-5">
                  {loading ? (
                    <div className="py-4">
                      <PropagateLoader color="#17B28D" size={30} />
                    </div>
                  ) : (
                    <input
                      type="submit"
                      value="Create Now"
                      className="w-40 h-12 bg-primaryColor1 rounded-lg text-white cursor-pointer"
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </ModalInvest>
      ) : null}

      {picmodal ? (
        <ModalInvestItem close={handleClosePicModal}>
          <div className="flex items-center justify-center flex-col px-4 py-8">
            <div className="flex items-center justify-between gap-x-8 flex-col gap-y-10 md:flex-row ">
              <div className="w-80 h-80 flex">
                <img className="!w-full !h-full" src={props.data.images[0]} />
              </div>
              <div className="font-poppins flex flex-col gap-y-2 tracking-wide font-bold">
                <p className="opacity-50">{props.data.property_name}</p>
                <p className="text-primaryColor1">
                  {localCurrency(props.data.current_price)}
                </p>
                <p className="opacity-50 text-justify tracking-normal">
                  {props.data.description}
                </p>
                <div className="flex items-center gap-x-3 tracking-normal text-white [&>button]:px-5 [&>button]:py-3 [&>button]:rounded-lg">
                  <button
                    onClick={() => {
                      handleModal();
                      handleClosePicModal();
                    }}
                    className="bg-primaryColor1"
                  >
                    Confirm
                  </button>
                  <button
                    onClick={handleClosePicModal}
                    className="bg-red-600 font-medium"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalInvestItem>
      ) : null}

      {confirmModal ? (
        <ModalConfirmItem close={handleCloseConfirmModal}>
          <div className="flex items-center justify-center font-poppins p-4">
            <div className="flex flex-col gap-y-6 text-xs sm:text-lg">
              <h5 className="font-bold text-lg sm:text-2xl">
                Kindly Confirm Your Details
              </h5>
              <div className="flex flex-col gap-y-3 sm:gap-y-6">
                <p>
                  Full Name:
                  {` ${instate.first_name} ${instate.middle_name} ${instate.last_name}`}
                </p>
                <p>Property Name: {props.data.title}</p>
                <p>Amount: {localCurrency(props.data.current_price)}</p>
                <p>Quantity: {instate.quantity}</p>
                <p>Duration: {`${instate.duration} `} Years</p>
                <p>How often do you want your payout: {` ${selected}`} </p>
              </div>

              <div className="flex flex-col gap-y-3 sm:gap-y-6">
                <h5 className="text-base sm:text-xl font-bold">
                  Make your Payments into this Account
                </h5>
                <p className="">Landshop Limited</p>
                <p className="text-primaryColor1 font-bold text-lg sm:text-2xl">
                  0070474359
                </p>
                <p className="">Sterling Bank Plc</p>
                <p className="">
                  We would contact you shortly for further information
                </p>
              </div>
              <div className="flex items-center justify-center flex-col lg:flex-row gap-x-2">
                <button
                  onClick={closeModalHandler}
                  className="px-7 py-3 bg-orange-10 rounded-lg text-white font-bold"
                >
                  Done
                </button>

                <button
                  onClick={handleExternalCheckout}
                  disabled={loading}
                  className="px-7 py-3 bg-primaryColor1 rounded-lg text-white font-bold"
                >
                  Pay With Fluterwave
                </button>
              </div>
            </div>
          </div>
        </ModalConfirmItem>
      ) : (
        ""
      )}
    </>
  );
};
