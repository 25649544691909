import React from "react";
import TandC2 from "../components/Grow/TandC2";

const TermsandCondition2 = () => {
  return (
    <div>
      <TandC2 />
    </div>
  );
};

export default TermsandCondition2;
