import axios from "axios";
import React, { useEffect, useState } from "react";
import logo from "../../assets/beta_logo.png";
import { GrowGenType } from "../../typings";
import { API } from "../../utils/backend";
import GrowCard from "./GrowCard";
import GrowForm from "./GrowForm";

export const GrowBeta = () => {
  const [grow, setGrow] = useState<GrowGenType[]>([]);
  const [moreInfo, setMoreInfo] = useState<GrowGenType | null>(null);

  const getGrows = async () => {
    try {
      const growResponse = await axios.get(`${API}grows?mortgage_type=beta`);
      setGrow(growResponse?.data?.data?.grows?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGrows();
  }, []);

  const handleCloseModal = () => {
    setMoreInfo(null);
  };
  const handleOpenModal = (item: GrowGenType) => {
    setMoreInfo(item);
  };

  return (
    <>
      <GrowCard
        img={logo}
        moreinfo={[
          "This na the ogbonge property plan for we people wey wan own house, but no get plenty savings to build at once. With Beta Mortgage, Landshop specially collabo with Living Trust Mortgage Bank to support the average man and woman with mortgage loans to GROW dem PROPERTY.",
          "Once you qualify, Landshop + Living Trust Mortgage Bank go loan you 70% of the money to GROW the house you want. Only thing be say, you go pay shikini 2.5% of your monthly earnings, and deposit 30% of the whole amount of the property upfront within 1 – 3 months, for your Living Trust Mortgage Bank account. Then you go come repay the rest of the 70% you borrow at an interest rate of 6% every year for the next 30 years.",
        ]}
        subtitle="Own a Home with 30% Down Payment"
        title="beta"
        data={grow}
        cardBtnClick={handleOpenModal}
      />
      <GrowForm data={moreInfo} handleCloseModal={handleCloseModal} />
    </>
  );
};
