import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeftOnRectangleIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/solid";
import Avatar from "../Avatar";
import { useAppDispatch, useAppSelector } from "../../store";
import routes from "../../common/routes";
import { logUserOut } from "../../store/auth/thunk";

const navItems = [
  {
    name: "Dashboard",
    link: routes.dashboard.index,
    children: [
      { name: "Grow Products", link: routes.dashboard.grow },
      { name: "Invest Products", link: routes.dashboard.invest },
      { name: "Develop", link: routes.dashboard.develop },
    ],
    icon: <RectangleGroupIcon />,
  },
  // {
  //   name: "Inbox",
  //   link: routes.inbox,
  //   icon: <InboxArrowDownIcon />,
  // },
  // {
  //   name: "Terms and Condition",
  //   link: routes.terms,
  //   icon: <NewspaperIcon />,
  // },
  // {
  //   name: "Notification",
  //   link: routes.notification,
  //   icon: <BellIcon />,
  // },
  {
    name: "Log Out",
    icon: <ArrowLeftOnRectangleIcon />,
  },
];

const messageList: any[] = [
  {
    name: "Seun From Landshop",
    isOnline: true,
  },
  {
    name: "Customer Care",
    isOnline: false,
  },
  {
    name: "Pisco Thrift",
    isOnline: true,
  },
];

function SideNav() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const first_name = user?.first_name;
  const occupation = user?.occupation;

  const profileClickHandler = () => {
    if (pathname === routes.profile) return;
    navigate(routes.profile);
  };
  const logoutHandler = () => {
    document.body.style.cursor = "progress";
    dispatch(logUserOut())
      .unwrap()
      .then((res) => {
        navigate(routes.nonprotected.login);
      })
      .finally(() => {
        document.body.style.cursor = "default";
      });
  };

  // const messageHandler = () => {
  //   navigate(routes.inbox);
  // };

  return (
    <div className="mt-20 lg:bg-green-10/80 px-6 pb-12 pt-16 rounded-[1.25rem]">
      <button
        className="flex items-center gap-x-3"
        onClick={profileClickHandler}
      >
        <Avatar className="w-12 h-12" />
        <div className="font-medium">
          {occupation ? (
            <span className="lg:text-white/30 text-black  text-xs uppercase block text-left">
              {occupation}
            </span>
          ) : null}
          {first_name ? (
            <span className="text-black lg:text-white text-sm block text-left">
              {first_name}
            </span>
          ) : null}
        </div>
      </button>
      <div className="flex flex-col gap-y-8 max-h-[26rem] overflow-y-scroll no-scrollbar mt-8">
        <ul className="text-black lg:text-white ">
          <p className="text-xs uppercase lg:text-white/30 text-black  pl-5">
            Main
          </p>
          {navItems.map((itms) => {
            if (itms.link) {
              if (itms.children) {
                return (
                  <li key={itms.name} className="w-full">
                    <NavLink
                      to={itms.link}
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center gap-x-4 py-4 px-5 bg-white/5 rounded-xl"
                          : "flex items-center gap-x-4 py-4 px-5 hover:bg-white/5 hover:rounded-xl"
                      }
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="w-6 h-6">{itms.icon}</span>
                      <span className="flex-1 text-sm font-medium  ">
                        {itms.name}
                      </span>
                    </NavLink>
                    <ul className="pl-12 block relative before:absolute before:w-[1px] before:h-16 before:left-[2.125rem] before:top-0 lg:before:bg-white before:bg-black before:opacity-40 cursor-pointer mt-1">
                      {itms.children.map((child) => (
                        <li
                          key={child.name}
                          className="text-xs py-1.5 relative before:absolute before:w-3.5 before:h-5 before:-left-3.5 before:-top-1 before:rounded-bl-full before:border before:border-r-transparent before:border-t-transparent lg:before:border-white/40 before:border-black/40"
                        >
                          <NavLink
                            to={child.link}
                            className={({ isActive }) =>
                              isActive
                                ? "bg-white/5 rounded-xl px-3.5 py-2 lg:text-white text-black"
                                : "px-3.5 py-2 hover:bg-white/5 hover:rounded-xl lg:text-white/40 text-black/40"
                            }
                          >
                            <span className="flex-1 text-sm font-medium  ">
                              {child.name}
                            </span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              }
              // return (
              //   <li key={itms.name} className="w-full">
              //     <NavLink
              //       className={({ isActive }) =>
              //         isActive
              //           ? "flex items-center gap-x-4 py-4 px-5 bg-white/5 rounded-xl"
              //           : "flex items-center gap-x-4 py-4 px-5 hover:bg-white/5 hover:rounded-xl"
              //       }
              //       to={itms.link}
              //     >
              //       <span className="w-6 h-6">{itms.icon}</span>
              //       <span className="flex-1 text-sm font-medium    ">
              //         {itms.name}
              //       </span>
              //     </NavLink>
              //   </li>
              // );
            }
            return (
              <li key={itms.name} className="w-full">
                <button
                  className="flex items-center gap-x-4 py-4 px-5 w-full text-left hover:bg-white/5 hover:rounded-xl"
                  onClick={logoutHandler}
                >
                  <span className="w-6 h-6">{itms.icon}</span>
                  <span className="flex-1 text-sm font-medium  ">
                    {itms.name}
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
        {/* <ul>
          <p className="text-xs uppercase lg:text-white/30 text-black  pl-5">messages</p>

          <ul>
            {messageList.map((itm) => (
              <li key={itm.name} className="w-full">
                <button
                  className="flex items-center gap-x-4 py-4 px-5 w-full text-left"
                  onClick={messageHandler}
                >
                  <div
                    className={`bg-red-700 w-6 h-6 rounded-full relative before:absolute before:w-1.5 before:h-1.5 before:bottom-0 before:right-0 before:rounded-full ${
                      itm.isOnline
                        ? "before:bg-green-20"
                        : "before:bg-[#250D0E] before:border before:border-[#9F9595]"
                    }`}
                  />
                  <span className="flex-1 text-sm font-medium  text-white/60">
                    {itm.name}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </ul> */}
      </div>
    </div>
  );
}

export default SideNav;
