import { useEffect, useState } from "react";
import { XMarkIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { DropDownState, ToogleState } from "../typings";
import { motion } from "framer-motion";
import menuIcon from "../assets/MenuIcon.png";
import { fetchLocalData } from "../common/service/storage";
import routes from "../common/routes";
import { FaUserCircle } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchUserProfile } from "../store/auth/thunk";

export default function Nav() {
	// create a state for the navigation bar
	const [isToggle, setIsToggle] = useState<ToogleState>(false);

	const [dropDown, setDropDown] = useState<DropDownState>(false);

	const [bookDrop, setBookDrop] = useState<DropDownState>(false);
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.auth?.user);
	const { access_token } = fetchLocalData();
	const isUser = access_token || user?._id;

	const navigate = useNavigate();

	useEffect(() => {
		if (access_token && !user?._id) {
			dispatch(fetchUserProfile());
		}
	}, []); //eslint-disable-line

	//create an Onclick function to mobile view
	const switchNavigation = () => {
		setIsToggle(true);
	};

	//create an Onclick function to desktop view
	const switchToMain = () => {
		setIsToggle(false);
	};

	const enableDropDown = () => {
		setDropDown(!dropDown);
	};

	const loginFunc = () => {
		navigate(routes.nonprotected.login);
	};

	//eslint-disable-next-line
	const enableBookDown = () => {
		setBookDrop(!bookDrop);
	};
	return (
		<div className="relative z-10">
			<div className="flex items-center lg:justify-around justify-between px-4 lg:px-0  mx-auto bg-white w-screen h-24 shadow-lg">
				<div className="">
					<Link to={"/"}>
						<img src={logo} alt="logo" className="w-[80px] h-[45px]" />
					</Link>
				</div>
				<div className="hidden xl:gap-x-[2rem] lg:flex lg:gap-x-[1.1rem] ">
					<Link to="/">
						<span className="hover:text-primaryColor1 font-urbanist cursor-pointer">
							Home
						</span>
					</Link>
					<div className="relative">
						<span className="lg:hover:text-primaryColor1 font-urbanist cursor-pointer flex items-center justify-center gap-x-3">
							Projects
							<ChevronDownIcon
								className="w-4 h-4 text-black text-bold"
								onClick={enableDropDown}
							/>
						</span>

						{dropDown && (
							<div className="bg-white w-[150px] h-[260px] p-2 z-10 shadow-lg absolute top-11 flex flex-col items-center justify-center gap-y-1">
								<Link to="/grow">
									<li
										className="text-primaryColor1 font-urbanist font-bold list-none 
							  hover:cursor-pointer hover:bg-primaryColor1 p-3 w-[150px] hover:text-white"
									>
										Grow
									</li>
								</Link>
								<Link to="/invest">
									<li
										className="text-primaryColor1 font-urbanist font-bold list-none
							   hover:cursor-pointer hover:bg-primaryColor1 p-3 w-[150px] hover:text-white"
									>
										Incubate
									</li>
								</Link>
								<Link to="/develop">
									<li
										className="text-primaryColor1 font-urbanist font-bold list-none
							   hover:cursor-pointer hover:bg-primaryColor1 p-3 w-[150px] hover:text-white"
									>
										Develop
									</li>
								</Link>
								<Link to="/devpro">
									<li
										className="text-primaryColor1 font-urbanist font-bold list-none
							   hover:cursor-pointer hover:bg-primaryColor1 p-3 w-[150px] hover:text-white"
									>
										DevPro
									</li>
								</Link>
							</div>
						)}
					</div>

					{/* <Link to="/tribe"> */}
					{/* <Link to="#"> */}
					{/* <span className="hover:text-primaryColor1 font-urbanist cursor-pointer"> */}
					{/* Join Our Tribe */}
					{/* </span> */}
					{/* </Link> */}
					<a className="hover:text-primaryColor1 font-urbanist cursor-pointer" href="https://portal.coralrealtorsnetwork.com/320" target="_blank" rel="noopener"><span >
						Join Our Tribe
					</span></a>
					<Link to="/about">
						<span className="hover:text-primaryColor1 font-urbanistcursor-pointer">
							About
						</span>
					</Link>
					<Link to="/contact">
						{/* <Link to="#"> */}
						<span className="hover:text-primaryColor1 font-urbanist cursor-pointer">
							Contact Us
						</span>
					</Link>
					{/* <Link to = "/blog"> */}
					<Link to="/blog">
						<span className="hover:text-primaryColor1 font-urbanistcursor-pointer">
							Blog
						</span>
					</Link>
					<Link to="/thift">
						{/* <Link to="#"> */}
						<span className="hover:text-primaryColor1 font-urbanistcursor-pointer">
							Thrift
						</span>
					</Link>

					<Link to="/faq">
						<span className="hover:text-primaryColor1 font-urbanist cursor-pointer">
							FAQ
						</span>
					</Link>
				</div>
				{!isUser ? (
					<div className="hidden  space-x-6 lg:inline-flex lg:space-x-1">
						<button
							className="bg-primaryColor1  transition-all duration-300 ease-in-out text-white px-6 py-2 rounded-lg hover:bg-transparent hover:border hover:border-primaryColor1  hover:text-primaryColor1"
							onClick={loginFunc}
						>
							Sign In
						</button>

						{/* Other Buttons */}
						<div className="relative">
							<button className="bg-primaryColor2  transition-all duration-300 ease-in-out text-white px-7 py-2 rounded-lg hover:bg-transparent hover:border hover:border-primaryColor2  hover:text-primaryColor2 flex items-center justify-center gap-x-4 lg:px-1 lg:py-3  lg:text-sm xl:px-3 ">
								Book Inspection
								<ChevronDownIcon
									className="w-5 h-5 text-bold"
									// onClick = {enableBookDown}
								/>
								{bookDrop && (
									<div
										className="bg-white w-[150px] h-[100px] p-7 z-10 shadow-lg absolute top-13 flex flex-col items-center justify-center gap-y-3
							lg:bg-white lg:w-[150px] lg:h-[100px] lg:p-7 lg:z-10 lg:shadow-lg  lg:absolute lg:top-14 lg:flex lg:flex-col lg:items-center lg:ustify-center lg:gap-y-3
							
							"
									>
										<li
											className="text-primaryColor1 font-urbanist font-bold list-none 
							  :cursor-pointer hover:bg-primaryColor1 p-3 w-[150px] hover:text-white"
										>
											Physical
										</li>
										<li
											className="text-primaryColor1 font-urbanist font-bold list-none
							   hover:cursor-pointer hover:bg-primaryColor1 p-3 w-[150px] hover:text-white"
										>
											Virtual
										</li>
									</div>
								)}
							</button>
						</div>
						{/* <div>
            <CartItem />
          </div> */}
					</div>
				) : (
					<Link to={routes.profile} className="text-xl hidden lg:block">
						<FaUserCircle />
					</Link>
				)}

				<div className="lg:hidden">
					<img
						className="h-8 w-8 mx-auto text-primaryColor1 cursor-pointer"
						onClick={switchNavigation}
						src={menuIcon}
						alt=""
					/>
				</div>
			</div>

			{/* Navigation for smaller screens */}
			{isToggle && (
				<motion.div
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: 1,
					}}
					transition={{
						duration: 0.3,
					}}
					className=" top-0 right-0 z-10 fixed bg-white shadow-lg
		 w-screen h-screen transition-all duration-700 ease-in-out 
		 flex flex-col items-center justify-center
		 lg:hidden "
				>
					<div className="absolute top-0 right-0 p-5">
						<XMarkIcon
							className="h-12 w-12 text-black cursor-pointer"
							onClick={switchToMain}
						/>
					</div>

					<div className="flex flex-col gap-y-[1.4rem] items-center justify-center">
						<Link to="/">
							<span className="text-black hover:text-primaryColor2 text-2xl font-urbanist  cursor-pointer">
								Home
							</span>
						</Link>
						<Link to="/projects">
							{/*{" "}
            <Link to="#"> */}
							<span className="text-black hover:text-primaryColor2 text-2xl  font-urbanist  cursor-pointer">
								Projects
							</span>
						</Link>
						<Link to="/tribe">
							<span className="text-black hover:text-primaryColor2 text-2xl font-urbanist cursor-pointer">
								Join Our Tribe
							</span>
						</Link>
						<Link to="/about">
							<span className="text-black hover:text-primaryColor2 text-2xl font-urbanist cursor-pointer">
								About
							</span>
						</Link>
						<Link to="/contact">
							<span className="text-black hover:text-primaryColor2 text-2xl font-urbanist cursor-pointer">
								Contact
							</span>
						</Link>
						{/* <Link to="/tribe"> */} {/* <Link to="/blog"> */}{" "}
						<Link to="#">
							<span className="text-black hover:text-primaryColor2 text-2xl font-urbanist cursor-pointer">
								Blog
							</span>
						</Link>
						{/* <Link to="/thift"> */}
						<Link to="/thift">
							<span className="text-black hover:text-primaryColor2 text-2xl font-urbanist cursor-pointer">
								Thrift
							</span>
						</Link>
						{/* <Link to="/contact"> */}
						<Link to="/faq">
							<span className="text-black hover:text-primaryColor2 text-2xl font-urbanist cursor-pointer">
								FAQ
							</span>
						</Link>
						{isUser ? (
							<Link to={routes.profile} className="text-xl block">
								<FaUserCircle />
							</Link>
						) : (
							<>
								<div>
									<button
										className="bg-primaryColor1 border border-white font-bold  transition-all duration-300 ease-in-out text-white px-6 py-2 rounded-lg"
										onClick={loginFunc}
									>
										Sign In
									</button>
								</div>
								<div>
									<button className="bg-primaryColor2 border border-white font-bold  transition-all duration-300 ease-in-out text-white px-6 py-2 rounded-lg">
										Book Inspection
									</button>
								</div>
							</>
						)}
					</div>
				</motion.div>
			)}
		</div>
	);
}
