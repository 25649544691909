import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export const tail = (...className: clsx.ClassValue[]) => {
  return twMerge(clsx(...className));
};

export const validationRules = {
  string: /(.|\s)*\S(.|\s)*/,
  email:
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  phone: /^\+?[1-9][0-9]{7,14}$/,
  number: /^\d+$/,
  website:
    /^http:\/\/localhost|https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, //eslint-disable-line
  ip: /\b(?:\d{1,3}\.){3}\d{1,3}\b/,
};

export const truncatedText = (text: string, maxlength: number) => {
  if (text.length > maxlength) {
    return `${text.substring(0, 10)}...${text.substring(text.length - 4)}`;
  }

  return text;
};

export const localCurrency = (amount: number) => {
  const price = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  return price.format(amount);
};

export const formatDate = (date: string) => {
  let d = new Date(date);
  return d.toString();
}
