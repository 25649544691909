import React from 'react'
import parse from 'html-react-parser';
import recent1 from "../assets/recent1.png";
import { formatDate } from '../common/utils/helper';



type Props = {
	new: Post
}
interface Post {
	title: { rendered: string }
	content: { rendered: string }
	rttpg_author: { display_name: string }
	excerpt: { rendered: string }
	date: string,
	link: string,
	rttpg_featured_image_url: string
}

const RecentEach = (props: Props) => {


	return (
		<div className='w-[360px] h-[450px] bg-white rounded-md shadow-2xl flex flex-col items-center justify-start gap-y-[1rem]'>
			<div className='w-[360px] h-[auto] rounded-md'>
				<img src={props.new.rttpg_featured_image_url ? props.new.rttpg_featured_image_url : recent1} alt="" />
			</div>
			<div className='flex flex-col items-start justify-start w-[336px] h-[auto] gap-y-1'>

				<div className=''>
					<a href={props.new.link} target='_blank'><h5 className='text-lg font-bold font-poppins'>{parse(props.new.title.rendered)}</h5></a>
				</div>
				<div className=''>
					<h6 className='text-xs font-poppins text-gray-500'>By {" "} {props.new.rttpg_author.display_name} </h6>
				</div>
			</div>
			<div className='w-[336px] h-[100px] overflow-auto'>
				<h3 className='font-poppins'>
					{parse(props.new.excerpt.rendered)}
				</h3>
			</div>
			<div className=''>
				<h3 className='font-poppins text-[#616161]'>
					{formatDate(props.new.date).slice(0, 15)}
				</h3>
			</div>


		</div>
	)
}

export default RecentEach