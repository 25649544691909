import axios from "axios";
import { useEffect, useState } from "react";
import { InvestHouseData1 } from "../data";
import { InvestGenType, loadType } from "../typings";
import { API } from "../utils/backend";
import { InvestEachHouse } from "./InvestEachHouse";
import { InvestHouseTwo } from "./InvestHouseTwo";
import { PropagateLoader } from "react-spinners";
import CustomButton from "./CustomButton";

const initialPageData = {
  hasNextPage: false,
  hasPreviousPage: false,
  itemCount: 0,
  page: 1,
  pageCount: 0,
};

export const InvestHouseCards = () => {
  // const [cartItems, setCartItems] = useState<any>([]);
  const [cardsData, setCardsData] = useState<any[]>([]);
  const [showMore, setShowMore] = useState(initialPageData);
  const [loading, setLoading] = useState({ initial: false, sub: false });

  const getInvest = async (page: number, type: loadType) => {
    setLoading((prev) => ({ ...prev, [type]: true }));
    try {
      const InvestResponse: InvestGenType = await axios.get(
        `${API}invests?property_type=house&page=${page}&limit=20`
      );

      if (type === "initial") {
        setCardsData(InvestResponse?.data.data.invests.data);
      } else {
        setCardsData((prev) => [
          ...prev,
          ...InvestResponse?.data.data.invests.data,
        ]);
      }
      setShowMore(InvestResponse.data.data.invests.meta);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, [type]: false }));
    }
  };
  useEffect(() => {
    getInvest(showMore.page, "initial");
  }, []);

  const showMoreHandler = () => {
    getInvest(showMore.page + 1, "sub");
  };

  // const [invest, setInvest] = useState<InvestGenType>();
  return (
    //   <div className=''>
    //       <div className='
    //         lg:grid lg:grid-cols-3 lg:gap-x-[20rem] lg:gap-y-3 lg:overflow-auto
    //         xl:grid xl:grid-cols-3 xl:gap-x-[10rem] xl:gap-y-3 xl:overflow-auto
    //         md:grid  md:grid-cols-3 md:gap-x-[22rem] md:gap-y-3 md:overflow-auto
    //         sm:grid  sm:grid-cols-3 sm:gap-x-[24rem] sm:gap-y-3 sm:overflow-auto
    //         xs:grid  xs:grid-cols-3 xs:gap-x-[24rem] xs:gap-y-3 xs:overflow-auto

    //       ' >
    //             {InvestHouseData1?.map(house => (
    //                 <InvestEachHouse  house={house}/>
    //             ))}

    //       </div>
    //       <div>
    //           <InvestHouseTwo />
    //       </div>

    //  </div>
    <div className="">
      <div>
        <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 h-96 overflow-y-scroll lg:h-auto lg:overflow-y-auto">
          {/* {invest?.data.data.invests.data.filter(x => x.property_type === "land").map((land, index) => (
          <InvestEachLand key={index} data={land} />
        ))} */}
          {loading.initial ? (
            <div className="flex items-center justify-center col-span-full">
              <PropagateLoader color="#17B28D" size={30} />
            </div>
          ) : cardsData.length === 0 ? (
            <p className="text-center col-span-full">* No Property Available</p>
          ) : (
            cardsData.map((land, index) => (
              <InvestEachHouse key={index} data={land} />
            ))
          )}

          {/* {invest?.data.data.invests.data.map((land, index) => (
          <InvestEachLand key={index} data={land} />
        ))} */}
        </div>
        {showMore.hasNextPage ? (
          <div className="flex  w-[100%] items-center justify-center mt-9">
            <CustomButton
              onClick={showMoreHandler}
              className="InvestSeeMore"
              isloading={loading.sub}
            >
              See More
            </CustomButton>
          </div>
        ) : null}
      </div>
      {/* <div>
    <InvestHouseTwo />
</div> */}
    </div>
  );
};
