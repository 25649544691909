const ENDPOINTS = {
  login: {
    method: "POST",
    url: "/auth/login",
  },
  register: {
    method: "POST",
    url: "/auth/register",
  },
  update_profile: {
    method: "PUT",
    url: "/auth/profile",
  },
  fetch_profile: {
    method: "GET",
    url: "/auth/profile",
  },
  logout: {
    method: "GET",
    url: "/auth/logout",
  },
  update_password: {
    method: "PUT",
    url: "/auth/update-password",
  },
  forgot_password: {
    method: "POST",
    url: "/auth/forgot-password",
  },
  reset_password: {
    method: "POST",
    url: "/auth/reset-password",
  },
  upload_files: {
    method: "POST",
    url: "/upload/files",
  },
  fetch_user_grow_properties: {
    method: "GET",
    url: (uid: string) => `/grows/purchase?user_id=${uid}`,
  },
  fetch_user_develop_properties: {
    method: "GET",
    url: (uid: string) => `/develops/purchase?user_id=${uid}`,
  },
  fetch_user_invest_properties: {
    method: "GET",
    url: (uid: string) => `/invests/purchase?user_id=${uid}`,
  },
  purchase_ref_grow_properties: {
    method: "POST",
    url: (id: string) => `/grows/purchase/${id}`,
  },
  purchase_ref_develop_properties: {
    method: "POST",
    url: (id: string) => `/develops/purchase/${id}`,
  },
  purchase_ref_invest_properties: {
    method: "POST",
    url: (id: string) => `/invests/purchase/${id}`,
  },
};

export default ENDPOINTS;
