// GET POST PUT DELETE PATCH
export const ENDPOINTS = {
  login: {
    method: "POST",
    url: "/auth/login",
  },
  register: {
    method: "POST",
    url: "/auth/register",
  },
  update_profile: {
    method: "PUT",
    url: "/auth/profile",
  },
  fetch_profile: {
    method: "GET",
    url: "/auth/profile",
  },
  logout: {
    method: "GET",
    url: "/auth/logout",
  },
  update_password: {
    method: "PUT",
    url: "/auth/update-password",
  },
  forgot_password: {
    method: "POST",
    url: "/auth/forgot-password",
  },
  reset_password: {
    method: "POST",
    url: "/auth/reset-password",
  },
};
