import React from "react";
import { FaUmbrellaBeach } from "react-icons/fa";
import {
  GiBiohazard,
  GiCctvCamera,
  GiElectricalResistance,
  GiGate,
  GiRoad,
  GiSoccerKick,
} from "react-icons/gi";

type Props = {
  type: string;
};

function Amenities({ type }: Props) {
  let template: JSX.Element | null = null;
  switch (true) {
    case type.toLowerCase().includes("beach"):
      template = <FaUmbrellaBeach />;
      break;
    case type.toLowerCase().includes("electricity"):
      template = <GiElectricalResistance />;
      break;
    case type.toLowerCase().includes("gate"):
      template = <GiGate />;
      break;
    case type.toLowerCase().includes("camera"):
    case type.toLowerCase().includes("cctv"):
      template = <GiCctvCamera />;
      break;
    case type.toLowerCase().includes("recreational"):
      template = <GiSoccerKick />;
      break;
    case type.toLowerCase().includes("road"):
      template = <GiRoad />;
      break;
    case type.toLowerCase().includes("sewage"):
      template = <GiBiohazard />;
      break;

    default:
      break;
  }

  return <>{template}</>;
}

export default Amenities;
