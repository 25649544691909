import Nav from "../components/Nav";
import landshopAboutLogo from "../assets/landshopAboutLogo.png";
import landshopAboutFaceBook from "../assets/landshopAboutFaceBookWhite.png";
import landshopAboutTwitter from "../assets/landshopAboutTwitterWhite.png";
import landshopAboutYoutube from "../assets/landshopAboutYoutubeWhite.png";
import landshopAboutPinterest from "../assets/landshopAboutPinterestWhite.png";
import buttonCont from "../assets/buttonContact.png";
import location from "../assets/location.png";
import phone from "../assets/phone.png";
import envelop from "../assets/envelop.png";
import whatsapp from "../assets/whatsapp.png";
import { FooterBlack } from "../components/FooterBlack";
import { useState } from "react";
import { API } from "../utils/backend";
import axios from "axios";
import Swal from "sweetalert2";
import { PropagateLoader } from "react-spinners";

export default function Contact() {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);



  const submitContactForm = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      message: message
    }

    const response = await axios.post(`${API}contact-us`, data);
    if ([200, 201].includes(response.status)) {
      Swal.fire({
        icon: "success",
        title: "Successful",
      });
      setFirstName("")
      setLastName("")
      setEmail("")
      setPhoneNumber("")
      setMessage("")

    }
    else {
      Swal.fire({
        icon: "error",
        title: "Form submission failed",
      });
    }
    setLoading(false)
  }
  return (
    <div className="h-auto">
      <Nav />

      {/* Banner */}
      <div
        className=" 
		
		 w-full bg-bgContact bg-no-repeat flex flex-col items-center justify-center relative bg-cover md:bg-contain bg-top
		"
      >
        <div
          className="
			        lg:w-full lg:pt-80  lg:gap-y-7
					  sm:gap-y-8 
					flex flex-col items-center justify-center gap-y-8 pt-10
					
					"
        >
          <div className="lg:self-start lg:max-w-[1000px] lg:mx-auto text-center  lg:text-left">
            <span className="lg:font-bold text-primaryColor1 text-5xl font-urbanist text-center">
              Get In Touch
            </span>
          </div>
          <div className="lg:self-center mx-auto p-5 pl-2 lg:max-w-[1000px] text-center  lg:text-left">
            <span className="text-white text-left text-base font-poppins">
              Interested in learning more about Landshop and its properties?
              <br /> Feel free to reach out to us anytime
            </span>
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center  pt-14 ">
          <div
            className="
lg:flex-row lg:h-[550px]
            lg:mt-8 lg:shadow-2xl lg:rounded-xl lg:w-[1000px] lg:mx-auto 
		  
sm:mt-[15rem] md:mt-[0]

            flex flex-col-reverse bg-white  items-center justify-center
            mt-8 rounded-sm w-full
		
		 
		 
		 "
          >
            <div
              className="
      xl:pl-[5rem]
			lg:w-[60%] lg:gap-y-5 lg:pt-[-2rem]
			flex flex-col items-center justify-center py-10 pl-[0.3rem] 
			
			"
            ><form>
                {/* form */}
                <div className="xl:self-center xl:ml-[-5rem] lg:self-center lg:ml-[-5rem] sm:self-center ml-[0rem] md:self-center md:ml-[0rem] lg:pt-3">
                  <span
                    className="
lg:ml-[-15.5rem] lg:mt-[-2rem] 
					  text-gray-500  mt-2 font-manrope text-2xl text-center
					sm:block hidden
					"
                  >
                    Send us a message
                  </span>
                </div>
                <div
                  className="
				  
				  lg:flex-row lg:items-center lg:gap-x-3 lg:mt-2
				  md:flex-row md:items-center md:gap-x-3 md:mt-5
				  sm:flex sm:flex-col sm:items-center sm:gap-x-3 sm:mt-5 
				  
				  "
                >



                  <div
                    className="flex flex-col gap-y-5
					"
                  >
                    <div>
                      <label className="text-gray-500 font-normal text-xs font-poppins after:content-['_*'] after:text-red-600">
                        First Name
                      </label>
                    </div>
                    <div>
                      <input
                        required
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="
								lg:border-primaryColor2 lg:border-l-0 lg:border-t-0 lg:border-r-0  lg:outline-none lg:bg-transparent lg:w-[14rem] lg:focus:ring-0
								md:border-primaryColor2 md:border-l-0 md:border-t-0 md:border-r-0  md:outline-none md:bg-transparent md:focus:ring-0
								sm:border-primaryColor2 sm:border-t-0 sm:border-r-0 sm:border-l-0  sm:outline-none sm:bg-transparent sm:w-[17rem] sm:focus:ring-0
							
							"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-5">
                    <div>

                      <label className="text-gray-500 font-normal font-poppins text-xs after:content-['_*'] after:text-red-600">
                        Last Name
                      </label>
                    </div>
                    <div>
                      <input
                        required
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}

                        className="
                      
									lg:border-primaryColor2 lg:border-l-0 lg:border-t-0 lg:border-r-0  lg:outline-none lg:bg-transparent lg:w-[14rem] lg:focus:ring-0
									md:border-primaryColor2 md:border-l-0 md:border-t-0 md:border-r-0  md:outline-none md:bg-transparent md:focus:ring-0
									sm:border-primaryColor2 sm:border-t-0 sm:border-r-0 sm:border-l-0  sm:outline-none sm:bg-transparent sm:w-[17rem] sm:focus:ring-0
							
							"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className=" 
				   lg:flex-row lg:items-center lg:gap-x-3 lg:mt-5
				   md:flex-row md:items-center md:gap-x-3 md:mt-5
				   sm:flex sm:flex-col sm:items-center sm:gap-x-3 sm:mt-5
				  
				  "
                >
                  <div className="flex flex-col gap-y-5">
                    <div>
                      <label className="text-gray-500 font-normal font-poppins text-xs after:content-['_*'] after:text-red-600">
                        Email
                      </label>
                    </div>
                    <div>
                      <input
                        type="email"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}

                        className="
									lg:border-primaryColor2 lg:border-l-0 lg:border-t-0 lg:border-r-0  lg:outline-none lg:bg-transparent lg:w-[14rem] lg:focus:ring-0
									md:border-primaryColor2 md:border-l-0 md:border-t-0 md:border-r-0  md:outline-none md:bg-transparent md:focus:ring-0
									sm:border-primaryColor2 sm:border-t-0 sm:border-r-0 sm:border-l-0  sm:outline-none sm:bg-transparent sm:w-[17rem] sm:focus:ring-0
							
							"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-5">
                    <div>
                      <label className="text-gray-500 font-normal font-poppins text-xs after:content-['_*'] after:text-red-600">
                        Phone Number
                      </label>
                    </div>
                    <div>
                      <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}

                        required
                        className="
									lg:border-primaryColor2 lg:border-l-0 lg:border-t-0 lg:border-r-0  lg:outline-none lg:bg-transparent lg:w-[14rem] lg:focus:ring-0
									md:border-primaryColor2 md:border-l-0 md:border-t-0 md:border-r-0  md:outline-none md:bg-transparent md:focus:ring-0
									sm:border-primaryColor2 sm:border-t-0 sm:border-r-0 sm:border-l-0  sm:outline-none sm:bg-transparent sm:w-[17rem] sm:focus:ring-0
							
							
							"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className=" 
				  lg:flex-row lg:items-center lg:gap-x-[2.5rem]
				  sm:flex sm:flex-col sm:items-center sm:gap-y-5
				 
				 "
                >
                  <div className="flex flex-col gap-y-5">
                    <div>
                      <label className="text-gray-500 font-normal font-poppins text-xs after:content-['_*'] after:text-red-600">
                        Message
                      </label>
                    </div>
                    <div>
                      <textarea
                        value={message}
                        required
                        onChange={(e) => setMessage(e.target.value)}

                        className="
										lg:border-primaryColor2 lg:border-l-0 lg:border-t-0 lg:border-r-0  lg:outline-none lg:bg-transparent lg:w-[22rem] lg:focus:ring-0
										md:border-primaryColor2 md:border-l-0 md:border-t-0 md:border-r-0  md:outline-none md:bg-transparent md:focus:ring-0
										sm:border-primaryColor2 sm:border-t-0 sm:border-r-0 sm:border-l-0  sm:outline-none sm:bg-transparent sm:w-[17rem] sm:focus:ring-0
							
							
							"
                      />
                    </div>
                  </div>

                  <div>
                    {loading ?  (
                        <PropagateLoader color="#17B28D" size={30} />
                      ) : (
                        <div className="xs:text-center" onClick={submitContactForm}>
                      <button type="submit">
                        <img
                          className="xs:w-[75.64px]  xs:h-[71.64px] lg:w-[105.12px] lg:h-[105.16px]  xl:w-[105.12px] xl:h-[105.16px] cursor-pointer  "
                          src={buttonCont}
                          alt=""
                        />
                      </button>

                    </div>
                      )}
                    
                  </div>

                </div>

              </form>
            </div>



            <div
              className="
			lg:w-[40%] lg:h-[550px] lg:mr-0 lg:gap-y-10
      md:w-[70%]
			bg-white border border-primaryColor1 shadow-2xl  rounded-xl w-[90%] h-[auto] flex flex-col items-start justify-center gap-y-5 py-8 px-8
			"
            >
              <div className="">
                <span className="text-2xl font-urbanist ">
                  Contact Information
                </span>
              </div>

              <div className="flex items-center justify-center gap-x-5 ">
                <div>
                  <img src={envelop} alt="" />
                </div>
                <div>
                  <span className="text-sm font-normal font-poppins">
                    Suite 1. 3rd Floor, Kings Deck,
                    <br /> Alternative Route, Off Chevron Drive,
                    <br /> Lekki-Epe Expressway Lagos
                  </span>
                </div>
              </div>

              <div className="flex items-center justify-center gap-x-8 ">
                <div>
                  <img src={location} alt="" />
                </div>
                <div>
                  <span className="text-sm font-normal font-poppins">
                  +234 708 067 2040
                  </span>
                </div>
              </div>

              <div className="flex items-center justify-center gap-x-8 ">
                <div>
                  <img src={whatsapp} alt="" />
                </div>
                <div>
                  <span className="text-sm font-normal font-poppins">
                  +234 916 997 3355
                  </span>
                </div>
              </div>

              <div className="flex items-center justify-center gap-x-8 ">
                <div>
                  <img src={phone} alt="" />
                </div>
                <div>
                  <span className="text-sm font-thin font-manrope">
                    care@landshop.ng
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact */}

      {/* Footer */}
      <div className="">
        <FooterBlack />
      </div>
    </div>
  );
}
