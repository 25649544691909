import { BoardTeamProps } from '../typings'
import proper from '../assets/Vector_White.png'


export default function BoardCard( {brd  ,open , index, handleClick} : BoardTeamProps) {

	

  return (
	<div className='bg-white  w-[192px] h-[292px] rounded-lg shadow-lg hover:scale-105  flex flex-col items-center justify-center gap-y-5'>
		<div>
           <img src={brd.image} alt="" />
		</div>
		<div>
           <span className='font-bold text-sm font-urbanist'>{brd.name}</span>
		</div>
		<div>
           <span className='font-bold text-sm font-urbanist text-[#000000]'>{brd.position}</span>
		</div>
		<div>
		    <button className='bg-primaryColor1 font-urbanist flex items-center gap-x-4 text-white px-7 py-2 rounded-lg
			 hover:transition-all duration-300 ease-in-out  hover:border hover:border-primaryColor1 hover:bg-transparent hover:text-primaryColor1'
			
			//  onClick = {() =>open(index)}
			onClick = {() => handleClick(brd)}

			 >
			Learn More

			<img src={proper} alt="" />
			</button>

		</div>
        

	</div>
     

  )
}